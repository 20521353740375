import React, { useState, useEffect } from "react"
import Spinner from 'react-bootstrap/Spinner';
import {
  Container,
  Row,
  Col,
  //Table,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Form,
  FormGroup,
  Label,
  CardBody,
  CardTitle,
  //CardSubtitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback, Table,
  UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,Alert
} from "reactstrap"
//import Select from "react-select"
import ExchangeForm from "../Settings/ExchangeForm"
import { Link,useLocation } from "react-router-dom"
import TradingView from '../TradingView/TradingView'
import classnames from "classnames"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useForm } from "react-hook-form";
import api from '../../../helpers/api_helper';
import { toast } from "react-toastify";
import firebase from "firebase/compat/app";
import CryptoJS from "crypto-js";
import Switch from "react-switch";
// import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import deltalogo from "../../../assets/images/delta.png"
import binancelogo from "../../../assets/images/binance.png"
const { default: axios } = require("axios");
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select"
import Slider from "react-rangeslider"
import { getUserExchangePairs,getAllUserExchangePairs,getCCXTAllExchanges, getCCXTExchangeMarkets,getCCXTUserBalance, getCCXTUserExchangePairs, getCCXTUserExchanges, updateCCXTExchangeConfig, updateCCXTExchangePair, updateCCXTExchangeStatus,updateExchangeSl, deleteUserExchange } from "helpers/ccxt_helper"
import { getExchangeName, trendFormValidation ,formatUTCDate ,getSettingsDateTimeFormat } from "../../../services/common.service";
// import ToolTipData from '../../Settings/Settings.json'
import { MinimumTakeProfit,TaillingTakeProfit,CredentialsSetupMSG } from "../../../helpers/help-msg";
import parse from 'html-react-parser';
import StackedColumnChart from "../Chart/StackedColumnChart"
import binance from '../../../assets/images/exchanges/binance.png'
import delta from '../../../assets/images/exchanges/delta.png'
import { async } from "q"
const Settings = () => {
  document.title = "Spot Trade - Settings";
  firebase.auth();
  const firestoreDb = firebase.firestore();
  const currentUser = JSON.parse(localStorage.getItem('user'));
  let uid = currentUser.uid;
   //tooltip
   const apiUrl = `${process.env.REACT_APP_API_URL}/static/helptexts.json`;
   const [ToolTipData, setToolTipData] = useState([]);
   useEffect(()=>{
     try{
       const gettoottipData = async () => {
       const response = await axios.get(`${process.env.REACT_APP_API_URL}/static/helptexts.json`)
        setToolTipData(JSON.parse(JSON.stringify(response.data)))
         }
       gettoottipData()
       }catch(error){
           console.log(error)
       }
 
   },[apiUrl])
   //tooltip
  const [copiedSettings,setCopiedSettings]=useState('');
  const { register, handleSubmit, setValue, reset, control, formState: { errors } } = useForm();
  const { register: register3, handleSubmit: handleSubmit3, setValue: setValue3, control: control3, formState: { errors: errors3 } } = useForm();
  const { register: register4, handleSubmit: handleSubmit4, setValue: setValue4, control: control4, formState: { errors: errors4 } } = useForm();
  const [activeTab, setactiveTab] = useState('1');
  const [selectNewExchange, setSelectNewExchange] = useState('SelectExchange');
  const [visible, setVisible] = useState(false);
  const [addPairVisible, setAddPairVisible] = useState(false);
  const [pairExchange, setPairExchange] = useState('');
  const [settingsvisible, setSettingsvisible] = useState(false);
  const [TrendFollowSettings, setTrendFollowSettings] = useState(false);
  const [settingspair, setSettingspair] = useState('Trading Pair')
  const [Trendfollowpair, setTrendFollowpair] = useState('Trading Pair')
  const [pairVisible, setPairVisible] = useState(false);
  const [pairListModal, setPairListModal] = useState(false);
  const [editExchange, setEditExchange] = useState(false);
  const [keyStatus, setKeyStatus] = useState(true);
  const [formLoader1, setFormLoader1] = useState(false);
  const [formLoader6, setFormLoader6] = useState(false);
  const [formLoader2, setFormLoader2] = useState({});
  const [formLoader3, setFormLoader3] = useState(false);
  const [formLoader4, setFormLoader4] = useState(true);
  const [formLoader5, setFormLoader5] = useState({ loading: false });
  const [formLoader8, setFormLoader8] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [editExchangeValues, setEditExchangeValues] = useState({});
  const [editSettingsValues, setEditSettingsValues] = useState({});
  const [form6_loader, setForm6Loader] = useState({ loading: false });
  const [form7_loader, setForm7Loader] = useState({ loading: false });
  const [pairStatus, setPairStatus] = useState({});
  const [pairSettings, setPairSettings] = useState({});
  const [TrendFollowSettingspair, setTrendFollowSettingspair] = useState({});
  const [selectedPair, setSelectedPair] = useState([]);
  const [modalExchangePairs, setModalExchangePairs] = useState([]);
  const [pairUpdateButton, setPairUpdateButton] = useState([]);
  const [pairListLoading, setPairListLoading] = useState(true);
  const [separatePairs, setSeparatePairs] = useState([]);
  const [viewPair, setviewPair] = useState('SelectExchange');
  const [viewGraph,setviewGraph] = useState('SelectExchange');
  const [filterDate,setFilterDate] = useState("7");
  const [pairupdateLoading, setPairupdateLoading] = useState(false);
  const [isfetch,setisfetch] = useState(false);
  const [pairsfetch,setpairsfetch] = useState(0)
  const [visibleMin, setVisibleMin] = useState(false);
  const [visibleTailling, setVisibleTailling] = useState(false);
  const [MintakeProfitMsg,setMintakeProfitMsg] = useState();
  const [CredentialsSetupMsg,setCredentialsSetupMsg] = useState();
  const [TaillingTakeProfitMsg,setTaillingTakeProfitMsg] = useState();
  const [CredentialSetup,setCredentialSetup] = useState(false);
  const [TrendSettingsPopup,setTrendSettingsPopup] = useState(false);
  const [isDeleteconfirmpopup, setisDeleteconfirmpopup] = useState(false);
  const [formLoader7,setFormLoader7] = useState({});
  const [tradeStatus, setTradeStatus] = useState("2");
  const [trendSettings, setTrendSettings] = useState({
    trend_method: "sma",
    short_term: 9,
    long_term: 20,
    time_frame: "15m",
    st_time_frame: "15m",
    macd_time_frame: "15m",
    ema_time_frame: "15m",
    macd_long_term: 26,
    macd_short_term: 12,
    macd_signal_term: 9,
    ema_short_term: 9,
    ema_long_term: 21,
    st_atr_length: 10,
    st_factor: 2,
    trading_type:"short_term"
  })
  const [ExchangeUpdated,setExchangeUpdated] = useState(false)
  const [SelectedExchangeinPair,setSelectedExchangeinPair]=useState('')
  const [DefaultColorSetting,setDefaultColorSetting] = useState({})
  const [URLEXCHANGE, setURLEXCHANGE] = useState('');
  const [orderSettings, setOrderSettings] = useState({ stop_loss_type: "percentage" });
  const [pairOrderSettings, setPairOrderSettings] = useState({ stop_loss_type: "percentage" });
  const [indexPrices, setIndexPrices] = useState({});
  const [spotPairs, setSpotPairs] = useState([]);
  const [SelectedSpotPair,setSelectedSpotPair]=useState({});
  const [ccxtUserPairs, setCcxtUserPairs] = useState({});
  const [pairTrendFollow, setPairTrendFollow] = useState({ });
  const [ExchnageId,setExchnageId] = useState();
  const [PairId,setPairId] = useState();
  const [loader,setloader] = useState(false);
  const [balance_errors,setBalanceErrors] = useState({});
  const [orderIntervel, setOrderIntervel] = useState('');
  const [pasteSettingsModal,setPasteSettingsModal]=useState(false)
  const [pastePair,setPastePair]=useState({})
  const [exchangeRate,setExchangeRate]=useState([])
  const location = useLocation();
  const [topList,setTopList]=useState({})
  const [pairErrors, setPairErrors] = useState({
    exchange_error: '',
    pair_error: '',
  });
  const [totalPNL,setTotalPNL]=useState({total_gain:0,total_lose:0})
  
 
  useEffect(() => {
    const pathname = location.pathname;
    const search = location.search;
 
    if (search.includes("pairs")) {
      setactiveTab("4");
    } else if (pathname.includes("settings")) {
      setactiveTab("1");
    }
  }, [location]);
  const TrendFollowSettings1 = ({ trendSettingsInfo }) => {
    const trendSettings = JSON.parse(trendSettingsInfo.Trend_settings);
    // Helper function to format each setting
    const formatSetting = (label, value) => (
<span>
        {label}: <b className="text-info">{value}</b>
</span>
    );
    // Function to render settings based on the trend method
    const renderSettingsByMethod = (method) => {
      switch (method) {
        case 'sma':
          return [
            trendSettings.trend_method && formatSetting('Trend Method', 'SMA'),
            trendSettings.time_frame && formatSetting('Interval', trendSettings.time_frame),
            trendSettings.short_term && formatSetting('Short Length', trendSettings.short_term),
            trendSettings.long_term && formatSetting('Long Length', trendSettings.long_term)            
          ].filter(Boolean); // Filter out any undefined or null values
        case 'ema':
          return [
            trendSettings.trend_method && formatSetting('Trend Method', 'EMA'),
            trendSettings.ema_time_frame && formatSetting('Interval', trendSettings.ema_time_frame),
            trendSettings.ema_short_term && formatSetting('Short Length', trendSettings.ema_short_term),
            trendSettings.ema_long_term && formatSetting('Long Length', trendSettings.ema_long_term)           
          ].filter(Boolean); // Filter out any undefined or null values
        case 'macd':
          return [
            trendSettings.trend_method && formatSetting('Trend Method', 'MACD'),
            trendSettings.macd_time_frame && formatSetting('Interval', trendSettings.macd_time_frame),
            trendSettings.macd_short_term && formatSetting('MACD Short Length', trendSettings.macd_short_term),
            trendSettings.macd_long_term && formatSetting('MACD Long Length', trendSettings.macd_long_term),
            trendSettings.macd_signal_term && formatSetting('MACD Signal Length', trendSettings.macd_signal_term)            
          ].filter(Boolean); // Filter out any undefined or null values
        case 'supertrend':
          return [
            trendSettings.trend_method && formatSetting('Trend Method', 'Supertrend'),
            trendSettings.st_time_frame && formatSetting('Interval', trendSettings.st_time_frame),
            trendSettings.st_atr_length && formatSetting('ATR Length', trendSettings.st_atr_length),
            trendSettings.st_factor && formatSetting('Factor', trendSettings.st_factor),
            trendSettings.st_min_distance && trendSettings.st_min_distance.trim() !== '' && formatSetting('Minimum Supertrend Distance', trendSettings.st_min_distance),
            trendSettings.st_max_distance && trendSettings.st_max_distance.trim() !== '' && formatSetting('Maximum Supertrend Distance', trendSettings.st_max_distance)          
          ].filter(Boolean); // Filter out any undefined or null values
        default:
          return [];
      }
    };

    // Render the settings based on the trend method in the data
    const settingsToDisplay = renderSettingsByMethod(trendSettings.trend_method);
    // Helper function to split settings into chunks
    const orderSettingArray = (arr, size) => {
      const result = [];
      for (let i = 0; i < arr.length; i += size) {
        result.push(arr.slice(i, i + size));
      }
      return result;
    };
    const orderSettings = orderSettingArray(settingsToDisplay, 5);
    return orderSettings.length > 0 ? (
      orderSettings.map((order, index) => (
<p key={index} className="text-muted text-secondary font-size-11 mb-1">
          {order.map((setting, i) => (
<span key={i}>
              {setting}
              {i < order.length - 1 ? ' | ' : ''}
</span>
          ))}
</p>
      ))
    ) : (
      ''
    );
  };


  const handleError = (err) => {
    if (err && err.response && err.response.data && err.response.data.message) {
      toast.error(err.response.data.message, { toastId: err.response.data.message });
    } else if (err.message) {
      toast.error(err.message, { toastId: err.message });
    } else {
      toast.error(err, { toastId: err });
    }
  }

  const handlePairExchange = async (e) => {
    setFormLoader8(true)
    setSpotPairs([]);
    setSelectedSpotPair({})
    let exchange_id = e.target.value
    if(exchange_id != ""){
    await api({ contentType: true, auth: true }).get('/ccxt/get-spot-symbols/'+exchange_id).then((res) => {
      // console.log('res.data....',res.data);
      setPairListLoading(false);
      //setSpotPairs
      const symbolsListResponse = res.data.response.symbols;
      if(symbolsListResponse.length) {
        const result = symbolsListResponse.map(item => {
          return {
            label: item.id.replace('_', '/'),
            value: item.id
          }
        })
        setPairExchange(exchange_id)
        setSpotPairs(result);
        setFormLoader8(false)
      }else {
        toast.error("No pairs Available");
        setFormLoader8(false)
        setPairExchange("");
        setAddPairVisible(false)
      }
    }).catch((err) => {
      toast.error("No pairs Available");
      setFormLoader8(false)
      setPairExchange("");
      setAddPairVisible(false)
      handleError(err);
        })    
    }else{
      setSpotPairs([]);
      setFormLoader8(false)
    }
  }

  const handleOrderInterval = (e,name) => {
    var time = e[0]
    var hours = time.getHours();
    var minutes = time.getMinutes();
    var H_M = toSeconds(hours, minutes, 0);
    setPairOrderSettings({
      ...pairOrderSettings,
      [name]: H_M
    })
    
  }
  const copySettings = (exchange_id,pair,trendsettings, ordersettings) => {
    let copiedSettingsval = {
      exchange_id:exchange_id,
      pair:pair,
      trendsettings: trendsettings ? JSON.parse(trendsettings):'',
      ordersettings: ordersettings?JSON.parse(ordersettings):'',
    };
    setCopiedSettings(copiedSettingsval);
  };
  const pasteSettings = (exchange_id,pair,pair_id) => {
    console.log("copiedSettings",copiedSettings)
    if (copiedSettings) {
      setPastePair({exchange_id:exchange_id,pair_id:pair_id,
        pair:pair})
      setPasteSettingsModal(true)
    }
  }
  const pasteApply= async(pairinfo) =>{
    try{
      if(copiedSettings.ordersettings){
        let bodyParams = copiedSettings.ordersettings;
        bodyParams.pair_id = pairinfo.pair_id;
        bodyParams.exchange_id = pairinfo.exchange_id;

        await api({ contentType: true, auth: true }).post('/ccxt/update-pair-order-settings', bodyParams).then(async(res) => {
          if (res.status === 200) {
            var exchangeid = document.getElementById('select_exchange_pair').value;
            await getSeparetePairsList(exchangeid,"no_load");
          }
        }).catch(err => {
          throw err
        })
     }
     if(copiedSettings.trendsettings){
        let trendSettingsval=copiedSettings.trendsettings
        trendSettingsval['display_text'] =pairinfo.pair
        trendSettingsval['exchange_id'] =pairinfo.exchange_id
        await api({ contentType: true, auth: true }).post('/ccxt/update-trend-settings', trendSettingsval).then(async(res) => {
          var exchangeid = document.getElementById('select_exchange_pair').value;
          await getSeparetePairsList(exchangeid,"no_load");
        
        }).catch(err => {
          throw err
        })
     }
    setPasteSettingsModal(false);
    toast.success("Settings updated successfully");
    }catch(err){
      console.log("paste error",err)
      toast.error("There is something went wrong when applying to copied changes, try again later")
    }
  }
  const renderTrendSettings = () => {
    const { trend_method, short_term, long_term, time_frame, st_time_frame, macd_time_frame, ema_time_frame,ema_short_term,ema_long_term, exchange_id, display_text, macd_short_term, macd_long_term, macd_signal_term,st_atr_length,st_factor,st_max_distance,st_min_distance } = copiedSettings.trendsettings;

    switch (trend_method) {
      case 'sma':
      return (
      <>
      <p>Trend Method: <b>SMA</b><br />
      Short Term: <b>{short_term}</b><br />
      Long Term: <b>{long_term}</b><br />
      Time Frame: <b>{time_frame}</b></p>
      </>
      );
      case 'ema' :
      return (
      <>
      <p>Trend Method: <b>EMA</b><br />
      Short Term: <b>{ema_short_term}</b><br />
      Long Term: <b>{ema_long_term}</b><br />
      Time Frame: <b>{ema_time_frame}</b></p>
      </>
      )
      case 'macd':
      return (
      <>
      <p>Trend Method: <b>MACD</b><br />
      MACD Short Term: <b>{macd_short_term}</b><br />
      MACD Long Term: <b>{macd_long_term}</b><br />
      MACD Signal Term: <b>{macd_signal_term}</b><br />
      Time Frame: <b>{macd_time_frame}</b></p>
      </>
      );
      case 'supertrend':
      return (
      <>
      <p>Trend Method: <b>Supertrend</b><br />
      ATR length: <b>{st_atr_length}</b><br />
      Factor: <b>{st_factor}</b><br />
      Minimum supertrend distance:<b>{st_min_distance}</b><br />
      Maximum supertrend distance:<b>{st_max_distance}</b><br />
      Time Frame: <b>{st_time_frame}</b></p>
      </>
      )
      // Add cases for other trend methods if any
      default:
        return <p>No valid trend method selected.</p>;
    }
  };

  const timeFormat = (seconds) => {
    var totalNumberOfSeconds = seconds;
   var hours = parseInt( totalNumberOfSeconds / 3600 );
   var minutes = parseInt( (totalNumberOfSeconds - (hours * 3600)) / 60 );
   var seconds = Math.floor((totalNumberOfSeconds - ((hours * 3600) + (minutes * 60))));
   if(seconds >0){
   var result = (hours < 10 ? "0" + hours : hours) + "h " + (minutes < 10 ? "0" + minutes : minutes) + "m " + (seconds  < 10 ? "0" + seconds : seconds)+ "s";
   }else{
    var result = (hours < 10 ? "0" + hours : hours) + "h " + (minutes < 10 ? "0" + minutes : minutes) + "m";
   }
   return result;
  }
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    );
  };
  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Yes
      </div>
    );
  };
//New changes *********
const handleSelect = (selectedList, selectedItem) => {
  // console.log('selectedList ====>>>> ',selectedList);
  setSelectedSpotPair(selectedList);
} 
const CreateOrder = async (exchangedata,index,Side ) => {
  try {
   
    let pairdata=exchangedata.data;
    let pairOrderSettings=pairdata.settings?JSON.parse(pairdata.settings):{};
    pairdata['side'] = Side;
    pairdata['trading_pair_db_id'] = exchangedata.id;
    pairdata['signal_data'] = exchangedata.signal_data;
    pairdata['signal_data']['trend_signal_id']=exchangedata.trend_signal_id
    let balance = ccxtUserBalances[pairdata.exchange_id];
         let base_balance=balance[pairdata.base] && balance[pairdata.base]['free'] ? balance[pairdata.base]['free'] : 0
         let quote_balance= balance[pairdata.quote] && balance[pairdata.quote]['free']?balance[pairdata.quote]['free']:0
      if (pairOrderSettings.quantity_type !== "balance_per" && pairOrderSettings.quantity_type !== "balance_per_base") {
          if(pairOrderSettings.quantity > base_balance && Side=='sell'){
            // console.log(pairOrderSettings.quantity, "your balance is",base_balance)
            toast.error("Insufficient balance on your base currency "+pairdata.base)
            setForm7Loader({
              loading: false,
            });
            return false
          }
          if(indexPrices[pairdata.id]*pairOrderSettings.quantity > quote_balance  && Side=='buy'){
           
            toast.error("Insufficient balance on your quote currency "+pairdata.quote)
            setForm7Loader({
              loading: false,
            });
            return false
          }
        }
          // return false
          setFormLoader7( {...formLoader7 ,[index]: true}); 
    await api({ contentType: true, auth: true }).post('/ccxt/create-order', pairdata).then(async(res) => {
      if (res.status === 200) {
        toast.success("Order created successfully");
        setFormLoader7({
          ...formLoader7,
          [index]: false,
        })
      }
    }).catch((err) => {
      handleError(err);
      setFormLoader7({
        ...formLoader7,
        [index]: false,
      })
    })
  } catch (e) {
    console.log(e);
    handleError(e);
    setFormLoader7({
      ...formLoader7,
      [index]: false,
    })
  }
}

const checkValidation = () => {
  let valid = true;
  if(pairExchange =="" && (Object.keys(SelectedSpotPair).length == 0 || SelectedSpotPair.value!="")){
    setPairErrors({exchange_error:"Select Exchange",pair_error:"Select Pair"})
    valid = false
  }else if(pairExchange =="") {
    setPairErrors({pair_error:"",exchange_error:"Select Exchange"})
    valid = false
  }else if(Object.keys(SelectedSpotPair).length == 0 || SelectedSpotPair.value==""){
    setPairErrors({exchange_error:"",pair_error:"Select Pair"})
    valid = false
  }else{
    setPairErrors({exchange_error:"",pair_error:""})
  }
  return valid;
}

const addNewPair=async() =>{
  setFormLoader6(true);
  if(checkValidation()) {
    let bodyParams={
      "exchange_id": pairExchange,
      "symbol_id": SelectedSpotPair?.value,
      "is_add_pair": 1,
      "symbol":SelectedSpotPair?.value
  }
  await api({ contentType: true, auth: true }).post('/ccxt/add-ccxt-pairs', bodyParams).then(async(res) => {
    let indexinfo = indexPrices;
    await api({ contentType: true, auth: true }).post('/ccxt/getTicker', bodyParams).then((res) => {
      let ticker = res.data.response;
      let current_index_price=ticker.close?ticker.close:ticker.info.spot_price?ticker.info.spot_price:'';
       indexinfo[bodyParams.symbol_id]=Number(current_index_price)
       setIndexPrices(indexinfo);
    });
    toast.success("Pair added successfully");
    setFormLoader6(false);
    setAddPairVisible(false)
    setPairExchange("");
    setPairListLoading(true)
    await setupCCXTUserExchanges();
    if(viewPair =="SelectExchange") {
      await getAllUserExchangePairs().then((res) => {
        if (res.data) {
          let markets = res.data.response.data;
          setSeparatePairs(markets);
          setTimeout(()=>{
            setPairListLoading(false);
          },2000)
        }
      })
    }else{
      await getUserExchangePairs(viewPair).then((res) => {
        if (res.data) {
          let markets = res.data.response.data;
          setSeparatePairs(markets);
          setTimeout(()=>{
            setPairListLoading(false);
          },2000)
        }
      })
    }
   }).catch((err) => {
    handleError(err);
    setFormLoader6(false);
    setAddPairVisible(false)
    setPairListLoading(false);
      })
  }else{
    setFormLoader6(false);
  }
}
const getSeparetePairsList = async (exchange_id,type="") => {
  try {
    //setPairListLoading(true);
    if (exchange_id === 'SelectExchange') {
      setSelectExchange(true)
      setSeparatePairs['']
      getAllUserExchangePairs().then((res) => {
        if (res.data) {
          let markets = res.data.response.data;
          setSeparatePairs(markets);
          setPairListLoading(false);
        }
      }).catch(e => {
        setSeparatePairs([])
        setSelectExchange(false)
        setPairListLoading(false);
      });
    }
    else {
      setSelectExchange(false)
      if(type==""){
      setPairListLoading(true);
      }
      //get all ccxt spot pairs list
      // setSelectedSpotPair([])
      // await api({ contentType: true, auth: true }).get('/ccxt/get-spot-symbols/'+exchange_id).then((res) => {
      //     // console.log('res.data....',res.data);
      //     setPairListLoading(false);
      //     //setSpotPairs
      //     const symbolsListResponse = res.data.response.symbols;
      // const result = symbolsListResponse.map(item => {
      //   return {
      //     label: item.id.replace('_', '/'),
      //     value: item.id 
      //   }
      // })      
      
      // setSpotPairs(result);
        // });

        //get pairs from db
        getUserExchangePairs(exchange_id).then((res) => {
          if (res.data) {
            let markets = res.data.response.data;
            setSeparatePairs(markets);
            setPairListLoading(false);
          }
        }).catch(e => {
          setSeparatePairs([])
          setSelectExchange(false)
          setPairListLoading(false);
        });

      // await getCCXTExchangeMarkets(exchange_id).then((res) => {
      //   if (res.data) {
      //     let markets = res.data.response.markets;

      //     setSeparatePairs(markets);
      //     setPairListLoading(false);
     
      //   }
      // }).catch(e => {
      //   setSeparatePairs([])
      //   setSelectExchange(false)
      //   setPairListLoading(false);
      //   throw e
      // })
    }
  } catch (e) {
    setSeparatePairs([])
    setSelectExchange(false)
    setPairListLoading(false);
    handleError(e);
  }
}
const changePairStatus = async (settingspair,exchange_id, pair_id, statusval) => {

  try {
    if(statusval==true){
    let pairOrderSettings = settingspair.settings?JSON.parse(settingspair.settings):{};
    if(typeof pairOrderSettings.quantity == "undefined"){
      toast.warning("Please add your order settings to initiate trading")
    }
    let balance = ccxtUserBalances[exchange_id];
    let base_balance= balance[settingspair.base] ? balance[settingspair.base]['free'] : "0"
    let quote_balance= balance[settingspair.quote] ? balance[settingspair.quote]['free'] : "0"
        if (pairOrderSettings.quantity > base_balance && pairOrderSettings.quantity_type !== "balance_per" && pairOrderSettings.quantity_type !== "balance_per_base") {
       toast.warning("Insufficient balance on your base currency "+settingspair.base)
       setForm7Loader({
         loading: false,
       });
     }
        if (indexPrices[settingspair.id] * pairOrderSettings.quantity > quote_balance && pairOrderSettings.quantity_type !== "balance_per" && pairOrderSettings.quantity_type !== "balance_per_base") {
       toast.warning("Insufficient balance on your quote currency "+settingspair.quote)
       setForm7Loader({
         loading: false,
       });
     }
        if (pairOrderSettings.quantity_type == "balance_per" && quote_balance == 0) {
          toast.warning("Please note you don't have balance on your quote currency " + settingspair.quote)
          setForm7Loader({
            loading: false,
          });
        }
        if (pairOrderSettings.quantity_type == "balance_per_base" && base_balance == 0) {
          toast.warning("Please note you don't have balance on your base currency " + settingspair.base)
          setForm7Loader({
            loading: false,
          });
        }
    }
    setPairStatus({ ...pairStatus, [pair_id]: statusval });
    let bodyParams = {
      "exchange_id": exchange_id,
      "symbol_id": pair_id,
      "status": statusval
    }
    await api({ contentType: true, auth: true }).post('/ccxt/update-exchange-pair', bodyParams).then(async(res) => {
      var exchangeid = document.getElementById('select_exchange_pair').value;
      await getSeparetePairsList(exchangeid,"no_load");
      toast.success("Pair status updated successfully");
    }).catch((err) => {
      handleError(err);
    })
  } catch (e) {
    handleError(e);
  }
}
//New changes *********





function toHoursAndMinutes(totalSeconds) {
  const totalMinutes = Math.floor(totalSeconds / 60);

  const seconds = totalSeconds % 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return hours + ":" + minutes ;
}

function toSeconds(hours, minutes, seconds) {
  return hours * 3600 + minutes * 60 + seconds;
}





  const updateNewTrendSettings = async () => {
    try {
      setForm6Loader({
        loading: true,
      });
      
      trendSettings['display_text'] =Trendfollowpair.display_text
      trendSettings['exchange_id'] =Trendfollowpair.exchange_id
      let bodyParams = trendSettings;
      let errmsg = trendFormValidation(trendSettings)
      if (errmsg != "") {
        throw toast.error(errmsg)
      }
      await api({ contentType: true, auth: true }).post('/ccxt/update-trend-settings', bodyParams).then(async(res) => {
        setForm6Loader({
          loading: false,
        });
        var exchangeid = document.getElementById('select_exchange_pair').value;
        await getSeparetePairsList(exchangeid,"no_load");
        toast.success("Trend following settings updated");
        setTrendFollowSettings(false)
      }).catch(err => {
        throw err
      })

    }
    catch (error) {
      setForm6Loader({
        loading: false,
      });
      
      error.response && error.response.data && error.response.data.message && toast.error(error.response.data.message);

    }
  }
  // const updateOrderSettings = async (form_data) => {
  //   try {
  //     setForm6Loader({
  //       loading: true,
  //     });

  //     let bodyParams = orderSettings;

  //     await api({ contentType: true, auth: true }).post('/ccxt/update-order-settings', bodyParams).then((res) => {
  //       setForm6Loader({
  //         loading: false,
  //       });
  //       toast.success("Order settings updated");
  //     }).catch(err => {
  //       throw err
  //     })

  //   }
  //   catch (error) {
  //     setForm6Loader({
  //       loading: false,
  //     });
  //     err.response && toast.error(err.response.data.message);

  //   }
  // }
  const updatePairOrderSettings = async (form_data) => {
  
    try {
      setForm7Loader({
        loading: true,
      });
      if(!pairOrderSettings.fill_type || pairOrderSettings.fill_type==''){
        toast.error("Please select fill type")
        setForm7Loader({
          loading: false,
        });
        return false
      }
      if(pairOrderSettings.fill_type == "wait_and_fill" && (!pairOrderSettings.price_distance_percentage || pairOrderSettings.price_distance_percentage=="")){
        toast.error("Please enter price distance % for wait and fill")
        setForm7Loader({
          loading: false,
        });
        return false
      }
      if(!pairOrderSettings.quantity || pairOrderSettings.quantity==""){
        toast.error("Please enter quantity")
        setForm7Loader({
          loading: false,
        });
        return false
      }
      console.log('updata pair order settings222',pairOrderSettings)
     
      
        let balance = ccxtUserBalances[settingspair.exchange_id];
       if(balance && balance[settingspair.base] && balance[settingspair.quote]){
         let base_balance= balance[settingspair.base]['free']
         let quote_balance= balance[settingspair.quote]['free']
        if (pairOrderSettings.quantity > base_balance && pairOrderSettings.quantity_type !== "balance_per" && pairOrderSettings.quantity_type !== "balance_per_base") {
            toast.warning("Please note you have insufficient balance on your base currency "+settingspair.base)
            setForm7Loader({
              loading: false,
            });
            //return false
          }
        if (pairOrderSettings.quantity_type == "balance_per" && quote_balance == 0) {
          toast.warning("Please note you don't have balance on your quote currency " + settingspair.quote)
          setForm7Loader({
            loading: false,
          });
        }
        if (pairOrderSettings.quantity_type == "balance_per_base" && base_balance == 0) {
          toast.warning("Please note you don't have balance on your base currency " + settingspair.base)
          setForm7Loader({
            loading: false,
          });
        }
          console.log('updata pair order settings indexPrices')
        if (indexPrices && indexPrices.length > 0 && indexPrices[settingspair.id] && indexPrices[settingspair.id] * pairOrderSettings.quantity > quote_balance && pairOrderSettings.quantity_type !== "balance_per" && pairOrderSettings.quantity_type !== "balance_per_base") {
            toast.warning("Please note you have insufficient balance on your quote currency "+settingspair.quote)
            setForm7Loader({
              loading: false,
            });
            //return false
          }
        }
          console.log('updata pair order settings bal')
          
      let exchange_id = settingspair.exchange_id;
      let pair_id = settingspair.id;
      let bodyParams = pairOrderSettings;
      if(pairOrderSettings && pairOrderSettings.order_side && (pairOrderSettings.order_side=="sell" || pairOrderSettings.order_side=="buy")){
        //remove unwanted setting from bodyParams
        delete bodyParams.min_take_profit_percentage;
        delete bodyParams.stop_loss;
        delete bodyParams.trailing_take_profit_percentage;
        delete bodyParams.waiting_time_for_next_order_stop_loss;
        delete bodyParams.waiting_time_for_next_order_trailing_profit;

      }
      bodyParams.pair_id = pair_id;
      bodyParams.exchange_id = exchange_id;
      // let db_id = pairSettings[pair_id].db_id ? pairSettings[pair_id].db_id : '';
      // await firestoreDb.collection("users").doc(uid).collection("ccxt_pairs").where('exchange_id', '==', exchange_id)
      //   .where('id', '==', pair_id).limit(1).get().then(async (all_data) => {
      //     if (all_data.docs.length > 0) {
      //       all_data.forEach(function (doc) {
      //         if (doc.exists) {
      //           db_id = doc.id
      //           var updateData = doc.data();
      //           updateData.qty = pairOrderSettings.quantity;
      //           firestoreDb.collection("users").doc(uid).collection("ccxt_pairs").doc(doc.id).update(updateData);
      //           setisfetch(true);
      //           setPairupdateLoading(false);
      //         }

      //       })
      //     }
      //     else {

      //       // add
      //       let exchangeData = false;
      //       await firestoreDb.collection("ccxt").doc("markets")
      //         .collection(exchange_id).doc(pair_id).get().then(async (doc) => {
      //           if (doc.exists) {
      //             exchangeData = doc.data();
      //             exchangeData.qty = pairOrderSettings.quantity
      //             await firestoreDb.collection("users").doc(uid).collection("ccxt_pairs").add(exchangeData);
      //             db_id = result.id
      //             setisfetch(true);
      //             //  toast.success("Pairs details added");
      //             setPairupdateLoading(false);
      //           }
      //         });
      //     }
      //   })
      // bodyParams.db_id = db_id
      console.log("bodyParams",bodyParams)
      await api({ contentType: true, auth: true }).post('/ccxt/update-pair-order-settings', bodyParams).then(async(res) => {
        if (res.status === 200) {
         
          var exchangeid = document.getElementById('select_exchange_pair').value;
          await getSeparetePairsList(exchangeid,"no_load");
          toast.success("Settings updated");
          setSettingsvisible(false)
        }
        setForm7Loader({
          loading: false,
        });
        pairSettings[pair_id].settings = bodyParams;
        setPairSettings(pairSettings);
        TrendFollowSettingspair[pair_id].settings = bodyParams;
        setTrendFollowSettingspair(TrendFollowSettingspair)
      }).catch((err) => {
        throw err
      })

    }
    catch (error) {
      setForm7Loader({
        loading: false,
      });
      error.response && toast.error(error.response.data.message);

    }
  }
  

  //Get settings
  const getSettings = async () => {
    try {
      const check_trade_status = await api({ contentType: true, auth: true }).get('/user/profile')
    .then(res => {
      if(res.data.response)
      {
        setTradeStatus(res.data.response.user.trade_status);
      }
    });  
      await api({ contentType: true, auth: true }).get('/ccxt/update-trend-settings-data').then((res) => {
        var data = res.data.response.data;
       
        //setTrendSettings(data)
        }).catch((err) => {
          throw err
        })
      // var get_trend_settings = await firestoreDb.collection("users").doc(uid).collection("ccxt_settings").doc('trend_following_settings');

      // get_trend_settings.get().then((res) => {
      //   if (res.exists) {
      //     const trend_settings = res.data();
      //     setTrendSettings(trend_settings)
      //   }
      // });

      var get_order_settings = await firestoreDb.collection("users").doc(uid).collection("ccxt_settings").doc('order_settings');

      get_order_settings.get().then((res) => {
        if (res.exists) {
          const order_settings = res.data();
          setOrderSettings(order_settings)
        }
      });

    } catch (e) {
      handleError(e);
    }
  }

  const [ccxtAllExchanges, setCCXTAllExchanges] = useState([]);
  //Get Exchanges list
  const getCCXTAllExchangesList = async () => {
    try {
      await getCCXTAllExchanges().then((res) => {
        setCCXTAllExchanges(res.data.response.exchanges);
      }).catch(e => {
        throw e
      })
    } catch (e) {
      //setIsLoading(false);
      handleError(e);
    }
  }

  //console.log('ccxtAllExchangesccxtAllExchanges....',ccxtAllExchanges);
  const [SelectExchange, setSelectExchange] = useState(false)
  const [SelectExchange2, setSelectExchange2] = useState(false)
  const [deleteExchange, setdeleteExchange] = useState(false)
  const [deletevisible, setdeletevisible] = useState(false)
  const [ccxtUserExchanges, setCCXTUserExchanges] = useState([]);
  const [exchangeStatuses, setExchangeStatuses] = useState({});
  const [ccxtUnSelectExchanges, setCCXTUnSelectExchanges] = useState([]);
  const [ccxtUserBalances, setCCXTUserBalances] = useState({});  
  //Get Exchanges list
  const getCCXTUserExchangesList = async () => {
    try {
      setCCXTUnSelectExchanges(ccxtAllExchanges);
      await setupCCXTUserExchanges();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  }
  const setupExchangesBalance = async () => {
    await api({ contentType: true, auth: true }).post('/ccxt/get-all-exchanges-pairs',{"only_balance":true}).then(async(res) => {
        if (res.data.httpCode && res.data.httpCode.message == "No exchange configs found") {
          setIsLoading(false)
          setPairListLoading(false)
          return;
        }
        let balance = res.data.response.balance;
        let indexinfo = res.data.response.indexinfo;
        setIndexPrices(indexinfo);
        setCCXTUserBalances(balance);
      }).catch(e => {
        throw e
      });
    }
  const setupCCXTUserExchanges = async () => {
    await api({ contentType: true, auth: true }).post('/ccxt/get-all-exchanges-pairs',{"only_balance":false}).then(async(res) => {
    // await getCCXTUserExchanges().then(async (res) => {
      if (res.data.httpCode && res.data.httpCode.message == "No exchange configs found") {
        setIsLoading(false)
        setPairListLoading(false)
        return;
      }
      // else if(){
      //   setPairListLoading(false)
      // }
      let exchanges = res.data.response.exchanges;
      let balance = res.data.response.balance;
      // let exchange = res.data.response.exchange;
      let exchange_statuses = {};
      let indexinfo = res.data.response.indexinfo;
      // setIndexPrices(indexinfo);
      // setCCXTUserBalances(balance);
      if (exchanges && exchanges.length) {
        let user_exchanges = await Promise.all(exchanges.map(async (exchange) => {

          // console.log('exchange....',exchange);

        //   exchange.pairs = [];
        //   await getCCXTUserExchangePairs(exchange.exchange_id).then(async (res)  => {
        //     let markets = res.data.response.data;
           
        //     let exchange_pairs = [];
        //     if (markets && markets.length) {
        //       exchange_pairs = markets
        //     }
        //     if(exchange_pairs.length > 0){
        //       const bodyParams = {
        //         exchange_id:  '',
        //         symbol: []
        //       }
        //     exchange_pairs.map(async(pairs,index) => {
        //      // if (pairs.data.status == 1 || pairs.data.status == "true"){
        //         exchange.pairs.push(pairs.data)
        //         bodyParams.symbol.push(pairs.data.id)
        //         bodyParams.exchange_id =  pairs.data.exchange_id

        //     //  }
        //     })
            
        //      await api({ contentType: true, auth: true }).post('/ccxt/get-Ticker', bodyParams).then((res) => {
        //       let ticker = res.data.response;
        //       bodyParams.symbol.map(async(id,index)=>{
        //       let current_index_price=ticker[id].close?ticker[id].close:ticker[id].info.spot_price?ticker[id].info.spot_price:'';
        //       //console.log('IndexPrices....',current_index_price,bodyParams);
        //        indexinfo[id]=Number(current_index_price)
        //        setIndexPrices(indexinfo);
        //        //console.log('IndexPrices',indexPrices);
        //       })
        //     });
        //   }
         
        //     // exchange.pairs = exchange_pairs;
        //  }).catch(e => {
        //   console.log("no pairs found")
        // });;


         
          exchange.details = ccxtAllExchanges.find(item => item.id == exchange.exchange_id);
          var key_secret = __KEY_SECRET__;
          var decry_appkey = CryptoJS.AES.decrypt(exchange.appKey, key_secret);
          var decryptedAppkey = JSON.parse(decry_appkey.toString(CryptoJS.enc.Utf8));
          var decry_appsecret = CryptoJS.AES.decrypt(exchange.appSecret, key_secret);
          var decryptedAppsecret = JSON.parse(decry_appsecret.toString(CryptoJS.enc.Utf8));
          exchange.display_apikey = decryptedAppkey.slice(0, 3) + '******' + decryptedAppkey.slice(-3);
          exchange.display_api_secret = decryptedAppsecret.slice(0, 3) + '******' + decryptedAppsecret.slice(-3);
          // await getCCXTUserBalance(exchange.exchange_id).then((res) => {
          //   let balance = res.data.response.data;            
          //   if (balance) { 
          //     if(!ccxtUserBalances[exchange.exchange_id]){
          //       ccxtUserBalances[exchange.exchange_id]={}
          //     }
          //     ccxtUserBalances[exchange.exchange_id] = balance;
          //     setCCXTUserBalances(ccxtUserBalances);
          //   }
          // }).catch(e => {
          //   console.log("no balance found",e)
          //   // throw e
          // });
          
         
          exchange_statuses[exchange.exchange_id] = exchange.status === 'active' ? true : false;
        
          return exchange;
        }));
        setExchangeStatuses(exchange_statuses);
        const filteredItem = user_exchanges.find(item => item.exchange_id === 'binance_us_paper_trade');
        const otherItems = user_exchanges.filter(item => item.exchange_id !== 'binance_us_paper_trade');
        if (filteredItem) {
          user_exchanges = [...otherItems, filteredItem];
        } else {
          user_exchanges = user_exchanges;  
        }
        setCCXTUserExchanges(user_exchanges);
        // setSelectedExchangeinPair(user_exchanges[0].exchange_id)
        // ccxtAllExchanges.map((exchange, index) => {
        //   let list = ccxtAllExchanges
        //   for (let i = 0; i < ccxtUserExchanges.length; i++) {
        //     if (ccxtUserExchanges[i].exchange_id === exchange.id) {
        //       delete list[index]
        //     }
        //   }
        //   setCCXTAllExchanges(list)
        // })

        var deSelectedRows = user_exchanges;
        var selectedRows = [];
        
        selectedRows = ccxtAllExchanges.filter(function(cv){
          return !deSelectedRows.find(function(e){
              return e.exchange_id == cv.id;
          });
      });   
      
      setCCXTUnSelectExchanges(selectedRows);
        
        setisfetch(false);
      }else{
        setCCXTUserExchanges([])
        setPairListLoading(false);
      }
      setupExchangesBalance();
    }).catch(e => {
      throw e
    });
  }

  const [ActiveOrderList, setActiveOrderList] = useState([])
  const getPairIndex = async(pair) => {

   // console.log('indexinfo....',indexinfo);
  //  await getCCXTUserBalance(pair.exchange_id).then((res) => {
  //   let balance = res.data.response.data;            
  //   if (balance) { 
  //     setCCXTUserBalances(balance);
  //   }
  // }).catch(e => {
  //   throw e
  // });

  }
  const getActiveOrders = () => {
    const firestoreDbUser = firestoreDb.collection("users").doc(uid);
    const ordersRef = firestoreDbUser.collection("ccxt_orders").orderBy("created_at", "desc");
    let orders_info = [];
    ordersRef.onSnapshot((querySnapshot) => {
      //console.log(querySnapshot)
      querySnapshot.forEach((doc) => {
        let item = doc.data();
        orders_info.push(item.exchange_id)
      })

    })
    setActiveOrderList(orders_info)
  }
  const ActiveOrders = async (exchange_id) => {
    let count = 0;
    if (ActiveOrderList.length !== 0) {
      ActiveOrderList.map((item) => {
        if (item === exchange_id && count !== 1) {
          count = 1;
          setdeleteExchange(false)
          toast.error("There are some orders that are active now. Please close those")
        }
      })
      if (count === 0) {
        setdeletevisible(!deleteExchange);
      }
    } else {
      setdeletevisible(!deleteExchange);
    }
  }
  const ActiveOrdersforpair = async (exchange_id,symbol_id) => {
    const firestoreDbUser = firestoreDb.collection("users").doc(uid);
    const ordersRef = firestoreDbUser.collection("ccxt_orders").where("exchange_id",'==',exchange_id).where("symbol",'==',symbol_id).orderBy("created_at", "desc");
    let orders_info = [];
    ordersRef.onSnapshot((querySnapshot) => {
      //console.log(querySnapshot)
      querySnapshot.forEach((doc) => {
        let item = doc.data();
        orders_info.push(item.exchange_id)
      })

    })
    if (orders_info.length > 0){
      toast.error(`There are some orders that are active now for ${symbol_id}. Please close those`)
      return false
    }else{
      return true
    }
    
  }
  const DeleteExchangePairs = async (exchange_id,symbol) => {
    try{
      var ActiveOrderListcount = await ActiveOrdersforpair(exchange_id,symbol);
      if (ActiveOrderListcount === false){
        setloader(false)
        return false
      }else if (ActiveOrderListcount === true){
        var bodyParams = {exchange_id: exchange_id,symbol_id: symbol}
        await api({ contentType: true, auth: true }).post('/ccxt/Delete-single-pairs', bodyParams).then(async(res) => {
          let response = res;
            if (response.status !== 200){
              toast.error("Something went wrong");
            }else{
              var exchangeid = document.getElementById('select_exchange_pair').value;
              await getSeparetePairsList(exchangeid,"no_load");
              setloader(false)
              setisDeleteconfirmpopup(false)
              toast.success("Pair deleted successfully");
            }
        });
      }
    }catch(e){
      setloader(false)
      setisDeleteconfirmpopup(false)
      handleError(e);
    }
  }
  const DeleteValidation = async (data) => {
    try {
      setIsLoadingDelete(true);
      // await firestoreDb.collection("users").doc(uid).collection("ccxt_config").where('exchange_id', '==', data).get()
      //   .then((all_data) => {
      //     if (all_data.docs.length > 0) {
      //       let updateData = {
      //         is_delete: true
      //       }
      //       all_data.forEach(async function (doc) {
      //         if (doc.exists) {
      //           // var data = doc.data();
      //           const result = await firestoreDb.collection("users").doc(uid).collection("ccxt_config").doc(doc.id).delete();
      //           await setupCCXTUserExchanges();
      //           //await getCCXTAllExchangesList();
      //           // window.location.reload(false);
                // var bodyParams = {exchange_id: data}
      //           await api({ contentType: true, auth: true }).post('/ccxt/Delete-pairs', bodyParams).then((res) => {
      //             let response = res;
      //               if (response.status !== 200){
      //                 toast.error("Something went wrong");
      //               }
      //           });
      //           toast.success("Exchange Deleted Successfull");
      //           setCCXTUserExchanges([])
      //           setCCXTExchangePairs([])
      //           setdeletevisible(false)
      //           setdeleteExchange(false)
      //           setIsLoadingDelete(false);
      //         }
      //       })
      //     }
      //   }
      //   )
      let bodyParams = {exchange_id: data}
      await deleteUserExchange(bodyParams).then(async (res)=>{
        if(res.data.message =="Exchange removed Successfully") {
        toast.success("Exchange Deleted Successfully");
        setdeleteExchange(true);
        await setupCCXTUserExchanges();
        // setCCXTUserExchanges([])
        // setCCXTExchangePairs([])
        setdeletevisible(false)
        setdeleteExchange(false)
        setIsLoadingDelete(false);
        }else {
          toast.success(res.data.message);
          setIsLoadingDelete(false);
        }
      })
    } catch (e) {
      setdeletevisible(false)
      setdeleteExchange(false)
      setIsLoadingDelete(false);
      handleError(e);
    }
  }

  const validation = useFormik({
    initialValues: {
      api_key: '',
      api_secret: ''
    },
    validationSchema: Yup.object({
      api_key: Yup.string().required("Please Enter Your API Key"),
      api_secret: Yup.string().required("Please Enter Your API Secret")
    }),
    onSubmit: async (values) => {
      if (editExchange) {
        setFormLoader1(true);
        if (!selectNewExchange) {
          toast.error('No exchange selected', { toastId: 'no_exchange_selected' });
          setFormLoader1(false);
        }

        // Add Exchange
        const bodyParams = {
          exchange_id: selectNewExchange,
          appKey: values.api_key,
          appSecret: values.api_secret
        }

        await updateCCXTExchangeConfig(bodyParams).then(async (res) => {
          await setupCCXTUserExchanges();
          setExchangeUpdated(true);
          toast.success("API key settings updated");
          setVisible(false);
          validation.resetForm();
          setFormLoader1(false);
        }).catch(err => {
          setFormLoader1(false);
          handleError(err);
        })
      }
      else {

        setFormLoader1(true);
        if (!selectNewExchange) {
          toast.error('No exchange selected', { toastId: 'no_exchange_selected' });
          setFormLoader1(false);
        }

        // add exchange
        const bodyParams = {
          exchange_id: selectNewExchange,
          appKey: values.api_key,
          appSecret: values.api_secret
        }
        await updateCCXTExchangeConfig(bodyParams).then(async (res) => {
          await setupCCXTUserExchanges();
          //await getCCXTAllExchangesList();
          setExchangeUpdated(true);
          toast.success("A new exchange added");
          setVisible(false);
          validation.resetForm();
          setFormLoader1(false);
        }).catch(err => {
          setFormLoader1(false);
          handleError(err);
        })
      }
    },
  })

  const  handleSettingsSubmit = async (values, index) => {
    console.log(`Form ${index} Submitted:`, values);
    // Handle form submission here
    const bodyParams = {
      exchange_id: values.exchange_id,
      stop_loss: values.stop_loss,
    }
    await updateExchangeSl(bodyParams).then(async (res) => {
      if(res.data.message == "Stop loss updated successfully") {
        await setupCCXTUserExchanges();
        toast.success(res.data.message);
      }else{
        toast.error(res.data.message);
      }
    }).catch(err => {
      handleError(err);
    })
  };


  const [ccxtExchangePairs, setCCXTExchangePairs] = useState([]);
  //Get Exchanges list
  const getCCXTExchangePairsList = async (exchange_id) => {
    try {
      await getCCXTExchangeMarkets(exchange_id).then((res) => {
        if (res.data) {
          let markets = res.data.response.markets;
          let exchange_pairs = markets.map(market => {
            return {
              label: market.display_text,
              value: market.id,
            }
          })
          setCCXTExchangePairs(exchange_pairs);
        }
      }).catch(e => {
        throw e
      })
    } catch (e) {
      handleError(e);
    }
  }

  const [ccxtUserExchangePairs, setCCXTUserExchangePairs] = useState([]);
  const setupCCXTUserExchangePairs = async (exchange_id) => {
    await getCCXTUserExchangePairs(exchange_id).then((res) => {
      let markets = res.data.response.data;
      let exchange_pairs = [];
      if (markets && markets.length) {
        exchange_pairs = markets.map(market => {
          return {
            label: market.display_text,
            value: market.id,
          }
        })
        setCCXTUserExchangePairs(exchange_pairs);
      } else {
        setCCXTUserExchangePairs(exchange_pairs);
      }

    }).catch(e => {
      throw e
    });
  }

  //Get Exchanges list
  const getCCXTUserExchangePairsList = async (exchange_id) => {
    try {
      await setupCCXTUserExchangePairs(exchange_id);
      setIsLoading(false);
    } catch (e) {
    }
  }

  const PairValidation = useFormik({
    initialValues: {
      exchange_pair: "",
    },
    validationSchema: Yup.object({
      exchange_pair: Yup.array().min(1, 'Pick at least one pair')
        .of(
          Yup.object().shape({
            label: Yup.string().required(),
            value: Yup.string().required(),
          })
        )
    }),
    onSubmit: async (values) => {

      setFormLoader3(true);
      /* if (!selectedPair || !selectedPair.length) {        
        toast.error('Please select alteast one pair', { toastId: 'select_atleast_1_pair' }); 
        setFormLoader3(false);
        return false;
      } */

      //Selected users      
      let selected_pairs_arr = selectedPair.map(selected_pair => selected_pair.value);
      let selected_pairs = '';
      if (selected_pairs_arr.length) {
        selected_pairs = selected_pairs_arr.join(",")
      }

      // update Pairs
      const bodyParams = {
        exchange_id: selectNewExchange,
        symbols: selected_pairs,
      }
      await updateCCXTExchangePair(bodyParams).then(async (res) => {
        await setupCCXTUserExchanges();
        toast.success("Pair details updated");
        setPairVisible(false);
        PairValidation.resetForm();
        setFormLoader3(false);
      }).catch(err => {
        setFormLoader3(false);
        handleError(err);
      })

    },
  })


  const ExchangePairValidation = useFormik({
    initialValues: {
      pair_qty: "",
    },
    validationSchema: Yup.object({
      pair_qty: Yup.string().required("Please Enter Qty")
    }),
    onSubmit: async (values) => {



    },
  })


  
  //console.log('ddddddd',pairStatus);


  const changeExchangeStatus = async (exchange_id, e) => {

    try {
      setFormLoader2({ ...formLoader2, [exchange_id]: true });
      setExchangeStatuses({ ...exchangeStatuses, [exchange_id]: e });

      // Add Exchange
      const bodyParams = {
        exchange_id: exchange_id,
        status: e ? "active" : "disabled"
      }

      await updateCCXTExchangeStatus(bodyParams).then(async (res) => {
        toast.success("Staus updated successfully");
        setFormLoader2({ ...formLoader2, [exchange_id]: false });
      }).catch(err => {
        setFormLoader2({ ...formLoader2, [exchange_id]: false });
        setExchangeStatuses({ ...exchangeStatuses, [exchange_id]: !e });
        handleError(err);
      })
    } catch (e) {
      setFormLoader2({ ...formLoader2, [exchange_id]: false });
      setExchangeStatuses({ ...exchangeStatuses, [exchange_id]: !e });
      handleError(e)
    }

  }
  /*trend following settings**/
  const handleTrendSettingsChange = (e) => {
    setTrendSettings({
      ...trendSettings,
      [e.target.name]: e.target.value
    })
    trendSettings[e.target.name] = e.target.value;
    if(e.target.name == "trading_type" || e.target.name == "trend_method"){
      //need to change the default values for trendSettings
     
      let jsonData=[
        {
            "trading_type": "short_term",
            "TIMEFRAME": "15m",
            "EMA": "9,21",
            "SMA": "9,20",
            "SUPERTREND": "10,2",
            "MACD": "12,26,9"
        },
        {
            "trading_type": "medium_term",
            "TIMEFRAME": "4h",
            "EMA": "20,50",
            "SMA": "21,55",
            "SUPERTREND": "20,3",
            "MACD": "12,26,9"
        },
        {
            "trading_type": "long_term",
            "TIMEFRAME": "1d",
            "EMA": "50,200",
            "SMA": "50,200",
            "SUPERTREND": "30,9",
            "MACD": "24,52,18"
        }
    ]
    console.log("trendSettings",e.target.name,trendSettings,trendSettings.trading_type)
    const termData = jsonData.find(item => item.trading_type ===trendSettings.trading_type);
    console.log(termData.SMA)
    if(trendSettings.trend_method=="sma"){
      trendSettings.short_term=termData.SMA.split(',')[0]
      trendSettings.long_term=termData.SMA.split(',')[1]
      trendSettings.time_frame=termData.TIMEFRAME
    }else if(trendSettings.trend_method=="ema"){
      trendSettings.ema_short_term=termData.EMA.split(',')[0]
      trendSettings.ema_long_term=termData.EMA.split(',')[1]
      trendSettings.ema_time_frame=termData.TIMEFRAME
 
    }else if(trendSettings.trend_method=="supertrend"){
      trendSettings.st_atr_length=termData.SUPERTREND.split(',')[0]
      trendSettings.st_factor=termData.SUPERTREND.split(',')[1]
      trendSettings.st_time_frame=termData.TIMEFRAME

    }else if(trendSettings.trend_method=="macd"){
      trendSettings.macd_short_term=termData.MACD.split(',')[0]
      trendSettings.macd_long_term=termData.MACD.split(',')[1]
      trendSettings.macd_signal=termData.MACD.split(',')[2]
      trendSettings.macd_time_frame=termData.TIMEFRAME
    } 
  }
  setTrendSettings(trendSettings)
    setDefaultColorSetting({
      ...DefaultColorSetting,
      [e.target.name]: e.target.value
    })
  }

  const handleOrderSettingsChange = (e) => {
    setOrderSettings({
      ...orderSettings,
      [e.target.name]: e.target.value
    })
  }
  const handlePairOrderSettingsChange = (e) => {
    //console.log("pairOrderSettings",pairOrderSettings)
    setPairOrderSettings({
      ...pairOrderSettings,
      [e.target.name]: e.target.value
    })
    setPairTrendFollow({
      ...pairTrendFollow,
      [e.target.name]: e.target.value
    })
 
  }
  const handleOrderSettingsSliderChange = (value, field) => {
    setOrderSettings({
      ...orderSettings,
      [field]: value
    })
  }
  const handleTrendSettingsSliderChange = (value, field) => {
    setTrendSettings({
      ...trendSettings,
      [field]: value
    })
    setDefaultColorSetting({
      ...DefaultColorSetting,
      [field]: value
    })
  }
  const handleTrendSettingsTimeChange = (e, field) => {
    const order_get_hour = new Date(e).getHours()
    const order_get_minutes = new Date(e).getMinutes();
    let order_total_secs;
    if (order_get_hour == '0') {
      order_total_secs = Number(order_get_minutes) * 60;
    }
    else {
      const total_hours = Number(order_get_hour) * 3600;
      const total_minutes = Number(order_get_minutes) * 60;
      order_total_secs = total_hours + total_minutes;
    }

    setTrendSettings({
      ...trendSettings,
      [field + '_clock']: new Date(e).getHours() + ':' + new Date(e).getMinutes() + ':' + new Date(e).getSeconds(),
      [field]: order_total_secs
    })
    setDefaultColorSetting({
      ...DefaultColorSetting,
      [field + '_clock']: new Date(e).getHours() + ':' + new Date(e).getMinutes() + ':' + new Date(e).getSeconds(),
      [field]: order_total_secs
    })

  }
  useEffect(() => {   
    getSettings() 
    setSelectExchange2(true)
    setSelectExchange(true)    
    getActiveOrders()
    
    setTaillingTakeProfitMsg(TaillingTakeProfit());
    setMintakeProfitMsg(MinimumTakeProfit())
    setCredentialsSetupMsg(CredentialsSetupMSG(selectNewExchange))
    if(window.location.search && window.location.search.substring(1)=="pairs"){

      setactiveTab("4")

    }
    
  }, []);
  useEffect(() => {
    setCredentialsSetupMsg(CredentialsSetupMSG(selectNewExchange))
  }, [selectNewExchange]);
  useEffect(() => {
    if (deleteExchange == true) {
    getCCXTAllExchangesList();
    }
  }, [deleteExchange])
  useEffect(() => {
    if (ExchangeUpdated == true) {
    getCCXTAllExchangesList();
    }
  }, [ExchangeUpdated])
  useEffect(() => {
    if (ccxtAllExchanges.length) {
      getCCXTUserExchangesList();
    }
  }, [ccxtAllExchanges]);
  useEffect(() => {
    getCCXTAllExchangesList();
    if (ccxtAllExchanges.length) {
      getCCXTUserExchangesList();
    }
  }, [isfetch])

  useEffect(() => {
    if(tradeStatus == 1){
     if (ccxtUserExchanges.length) {
      //getCCXTUserExchangesList();
       if(document.getElementById('select_exchange_pair').value !== 'SelectExchange'){
         setSelectExchange(false)
         getSeparetePairsList(document.getElementById('select_exchange_pair').value)
       }
       else
       {
         setPairListLoading(false);
         setSelectExchange(true)
       }
     }
    }
   }, [ccxtUserExchanges]);

  // useEffect(()=>{
  //   getCCXTAllExchangesList();
  //   if (ccxtAllExchanges.length) {
  //     getCCXTUserExchangesList();      
  //   }
  // },[isfetch])


  useEffect(()=>{
    if(tradeStatus == 1 && activeTab == '4'){
        if (ccxtUserExchanges.length && pairsfetch === 0) {
            setpairsfetch(1)    
            get_user_all_pairs(ccxtUserExchanges[0].details.id)    
            getSeparetePairsList(document.getElementById('select_exchange_pair').value)    
          }    
    }
      },[ccxtUserExchanges,activeTab]); 

  // useEffect(() => {
  //   if (ccxtUserExchanges.length) {    
  //     if (document.getElementById('select_exchange').value !== 'SelectExchange')  {
  //       setSelectExchange(false)
  //     let exchange_id = document.getElementById('select_exchange').value;
  //     getBalanceDetails(exchange_id);
  //     }else{
  //       setFormLoader1(false);
  //       setSelectExchange(true)
  //     }
  //   }    
  // }, [ccxtUserExchanges]);
  useEffect(() => {
  if (ccxtUserBalances && Object.keys(ccxtUserBalances).length > 0){
    get_user_all_pairs_balance_error("SelectExchange")
  }  
  },[ccxtUserBalances])
  const [getuserpairsdata, setgetuserpairsdata] = useState([])
  const get_user_all_pairs_balance_error = async (exchange_id) => {
    try {
      reset();
      // setSelectedExchangeinPair(exchange.details.id)
      let pair_statuses = {}; let pair_settings = {};
      let balance_errors = []
      const balance_error_symbols = [];
        // await api({ contentType: true, auth: true }).get('/ccxt/exchange-pair-data').then((res) => {
          var dat = getuserpairsdata;
          if (dat !== null) {
          for (let i = 0; i < dat[0].length; i++){
            var data = dat[0][i].data;
            let data_settings=data.settings?JSON.parse(data.settings):''
            if (data_settings && data_settings.quantity_type !== 'balance_per' && data_settings.quantity_type !== 'balance_per_base' && data_settings.quantity > 0 && (data.status == '1' || data.status == 'true')) {
              let balance=ccxtUserBalances[data.exchange_id]       
                  
            let base_balance= balance[data.base]['free']
            let quote_balance= balance[data.quote]['free']

             if(indexPrices[data.id]*data_settings.quantity > quote_balance &&  !balance_error_symbols.includes(data.exchange_id+data.quote)){
              balance_error_symbols.push(data.exchange_id+data.quote)
              balance_errors.push(getExchangeName(data.exchange_id)+" - "+data.quote+" available balance is "+quote_balance+"  which is below the required amount of "+indexPrices[data.id]*data_settings.quantity+" "+data.quote)
              setBalanceErrors(balance_errors)
            }
          }else{
            let balance=ccxtUserBalances[data.exchange_id]
          let quote_balance= balance[data.quote]['free']
              let base_balance = balance[data.base]['free']
        
              if (data_settings.quantity_type == 'balance_per' && quote_balance <= 0 && data_settings.quantity > 0 && (data.status == '1' || data.status == 'true')) {
          
           if (!balance_error_symbols.includes(data.exchange_id + data.quote)) {
              balance_error_symbols.push(data.exchange_id+data.quote)
              balance_errors.push(getExchangeName(data.exchange_id)+" - "+data.quote+" don't have balance to place orders")
              }
              setBalanceErrors(balance_errors)
            }
              if (data_settings.quantity_type == 'balance_per_base' && quote_balance <= 0 && data_settings.quantity > 0 && (data.status == '1' || data.status == 'true')) {

                if (!balance_error_symbols.includes(data.exchange_id + data.quote)) {
                  balance_error_symbols.push(data.exchange_id + data.quote)

                  balance_errors.push(getExchangeName(data.exchange_id) + " - " + data.quote + " don't have balance to place orders")
                }
                setBalanceErrors(balance_errors)
              }
          }
          }
          }
        // }).then(() => {
        //   setBalanceErrors(balance_errors)
        // })
    }
    catch
    {

    }
  }
  const [IsLoadingDelete, setIsLoadingDelete] = useState(false);
  const get_user_all_pairs = async (exchange_id) => {
    try {
      reset();
      // setSelectedExchangeinPair(exchange.details.id)
      let pair_statuses = {}; let pair_settings = {};
      // let update_query = firestoreDb.collection("users").doc(uid).collection("ccxt_pairs").where('exchange_id', '==', exchange_id)
      //   .get().then(async (all_data) => {
      let balance_errors = []
      const balance_error_symbols = [];
        await api({ contentType: true, auth: true }).get('/ccxt/exchange-pair-data').then((res) => {
          var dat = [res.data.response.data];
          setgetuserpairsdata(dat)
          let orders_info = [];
          if (dat !== null) {
          for (let i = 0; i < dat[0].length; i++){
            var data = dat[0][i].data;
            let data_settings=data.settings?JSON.parse(data.settings):''
        
            if (data_settings && data_settings.quantity_type !== 'balance_per' && data_settings.quantity_type !== 'balance_per_base' && data_settings.quantity > 0 && (data.status == '1' || data.status == 'true')) {
              let balance=ccxtUserBalances[data.exchange_id]
              
              //Binance - USDT available balance is 0.25  which is below the required amount of 1 ETH
       
           // let balance = ccxtUserBalances;            
                  
            let base_balance= balance[data.base]['free']
            let quote_balance= balance[data.quote]['free']
           

            //  if(data_settings.quantity > base_balance){
            //   // toast.error("Insufficient balance on your base currency "+data.base)
            //   balance_errors.push(getExchangeName(data.exchange_id)+" - "+data.base+" available balance is "+base_balance+"  which is below the required amount of "+data_settings.quantity+" "+data.base)
            //  // console.log("balance_errors1",balance_errors)
            //   setBalanceErrors(balance_errors)
            //  }

             if(indexPrices[data.id]*data_settings.quantity > quote_balance &&  !balance_error_symbols.includes(data.exchange_id+data.quote)){
              balance_error_symbols.push(data.exchange_id+data.quote)
              balance_errors.push(getExchangeName(data.exchange_id)+" - "+data.quote+" available balance is "+quote_balance+"  which is below the required amount of "+indexPrices[data.id]*data_settings.quantity+" "+data.quote)
              setBalanceErrors(balance_errors)
              //console.log("balance_errors2",balance_errors)
             
             
         
            ////////////////
            }
          }else{
            let balance=ccxtUserBalances[data.exchange_id]
              
            //Binance - USDT available balance is 0.25  which is below the required amount of 1 ETH
     
         // let balance = ccxtUserBalances;            
                
         
          let quote_balance= balance[data.quote]['free']
              let base_balance = balance[data.base]['free']
        
              if (data_settings.quantity_type == 'balance_per' && quote_balance <= 0 && data_settings.quantity > 0 && (data.status == '1' || data.status == 'true')) {
          
           if (!balance_error_symbols.includes(data.exchange_id + data.quote)) {
              balance_error_symbols.push(data.exchange_id+data.quote)
             
              balance_errors.push(getExchangeName(data.exchange_id)+" - "+data.quote+" don't have balance to place orders")
              console.log("balance_errors",balance_errors)
              }
              setBalanceErrors(balance_errors)
           
            }
              if (data_settings.quantity_type == 'balance_per_base' && quote_balance <= 0 && data_settings.quantity > 0 && (data.status == '1' || data.status == 'true')) {

                if (!balance_error_symbols.includes(data.exchange_id + data.quote)) {
                  balance_error_symbols.push(data.exchange_id + data.quote)

                  balance_errors.push(getExchangeName(data.exchange_id) + " - " + data.quote + " don't have balance to place orders")
                  console.log("balance_errors", balance_errors)
                }
                setBalanceErrors(balance_errors)

              }
          }

            if (dat[0][i]['exchange_id'] === exchange_id) {
              var data = dat[0][i];
              if (!data.settings) { data.settings = {} };
              data.settings.quantity = data.qty;
              orders_info.push(data);
              //setUsersPair(orders_info)
              pair_statuses[data.id] = data.status == '1' ? true : false;
              pair_settings[data.id] = data;
  
              setPairStatus(pair_statuses);
              setPairSettings(pair_settings);
              ////////////////
              




            }
          }

          setCcxtUserPairs({"data":res.data.response.data === null ? [] : res.data.response.data})
        }


          let orders_infos = _.sortBy(orders_info, 'id');

          //console.log('orders_infos....',orders_infos)

          orders_infos.map((data, index) => {
            setValue(`pair_qty[${index}]`, data.qty, { shouldValidate: false });
            //console.log('datadatadata....',data.exchange_id+data.id)
            // if(data.status)
            // {
            //   setPairStatus({ ...pairStatus, [data.exchange_id+data.id.toString()] :  true });
            // }
            //setPairStatus({ ...pairStatus, [data.exchange_id+data.id.toString()] :  true });
            //setPairStatus()
            //pairStatus[exchange.exchange_id+exchange.id]

          })

        }).then(() => {
          setBalanceErrors(balance_errors)
        })
    }
    catch
    {

    }
  }

  const handleWheel = (e) => {
    e.target.blur();
  };

  const [UpdateDataValue, setUpdateDataValue] = useState([])
  const [SelectedExchange, setSelectedExchange] = useState('')
  const [NoData, setNoData] = useState(false)
  const HandleNoData = () => {
    let selected_exchange = document.getElementById('select_exchange').value;
    let a = ccxtUserExchanges.filter((item) => item.exchange_id === selected_exchange)
    a.length === 0 ? setNoData(true) : setNoData(false)
  }
  const convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  const [summary, setSummary] = useState({});
  const [enddate, setenddate] = useState('')
  const [startdate, setstartdate] = useState('')
  const [filter_symbole,setfilter_symbole]= useState('all')
  const [symbolfilterhide,setsymbolfilterhide] = useState(false)
  const [filter_dropdown,setfilter_dropdown]= useState([])

  function getStartAndEndOfWeek(date) {
    const dayOfWeek = date.getDay();
    const start = new Date(date);
    if(filterDate == "last") {
      start.setDate(date.getDate() - (dayOfWeek === 0 ? 8 :dayOfWeek + 8 ));
    }else{
      start.setDate(date.getDate() -  (dayOfWeek === 0 ? 1 : dayOfWeek + 1));
    }
    const end = new Date(start);
    end.setDate(start.getDate() + 7);
    return { start, end };
}

function getStartAndEndOfMonth(date) {
  let start,end;
  if(filterDate == "this_month") {
     start = new Date(date.getFullYear(), date.getMonth(), 1);
     start = new Date(start - 1);
     end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }else {
     start = new Date(date.getFullYear(), date.getMonth() - 1, 1);
     start = new Date(start - 1);
     end = new Date(date.getFullYear(), date.getMonth(), 0);
  }
  return { start, end };
}

function getCurrentAndLastQuarterDates(now) {
  const currentMonth = now.getMonth();
  let currentYear = now.getFullYear();

  // Determine the current quarter
  let currentQuarter = Math.floor(currentMonth / 3) + 1;
  let startMonth, endMonth,start,end;

  // Get the current quarter's start and end dates
  // const thisQuarter = getQuarterStartAndEnd(currentYear, currentQuarter);
    if(filterDate == "last_quarter") {
      if (currentQuarter === 1) {
        // Last quarter was Q4 of the previous year
        currentQuarter = 4;
        currentYear = currentYear - 1;
      } else {
         currentQuarter = currentQuarter - 1;
          // lastQuarterYear = currentYear;
      }
    }

    switch (currentQuarter) {
      case 1:
          startMonth = 0;  
          endMonth = 2;   
          break;
      case 2:
          startMonth = 3; 
          endMonth = 5;    
          break;
      case 3:
          startMonth = 6;
          endMonth = 8;  
          break;
      case 4:
          startMonth = 9; 
          endMonth = 11;  
          break;
  }

  start = new Date(currentYear, startMonth, 1);
  start = new Date(start - 1);
  end = new Date(currentYear, endMonth + 1, 0);

  return { start, end };
}

  const handleChartFilter = (e) => {
    setFilterDate(e.target.value);
    setTopList({})
  }

  var date = new Date();
  useEffect(() => {
    if(activeTab == '5'){
    let {sDate,eDate }= getMonday();
      var exchange_id = document.getElementById('select_exchange_graph') !== null ? document.getElementById('select_exchange_graph').value : 'SelectedExchange';
      setSummary({}) 
      fetchPNLData(sDate, eDate, exchange_id);
    }
  }, [viewGraph,filterDate,filter_symbole,activeTab]);
  const [Currency,setCurrency] = useState([])
  function getMonday() {
    let sDate,eDate
    let d = new Date();

    if(filterDate == "last"){

      const lastWeek = getStartAndEndOfWeek(d);
      sDate = lastWeek.start;
      eDate = lastWeek.end;
    }else if(filterDate == "this"){
      const thisWeek = getStartAndEndOfWeek(d);
      sDate = thisWeek.start;
      eDate = thisWeek.end;
    }
    else if(filterDate == "this_month")
    {
      const thisMonth = getStartAndEndOfMonth(d);
      sDate = thisMonth.start;
      eDate = thisMonth.end; 
    }else if(filterDate == "last_month")
    {
      const lastMonth = getStartAndEndOfMonth(d);
      sDate = lastMonth.start;
      eDate = lastMonth.end;
    }else if(filterDate == "this_quarter")
    {
      const thisQuarter = getCurrentAndLastQuarterDates(d);
      sDate = thisQuarter.start;
      eDate = thisQuarter.end; 
    }else if(filterDate == "last_quarter")
    {
      const lastQuarter = getCurrentAndLastQuarterDates(d);
      sDate = lastQuarter.start;
      eDate = lastQuarter.end;
    }else{
    let diff = d.getDate() - filterDate;
    var currentMonday = new Date(d.setDate(diff));
    sDate = `${currentMonday.getFullYear()}-${(currentMonday.getMonth() + 1).toString().length === 1 ? '0' + (currentMonday.getMonth() + 1) : currentMonday.getMonth() + 1}-${(currentMonday.getDate()).toString().length === 1 ? '0' + (currentMonday.getDate()) : currentMonday.getDate()}`
    eDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().length === 1 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}-${(date.getDate()).toString().length === 1 ? '0' + (date.getDate()) : date.getDate()}`
    }
    // setenddate(`${date.getFullYear()}-${(date.getMonth() + 1).toString().length === 1 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}-${(date.getDate()).toString().length === 1 ? '0' + (date.getDate()) : date.getDate()}`)
    return {sDate,eDate};
  }
  
  // const [default_value,setdefault] = useState(false)
  let list = []
  function onlyUnique(value, index, array) {
    if (list.includes(value.data.symbol) === false){
      list.push(value.data.symbol)
      setsymbolfilterhide(false)
    }
    // return array.indexOf(value.data.symbol) === index;
  }
  // useEffect(() => {
  //   if(tradeStatus==1){
  //   setenddate(`${date.getFullYear()}-${(date.getMonth() + 1).toString().length === 1 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}-${(date.getDate()).toString().length === 1 ? '0' + (date.getDate()) : date.getDate()}`)
  //   let sDate = getMonday();
  //     var exchange_id = document.getElementById('select_exchange_graph') !== null ? document.getElementById('select_exchange_graph').value : 'SelectedExchange';
  //     setSummary({})
  //     fetchPNLData(uid, sDate, enddate, exchange_id);
  //   }
  // }, [filter_symbole]);
  // usage example:
  const aggregateData = (graph_data) => {
    const groupedData = {};
  
    graph_data.forEach(item => {
      const { time, pnl_data, org_pnl, org_currency, currency } = item;
  
      if (!groupedData[time]) {
        groupedData[time] = { pnl_data: 0, org_pnl: 0, org_currency, currency, time };
      }
  
      groupedData[time].pnl_data += parseFloat(pnl_data);
      groupedData[time].org_pnl += parseFloat(org_pnl);
    });
  
    // Convert the grouped data object back to an array
    return Object.values(groupedData).map(item => ({
      ...item,
      pnl_data: item.pnl_data.toString(),
      org_pnl: item.org_pnl.toString()
    }));
  };
  const sumByCurrency = (graph_data) => {
    const result = {};
  
    graph_data.forEach(item => {
      const { currency, pnl_data } = item;
  
      if (!result[currency]) {
        result[currency] = 0;
      }
  
      result[currency] += parseFloat(pnl_data);
    });
  
    return result;
  };
  const fetchPNLData = async (startdate, enddate,exchange_id) => {

    setFormLoader5({
      loading: true,
    })
    // setdefault(false)
    const datestart = new Date(startdate);
    const dateend = new Date(enddate);
    // var time_difference = dateend.getTime() - datestart.getTime();
    // var days_difference = time_difference / (1000 * 60 * 60 * 24);
    let graph_data = '';
    var startdate1;
    var enddate1;
    // let total_pnl_val = '';
    // let graph_data_arr = '';
    var pnls = {};
    let cur = [];
    let sevendays_pnl=0
    let stocks = {};
    let today_date = date.getDate().toString().padStart(2, '0')
    let current_mon = (date.getMonth()+1).toString().padStart(2, '0')
    let today_pnl = 0.00;
      let currency = [];
      startdate1 = (new Date(startdate));

      startdate1.setDate(datestart.getDate()) - 1

      enddate1 = (new Date(enddate));

      enddate1.setDate(dateend.getDate())

      var time = new Date();

      let converted_start_date = new Date(convert(time));

      converted_start_date.setHours(0, 0, 0, 0);

      let converted_end_date2 = new Date(convert(time));

      converted_end_date2.setHours(23, 59, 59, 999);


      var start = ((`${new Date(startdate1).getFullYear()}-${(new Date(startdate1).getMonth() + 1).toString().length === 1 ? '0' + (new Date(startdate1).getMonth() + 1) : new Date(startdate1).getMonth() + 1}-${startdate1.getDate().toString().length === 1 ? '0' + startdate1.getDate() : startdate1.getDate()}${converted_start_date.toISOString().slice(10, -1)}z`).toString())

      var end = ((`${new Date(enddate1).getFullYear()}-${(new Date(enddate1).getMonth() + 1).toString().length === 1 ? '0' + (new Date(enddate1).getMonth() + 1) : new Date(enddate1).getMonth() + 1}-${enddate1.getDate().toString().length === 1 ? '0' + enddate1.getDate() : enddate1.getDate()}${converted_end_date2.toISOString().slice(10, -1)}z`).toString())

      // var firestoreDb1 = firestoreDb.collection("users").doc(uid);

      let dates;
      var body = {
          filterStartDate: start,
          filterEndDate: end,
          filter_exchange: exchange_id
      }
      await api({ contentType: true, auth: true }).post(exchange_id === 'SelectedExchange' ? '/CCXT/ccxt-completed-orders-history':'/CCXT/ccxt-completed-orders-history-1', body).then(async (res) => {
        var Result = res.data.response.data;
        if(filter_symbole === "all") {
          Result.filter(onlyUnique)
          setfilter_dropdown(list)
        }

         // Result.forEach(function (doc) {

        //   var data = doc.data;

        //   data.id = doc.id;

        //   data.expiry_date = formatUTCDate(data.expiry_date);

        //   data.created_at = getSettingsDateTimeFormat(data.created_at);

        //   data.triggered_at = data.triggered_at ? getSettingsDateTimeFormat(data.triggered_at) : data.updated_at;

        //   orders_info.push(data);

        // });
        
        let orders_info = [];

        const datas = Result.reduce((acc, obj) => {
          if (obj.data.status === 'closed') {

          // obj.data.expiry_date = formatUTCDate(obj.data.expiry_date);

          obj.data.created_at = getSettingsDateTimeFormat(obj.data.created_at);

          // obj.data.triggered_at = obj.data.triggered_at ? getSettingsDateTimeFormat(obj.data.triggered_at) : getSettingsDateTimeFormat(obj.data.updated_at);

          // const key = obj.data.created_at.split(' ')[0].replace(/\//g, "-"); // Group by the `created_at` field

          const [day, month, year] = obj.data.created_at.split(" ")[0].split('/');

          // Rearrange and join the date components in the desired format
          let key;
          if(filterDate == "this_quarter" || filterDate == "last_quarter") {
            key = `${year}-${month}`
          }else{
            key = `${year}-${month}-${day}`;
          }
          // const key = obj.data.created_at.split('T')[0]; // Group by the `created_at` field

          if (!acc[key]) {
            acc[key] = []; // Initialize an array if it doesn’t exist for this key
          }
          acc[key].push(obj.data); // Add the object to the array
          }
          return acc;
        }, {});

        const keys = Object.keys(datas);

        // Reverse the array of keys
        const reversedKeys = keys.reverse();
        for(let key of reversedKeys) {
         orders_info = datas[key];

        let pnl = [];
        let totalpnl = {};
        let todayPnl = {};
        if (filter_symbole === 'all') {
          orders_info = orders_info
        }
        else if (filter_symbole !== 'all') {
          orders_info = orders_info.filter(order => order.symbol === filter_symbole);
        }

        let total_pnl = orders_info.filter(order => order.status === 'closed').reduce((sum, item) => {
          // console.log(item,"itemmmmmmmm");
          if (item.pnl_value && item.pnl_value !== "") {
            // if(Object.keys(topList).length == 0){
              if (stocks.hasOwnProperty(item.symbol)) {
                if(item.pnl_value >= 0){
                  stocks[item.symbol].gain_pnl += item.pnl_value; 
                }else{
                  stocks[item.symbol].lose_pnl += item.pnl_value; 
                }
              } else {
                if(item.pnl_value >= 0){
                  stocks[item.symbol] = { gain_pnl:item.pnl_value,lose_pnl:0,quote:item.settings.quote,exchange:item.exchange_id}
                }else{
                  stocks[item.symbol] = { gain_pnl:0,lose_pnl:item.pnl_value,quote:item.settings.quote,exchange:item.exchange_id}
                }
              }
            // }
            // console.log("item.pnl_value", item.pnl_value,"--------",item.settings.quote)
              if (totalpnl[item.settings.quote]) {
                  totalpnl[item.settings.quote] =  (Number(totalpnl[item.settings.quote]) + parseFloat(item.pnl_value ? Number(item.pnl_value).toFixed(6) : 0)).toFixed(4);
                  if (currency.includes(item.settings.quote) === false){
                    currency.push(item.settings.quote)
                  } 
                  // if (cur.includes(item.settings.quote) === false){
                  //   cur.push(item.settings.quote)
                  // }
                  pnls[item.settings.quote] =(parseFloat(pnls[item.settings.quote]) + parseFloat(item.pnl_value ? Number(item.pnl_value).toFixed(6):0)).toFixed(4)
              } else {
                  totalpnl[item.settings.quote] = parseFloat(item.pnl_value ? Number(item.pnl_value).toFixed(6) : 0).toFixed(4);
                  if (currency.includes(item.settings.quote) === false){
                      currency.push(item.settings.quote)
                    }
                    // if (cur.includes(item.settings.quote) === false){
                    //   cur.push(item.settings.quote)
                    // }
                  pnls[item.settings.quote] =(parseFloat(pnls[item.settings.quote] !== undefined ? pnls[item.settings.quote] : 0) +  parseFloat(item.pnl_value ? Number(item.pnl_value).toFixed(6):0)).toFixed(4)
              }
          }
          if (item.created_at !== undefined) {

           
             let created_at = item.created_at.split(" ")[0].split('/');
             let created_mon = created_at[1] ? created_at[1]:""
             dates = created_at[0]?created_at[0]:"";
             
            if(dates == today_date && current_mon == created_mon) {
              if (todayPnl[item.settings.quote]) {
                todayPnl[item.settings.quote] =  (Number(todayPnl[item.settings.quote]) + parseFloat(item.pnl_value ? Number(item.pnl_value).toFixed(6) : 0)).toFixed(4);
              }else{
                  todayPnl[item.settings.quote] = parseFloat(item.pnl_value ? Number(item.pnl_value).toFixed(6) : 0).toFixed(4);
                }
            }
          } 
          return Number(sum) + Number(pnl);

        }, 0);
        pnl.push(totalpnl)

        if (pnl.length > 0 && dates !== undefined) {
        
          for (let i = 0;i < currency.length;i++){
            let currencyval=currency[i]
            let totalpnlval=totalpnl[currency[i]]
            let org_pnl=totalpnlval
            let org_currency=currencyval
           
            // console.log("sevendays_pnl not convert",sevendays_pnl)
            let exchangeRateval
            if (totalpnl[currency[i]] !== undefined){
             
              if(currency[i]!=="USDT"){
                if(exchangeRate[currencyval]){
                
                  exchangeRateval=exchangeRate[currencyval]
                }else{
                 
                  await api({ contentType: true, auth: true }).post('/CCXT/get-exchage-rate', {from: currency[i],to:'USDT'}).then(async (res) => {
                  
                    if(res.data.response&& res.data.response.data && res.data.response.data.rate!==''){
                    exchangeRateval=res.data.response.data.rate
                   
                    exchangeRate[currencyval]=exchangeRateval
                    setExchangeRate(exchangeRate)
                    }
                  });
                }
                // console.log("exchangeRateval",exchangeRateval)
                if(exchangeRateval){
                
                  // console.log("currency beforeremove",currency)
              
                totalpnlval=totalpnl[currency[i]]*Number(exchangeRateval)
              
                sevendays_pnl=Number(sevendays_pnl)+(Number(pnls[currencyval])*Number(exchangeRateval))
               
                currencyval="USDT"
              
                }else{
                  totalpnlval=totalpnl[currency[i]]
              
                sevendays_pnl=Number(sevendays_pnl)+(Number(pnls[currencyval]))
                }
              }else{
                 sevendays_pnl=Number(sevendays_pnl)+Number(pnls[currencyval])
              }
            }
            let time_date;
            if(filterDate =="this_quarter" || filterDate == "last_quarter") {
              let key_date = new Date(key);
              time_date = key_date.toString().slice(4, 7)+" "+ key_date.getFullYear();
            }else{
             time_date = `${dates}/${(new Date(key).getMonth() + 1).toString().length === 1 ? '0' + (new Date(key).getMonth() + 1) : new Date(key).getMonth() + 1}`
            }
          if (graph_data.length == 0) {
            if (totalpnlval !== undefined){
            graph_data = ('[{ "pnl_data": "' +  totalpnlval + '","org_pnl":"'+org_pnl+ '","org_currency":"'+org_currency+'","currency":"'+currencyval+'","time":"' + time_date + '"}');
            }
          } else {
            if (totalpnlval !== undefined){
            graph_data = graph_data + (',{ "pnl_data": "' + totalpnlval  + '","org_pnl":"'+org_pnl+ '","org_currency":"'+org_currency+'","currency":"'+currencyval+'","time":"' + time_date + '"}');
            }
          }

            const graph_data_val = aggregateData(JSON.parse(graph_data + ']'));
            
            const summedPnlByCurrency = sumByCurrency(JSON.parse(graph_data + ']'));
          
          if(Object.keys(todayPnl).length && todayPnl[currency[i]] !== undefined) {
              if(currency[i] !="USDT") {
                today_pnl = Number(today_pnl) + Number(todayPnl[currency[i]]) * Number(exchangeRate[currency[i]]);
              }else{
                today_pnl = Number(today_pnl) + Number(todayPnl[currency[i]]);
              }
              delete todayPnl[currency[i]];
          }

            setSummary({
              ...summary,
              currency : currencyval,
              total_pnl: today_pnl,
              _7days_pnl: summedPnlByCurrency ,
              graph_data: graph_data_val
            });
        }
        }
      }


      })

    // }
    setCurrency(cur)
    setFormLoader5({
      loading: false,
    })
    // console.log(summary,"graphhhhh");
    // if(Object.keys(topList).length == 0 && Object.keys(stocks).length > 0){
    if(Object.keys(stocks).length > 0){
      let gainers = {};
      let losers = {};
      let total_gain = 0
      let total_lose = 0
      for (let symbol in stocks) {
          if(stocks[symbol].quote != "USDT") {
            stocks[symbol].gain_pnl = stocks[symbol].gain_pnl * exchangeRate[stocks[symbol].quote]
            stocks[symbol].lose_pnl = stocks[symbol].lose_pnl * exchangeRate[stocks[symbol].quote]
          }
          // if (stocks[symbol].gain_pnl > 0) {
            gainers[symbol] = stocks[symbol];
            total_gain += stocks[symbol].gain_pnl
          // } else {
            losers[symbol] = stocks[symbol];
            total_lose += stocks[symbol].lose_pnl;
          // }
      }

      setTotalPNL({total_gain:total_gain,total_lose:total_lose})
      let gainersArray = Object.entries(gainers);
      let losersArray = Object.entries(losers);
      gainersArray.sort(([, a], [, b]) => b.gain_pnl - a.gain_pnl);
      losersArray.sort(([, a], [, b]) => a.lose_pnl - b.lose_pnl);
      let sortedGainers = Object.fromEntries(gainersArray);
      let sortedLosers = Object.fromEntries(losersArray);
      setTopList({gainers:sortedGainers,losers:sortedLosers})
    }else{
      setTotalPNL({total_gain:0,total_lose:0})
      setTopList({})
    }
  }
  if (ToolTipData=="" && ToolTipData.length === 0) {
    return <div>Loading...</div>;
  }else{
    // console.log(ToolTipData)
  }
  // Function to group and sum the pnl_data and org_pnl fields
 
  
 const arrangeSettingsArray = (settinginfo)=>{
  const formatSetting = (label, value) => (
    <span>
      {label}: <b className="text-info">{value}</b>
    </span>
  );

  const settingsArray = [
      settinginfo && settinginfo.quantity && formatSetting('Quantity', `${(settinginfo.quantity || settinginfo.qty)}${settinginfo.quantity_type == "balance_per" || settinginfo.quantity_type == "balance_per_base" ? "% of margin" : ""}`),
    settinginfo && settinginfo.fill_type && formatSetting('Fill type', settinginfo.fill_type === 'instant_fill' ? 'Instant Fill' : 'Wait and Fill'),
    settinginfo && settinginfo.price_distance_percentage && settinginfo.fill_type !== 'instant_fill' && formatSetting('Price Distance % for Wait and Fill', settinginfo.price_distance_percentage),
    settinginfo && settinginfo.stop_loss && formatSetting('Market Stop Loss', `${settinginfo.stop_loss}${settinginfo.stop_loss_type === "points" ? 'Pts' : "%"}`),
    settinginfo && settinginfo.min_take_profit_percentage && formatSetting('Minimum Take Profit%', settinginfo.min_take_profit_percentage),
    settinginfo && settinginfo.trailing_take_profit_percentage && formatSetting('Trailing Take Profit%', settinginfo.trailing_take_profit_percentage),
    settinginfo && settinginfo.waiting_time_for_next_order_stop_loss && formatSetting('Waiting Time for Order after Stop Loss', timeFormat(settinginfo.waiting_time_for_next_order_stop_loss)),
    settinginfo && settinginfo.waiting_time_for_next_order_trailing_profit && formatSetting('Waiting Time for Order after Trailing Take Profit', timeFormat(settinginfo.waiting_time_for_next_order_trailing_profit)),
    settinginfo && settinginfo.order_side && formatSetting('Order Side', settinginfo.order_side),
    settinginfo && settinginfo.order_side === "sell" && settinginfo.min_sell_price && formatSetting('Minimum Sell Price', settinginfo.min_sell_price),
    settinginfo && settinginfo.order_side === "buy" && settinginfo.max_buy_price && formatSetting('Maximum Buy Price', settinginfo.max_buy_price)
  ].filter(Boolean);
   
  
    const orderSettingArray = (arr, size) => {
      const result = [];
      for (let i = 0; i < arr.length; i += size) {
        result.push(arr.slice(i, i + size));
      }
      return result;
    };
  
    const orderSettings = orderSettingArray(settingsArray, 5);
    return orderSettings;
  }
  return (
    tradeStatus == 1 ?
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Col lg="6" sm="3" style={{"margin":"0px auto"}}>
        {balance_errors.length > 0 ?
              <Alert color="danger">
                  <i className="mdi mdi-alert-outline me-2"></i><strong>Insufficient Funds Alert</strong><br />
                  { balance_errors.map((item,index) => {
                    return(
                      <React.Fragment key={index}>
                        {item}<br />
                      </React.Fragment>
                    )
                  }
                  )}
                <br />
                To avoid any disruptions or order placement problems, we recommend maintaining a suitable balance in your account.
                </Alert> : ''}
                </Col>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="DeriSnap" breadcrumbItem="Settings" />
          

          <div className="checkout-tabs">
            <Row>
             
            <Col lg="2" sm="3">
                <Nav className="flex-column" pills>
                <NavItem>
                  <Link
                    to="/ccxt/settings"
                    className={classnames("nav-link", { 
                      active: activeTab === "1" && location.pathname === "/ccxt/settings" && !location.search 
                    })}
                    onClick={() => {
                      setactiveTab("1");
                      document.getElementById('select_exchange').value = 'SelectExchange';
                      setSelectExchange2(true);
                      setSelectExchange(true);
                    }}
                  >
                    <i className="bx bx-move-horizontal bx-border-circle border-white bx-tada-hover check-nav-icon mt-4 mb-2" />
                    <p className="fw-bold mb-4">Exchanges</p>
                  </Link>
                </NavItem>

                <NavItem>
                  <Link
                    to="/ccxt/settings?pairs"
                    className={classnames("nav-link", { 
                      active: activeTab === "4" && location.pathname === "/ccxt/settings" && location.search === "?pairs" 
                    })}
                    onClick={() => {
                      setactiveTab("4");
                      setSelectExchange2(true);
                      setSelectExchange(true);
                      setPairListLoading(true);
                      if (ccxtUserExchanges.length > 0) {
                        setviewPair("SelectExchange");
                        document.getElementById('select_exchange_pair').value = 'SelectExchange';
                        if (pairsfetch === 1) {
                          getSeparetePairsList("SelectExchange");
                          get_user_all_pairs("SelectExchange");
                        }
                      } else {
                        document.getElementById('select_exchange_pair').value = 'SelectExchange';
                        setSelectExchange(true);
                        getSeparetePairsList("SelectExchange");
                        get_user_all_pairs("SelectExchange");
                      }
                    }}
                  >
                    <i className="bx bx-stats bx-border-circle border-white bx-tada-hover check-nav-icon mt-4 mb-2" />
                    <p className="fw-bold mb-4">Trade</p>
                  </Link>
                </NavItem>
                  {/* <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        setactiveTab("2")

                      }}
                    >
                      <i className="bx bx-line-chart d-block check-nav-icon mt-4 mb-2" />
                      <p className="fw-bold mb-4">Trend Following Settings</p>
                    </NavLink>
                  </NavItem> */}
                 {/** <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "3" })}
                      onClick={() => {
                        setactiveTab("3")
                      }}
                    >
                      <i className="bx bx-cog d-block check-nav-icon mt-4 mb-2" />
                      <p className="fw-bold mb-4">Order Settings</p>
                    </NavLink>
                  </NavItem> 
                   <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "5" })}
                      onClick={() => {
                        setactiveTab("5")

                      }}
                    >
                      <i className="dripicons-graph-line d-block check-nav-icon mt-4 mb-2" />
                      {/* <p className="fw-bold mb-4">Last 7 days PNL ( {summary.total_pnl ? (<span className={parseFloat(summary.total_pnl) < 0 ? "text-danger" : "text-success"}>{summary.total_pnl.toFixed(2)} USDT</span>) : <snap className="text-danger">NA</snap>} )</p> 
                    </NavLink>
                  </NavItem>*/}
                  <NavItem>
                    <NavLink
                      className={classnames("nav-link", { active: activeTab === "5" })}
                      onClick={() => {
                        setactiveTab("5");
                        document.getElementById('select_exchange_graph').value = 'SelectedExchange';
                        setviewGraph('SelectedExchange');
                      }}
                    >
                      <i className="dripicons-graph-line d-block check-nav-icon mt-4 mb-2" />
                      <p className="fw-bold mb-4">Last 7 days PNL</p>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col lg="10" sm="9">
                <Card className="box_4">
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                      {!isLoading ? 
                        <Row>
                          <Col xl={12}>
                            <Card className="border">
                              <CardBody>
                                <Row>
                                  <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-2 col-form-label"
                                  >
                                    Add Exchange
                                  </Label>
                                  
                                  <Col sm={4}>
                                    <select id="select_exchange" className="form-select" defaultValue="SelectExchange" onChange={(e) => { e.target.value !== 'SelectExchange' ? setSelectExchange2(false) : setSelectExchange2(true),setSeparatePairs([]), HandleNoData(), setSelectedExchange(e.target.value) }}>
                                      <option value="SelectExchange">Select Exchange</option>
                                      {ccxtUnSelectExchanges.map((exchange, index) => {
                                        return (
                                          <React.Fragment key={index}>
                                            <option value={exchange.id}> {exchange.name} </option>
                                          </React.Fragment>
                                        )
                                        // })
                                      })}
                                    </select>
                                  </Col>
                                    
                                  <Col sm={2}>
                                    {!SelectExchange2 ? (
                                      <button className="btn btn-primary me-1" onClick={() => {
                                        let selected_exchange = document.getElementById('select_exchange').value;
                                        let is_added = ccxtUserExchanges.find(item => item.exchange_id === selected_exchange);
                                        if (!is_added) {
                                          setVisible(!visible);
                                          setSelectNewExchange(selected_exchange);
                                          setEditExchange(false);
                                          setEditExchangeValues({});
                                        } else {
                                          toast.error('Exchange already added', { toastId: 'exchange_already_added' })
                                        }
                                      }}>
                                        <i className="mdi mdi-plus me-1"></i> Add Exchange
                                      </button>
                                    ) : ""
                                    }
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                        :''}
                        <Row>
                          {(!isLoading && ccxtUserExchanges && ccxtUserExchanges.length ?
                            ccxtUserExchanges.map((exchange, index) => {
                              return (
                                // NoData && index === ccxtUserExchanges.length-1? (
                                //   <Col xl={12}>
                                //   <div className="p-4 border text-center">
                                //     <h6 className="text-muted">No Exhange found</h6>
                                //   </div>
                                // </Col>
                                // ) : 
                                <Col xl={4} key={index}>
                                  <Card className="border">
                                    <CardTitle className="px-4 pt-4">
                                      <a href={exchange.details.urls && exchange.details.urls.www ? exchange.details.urls.www : "#"} target={exchange.details.urls && exchange.details.urls.www ? "self" : ""}>{exchange.details.name}</a>
                                      {/* <button className="btn btn-primary  btn-sm float-end" onClick={async () => {
                                        setFormLoader4(true)
                                        setPairVisible(!pairVisible);
                                        setSelectNewExchange(exchange.exchange_id);
                                        await getCCXTExchangePairsList(exchange.exchange_id);
                                        await setupCCXTUserExchangePairs(exchange.exchange_id);
                                        setFormLoader4(false);

                                      }}>Update Pairs</button> */}
                                      <button className="btn btn-danger  btn-sm float-end mx-2 mdi mdi-delete" onClick={() => {
                                        // setdeleteExchange(true);
                                        setSelectNewExchange(exchange.exchange_id);
                                        ActiveOrders(exchange.exchange_id);
                                        // setEditExchangeValues({
                                        //   appKey: exchange.display_apikey,
                                        //   appSecret: exchange.display_api_secret
                                        // }  )
                                      }}></button>
                                      <button className="btn btn-success  btn-sm float-end mx-2" onClick={() => {
                                        setVisible(!visible);
                                        setEditExchange(true);
                                        setSelectNewExchange(exchange.exchange_id);
                                        setEditExchangeValues({
                                          appKey: exchange.display_apikey,
                                          appSecret: exchange.display_api_secret
                                        })
                                      }}>Update Key</button>
                                    </CardTitle>

                                    <CardBody>
                                      <Row>
                                        <Label
                                          htmlFor="formrow-firstname-Input"
                                          md="4"
                                          className="col-form-label"
                                        >
                                          API Key
                                        </Label>
                                        <Col md="8">
                                          <Label
                                            htmlFor="formrow-firstname-Input"
                                            md="5"
                                            className="input-border-less"
                                          >{exchange.display_apikey}</Label>
                                          {/* <input
                                            type="text"
                                            value={exchange.display_apikey}
                                            readOnly
                                            // disabled
                                            className="form-control"
                                            style={{border:"none"}}
                                          /> */}
                                        </Col>
                                      </Row>
                                      <FormGroup className="" row>
                                        <Label
                                          htmlFor="formrow-firstname-Input"
                                          md="4"
                                          className="col-form-label"
                                        >
                                          API Secret
                                        </Label>
                                        <Col md="8">
                                          <Label
                                            htmlFor="formrow-firstname-Input"
                                            md="5"
                                            disabled
                                            className="input-border-less"
                                          >{exchange.display_api_secret}</Label>
                                          {/* <input
                                            type="text"
                                            value={exchange.display_api_secret}
                                            readOnly
                                            // disabled
                                            className="form-control"
                                            style={{border:"none"}} 
                                          /> */}
                                        </Col>
                                      </FormGroup>
                                      <FormGroup className="" row>
                                        <Label
                                          htmlFor="formrow-firstname-Input"
                                          md="4"
                                          className="col-form-label"
                                        >
                                          Enabled
                                        </Label>
                                        <Col md="8">
                                          <Switch
                                            uncheckedIcon={<Offsymbol />}
                                            checkedIcon={<OnSymbol />}
                                            className="me-1 mb-sm-8 mb-2"
                                            onChange={(e) => {
                                              changeExchangeStatus(exchange.exchange_id, e);
                                            }}
                                            checked={exchangeStatuses[exchange.exchange_id]}
                                          />
                                          {formLoader2[exchange.exchange_id] ? <span><i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Processing... </span> : ''}
                                        </Col>
                                      </FormGroup>
                                      {exchange.exchange_id !== 'binance_us_paper_trade' ? <ExchangeForm
                                        key={index}
                                        item={exchange}
                                        index={index}
                                        handleSubmit={handleSettingsSubmit}
                                      /> : ""}  
                                     
                                      {/* <Row>
                                        <div className="col-lg-12">
                                        <Dropdown
                                          isOpen={otherMenu}
                                          toggle={() => {
                                            setOtherMenu(!otherMenu);
                                          }}
                                        >
                                          <DropdownToggle
                                            tag="i"
                                            className="btn nav-btn"
                                            type="button"
                                          >
                                            <i className="bx bx-dots-horizontal-rounded" />
                                          </DropdownToggle>
                                          <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem> <Link
                                              to="/transaction" className="text-dark"
                                            >All Transactions</Link></DropdownItem>
                                          </DropdownMenu>
                                        </Dropdown>
                                        </div>
                                      </Row> */}
                                      <Row>
                                        {exchange.pairs.length ?
                                          <div className="col-lg-12 p-0">
                                            <Label className="col-form-label p-0">
                                              <span className="badge badge-soft-info font-size-12"> {exchange.pairs.length} </span> {exchange.pairs.length === 1 ? 'Pair Added: ' : 'Pairs Added: '}
                                            </Label>{" "}
                                            {/* <div className="d-inline"> */}
                                              {/* {exchange.pairs[0] ? <p className="badge bg-primary font-size-11 m-1">{exchange.pairs[0].display_text} </p> : ""}
                                              {exchange.pairs[1] ? <p className="badge bg-primary font-size-11 m-1">{exchange.pairs[1].display_text}</p> : ""} */}
                                              {
                                                exchange.pairs.map((pair,index)=>
                                                (
                                                  <>
                                                  <span>{pair.display_text}{exchange.pairs.length-1 !== index ? ", ":" "}</span>
                                                  </>
                                                ))
                                              }
                                              {/* <b>{exchange.pairs !== undefined ? exchange.pairs : ''}</b> */}
                                              {/* {exchange.pairs.length > 2 ?
                                                <Link to="#" onClick={() => { setSelectNewExchange(exchange.exchange_id); setModalExchangePairs(exchange.pairs); setPairListModal(!pairListModal); }} ><p className="badge bg-success font-size-11 m-1">view More...</p></Link>
                                                :
                                                ""
                                              } */}
                                            {/* </div> */}
                                          </div>
                                          :
                                          <div className="col-lg-12">
                                            <Label className="col-form-label">
                                              <span className="badge badge-soft-info font-size-12">
                                                No Pair Added
                                              </span>
                                            </Label>
                                          </div>
                                        }
                                      </Row>
                                    </CardBody>
                                  </Card>
                                </Col>
                              )
                            })
                            :
                            isLoading ?
                              <Col xl={12}>
                                <div className="p-4 border text-center">
                                  <h6 className="text-muted">Exchanges Loading...</h6>
                                </div>
                              </Col>
                              :
                              <Col xl={12}>
                                <div className="p-4 border text-center">
                                  <h6 className="text-muted">No Exhange added</h6>
                                </div>
                              </Col>
                          )}
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <form key={6} onSubmit={handleSubmit3(updateNewTrendSettings)}>
                          <div >
                          <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="formrow-firstname-Input"
                                md="3"
                                className="col-form-label"
                              >
                                Trading type: <i className="bx bx-info-circle" id ="trading_type" />
                                    <UncontrolledTooltip placement="top" target="trading_type">
                                      {
                                        <>Please select the type of trading you prefer:<br />- Short term-INTRADAY:<br />This option is suitable for traders who aim to complete their trades within a single day.<br />- Medium term-SWING:<br />Choose this option if you prefer holding your trades for a few days to a few weeks.<br />- Long term-INVESTMENT:<br />Select this option if you have a long-term investment approach and plan to hold your trades for an extended period</>
                                      }
                                    </UncontrolledTooltip>
                                </Label>
                              <Col md="5">
                                <select name="trading_type" label="trading_type" className="form-select" onChange={(e) => handleTrendSettingsChange(e)} value={trendSettings.trading_type}>

                                  <option value='short_term'>Short term - INTRADAY</option>
                                  <option value='medium_term'>Medium term - SWING</option>
                                  <option value='long_term'>Long term - INVESTMENT</option>
                                </select>
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="formrow-firstname-Input"
                                md="3"
                                className="col-form-label"
                              >
                                Method for Trend signal: <i className="bx bx-info-circle" id ="Method_for_Trend_signal" />
                                    <UncontrolledTooltip placement="top" target="Method_for_Trend_signal">
                                        {ToolTipData.method_for_trend_signal}
                                    </UncontrolledTooltip>
                                </Label>
                              <Col md="5">
                                <select name="trend_method" label="trend_method" className="form-select" onChange={(e) => handleTrendSettingsChange(e)} value={trendSettings.trend_method}>

                                  <option value='sma'>SMA crossovers</option>
                                  <option value='ema'>EMA crossovers</option>
                                  <option value='macd'>MACD Signal Line cross </option>
                                  <option value='supertrend'>Supertrend</option>
                                </select>
                              </Col>
                            </FormGroup>
                           
                            {!trendSettings.trend_method || trendSettings.trend_method == "sma" ? <>

                              <FormGroup className="mb-4" row>
                                <Label
                                  htmlFor="formrow-firstname-Input"
                                  md="3"
                                  className="col-form-label"
                                >
                                  Interval <i className="bx bx-info-circle" id ="Interval1" />
                                    <UncontrolledTooltip placement="top" target="Interval1">
                                        {ToolTipData.sma_crossover.interval}
                                    </UncontrolledTooltip>
                                    </Label>
                                <Col md="9">
                                  <select name="time_frame" label="time_frame" required className="form-select"   value={trendSettings.time_frame} onChange={(e) => handleTrendSettingsChange(e)} >
                                    <option value='1m'>1 minute</option>
                                    <option value='5m'>5 minutes</option>
                                    <option value='15m'>15 minutes</option>
                                    <option value='30m'>30 minutes</option>
                                    <option value='1h'>1 hour</option>
                                    <option value='2h'>2 hours</option>
                                    <option value='4h'>4 hours</option>
                                    <option value='1d'>1 day</option>
                                    <option value='1w'>1 week</option>
                                    <option value='1M'>1 month</option>
                                  </select>
                                </Col>
                              </FormGroup>
                              <FormGroup className="mb-4" row>
                                <Label
                                  htmlFor="formrow-firstname-Input"
                                  md="3"
                                  className="col-form-label"
                                >
                                  Short length <i className="bx bx-info-circle" id ="Short_length" />
                                    <UncontrolledTooltip placement="top" target="Short_length">
                                        {ToolTipData.sma_crossover.short_length}
                                    </UncontrolledTooltip>
                                    </Label>
                                <Col md="9">
                                  <input type="number" required min={1} step={1} value={trendSettings.short_term ? trendSettings.short_term : ''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="short_term" className="form-control" />
                                </Col>
                              </FormGroup>
                              <FormGroup className="mb-4" row>
                                <Label
                                  htmlFor="formrow-firstname-Input"
                                  md="3"
                                  className="col-form-label"
                                >
                                  Long length <i className="bx bx-info-circle" id ="Long_length" />
                                    <UncontrolledTooltip placement="top" target="Long_length">
                                        {ToolTipData.sma_crossover.long_length}
                                    </UncontrolledTooltip>
                                    </Label>
                                <Col md="9">
                                  <input type="number" required min={1} step={1} value={trendSettings.long_term ? trendSettings.long_term : ''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="long_term" className="form-control" />
                                </Col>
                              </FormGroup>
                            </> :trendSettings.trend_method=="ema"? 
                               <>
                              
                               <FormGroup className="mb-4" row>
                               <Label
                                 htmlFor="formrow-firstname-Input"
                                 md="4"
                                 className="col-form-label"
                               >
                               Interval <i className="bx bx-info-circle" id ="TFBMINTREVAL" />
                                     <UncontrolledTooltip placement="top" target="TFBMINTREVAL"> 
                                         {ToolTipData.ema_crossover.interval}
                                     </UncontrolledTooltip>
                               </Label>
                               <Col md="8">                              
                               <select name="ema_time_frame" label="ema_time_frame" required className="form-select" value={trendSettings.ema_time_frame} onChange={(e) => handleTrendSettingsChange(e)} > 					  
                                 <option value='1m'>1 minute</option>
                                 <option value='5m'>5 minutes</option>
                                 <option value='15m'>15 minutes</option>
                                 <option value='30m'>30 minutes</option>
                                 <option value='1h'>1 hour</option>
                                 <option value='2h'>2 hours</option>
                                 <option value='4h'>4 hours</option>
                                 <option value='1d'>1 day</option>
                                 <option value='1w'>1 week</option>
                                 <option value='1M'>1 month</option>
                               </select>
                               </Col>
                             </FormGroup>
                             <FormGroup className="mb-4" row>
                               <Label
                                 htmlFor="formrow-firstname-Input"
                                 md="4"
                                 className="col-form-label"
                               >
                               Short length <i className="bx bx-info-circle" id ="ema_short" />
                                     <UncontrolledTooltip placement="top" target="ema_short"> 
                                         {ToolTipData.ema_crossover.short_length}
                                     </UncontrolledTooltip>
                               </Label>
                               <Col md="8">                              
                               <input type="number" onWheel={handleWheel}  inputMode="numeric"  required  min={1} step={1} value={trendSettings.ema_short_term?trendSettings.ema_short_term:9} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="ema_short_term" className="form-control" />
                               </Col>
                             </FormGroup>
                             <FormGroup className="mb-4" row>
                               <Label
                                 htmlFor="formrow-firstname-Input"
                                 md="4"
                                 className="col-form-label"
                               >
                               Long length <i className="bx bx-info-circle" id ="ema_long" />
                                     <UncontrolledTooltip placement="top" target="ema_long"> 
                                         {ToolTipData.ema_crossover.long_length}
                                     </UncontrolledTooltip>
                               </Label>
                               <Col md="8">                              
                               <input type="number" onWheel={handleWheel}  inputMode="numeric"  required  min={1} step={1} value={trendSettings.ema_long_term?trendSettings.ema_long_term:21} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="ema_long_term" className="form-control" />
                               </Col>
                             </FormGroup>
                               </>: trendSettings.trend_method == "macd" ?
                              <>

                                <FormGroup className="mb-4" row>
                                  <Label
                                    htmlFor="formrow-firstname-Input"
                                    md="5"
                                    className="col-form-label"
                                  >
                                    Interval <i className="bx bx-info-circle" id ="Interval" />
                                    <UncontrolledTooltip placement="top" target="Interval">
                                        {ToolTipData.macd_sign_line_cross.interval}
                                    </UncontrolledTooltip>
                                    </Label>
                                  <Col md="9">
                                    <select name="macd_time_frame" required label="macd_time_frame" className="form-select" value={trendSettings.macd_time_frame} onChange={(e) => handleTrendSettingsChange(e)} >
                                      <option value='1m'>1 minute</option>
                                      <option value='5m'>5 minutes</option>
                                      <option value='15m'>15 minutes</option>
                                      <option value='30m'>30 minutes</option>
                                      <option value='1h'>1 hour</option>
                                      <option value='2h'>2 hours</option>
                                      <option value='4h'>4 hours</option>
                                      <option value='1d'>1 day</option>
                                      <option value='1w'>1 week</option>
                                      <option value='1M'>1 month</option>
                                    </select>
                                  </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                  <Label
                                    htmlFor="formrow-firstname-Input"
                                    md="3"
                                    className="col-form-label"
                                  >
                                    Short length <i className="bx bx-info-circle" id ="Short_length" />
                                    <UncontrolledTooltip placement="top" target="Short_length">
                                        {ToolTipData.macd_sign_line_cross.short_length}
                                    </UncontrolledTooltip>
                                    </Label>
                                  <Col md="9">
                                    <input type="number" required min={1} step={1} value={trendSettings.macd_short_term ? trendSettings.macd_short_term : 12} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="macd_short_term" className="form-control" />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                  <Label
                                    htmlFor="formrow-firstname-Input"
                                    md="3"
                                    className="col-form-label"
                                  >
                                    Long length <i className="bx bx-info-circle" id ="Long_length" />
                                    <UncontrolledTooltip placement="top" target="Long_length">
                                        {ToolTipData.macd_sign_line_cross.long_length}
                                    </UncontrolledTooltip>
                                    </Label>
                                  <Col md="9">
                                    <input type="number" required min={1} step={1} value={trendSettings.macd_long_term ? trendSettings.macd_long_term : 26} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="macd_long_term" className="form-control" />
                                  </Col>
                                </FormGroup>

                                <FormGroup className="mb-4" row>
                                  <Label
                                    htmlFor="formrow-firstname-Input"
                                    md="3"
                                    className="col-form-label"
                                  >
                                    Signal length <i className="bx bx-info-circle" id ="Signal_length" />
                                    <UncontrolledTooltip placement="top" target="Signal_length">
                                        {ToolTipData.macd_sign_line_cross.Signal_length}
                                    </UncontrolledTooltip>
                                    </Label>
                                  <Col md="9">
                                    <input type="number" required min={1} step={1} value={trendSettings.macd_signal_term ? trendSettings.macd_signal_term : 9} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="macd_signal_term" className="form-control" />
                                  </Col>
                                </FormGroup>
                              </>
                              : trendSettings.trend_method == "supertrend" ? <>
                                <FormGroup className="mb-4" row>
                                  <Label
                                    htmlFor="formrow-firstname-Input"
                                    md="3"
                                    className="col-form-label"
                                  >
                                    Interval <i className="bx bx-info-circle" id ="Interval" />
                                    <UncontrolledTooltip placement="top" target="Interval">
                                        {ToolTipData.supertrend.interval}
                                    </UncontrolledTooltip>
                                    </Label>
                                  <Col md="9">
                                    <select name="st_time_frame" required label="st_time_frame" className="form-select" value={trendSettings.st_time_frame} onChange={(e) => handleTrendSettingsChange(e)} >
                                      <option value='1m'>1 minute</option>
                                      <option value='5m'>5 minutes</option>
                                      <option value='15m'>15 minutes</option>
                                      <option value='30m'>30 minutes</option>
                                      <option value='1h'>1 hour</option>
                                      <option value='2h'>2 hours</option>
                                      <option value='4h'>4 hours</option>
                                      <option value='1d'>1 day</option>
                                      <option value='1w'>1 week</option>
                                      {/*<option value='1M'>1 month</option>*/}
                                    </select>
                                  </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                  <Label
                                    htmlFor="formrow-firstname-Input"
                                    md="3"
                                    className="col-form-label"
                                  >
                                    ATR length <i className="bx bx-info-circle" id ="ATR_length" />
                                    <UncontrolledTooltip placement="top" target="ATR_length">
                                        {ToolTipData.supertrend.atr_length}
                                    </UncontrolledTooltip>
                                    </Label>
                                  <Col md="9">
                                    <input type="number" required min={1} step={1} value={trendSettings.st_atr_length ? trendSettings.st_atr_length : 10} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="st_atr_length" className="form-control" />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                  <Label
                                    htmlFor="formrow-firstname-Input"
                                    md="3"
                                    className="col-form-label"
                                  >
                                    Factor <i className="bx bx-info-circle" id ="Factor" />
                                    <UncontrolledTooltip placement="top" target="Factor">
                                        {ToolTipData.supertrend.factor}
                                    </UncontrolledTooltip>
                                    </Label>
                                  <Col md="9">
                                    <input type="number" required min={1} step={0.1} value={trendSettings.st_factor ? trendSettings.st_factor :2} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="st_factor" className="form-control" />
                                  </Col>
                                </FormGroup>
                                <h5 className="mt-5 mb-3 font-size-15">
                                  Supertrend distance for BTC/USDT trading pair
                                </h5>
                                <div className="p-4 border">
                                  <FormGroup className="mb-4" row>
                                    <Label
                                      htmlFor="formrow-firstname-Input"
                                      md="3"
                                      className="col-form-label"
                                    >
                                    Minimum supertrend distance <i className="bx bx-info-circle" id ="Minimum_supertrend_distance" />
                                    <UncontrolledTooltip placement="top" target="Minimum_supertrend_distance">
                                        {ToolTipData.supertrend.minimum_supertrend_distance}
                                    </UncontrolledTooltip>
                                    </Label>
                                    <Col md="9">
                                      <input type="number" step={0.00001} value={trendSettings.st_min_distance ? trendSettings.st_min_distance : ''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="st_min_distance" className="form-control" />
                                    </Col>
                                  </FormGroup>
                                  <FormGroup className="mb-4" row>
                                    <Label
                                      htmlFor="formrow-firstname-Input"
                                      md="3"
                                      className="col-form-label"
                                    >
                                    Maximum supertrend distance <i className="bx bx-info-circle" id ="Maximum_supertrend_distance" />
                                    <UncontrolledTooltip placement="top" target="Maximum_supertrend_distance">
                                        {ToolTipData.supertrend.maximum_supertrend_distance}
                                    </UncontrolledTooltip>
                                    </Label>
                                    <Col md="9">
                                      <input type="number" step={1} value={trendSettings.st_max_distance ? trendSettings.st_max_distance : ''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="st_max_distance" className="form-control" />
                                    </Col>
                                  </FormGroup>
                                </div>
                              </> : ''}

                          </div>
                          <div className="text-sm-end mt-3">
                            <Button className="btn-label w-md" color="success" type="submit" disabled={form6_loader.loading}>
                              {form6_loader.loading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> : ''}
                              <i className="bx bx-smile label-icon"></i> Submit
                            </Button>
                          </div>
                        </form>
                      </TabPane>
                      <TabPane tabId="5">
                        {
                          ccxtAllExchanges && ccxtAllExchanges.length > 0 ?
                        <>
                      <Row>
                          <Col xl={12}>
                            <Card className="border">
                              <CardBody>
                                <Row>

                                <Col sm={4}>
                                    <Label
                                      htmlFor="horizontal-firstname-Input"
                                      className="col-form-label"
                                    >
                                      Filter By
                                    </Label>
                                    <select id="filter_by" defaultValue={filterDate} disabled={formLoader5.loading}  className="form-select symbole_filter" onChange={(e) => { handleChartFilter(e)}}>
                                      <option value="7">Last 7 Days</option>
                                      <option value="this">This Week</option>
                                      <option value="last">Last Week</option>
                                      <option value="30">Last 30 Days</option>
                                      <option value="this_month">This Month</option>
                                      <option value="last_month">Last Month</option>
                                      <option value="this_quarter">This Quarter</option>
                                      <option value="last_quarter">Last Quarter</option>
                                    </select>
                                  </Col>
                                 
                                  <Col sm={4}>
                                    <Label
                                      htmlFor="horizontal-firstname-Input"
                                      className="col-form-label"
                                    >
                                      Select Exchange
                                    </Label>
                                    <select id="select_exchange_graph" defaultValue="SelectedExchange" disabled={formLoader5.loading}  className="form-select symbole_filter" onChange={async (e) => {setfilter_symbole('all');setSeparatePairs([]); setviewGraph(e.target.value);setsymbolfilterhide(true); setTopList({}) }}>
                                      <option value="SelectedExchange" selected={true}>Select Exchange</option>
                                      {ccxtUserExchanges.map((exchange, index) => {
                                        return (
                                        <React.Fragment key={index}>
                                          <option value={exchange.details.id} > {exchange.details.name} </option>
                                        </React.Fragment>
                                        )
                                      })}
                                    </select>
                                  </Col>
                                  { !symbolfilterhide  ? <>
                                
                            <Col md={4}>
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-form-label"
                            >
                              Select Symbol
                            </Label>
                              <select id="select_symbole_graph" defaultValue="all" disabled={formLoader5.loading}  className="form-select symbole_filter" onChange={async (e) => {setfilter_symbole(e.target.value) }}>
                                <option value="all" selected={"all" == filter_symbole}>All</option>
                                {filter_dropdown.map((exchange, index) => {
                                  return (
                                  <React.Fragment key={index}>
                                    <option value={exchange} selected={exchange == filter_symbole}> {exchange} </option>
                                  </React.Fragment>
                                  )
                                })}
                              </select>
                            </Col>
                            </> : ""
                            }
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      
                        <Card className="overflow-hidden" xl="8" style={{ minHeight: "96%" }}>
                        {/* {JSON.stringify(summary)} */}
                        <CardBody>
                          {Object.keys(summary).length !== 0 ?
                          <div className="d-sm-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="card-title">{filterDate == "7"? "Last 7 day's":filterDate=="30"?"Last 30 day's":filterDate == "this"?"This Week's":filterDate=="last"?"Last Week's":filterDate=="this_month"?"This Month's":filterDate=="last_month"?"Last Month's":filterDate =="this_quarter"?"This Quarter's":filterDate =="last_quarter"?"Last Quarter's":""} PNL :
                            {summary._7days_pnl && !formLoader5.loading ? (
                              Object.keys(summary._7days_pnl).map((currency, index, arr) => (
                                <span key={currency}>
                                  <span className={parseFloat(summary._7days_pnl[currency]) < 0 ? "text-danger" : "text-success"}>
                                    &nbsp;{parseFloat(summary._7days_pnl[currency]).toFixed(4)} {currency}
                                  </span>
                                  {index < arr.length - 1 && ", "}
                                </span>
                              ))
                            ) : (
                              <span className="text-danger">&nbsp;NA</span>
                            )}
                            </h4>
                             <div className="float-end" >
                            <h4 className="card-title" >Today&apos;s PNL :
                            {summary.total_pnl && !formLoader5.loading ? (<span className={parseFloat(summary.total_pnl) < 0 ? "text-danger" : "text-success"}>&nbsp;{summary && summary.total_pnl ? parseFloat(summary.total_pnl).toFixed(4) : 0} USDT</span>) : <snap className="text-danger"> NA</snap>} </h4>
                          </div>
                          </div>
                          :""}
                          {  !formLoader5.loading && Object.keys(summary).length !== 0   ? 
                          <StackedColumnChart  className="chartAreaWrapper" periodData={[summary.graph_data]}   dataColors='["--bs-primary", "--bs-warning", "--bs-success"]' currency={Currency}/>
                            : !formLoader5.loading && Object.keys(summary).length === 0 ? <Col xl={12}>
                            <div className="p-4 border text-center">
                              <h6 className="text-muted">No Data Found</h6> </div>
                          </Col>: <Col xl={12}>
                            <div className="p-4 border text-center">
                              <h6 className="text-muted">Loading...</h6>
                            </div>
                          </Col>
                        }
                          </CardBody>
                        </Card>
                        {!formLoader5.loading && Object.keys(topList).length > 0 ? 
                          <Row>
                          <Col xl={12}>
                            <Card className="border">
                              <CardBody>
                                <Row>
                                <Col xl={6}>
                                  <h4 className="card-title text-success">Gainers</h4>
                                  {Object.keys(topList.gainers).length >0 ?
                                  <div className="table-responsive">
                                  <Table striped bordered variant="dark">
                                      <thead>
                                        <tr>
                                          <th>Exchange</th>
                                          <th>Symbol</th>
                                          <th>PNL in USDT</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                      {Object.entries(topList.gainers).map(([symbol, { gain_pnl, exchange }]) => (
                                        gain_pnl > 0 &&
                                          <tr key={symbol}>
                                              <td>{exchange}</td>
                                              <td>{symbol}</td>
                                              <td className="text-success">{gain_pnl.toFixed(4)}</td>
                                          </tr>
                                      ))}
                                        <tr key="total_gain">
                                              <td></td>
                                              <td>Total Gain</td>
                                              <td className="text-success">{totalPNL.total_gain.toFixed(4)}</td>
                                          </tr>
                                      </tbody>
                                    </Table>
                                    </div>:<div className="p-4 border text-center">
                                    <h6 className="text-muted">No Data Found</h6> </div>}
                                  </Col>
                                  <Col xl={6}>
                                  <h4 className="card-title text-danger">Losers</h4>
                                  {Object.keys(topList.losers).length >0 ?
                                  <div className="table-responsive">
                                  <Table striped bordered variant="dark">
                                      <thead>
                                        <tr>
                                          <th>Exchange</th>
                                          <th>Symbol</th>
                                          <th>PNL in USDT</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                      {Object.entries(topList.losers).map(([symbol, { lose_pnl, exchange }]) => (
                                        lose_pnl < 0 &&
                                          <tr key={symbol}>
                                              <td>{exchange}</td>
                                              <td>{symbol}</td>
                                              <td className="text-danger">{lose_pnl.toFixed(4)}</td>
                                          </tr>
                                      ))}
                                       <tr key="total_lose">
                                              <td></td>
                                              <td>Total Loss</td>
                                              <td className="text-danger">{totalPNL.total_lose.toFixed(4)}</td>
                                          </tr>
                                      </tbody>
                                    </Table>
                                    </div>
                                    :<div className="p-4 border text-center">
                                    <h6 className="text-muted">No Data Found</h6> </div>}
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                          :null}
                        </> : <Col xl={12}>
                        <div className="p-4 border text-center">
                          <h6 className="text-muted">No Exchange added</h6>
                        </div>
                      </Col>
                        }
                      </TabPane> 
                      {/* <TabPane tabId="4">
                        <Row>
                          <Col xl={12}>
                            <Card className="border">
                              <CardBody>
                              <CardTitle>Select Exchange</CardTitle>
                                <Row className="p-2">
                                  {/* <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-2 col-form-label"
                                  >
                                    Select Exchange
                                  </Label> */}
                                  {/* <Col sm={4}>
                                    <select id="select_exchange_pair" className="form-select" onChange={async (e) => { setSeparatePairs([]); setviewPair(e.target.value), await getSeparetePairsList(e.target.value), await get_user_all_pairs(e.target.value) }}>
                                      <option value="SelectExchange">Select Exchange</option> */}
                                      {/* {ccxtUserExchanges.map((exchange, index) => {
                                        var  exchangeOptions;
                                        if (exchange.details.id === 'binance' ){
                                          exchangeOptions = <Col  style={{padding:"0px",margin:"5px",width:"60px"}} title={exchange.details.name} value={exchange.details.id} onClick={async e => {!pairListLoading ? (setSelectedExchangeinPair(exchange.details.id),setSeparatePairs([]), setviewPair(exchange.details.id), await getSeparetePairsList(exchange.details.id), await get_user_all_pairs(exchange.details.id)):""}}  sm={1}><img style={exchange.details.id === SelectedExchangeinPair ?{backgroundColor:"#556EE6"}:{}} className="shadow-lg p-1 rounded" src={binance}  width={"60px"}/></Col>
                                        }
                                        if (exchange.details.id === 'delta' ){
                                          exchangeOptions = <Col style={{padding:"0px",margin:"5px",width:"60px"}} value={exchange.details.id} title={exchange.details.name} onClick={async e => {!pairListLoading ? (setSelectedExchangeinPair(exchange.details.id),setSeparatePairs([]), setviewPair(exchange.details.id), await getSeparetePairsList(exchange.details.id), await get_user_all_pairs(exchange.details.id)):""}} sm={1}><img style={exchange.details.id === SelectedExchangeinPair ? {backgroundColor:"#556EE6"}:{}} className="shadow-lg p-1 rounded" src={delta}  width={"60px"}/></Col>
                                        }
                                        return (
                                          exchangeOptions
                                      )})} */}
                                    {/* </select> */}
                                  {/* </Col> */}
                               {/* </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row> */}
                        <TabPane tabId="4">
                        <Row>
                          <Col xl={12}>
                            <Card className="border">
                              <CardBody>
                                <Row>
                                <Col md={6}>
                                  <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-form-label"
                                  >
                                    Select Exchange
                                  </Label>
                                    <select id="select_exchange_pair" selected={true} defaultValue={viewPair} className="form-select" onChange={async (e) => { setSeparatePairs([]); setviewPair(e.target.value), await getSeparetePairsList(e.target.value), await get_user_all_pairs(e.target.value) }}>
                                      <option value="SelectExchange" >Select Exchange</option>
                                      {ccxtUserExchanges.map((exchange, index) => {
                                        return (
                                        <React.Fragment key={index}>
                                          <option value={exchange.details.id} > {exchange.details.name} </option>
                                        </React.Fragment>
                                        )
                                      })}
                                    </select>
                                  </Col>
                                <Col md={6} className="d-flex align-items-end justify-content-sm-end mt-3">
                                  <button className="btn btn-success mx-sm-5" onClick={() => { setAddPairVisible(true)}}><i className="bx bx-plus align-middle"></i>&nbsp;Add Pair</button> 
                                </Col>
                                  
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                        {/* <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            ExchangePairValidation.handleSubmit();
                            return false;
                          }}
                        > */}

                  
                          <Row>
                            <Card className="border p-3 rounded mt-4"> <CardTitle className="px-0">{SelectExchange ? 'All Pairs' : getExchangeName(viewPair)}</CardTitle>
                            {/* { !SelectExchange ?
                            (spotPairs && spotPairs.length ? 
                            <Row>
                            <Col lg="3">
                            <FormGroup className="mt-4 mb-0">
                            <Select id="select_add_pair" placeholder="Add new pair" value={SelectedSpotPair} onChange={handleSelect} options={spotPairs} classNamePrefix="select2-selection"></Select>
                            </FormGroup>
                           </Col>
                           <Col sm="2">
                           <FormGroup className="mt-4 mb-0">

                            <button className={formLoader6 ? "btn btn-success save-user disabled" : "btn btn-success save-user"}  style={{width:"50%"}}
                            // className="btn btn-success" 
                            onClick={() => {
                                        let selected_exchange =document.getElementById('select_exchange_pair').value
                                        let is_added = ccxtUserPairs.data && ccxtUserPairs.data.find(item => item.exchange_id === selected_exchange && item.id===SelectedSpotPair.value);
                                        if (!is_added) {
                                          setFormLoader6(true);
                                          addNewPair(selected_exchange, SelectedSpotPair.value);
                                        } else {
                                          toast.error('Pair already added', { toastId: 'pair_already_added' })
                                        }
                                      }}>{formLoader6 ? <span><i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>  </span> : 'Add Pair'}</button> 
                             
                            </FormGroup>
                             </Col>
                             </Row>
                            :''
                            ) : <><Alert color="danger"><text className="m-1"><b>Note : </b>To Add Pairs Please Select Exchange</text></Alert></>  } */}
                            <div className="table-responsive">
                              <Table className="table-nowrap align-middle mb-0">
                                <thead>
                                  <tr>
                                    <th scope="col" >Exchange</th>
                                    <th scope="col" className="col-md-6">Pair</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Settings</th>
                                    {/* <th scope="col">Trend Settings</th> */}
                                    <th scope="col">Create Order</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  { 
                                    !pairListLoading && separatePairs && separatePairs.length ? (
                                      separatePairs.map((exchangedata, index) => {
                                        let exchange=exchangedata.data;
                                        let settinginfo = exchange.settings ? JSON.parse(exchange.settings) : {};
                                        let settinginfo2 = exchange
                                        let TrendFollowSettingsinfo = exchange;
								   		                  let pairStatus=exchange.status == 'true' || exchange.status ==1 ?1:0;
                                        return (
                                          <tr key={index}>
                                            <th>{getExchangeName(exchange.exchange_id)}</th>
                                            <th scope="row">
                                              <div className="d-flex align-items-center">
                                                <div >
                                                  <span className="text-primary">{exchange.display_text} <a className="text-danger font-size-14" onClick={() => {
                                                  var id = exchangedata.data.symbol
                                                  var exchange_id = exchangedata.data.exchange_id
                                                  setisDeleteconfirmpopup(true)
                                                  setExchnageId(exchange_id)
                                                  setPairId(id)
                                                }}><i className="bx bx-trash"></i></a></span>
                                                
                                                  {settinginfo&& settinginfo.quantity > 0 ? (
                                                    arrangeSettingsArray(settinginfo).map((order, index) => (
                                                      <p key={index} className="text-muted text-secondary font-size-11 mb-1">
                                                        {order.map((setting, i) => (
                                                          <span key={i}>
                                                          {setting}{i < order.length - 1 ? ' | ' : ''}
                                                          </span>
                                                        ))}
                                                      </p>
                                                    ))
                                                  ) : (
                                                    <div className="text-warning font-size-11">Please Add Order Settings</div>
                                                )}
                                                {TrendFollowSettingsinfo.Trend_settings && Object.keys(JSON.parse(TrendFollowSettingsinfo.Trend_settings)).length > 0 ? (
                                                  <TrendFollowSettings1 trendSettingsInfo={TrendFollowSettingsinfo} />
                                                  ) : (
                                                  <div className="text-warning font-size-11">Please Add Trend Settings</div>
                                                  )}


                                                  {/* {settinginfo && settinginfo.quantity ? (<p className="text-muted text-secondary font-size-11">
                                                    Quantity: <b>{settinginfo && settinginfo.quantity ? settinginfo.quantity : settinginfo.qty ? settinginfo.qty : ''}</b>
                                                    { settinginfo ? <>
                                                    {settinginfo && settinginfo.fill_type && settinginfo.fill_type.length !== 0 ? <> | Fill type: <b>{settinginfo ? settinginfo.fill_type == 'instant_fill' ? 'Instant Fill' : 'Wait and Fill':""}</b></> : ''} 
                                                    {settinginfo && settinginfo.price_distance_percentage && settinginfo.price_distance_percentage.length !== 0 && settinginfo.fill_type !== 'instant_fill' ? <> | Price Distance % for Wait and Fill: <b>{settinginfo ? settinginfo.price_distance_percentage : ""}</b></> : ''}
                                                   {settinginfo && settinginfo.stop_loss && settinginfo.stop_loss.length !== 0 ? <> | Market Stop Loss: <b>{settinginfo ? settinginfo.stop_loss : ''}{settinginfo ? settinginfo.stop_loss_type == "points" ? 'Pts' : "%" : ""}</b></> : ''}
                                                   {(settinginfo &&  settinginfo.min_take_profit_percentage ? <> | Minimum Take Profit%:<b> {settinginfo ? settinginfo.min_take_profit_percentage : ""} </b></> : '')}
                                                   {( settinginfo &&  settinginfo.trailing_take_profit_percentage ? <> |  Trailing Take Profit%:<b> {settinginfo ? settinginfo.trailing_take_profit_percentage:""} </b><br /></> : '')}{( settinginfo &&  settinginfo.waiting_time_for_next_order_stop_loss ? <> Waiting Time for Order after Stop Loss:<b> {settinginfo ? timeFormat(settinginfo.waiting_time_for_next_order_stop_loss):"-"} </b></>: '')}{ (settinginfo &&  settinginfo.waiting_time_for_next_order_trailing_profit) ? <>{ settinginfo &&  settinginfo.waiting_time_for_next_order_stop_loss ? "|":<br/>} Waiting Time for Order after Trailing Take Profit:<b> {settinginfo ? timeFormat(settinginfo.waiting_time_for_next_order_trailing_profit):"-"} </b></>:'' }
                                                   {(settinginfo &&  settinginfo.order_side ? <> | Order Side:<b> {settinginfo ? settinginfo.order_side : ""} </b></> : '')}
                                                   {settinginfo && settinginfo.order_side =="sell" && settinginfo.min_sell_price?<> | Minimum Sell Price: <b>{settinginfo.min_sell_price}</b><br /></>:''}
                                                    {settinginfo && settinginfo.order_side =="buy" &&  settinginfo.max_buy_price?<> | Maximum Buy Price: <b>{settinginfo.max_buy_price}</b><br /></>:''}
                                                  </> :'' }
                                                  </p>)
                                                    : <div className="text-warning font-size-11">Please Add Order Settings</div>} */}
                                                  </div>
                                                </div>
                                              </th>
                                              <th>
                                                <div className="d-flex align-items-center mt-1"><div className="avatar-xs me-3">
                                                  <FormGroup className="" row>

                                                    <Col md="8">
                                                      <Switch
                                                        uncheckedIcon={<Offsymbol />}
                                                        checkedIcon={<OnSymbol />}
                                                        // onChange={(e) => setPairStatus(e)}
                                                        // checked={pairStatus}
                                                        onChange={(e) => {
                                                          changePairStatus(exchange, exchange.exchange_id, exchange.id, e);

                                                        }}
                                                        checked={pairStatus}
                                                        disabled={Object.keys(ccxtUserBalances).length === 0 ? true : false}  // disabled if it's NOT empty
                                                      />
                                                    </Col>
                                                  </FormGroup>
                                                </div>
                                                </div>
                                              </th>
                                              <th>
                                                <ul className="list-unstyled hstack gap-1 mb-0">
                                                  <li title="Order Config">
                                                    <a className="btn btn-sm btn-soft-primary" onClick={() => {
                                                      let selected_exchange = document.getElementById('select_exchange').value;
                                                      let is_added = ccxtUserExchanges.find(item => item.exchange_id === selected_exchange);
                                                      if (!is_added) {
                                                        setSettingsvisible(!settingsvisible);
                                                        setSettingspair(exchange);
                                                        setPairOrderSettings(settinginfo2 && settinginfo2.settings ? JSON.parse(settinginfo2.settings) : { fill_type: 'instant_fill', order_side: "both" });
                                                        // getPairIndex(exchange)
                                                      } else {
                                                        toast.error('Exchange already added', { toastId: 'exchange_already_added' })
                                                      }
                                                    }}><i className="bx bx-cog font-size-18"></i></a></li>
                                                  <li title="Strategy Config"><a className="btn btn-sm btn-soft-info" onClick={() => {
                                                    let selected_exchange = document.getElementById('select_exchange').value;
                                                    let is_added = ccxtUserExchanges.find(item => item.exchange_id === selected_exchange);
                                                    if (!is_added) {
                                                      setTrendFollowSettings(!TrendFollowSettings);
                                                      setTrendFollowpair(exchange);
                                                      setTrendSettings(TrendFollowSettingsinfo && TrendFollowSettingsinfo.Trend_settings !== undefined ? JSON.parse(TrendFollowSettingsinfo.Trend_settings) : {
                                                        trend_method: "sma",
                                                        short_term: 9,
                                                        long_term: 20,
                                                        time_frame: "15m",
                                                        st_time_frame: "15m",
                                                        macd_time_frame: "15m",
                                                        ema_time_frame: "15m",
                                                        exchange_id: exchange.exchange_id,
                                                        ema_long_term : 21,
                                                        ema_short_term : 9,
                                                        macd_long_term:26,
                                                        macd_short_term:12,
                                                        trading_type:"short_term"
                                                      });
                                                      setDefaultColorSetting(TrendFollowSettingsinfo && TrendFollowSettingsinfo.Trend_settings !== undefined ? JSON.parse(TrendFollowSettingsinfo.Trend_settings) : {})
                                                    } else {
                                                      toast.error('Exchange already added', { toastId: 'exchange_already_added' })
                                                    }
                                                  }}><i className="bx bx-line-chart font-size-18"></i></a></li>
                                                  {(settinginfo2 && settinginfo2.settings !== undefined) || (TrendFollowSettingsinfo && TrendFollowSettingsinfo.Trend_settings !== undefined) ?

                                                    <li title="Copy Config"><a className="btn btn-sm btn-soft-secondary" onClick={() => copySettings(exchange.exchange_id, exchange.display_text, TrendFollowSettingsinfo.Trend_settings, settinginfo2.settings)}><i className="bx bx-copy-alt  font-size-18"></i></a></li>
                                                    : ''
                                                  }

                                                  {copiedSettings && (copiedSettings['exchange_id'] !== exchange.exchange_id || copiedSettings['pair'] !== exchange.display_text) ?
                                                    <li title="Paste Config"><a className="btn btn-sm btn-soft-warning" onClick={() => pasteSettings(exchange.exchange_id, exchange.display_text, exchange.id, TrendFollowSettingsinfo.Trend_settings, settinginfo2.settings)}><i className="bx bx-paste  font-size-18"></i></a></li>
                                                    : ''}

                                                </ul>

                                              </th>
                                              <th>

                                                {ccxtUserBalances && Object.keys(ccxtUserBalances).length !== 0 ? (
                                                  exchangedata.signal_data &&
                                                    exchangedata.signal_data.signal &&
                                                    settinginfo2.settings &&
                                                    pairStatus == 1 ? (
                                                    exchangedata.signal_data.signal === 'uptrend' &&
                                                      settinginfo.quantity_type !== "balance_per_base" ? (
                                                      <>
                                                        <button
                                                          className={
                                                            formLoader7[settinginfo2.exchange_id + settinginfo2.id]
                                                              ? "btn btn-success save-user disabled"
                                                              : "btn btn-success save-user font-size-10"
                                                          }
                                                          title={
                                                            "Create buy order based on " +
                                                            exchangedata.signal_data.signal +
                                                            " signal received at " +
                                                            getSettingsDateTimeFormat(exchangedata.signal_data.updated_at)
                                                          }
                                                          onClick={() => {
                                                            let selected_exchange = document.getElementById('select_exchange_pair').value;
                                                            CreateOrder(exchangedata, settinginfo2.exchange_id + settinginfo2.id, 'buy');
                                                          }}
                                                        >
                                                          {formLoader7[settinginfo2.exchange_id + settinginfo2.id] ? (
                                                            <span>
                                                              <i className="ml-2 bx bx-hourglass bx-spin font-size-10 align-middle me-2"></i>
                                                            </span>
                                                          ) : (
                                                            'Buy '
                                                          )}
                                                          {settinginfo.quantity_type === "balance_per"
                                                            ? 'For ' +
                                                            parseFloat(
                                                              (ccxtUserBalances[settinginfo2.exchange_id] &&
                                                                ccxtUserBalances[settinginfo2.exchange_id][settinginfo2.quote] &&
                                                                ccxtUserBalances[settinginfo2.exchange_id][settinginfo2.quote]['free']
                                                                ? ccxtUserBalances[settinginfo2.exchange_id][settinginfo2.quote]['free']
                                                                : 0) *
                                                              settinginfo.quantity /
                                                              100
                                                            ).toFixed(4) +
                                                            ' ' +
                                                            settinginfo2.quote
                                                            : settinginfo.quantity + ' ' + settinginfo2.base}
                                                        </button>
                                                      </>
                                                    ) : (
                                                      exchangedata.signal_data.signal === 'downtrend' && settinginfo.quantity_type !== "balance_per" && (
                                                        <>
                                                          <button
                                                            className={
                                                              formLoader7[settinginfo2.exchange_id + settinginfo2.id]
                                                                ? "btn btn-danger save-user disabled"
                                                                : "btn btn-danger save-user font-size-10"
                                                            }
                                                            title={
                                                              "Create sell order based on " +
                                                              exchangedata.signal_data.signal +
                                                              " signal received at " +
                                                              getSettingsDateTimeFormat(exchangedata.signal_data.updated_at)
                                                            }
                                                            onClick={() => {
                                                              let selected_exchange = document.getElementById('select_exchange_pair').value;
                                                              CreateOrder(exchangedata, settinginfo2.exchange_id + settinginfo2.id, 'sell');
                                                            }}
                                                          >
                                                            {formLoader7[settinginfo2.exchange_id + settinginfo2.id] ? (
                                                              <span>
                                                                <i className="ml-2 bx bx-hourglass bx-spin font-size-10 align-middle me-2"></i>
                                                              </span>
                                                            ) : (
                                                              'Sell '
                                                            )}
                                                            {settinginfo.quantity_type === "balance_per_base"
                                                              ? '' +
                                                              parseFloat(
                                                                (ccxtUserBalances[settinginfo2.exchange_id] &&
                                                                  ccxtUserBalances[settinginfo2.exchange_id][settinginfo2.base] &&
                                                                  ccxtUserBalances[settinginfo2.exchange_id][settinginfo2.base]['free']
                                                                  ? ccxtUserBalances[settinginfo2.exchange_id][settinginfo2.base]['free']
                                                                  : 0) *
                                                                settinginfo.quantity /
                                                                100
                                                              ).toFixed(4) +
                                                              ' ' +
                                                              settinginfo2.base
                                                              : settinginfo.quantity + ' ' + settinginfo2.base}
                                                          </button>
                                                        </>
                                                      )
                                                    )
                                                  ) : (
                                                    <></>
                                                  )
                                      ): <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                      <i className="ml-2 bx bx-hourglass bx-spin font-size-10 align-middle me-2"></i>
                                    </span> }

                                              </th>
                                            </tr>



                                          )
                                        })
                                      )

                                        : pairListLoading ?
                                          <tr><td colSpan={6}>
                                            <Col xl={12}>
                                              <div className="p-4 border text-center">
                                                <h6 className="text-muted">Loading...</h6>
                                              </div>
                                            </Col>
                                          </td></tr>
                                          : <tr><td colSpan={6}>
                                            <Col xl={12}>
                                              <div className="p-4 border text-center">
                                                <h6 className="text-muted">No Pairs</h6>
                                              </div>
                                            </Col>
                                          </td></tr>

                                    }
                                  </tbody>
                                  {/* <tbody>
                                  {!SelectExchange ? (
                                    !pairListLoading && separatePairs && separatePairs.length ? (
                                      separatePairs.map((exchange, index) => {
                                        let settinginfo = pairSettings[exchange.id]
                                        let TrendFollowSettingsinfo = TrendFollowSettingspair[exchange.id]
                                        console.log('____________________________',TrendFollowSettingsinfo);
                                        //  console.log("settinginfo",settinginfo)
                                        return (
                                          <tr key={index}>
                                            <th scope="row">
                                              <div className="d-flex align-items-center">
                                                <div >
                                                  <span className="text-primary">{exchange.display_text}</span>
                                                  {settinginfo && settinginfo.qty ? (<p className="text-muted text-secondary font-size-11">
                                                    Quantity: <b>{settinginfo.settings.quantity ? settinginfo.settings.quantity : settinginfo.qty ? settinginfo.qty : ''}</b>
                                                    { settinginfo.settings ? <>
                                                    {settinginfo.settings.fill_type && settinginfo.settings.fill_type.length !== 0 ? <> | Fill type: <b>{settinginfo.settings.fill_type == 'instant_fill' ? 'Instant Fill' : 'Wait and Fill'}</b></> : ''} 
                                                    {settinginfo.settings.price_distance_percentage && settinginfo.settings.price_distance_percentage.length !== 0 && settinginfo.settings.fill_type !== 'instant_fill' ? <> | Price Distance % for Wait and Fill: <b>{settinginfo.settings.price_distance_percentage}</b></> : ''}
                                                   {settinginfo.settings.stop_loss && settinginfo.settings.stop_loss.length !== 0 ? <> | Stop loss: <b>{settinginfo.settings.stop_loss}{settinginfo.settings.stop_loss_type == "points" ? 'Pts' : "%"}</b></> : ''}
                                                   {(settinginfo.settings.min_take_profit_percentage ? <> | Minimum Take Profit%:<b> {settinginfo.settings.min_take_profit_percentage} </b></> : '')}
                                                   {(settinginfo.settings.trailing_take_profit_percentage ? <> |  Trailing Take Profit%:<b> {settinginfo.settings.trailing_take_profit_percentage} </b></> : '')}
                                                  </> :'' }
                                                  </p>)
                                                    : <div className="text-warning font-size-11">Settings not available</div>}
                                                </div>
                                              </div>
                                            </th>
                                            <th>
                                              <div className="d-flex align-items-center mt-1"><div className="avatar-xs me-3">
                                                <FormGroup className="" row>

                                                  <Col md="8">
                                                    <Switch
                                                      uncheckedIcon={<Offsymbol />}
                                                      checkedIcon={<OnSymbol />}
                                                      // onChange={(e) => setPairStatus(e)}
                                                      // checked={pairStatus}
                                                      onChange={(e) => {
                                                        changePairStatus(exchange,exchange.exchange_id, exchange.id, e);
                                                        
                                                      }}
                                                      checked= {pairStatus}
                                                    />
                                                  </Col>
                                                </FormGroup>
                                              </div>
                                              </div>
                                            </th>
                                            <th>
                                              <ul className="list-unstyled hstack gap-1 mb-0">
                                                <li  title="Order Settings">
                                                  <a className="btn btn-sm btn-soft-primary"   onClick={() => {
                                                  let selected_exchange = document.getElementById('select_exchange').value;
                                                  let is_added = ccxtUserExchanges.find(item => item.exchange_id === selected_exchange);
                                                  if (!is_added) {
                                                    setSettingsvisible(!settingsvisible);
                                                    setSettingspair(exchange);
                                                    setPairOrderSettings(settinginfo2 && settinginfo2.settings ? JSON.parse(settinginfo2.settings) : {fill_type:'instant_fill',order_side:"both"});
                                                    // getPairIndex(exchange)
                                                  } else {
                                                    toast.error('Exchange already added', { toastId: 'exchange_already_added' })
                                                  }
                                                }}><i className="bx bx-cog font-size-18"></i></a></li>
                                                  <li title="Trend Settings"><a className="btn btn-sm btn-soft-info"  onClick={() => {
                                                  let selected_exchange = document.getElementById('select_exchange').value;
                                                  let is_added = ccxtUserExchanges.find(item => item.exchange_id === selected_exchange);
                                                  if (!is_added) {
                                                    setTrendFollowSettings(!TrendFollowSettings);
                                                    setTrendFollowpair(exchange);
                                                    setTrendSettings(TrendFollowSettingsinfo && TrendFollowSettingsinfo.Trend_settings !== undefined ?  JSON.parse(TrendFollowSettingsinfo.Trend_settings) : {
                                                      trend_method: "sma",
                                                      short_term: 9,
                                                      long_term: 20,
                                                      time_frame: "15m",
                                                      st_time_frame: "15m",
                                                      macd_time_frame: "15m",
                                                      ema_time_frame:"15m",
                                                      exchange_id : exchange.exchange_id
                                                    });
                                                setDefaultColorSetting(TrendFollowSettingsinfo && TrendFollowSettingsinfo.Trend_settings !== undefined ?  JSON.parse(TrendFollowSettingsinfo.Trend_settings) :{})
                                                  } else {
                                                    toast.error('Exchange already added', { toastId: 'exchange_already_added' })
                                                  }
                                                }}><i className="bx bx-line-chart font-size-18"></i></a></li>
                                                 {(settinginfo2 && settinginfo2.settings !== undefined) || (TrendFollowSettingsinfo && TrendFollowSettingsinfo.Trend_settings !== undefined)?
                                                 
                                                  <li title="Copy Settings"><a className="btn btn-sm btn-soft-secondary" onClick={()=>copySettings(exchange.exchange_id,exchange.display_text,TrendFollowSettingsinfo.Trend_settings,settinginfo2.settings)}><i className="bx bx-copy-alt  font-size-18"></i></a></li>
                                                  :''
                                                 }
                                                  
                                                  {copiedSettings  && (copiedSettings['exchange_id'] !==exchange.exchange_id || copiedSettings['pair']!==exchange.display_text)  ?
                                                  <li title="Paste Settings"><a className="btn btn-sm btn-soft-warning" onClick={()=>pasteSettings(exchange.exchange_id,exchange.display_text,exchange.id,TrendFollowSettingsinfo.Trend_settings,settinginfo2.settings)}><i className="bx bx-paste  font-size-18"></i></a></li>
                                                  :''}

                                                  </ul>
                                             
                                            </th>
                                            <th>
                                              
                                              { exchangedata.signal_data && exchangedata.signal_data.signal && settinginfo2.settings && pairStatus == 1  ? exchangedata.signal_data.signal == 'uptrend' ? <><button className={formLoader7[settinginfo2.exchange_id+settinginfo2.id] === true ? "btn btn-success save-user disabled" : "btn btn-success save-user font-size-10"}  
                                                title={"Create buy order based on "+exchangedata.signal_data.signal+" signal received at "+exchangedata.signal_data.updated_at}
                                                    onClick={() => {
                                                    let selected_exchange =document.getElementById('select_exchange_pair').value
                                                    CreateOrder(exchangedata,settinginfo2.exchange_id+settinginfo2.id, 'buy')
                                                  }} 
                                                  >{formLoader7[settinginfo2.exchange_id+settinginfo2.id] === true ? <span><i className="ml-2 bx bx-hourglass bx-spin font-size-10 align-middle me-2"></i>  </span> : 'Buy '}{settinginfo.quantity_type=="balance_per"?'For '+parseFloat((ccxtUserBalances[settinginfo2.exchange_id] && ccxtUserBalances[settinginfo2.exchange_id][settinginfo2.quote] && ccxtUserBalances[settinginfo2.exchange_id][settinginfo2.quote]['free']?ccxtUserBalances[settinginfo2.exchange_id][settinginfo2.quote]['free']:0)*settinginfo.quantity/100).toFixed(4)+ ' ' + settinginfo2.quote:settinginfo.quantity+ ' ' + settinginfo2.base}</button></>
                                                   :  
                                                   settinginfo.quantity_type!=="balance_per" &&
                                                    <> 
                                                     
                                                  <button className={formLoader7[settinginfo2.exchange_id+settinginfo2.id] === true ? "btn btn-danger save-user disabled" : "btn btn-danger save-user font-size-10"}   title={"Create sell order based on "+exchangedata.signal_data.signal+" signal received at "+exchangedata.signal_data.updated_at}
                                                  // className="btn btn-success" 
                                                    onClick={() => {
                                                    let selected_exchange =document.getElementById('select_exchange_pair').value
                                                    CreateOrder(exchangedata,settinginfo2.exchange_id+settinginfo2.id ,'sell')
                                                  }}>{formLoader7[settinginfo2.exchange_id+settinginfo2.id] === true ? <span><i className="ml-2 bx bx-hourglass bx-spin font-size-10 align-middle me-2"></i>  </span> : 'Sell '}{settinginfo.quantity + ' ' + settinginfo2.base}</button> </>  : <></> 
                                                 
                                                
                                                  }
                                                
                                            </th>
                                          </tr>



                                        )
                                      })
                                    )

                                      : pairListLoading ? 
                                      <tr><td colSpan={6}>
                                        <Col xl={12}>
                                          <div className="p-4 border text-center">
                                            <h6 className="text-muted">Loading...</h6>
                                          </div>
                                        </Col>
                                      </td></tr> 
                                      : <tr><td colSpan={6}>
                                      <Col xl={12}>
                                        <div className="p-4 border text-center">
                                          <h6 className="text-muted">No Pairs</h6>
                                        </div>
                                      </Col>
                                    </td></tr> 

                                  }
                                </tbody>
                                {/* <tbody>
                                  {!SelectExchange ? (
                                    !pairListLoading && separatePairs && separatePairs.length ? (
                                      separatePairs.map((exchange, index) => {
                                        let settinginfo = pairSettings[exchange.id]
                                        let TrendFollowSettingsinfo = TrendFollowSettingspair[exchange.id]
                                        console.log('____________________________',TrendFollowSettingsinfo);
                                        //  console.log("settinginfo",settinginfo)
                                        return (
                                          <tr key={index}>
                                            <th scope="row">
                                              <div className="d-flex align-items-center">
                                                <div >
                                                  <span className="text-primary">{exchange.display_text}</span>
                                                  {settinginfo && settinginfo.qty ? (<p className="text-muted text-secondary font-size-11">
                                                    Quantity: <b>{settinginfo.settings.quantity ? settinginfo.settings.quantity : settinginfo.qty ? settinginfo.qty : ''}</b>
                                                    { settinginfo.settings ? <>
                                                    {settinginfo.settings.fill_type && settinginfo.settings.fill_type.length !== 0 ? <> | Fill type: <b>{settinginfo.settings.fill_type == 'instant_fill' ? 'Instant Fill' : 'Wait and Fill'}</b></> : ''} 
                                                    {settinginfo.settings.price_distance_percentage && settinginfo.settings.price_distance_percentage.length !== 0 && settinginfo.settings.fill_type !== 'instant_fill' ? <> | Price Distance % for Wait and Fill: <b>{settinginfo.settings.price_distance_percentage}</b></> : ''}
                                                   {settinginfo.settings.stop_loss && settinginfo.settings.stop_loss.length !== 0 ? <> | Stop loss: <b>{settinginfo.settings.stop_loss}{settinginfo.settings.stop_loss_type == "points" ? 'Pts' : "%"}</b></> : ''}
                                                   {(settinginfo.settings.min_take_profit_percentage ? <> | Minimum Take Profit%:<b> {settinginfo.settings.min_take_profit_percentage} </b></> : '')}
                                                   {(settinginfo.settings.trailing_take_profit_percentage ? <> |  Trailing Take Profit%:<b> {settinginfo.settings.trailing_take_profit_percentage} </b></> : '')}
                                                  </> :'' }
                                                  </p>)
                                                    : <div className="text-warning font-size-11">Settings not available</div>}
                                                </div>
                                              </div>
                                            </th>
                                            <td>
                                              <div className="text-muted">
                                                <FormGroup className="" row>

                                                  <Col md="8">
                                                    <Switch
                                                      uncheckedIcon={<Offsymbol />}
                                                      checkedIcon={<OnSymbol />}
                                                      // onChange={(e) => setPairStatus(e)}
                                                      // checked={pairStatus}
                                                      onChange={(e) => {
                                                        changePairStatus(exchange.exchange_id, exchange.id, e);
                                                      }}
                                                      checked={pairStatus[exchange.id]}
                                                    />
                                                  </Col>
                                                </FormGroup>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="d-flex align-items-center"><div className="avatar-xs me-3">
                                                <a className="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-18" onClick={() => {
                                                  let selected_exchange = document.getElementById('select_exchange').value;
                                                  let is_added = ccxtUserExchanges.find(item => item.exchange_id === selected_exchange);
                                                  if (!is_added) {
                                                    setSettingsvisible(!settingsvisible);
                                                    setSettingspair(exchange);
                                                    setPairOrderSettings(settinginfo && settinginfo.settings ? settinginfo.settings : {});
                                                    getPairIndex(exchange)
                                                  } else {
                                                    toast.error('Exchange already added', { toastId: 'exchange_already_added' })
                                                  }
                                                }}><i className="bx bx-cog d-block check-nav-icon "></i></a>

                                              </div></div>
                                            </td>
                                            <td>
                                              <div className="d-flex align-items-center"><div className="avatar-xs me-3">
                                                <a className="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-18" onClick={() => {
                                                  let selected_exchange = document.getElementById('select_exchange').value;
                                                  let is_added = ccxtUserExchanges.find(item => item.exchange_id === selected_exchange);
                                                  if (!is_added) {
                                                    setTrendFollowSettings(!TrendFollowSettings);
                                                    setTrendFollowpair(exchange);
                                                    console.log("settinginfo", exchange)
                                                    setPairTrendFollow(TrendFollowSettingsinfo && TrendFollowSettingsinfo.settings ? TrendFollowSettingsinfo.settings : {});
                                                  } else {
                                                    toast.error('Exchange already added', { toastId: 'exchange_already_added' })
                                                  }
                                                }}><i className="bx bx-line-chart d-block check-nav-icon "></i></a>

                                              </div></div>
                                            </td>
                                          </tr>



                                        )
                                      })
                                    )

                                      :
                                      <tr><td colSpan={4}>
                                        <Col xl={12}>
                                          <div className="p-4 border text-center">
                                            <h6 className="text-muted">Loading...</h6>
                                          </div>
                                        </Col>
                                      </td></tr>
                                    // pairListLoading ?
                                    //   <Col xl={12}>
                                    //     <div className="p-4 border text-center">
                                    //       <h6 className="text-muted">Loading...</h6>
                                    //     </div>
                                    //   </Col>
                                    //   :
                                    //   <Col xl={12}>
                                    //     <div className="p-4 border text-center">
                                    //       <h6 className="text-muted">Loading...</h6>
                                    //     </div>
                                    //   </Col>
                                  ) : (
                                    <tr><td colSpan={4}>
                                      <Col xl={12}>
                                        <div className="p-4 border text-center">
                                          <h6 className="text-muted">No Pairs</h6>
                                        </div>
                                      </Col>  </td></tr>
                                  )

                                  }
                                </tbody> */}
                              </Table></div> </Card>
                          </Row>




                          {/* </Form> */}
                        
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Modal isOpen={visible} toggle={() => { setVisible(false), validation.resetForm() }} centered>
        <ModalHeader toggle={() => { setVisible(false), validation.resetForm() }} tag="h4" className="text-capitalize">
          {editExchange ? "Update " + getExchangeName(selectNewExchange) + " API Keys" : getExchangeName(selectNewExchange) + " API keys setup"}{" "}{editExchange ? '': <i className="far fa-question-circle" onClick={() => {setCredentialSetup(!CredentialSetup)}} id ="Credentials_Setup" />}
        </ModalHeader>
        <ModalBody>
          {editExchange ?
            <p className="text-muted">Changing the key & secret will disconnect the active calls in our bot, so do not change the key & secret without support team.</p>
            :
            ""
          }
          <Form
            onSubmit={(e) => {
              document.getElementById('select_exchange').value = 'SelectExchange';
              setSelectExchange2(true)
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col className="col-12">
                <div className="mb-3">
                  <Label className="form-label">API Key <i className="bx bx-info-circle" id ="API_Key" />
                      <UncontrolledTooltip placement="top" target="API_Key">
                          {ToolTipData.ccxt_api_key}
                      </UncontrolledTooltip>
                    </Label>
                  <Input
                    name="api_key"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    defaultValue={(editExchangeValues && editExchangeValues.appKey) ? editExchangeValues.appKey : validation.values.api_key || ""}
                    invalid={
                      validation.touched.api_key && validation.errors.api_key ? true : false
                    }
                    autoComplete="off"
                  />
                  {validation.touched.api_key && validation.errors.api_key ? (
                    <FormFeedback type="invalid">{validation.errors.api_key}</FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">API Secret <i className="bx bx-info-circle" id ="API_Secret" />
                    <UncontrolledTooltip placement="top" target="API_Secret">
                        {ToolTipData.ccxt_api_secret}
                    </UncontrolledTooltip>
                  </Label>
                  <Input
                    name="api_secret"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    defaultValue={(editExchangeValues && editExchangeValues.appSecret) ? editExchangeValues.appSecret : validation.values.api_secret || ""}
                    invalid={
                      validation.touched.api_secret && validation.errors.api_secret ? true : false
                    }
                    autoComplete="off"
                  />
                  {validation.touched.api_secret && validation.errors.api_secret ? (
                    <FormFeedback type="invalid">{validation.errors.api_secret}</FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    className={formLoader1 ? "btn btn-success save-user disabled" : "btn btn-success save-user"}
                  >
                    {formLoader1 ? <span><i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Processing... </span> : 'Save'}
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      {/* delete */}
      <Modal isOpen={deletevisible} toggle={() => { setdeletevisible(false), setdeleteExchange(false) }} centered>
        <ModalHeader toggle={() => { setdeletevisible(false), setdeleteExchange(false) }} tag="h4" className="text-capitalize">
          {"Delete " + getExchangeName(selectNewExchange) + " API Keys"}
        </ModalHeader>
        <ModalBody>
          {/* {deleteExchange ? */}
            <p className="text-muted">Are you sure you want to delete {getExchangeName(selectNewExchange)} API Keys?</p>
            {/* :
            ""
          } */}
          <Form
            onSubmit={(e) => {
              document.getElementById('select_exchange').value = 'SelectExchange';
              e.preventDefault();
              DeleteValidation(selectNewExchange);
              setSelectExchange2(true)
              return false;
            }}
          >
            <Row>
              <Col className="col-12">

              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    className={IsLoadingDelete ? "btn btn-danger save-user disabled" : "btn btn-danger save-user"}
                  >
                    {IsLoadingDelete ? <span><i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Processing... </span> : 'Delete'}
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      {/* delete pairs */}
      <Modal isOpen={isDeleteconfirmpopup} toggle={() => { setisDeleteconfirmpopup(false)}} centered>
        <ModalHeader toggle={() => { setisDeleteconfirmpopup(false)}} tag="h4" className="text-capitalize">
          {"Delete " + (PairId ? PairId.replace('_','/') : "") + " Pair"}
        </ModalHeader>
        <ModalBody>
            <p className="text-muted">Are you sure you want to delete {PairId ? PairId.replace('_','/') : ""} Pair?</p>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              DeleteExchangePairs(ExchnageId,PairId)
              setloader(true) 
              return false;
            }}
          >
            <Row>
              <Col className="col-12">

              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    className={loader ? "btn btn-danger save-user disabled" : "btn btn-danger save-user"}
                  >
                    {loader ? <span><i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Processing... </span> : 'Delete'}
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={pairVisible} toggle={() => { setPairVisible(false) }} centered>
        <ModalHeader toggle={() => { setPairVisible(false) }} tag="h4" className="text-capitalize">
          Update {getExchangeName(selectNewExchange)} Pairs
        </ModalHeader>
        <ModalBody>
          {formLoader4 ?
            <Row>
              <Col xl={12}>
                <div className="p-4 border text-center">
                  <h6 className="text-muted">Loading...</h6>
                </div>
              </Col>
            </Row>
            :

            <Form
              onSubmit={(e) => {
                e.preventDefault();
                PairValidation.handleSubmit();
                return false;
              }}
            >
              <Row>
                <Col className="col-12">
                  <div className="mb-3">
                    <Select

                      isMulti
                      name="exchange_pair"
                      options={ccxtExchangePairs}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={selectedOption => {
                        setSelectedPair(selectedOption);
                        PairValidation.handleChange("exchange_pair", selectedOption);
                      }}
                      onBlur={PairValidation.handleBlur}
                      defaultValue={ccxtUserExchangePairs}

                      isClearable={true}
                      invalid={
                        PairValidation.touched.exchange_pair && PairValidation.errors.exchange_pair ? true : false
                      }
                    />

                    {PairValidation.touched.exchange_pair && PairValidation.errors.exchange_pair ? (
                      <FormFeedback type="invalid">{PairValidation.errors.exchange_pair}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button
                      type="submit"
                      className={formLoader3 ? "btn btn-success save-user disabled" : "btn btn-success save-user"}
                    >
                      {formLoader3 ? <span><i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Processing... </span> : 'Save'}
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          }
        </ModalBody>
      </Modal>
      <Modal isOpen={pairListModal} scrollable={true} toggle={() => { setPairListModal(false) }}>
        <ModalHeader toggle={() => { setPairListModal(false) }} tag="h4" className="text-capitalize">{getExchangeName(selectNewExchange)} Selected Pairs</ModalHeader>
        <ModalBody>
          {modalExchangePairs.map((pair, index) => {
            return (
              <p key={index} className="badge bg-primary font-size-11 m-1">{pair.display_text}</p>
            )
          })}
        </ModalBody>
        <div className="modal-footer">
          <button type="button" onClick={() => { setPairListModal(false); }} className="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </Modal>
      <Modal isOpen={settingsvisible} toggle={() => { setSettingsvisible(false), validation.resetForm() }} centered>
        <ModalHeader toggle={() => { setSettingsvisible(false) }} tag="h4" className="text-capitalize">Settings for <span className="text-primary">{settingspair.display_text}</span> Pair </ModalHeader>
        <ModalBody>

          <form key={6}  onSubmit={handleSubmit4(updatePairOrderSettings)}>
            <div >
             
              <Row>
                <Col>
                  <FormGroup className="mb-4" >
                    <Label
                      htmlFor="formrow-firstname-Input"
                      md="3"
                      className="col-form-label"
                    >
                      Fill type <i className="bx bx-info-circle" id ="Fill_type1" /><UncontrolledTooltip placement="top" target="Fill_type1">{ToolTipData.ccxt_trading_fill_type}</UncontrolledTooltip> 
                    </Label>
                    <select name="fill_type" label="fill_type" className="form-select" onChange={(e) => handlePairOrderSettingsChange(e)} value={pairOrderSettings && pairOrderSettings.fill_type?pairOrderSettings.fill_type:'instant_fill'}>
                      <option value=''>Select Fill Type</option>
                      <option value='instant_fill'>Instant Fill (Market order)</option>
                      <option value='wait_and_fill'>Wait and Fill (Limit order)</option>
                    </select>

                  </FormGroup>
                </Col>
              </Row>
              {
                  pairOrderSettings.fill_type === 'wait_and_fill' ? 
                    <Row>
                      <Col>
                        <FormGroup className="mb-0">
                          <Label htmlFor="formrow-firstname-Input">
                            Price Distance % for Wait and Fill (Limit Order) <i className="bx bx-info-circle" id ="Price_distance" /><UncontrolledTooltip placement="top" target="Price_distance">{ToolTipData.ccxt_price_distance}</UncontrolledTooltip> 
                          </Label>
                          <input autoComplete="off" type="number" value={pairOrderSettings && pairOrderSettings.price_distance_percentage ? pairOrderSettings.price_distance_percentage : ''} onChange={(e) => handlePairOrderSettingsChange(e)} onBlur={(e) => handlePairOrderSettingsChange(e)} name="price_distance_percentage" className="form-control" />
                        </FormGroup>
                      </Col>
                    </Row>
                    : ''
              }
              <Row>
                <Col>
                    <Label htmlFor="formrow-firstname-Input">
                      Quantity <span className="fw-bold" style={{fontSize:"10px"}}>
                       {/* ( {parseFloat(pairOrderSettings.quantity === 0 ? 0.000 : 0.001*pairOrderSettings.quantity)}{" "}{settingspair.base} )*/} 
                        </span> 
                      <i className="bx bx-info-circle" id ="Quantity" /><UncontrolledTooltip placement="top" target="Quantity">{ToolTipData.ccxt_quantity}</UncontrolledTooltip> 
                    </Label> 
                    <FormGroup className="input-group mb-0 ">
                                 
                                    
                                 <select
                           className="form-control"  id="inputGroupFileAddon05" name="quantity_type" onChange={(e) => handlePairOrderSettingsChange(e)}  value={pairOrderSettings && pairOrderSettings.quantity_type ? pairOrderSettings.quantity_type : ''}
                         >
                           <option value="order_qty">Order Qty</option>
                           <option value="balance_per">{settingspair.quote} Margin</option>
                           <option value="balance_per_base">{settingspair.base} Margin</option>
                         </select>
                    <input autoComplete="off"  step="any" type="number" value={pairOrderSettings && pairOrderSettings.quantity ? pairOrderSettings.quantity : ''} onChange={(e) =>  handlePairOrderSettingsChange(e)} onBlur={(e) => handlePairOrderSettingsChange(e)} name="quantity" className="form-control  w-50" min={settingspair.product_specs && settingspair.product_specs.min_order_size ? settingspair.product_specs.min_order_size: settingspair.limits && settingspair.limits.amount?settingspair.limits.amount.min:''} max={settingspair.product_specs && settingspair.product_specs.max_order_size ? settingspair.product_specs.max_order_size: settingspair.limits && settingspair.limits.amount?settingspair.limits.amount.max:''}  />
                    <Label className="input-group-text" >{!pairOrderSettings.quantity_type || pairOrderSettings.quantity_type=="order_qty"?settingspair.base:"%"}</Label>
                  </FormGroup>
                    {indexPrices[settingspair.id] ? (
                      <p
                        className="mb-0"
                        style={{ position: "absolute", right: "12px", bottom: "-1px", fontSize: "12px" }}
                      >
                        <span className="fw-bold">
                          {!pairOrderSettings.quantity_type || pairOrderSettings.quantity_type === "order_qty"
                            ? pairOrderSettings.quantity > 0
                              ? parseFloat(
                                pairOrderSettings.quantity === 0
                                  ? 0.000
                                  : parseFloat(indexPrices[settingspair.id] * pairOrderSettings.quantity).toFixed(4)
                              )
                              : "-"
                            : pairOrderSettings.quantity_type === "balance_per"
                              ? parseFloat(
                                (ccxtUserBalances[settingspair.exchange_id] &&
                                  ccxtUserBalances[settingspair.exchange_id][settingspair.quote] &&
                                  ccxtUserBalances[settingspair.exchange_id][settingspair.quote]['free']
                                  ? ccxtUserBalances[settingspair.exchange_id][settingspair.quote]['free']
                                  : 0) *
                                pairOrderSettings.quantity /
                                100
                              ).toFixed(4)
                              :pairOrderSettings.quantity_type === "balance_per_base"?parseFloat(
                                (ccxtUserBalances[settingspair.exchange_id] &&
                                  ccxtUserBalances[settingspair.exchange_id][settingspair.base] &&
                                  ccxtUserBalances[settingspair.exchange_id][settingspair.base]['free']
                                  ? ccxtUserBalances[settingspair.exchange_id][settingspair.base]['free']
                                  : 0) *
                                pairOrderSettings.quantity /
                                100
                              ).toFixed(4)
                              : "-"
                          }
                          {" "}
                        </span>
                        {pairOrderSettings.quantity_type === "balance_per_base"?settingspair.base:settingspair.quote}
                      </p>
                    ) : (
                      ''
                    )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className="mb-4" >
                    <Label
                      htmlFor="formrow-firstname-Input"
                      md="3"
                      className="col-form-label"
                    >
                      Select Side <i className="bx bx-info-circle" id ="order_side" /><UncontrolledTooltip placement="top" target="order_side">{ToolTipData.ccxt_order_side}</UncontrolledTooltip> 
                    </Label>
                    <select name="order_side" label="order_side" className="form-select" onChange={(e) => handlePairOrderSettingsChange(e)} value={pairOrderSettings && pairOrderSettings.order_side}>
                    <option value='both'>Both Short & Long</option>
                    <option value='short'>Short</option>
                    <option value='long'>Long</option>
                    <option value='sell'>Sell</option>
                    <option value='buy'>Buy</option>
                    </select>

                  </FormGroup>
                </Col>
              </Row>
              { pairOrderSettings && pairOrderSettings.order_side && pairOrderSettings.order_side!=="sell" && pairOrderSettings.order_side!=="buy" ? <>
              <Row>
                <Col>
                  <div className="">

                    <div>
                      <Label className="form-label">Stop Loss <i className="bx bx-info-circle" id ="Stop_loss" /><UncontrolledTooltip placement="top" target="Stop_loss">{ToolTipData.ccxt_stop_loss}</UncontrolledTooltip> </Label>
                      <FormGroup className="input-group mb-0 ">


                        <select
                          className="form-control" name="stop_loss_type" id="inputGroupFileAddon04" onChange={(e) => handlePairOrderSettingsChange(e)} value={pairOrderSettings && pairOrderSettings.stop_loss_type}
                        >
                          <option value="percentage">%</option>
                          <option value="points">Pts</option>
                        </select>
                        <input autoComplete="off" type="number" min={0} max={pairOrderSettings && pairOrderSettings.stop_loss_type != 'points' ? 100 : 10000} step={0.1} value={pairOrderSettings && pairOrderSettings.stop_loss ? pairOrderSettings.stop_loss : ''} onChange={(e) => handlePairOrderSettingsChange(e)} onBlur={(e) => handlePairOrderSettingsChange(e)} name="stop_loss" className="form-control w-50" aria-describedby="inputGroupFileAddon04" />
                      </FormGroup> </div></div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className="mb-0">
                    <Label htmlFor="formrow-firstname-Input">
                      Trailing Take Profit  % <i className="bx bx-info-circle" onClick={() => setVisibleMin(!visibleMin)}></i>
                    </Label> 
                    <input autoComplete="off" type="number" value={pairOrderSettings && pairOrderSettings.min_take_profit_percentage ? pairOrderSettings.min_take_profit_percentage : ''} onChange={(e) => handlePairOrderSettingsChange(e)} onBlur={(e) => handlePairOrderSettingsChange(e)} name="min_take_profit_percentage" className="form-control" />
                    {(settingspair.maker && settingspair.taker)?
              <div className="text-info font-size-10">Taker Fee: <b>{Number(settingspair.maker)*100}%</b>, Maker Fee: <b>{Number(settingspair.taker)*100}%</b></div>
              :''}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className="mb-0">
                    <Label htmlFor="formrow-firstname-Input">
                      Trailing Stop Price % <i className="bx bx-info-circle" onClick={() => setVisibleTailling(!visibleTailling)}></i>
                    </Label>
                    <input autoComplete="off" type="number" value={pairOrderSettings && pairOrderSettings.trailing_take_profit_percentage ? pairOrderSettings.trailing_take_profit_percentage : ''} onChange={(e) => handlePairOrderSettingsChange(e)} onBlur={(e) => handlePairOrderSettingsChange(e)} name="trailing_take_profit_percentage" className="form-control" />
                  </FormGroup>
                </Col>
              </Row>
              </>:''}
              { pairOrderSettings && pairOrderSettings.order_side && pairOrderSettings.order_side=="sell" ? <>
              <Row>
                <Col>
                  <FormGroup className="mb-0">
                    <Label htmlFor="formrow-firstname-Input">
                      Minimum Sell Price <i className="bx bx-info-circle" id ="min_sell_price_tar" /><UncontrolledTooltip placement="top" target="min_sell_price_tar">{ToolTipData.ccxt_min_sell_price}</UncontrolledTooltip> 
                    </Label> 
                    <input autoComplete="off" type="number" value={pairOrderSettings && pairOrderSettings.min_sell_price ? pairOrderSettings.min_sell_price : ''} onChange={(e) => handlePairOrderSettingsChange(e)} onBlur={(e) => handlePairOrderSettingsChange(e)} name="min_sell_price" className="form-control"   />
                   
                  </FormGroup>
                </Col>
              </Row></> :''}
              { pairOrderSettings && pairOrderSettings.order_side && pairOrderSettings.order_side=="buy" ? <>
              <Row>
                <Col>
                  <FormGroup className="mb-0">
                    <Label htmlFor="formrow-firstname-Input">
                      Maximum Buy Price <i className="bx bx-info-circle" id ="max_buy_price_tar" /><UncontrolledTooltip placement="top" target="max_buy_price_tar">{ToolTipData.ccxt_max_buy_price}</UncontrolledTooltip> 
                    </Label> 
                    <input autoComplete="off" type="number" value={pairOrderSettings && pairOrderSettings.max_buy_price ? pairOrderSettings.max_buy_price : ''} onChange={(e) => handlePairOrderSettingsChange(e)} onBlur={(e) => handlePairOrderSettingsChange(e)} name="max_buy_price" className="form-control" />
                  
                  </FormGroup>
                </Col>
              </Row></> :''}
              { pairOrderSettings && pairOrderSettings.order_side && pairOrderSettings.order_side!=="sell" && pairOrderSettings.order_side!=="buy" ? <>
              <Row>
                <Col>

                  <FormGroup className="mb-0">
                    <Label htmlFor="formrow-firstname-Input">
                    Waiting Time for Order after Stop Loss (H : M) <i className="bx bx-info-circle" id ="WTstoploss" />
                                    <UncontrolledTooltip placement="top" target="WTstoploss"> 
                                        {ToolTipData.intervalfororder_stop_loss}
                                    </UncontrolledTooltip> 
                    </Label>
                    <Flatpickr
                        className="form-control d-block"
                        placeholder="Select time"
                        name="waiting_time_for_next_order_stop_loss"
                        onChange={(e) => handleOrderInterval(e,'waiting_time_for_next_order_stop_loss')}
                        value={pairOrderSettings && pairOrderSettings.waiting_time_for_next_order_stop_loss ? toHoursAndMinutes(pairOrderSettings.waiting_time_for_next_order_stop_loss) : ''}
                        options={{                                      
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: "H:i",
                          time_24hr: true,
                        }}
                      />
                   
                  </FormGroup>
                  </Col>
                  <Col>
                  <FormGroup className="mb-0">
                    <Label htmlFor="formrow-firstname-Input">
                    Waiting Time for Order after Trailing Take Profit (H : M) <i className="bx bx-info-circle" id ="WTtakeprofit" />
                                    <UncontrolledTooltip placement="top" target="WTtakeprofit"> 
                                        {ToolTipData.intervalfororder_trailing_take_profit}
                                    </UncontrolledTooltip> 
                    </Label>
                    <Flatpickr
                        className="form-control d-block"
                        placeholder="Select time"
                        name="waiting_time_for_next_order_trailing_profit"
                        onChange={(e) => handleOrderInterval(e,'waiting_time_for_next_order_trailing_profit')}
                        value={pairOrderSettings && pairOrderSettings.waiting_time_for_next_order_trailing_profit ? toHoursAndMinutes(pairOrderSettings.waiting_time_for_next_order_trailing_profit) : ''}
                        options={{                                      
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: "H:i",
                          time_24hr: true,
                        }}
                      />
                   
                  </FormGroup>
                </Col>
              </Row>
              </>:''}
            </div>
            <div className="text-sm-end">
              <Button className="btn-label w-md" color="success" type="submit" disabled={form7_loader.loading}>
                {form7_loader.loading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> : ''}
                <i className="bx bx-smile label-icon"></i> Submit
              </Button>
            </div>
          </form>

        </ModalBody>

      </Modal>
      <Modal
        isOpen={visibleMin}
        toggle={() => {
        setVisibleMin(false);
        }}
        > 
        <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          To set the Minimum profit for an order.
        </h5>
        <button
          type="button"
          onClick={() => {
            setVisibleMin(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body">
        {parse(''+ MintakeProfitMsg)}
        </div>
        <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setVisibleMin(false);
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>                        
        </div>
      </Modal>
      <Modal
        isOpen={visibleTailling}
        toggle={() => {
        setVisibleTailling(false);
        }}
        >
        <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          To set the Minimum profit for an order.
        </h5>
        <button
          type="button"
          onClick={() => {
            setVisibleTailling(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body">
        {parse(''+ TaillingTakeProfitMsg)}
        </div>
        <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setVisibleTailling(false);
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>                        
        </div>
      </Modal>
      <Modal
        isOpen={TrendFollowSettings}
        toggle={() => {
        setTrendFollowSettings(false);
        }}
        style={{width:"100%"}} 
        className="modal-xl"
        >
        <ModalHeader toggle={() => { setTrendFollowSettings(false) }} tag="h4" className="text-capitalize">Trend Following Settings For <span className="text-primary">{Trendfollowpair.display_text}</span> Pair </ModalHeader>
        <div className="modal-body">
        <ModalBody > 
        <TabPane tabId="2">
                        <form key={6} onSubmit={handleSubmit3(updateNewTrendSettings)}>
                          <div >
                          <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="formrow-firstname-Input"
                                md="4"
                                className="col-form-label"
                              >
                                Trading type: <i className="bx bx-info-circle" id ="trading_type" />
                                    <UncontrolledTooltip placement="top" target="trading_type">
                                    {
                                        <>Please select the type of trading you prefer:<br />- Short term - INTRADAY:<br />This option is suitable for traders who aim to complete their trades within a single day.<br />- Medium term - SWING:<br />Choose this option if you prefer holding your trades for a few days to a few weeks.<br />- Long term - INVESTMENT:<br />Select this option if you have a long-term investment approach and plan to hold your trades for an extended period</>
                                      }
                                    </UncontrolledTooltip>
                                </Label>
                              <Col md="5">
                                <select name="trading_type" label="trading_type" className="form-select" onChange={(e) => handleTrendSettingsChange(e)} value={trendSettings.trading_type}>

                                  <option value='short_term'>Short term - INTRADAY</option>
                                  <option value='medium_term'>Medium term - SWING</option>
                                  <option value='long_term'>Long term - INVESTMENT</option>
                                </select>
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="formrow-firstname-Input"
                                md="4"
                                className="col-form-label"
                              >
                                Method for Trend signal: <i className="bx bx-info-circle" id ="Method_for_Trend_signal" />
                                    <UncontrolledTooltip placement="top" target="Method_for_Trend_signal">
                                        {ToolTipData.method_for_trend_signal}
                                    </UncontrolledTooltip>
                                </Label>
                              <Col md="5">
                                <select name="trend_method" label="trend_method" className="form-select" onChange={(e) => handleTrendSettingsChange(e)} value={trendSettings.trend_method}>

                                  <option value='sma'>SMA crossovers</option>
                                  <option value='ema'>EMA crossovers</option>
                                  <option value='macd'>MACD Signal Line cross</option>
                                  <option value='supertrend'>Supertrend</option>
                                </select>
                              </Col>
                            </FormGroup>
                            
                            {!trendSettings.trend_method || trendSettings.trend_method == "sma" ? <>

                              <FormGroup className="mb-4" row>
                                <Label
                                  htmlFor="formrow-firstname-Input"
                                  md="4"
                                  className="col-form-label"
                                >
                                  Interval <i className="bx bx-info-circle" id ="Interval1" />
                                    <UncontrolledTooltip placement="top" target="Interval1">
                                        {ToolTipData.sma_crossover.interval}
                                    </UncontrolledTooltip>
                                    </Label>
                                <Col md="8">
                                  <select name="time_frame" label="time_frame" required className="form-select" style={DefaultColorSetting.time_frame ? {} :{color:"gray"}} value={trendSettings.time_frame ? trendSettings.time_frame : "15m"} onChange={(e) => handleTrendSettingsChange(e)} >
                                    <option value='1m'>1 minute</option>
                                    <option value='5m'>5 minutes</option>
                                    <option value='15m'>15 minutes</option>
                                    <option value='30m'>30 minutes</option>
                                    <option value='1h'>1 hour</option>
                                    <option value='2h'>2 hours</option>
                                    <option value='4h'>4 hours</option>
                                    <option value='1d'>1 day</option>
                                    <option value='1w'>1 week</option>
                                    <option value='1M'>1 month</option>
                                  </select>
                                </Col>
                              </FormGroup>
                              <FormGroup className="mb-4" row>
                                <Label
                                  htmlFor="formrow-firstname-Input"
                                  md="4"
                                  className="col-form-label"
                                >
                                  Short length <i className="bx bx-info-circle" id ="Short_length" />
                                    <UncontrolledTooltip placement="top" target="Short_length">
                                        {ToolTipData.sma_crossover.short_length}
                                    </UncontrolledTooltip>
                                    </Label>
                                <Col md="8">
                                  <input type="number" required min={1} step={1} style={DefaultColorSetting.short_term ? {} :{color:"gray"}} value={trendSettings.short_term ? trendSettings.short_term : 9} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="short_term" className="form-control" />
                                </Col>
                              </FormGroup>
                              <FormGroup className="mb-4" row>
                                <Label
                                  htmlFor="formrow-firstname-Input"
                                  md="4"
                                  className="col-form-label"
                                >
                                  Long length <i className="bx bx-info-circle" id ="Long_length" />
                                    <UncontrolledTooltip placement="top" target="Long_length">
                                        {ToolTipData.sma_crossover.long_length}
                                    </UncontrolledTooltip>
                                    </Label>
                                <Col md="8">
                                  <input type="number" required min={1} step={1} style={DefaultColorSetting.long_term ? {} :{color:"gray"}} value={trendSettings.long_term ? trendSettings.long_term : 20} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="long_term" className="form-control" />
                                </Col>
                              </FormGroup>
                            </> :trendSettings.trend_method=="ema"? 
                               <>
                              
                               <FormGroup className="mb-4" row>
                               <Label
                                 htmlFor="formrow-firstname-Input"
                                 md="4"
                                 className="col-form-label"
                               >
                               Interval <i className="bx bx-info-circle" id ="TFBMINTREVAL" />
                                     <UncontrolledTooltip placement="top" target="TFBMINTREVAL"> 
                                         {ToolTipData.ema_crossover.interval}
                                     </UncontrolledTooltip>
                               </Label>
                               <Col md="8">                              
                               <select name="ema_time_frame" label="ema_time_frame" required className="form-select" style={DefaultColorSetting.ema_time_frame ? {} :{color:"gray"}}  value={trendSettings.ema_time_frame ? trendSettings.ema_time_frame : "15m"} onChange={(e) => handleTrendSettingsChange(e)} > 					  
                                 <option value='1m'>1 minute</option>
                                 <option value='5m'>5 minutes</option>
                                 <option value='15m'>15 minutes</option>
                                 <option value='30m'>30 minutes</option>
                                 <option value='1h'>1 hour</option>
                                 <option value='2h'>2 hours</option>
                                 <option value='4h'>4 hours</option>
                                 <option value='1d'>1 day</option>
                                 <option value='1w'>1 week</option>
                                 <option value='1M'>1 month</option>
                               </select>
                               </Col>
                             </FormGroup>
                             <FormGroup className="mb-4" row>
                               <Label
                                 htmlFor="formrow-firstname-Input"
                                 md="4"
                                 className="col-form-label"
                               >
                               Short length <i className="bx bx-info-circle" id ="ema_short" />
                                     <UncontrolledTooltip placement="top" target="ema_short"> 
                                         {ToolTipData.ema_crossover.short_length}
                                     </UncontrolledTooltip>
                               </Label>
                               <Col md="8">                              
                               <input type="number" onWheel={handleWheel}  inputMode="numeric"  required  min={1} step={1} style={DefaultColorSetting.ema_short_term ? {} :{color:"gray"}} value={trendSettings.ema_short_term?trendSettings.ema_short_term:9} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="ema_short_term" className="form-control" />
                               </Col>
                             </FormGroup>
                             <FormGroup className="mb-4" row>
                               <Label
                                 htmlFor="formrow-firstname-Input"
                                 md="4"
                                 className="col-form-label"
                               >
                               Long length <i className="bx bx-info-circle" id ="ema_long" />
                                     <UncontrolledTooltip placement="top" target="ema_long"> 
                                         {ToolTipData.ema_crossover.long_length}
                                     </UncontrolledTooltip>
                               </Label>
                               <Col md="8">                              
                               <input type="number" onWheel={handleWheel}  inputMode="numeric"  required  min={1} step={1} style={DefaultColorSetting.ema_long_term ? {} :{color:"gray"}} value={trendSettings.ema_long_term?trendSettings.ema_long_term:21} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="ema_long_term" className="form-control" />
                               </Col>
                             </FormGroup>
                               </>: trendSettings.trend_method == "macd" ?
                              <>

                                <FormGroup className="mb-4" row>
                                  <Label
                                    htmlFor="formrow-firstname-Input"
                                    md="4"
                                    className="col-form-label"
                                  >
                                    Interval <i className="bx bx-info-circle" id ="Interval" />
                                    <UncontrolledTooltip placement="top" target="Interval">
                                        {ToolTipData.macd_sign_line_cross.interval}
                                    </UncontrolledTooltip>
                                    </Label>
                                  <Col md="8">
                                    <select name="macd_time_frame" required label="macd_time_frame" className="form-select" style={DefaultColorSetting.macd_time_frame ? {} :{color:"gray"}} value={trendSettings.macd_time_frame} onChange={(e) => handleTrendSettingsChange(e)} >
                                      <option value='1m'>1 minute</option>
                                      <option value='5m'>5 minutes</option>
                                      <option value='15m'>15 minutes</option>
                                      <option value='30m'>30 minutes</option>
                                      <option value='1h'>1 hour</option>
                                      <option value='2h'>2 hours</option>
                                      <option value='4h'>4 hours</option>
                                      <option value='1d'>1 day</option>
                                      <option value='1w'>1 week</option>
                                      <option value='1M'>1 month</option>
                                    </select>
                                  </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                  <Label
                                    htmlFor="formrow-firstname-Input"
                                    md="4"
                                    className="col-form-label"
                                  >
                                    Short length <i className="bx bx-info-circle" id ="Short_length" />
                                    <UncontrolledTooltip placement="top" target="Short_length">
                                        {ToolTipData.macd_sign_line_cross.short_length}
                                    </UncontrolledTooltip>
                                    </Label>
                                  <Col md="8">
                                    <input type="number" required min={1} step={1} style={DefaultColorSetting.macd_short_term ? {} :{color:"gray"}} value={trendSettings.macd_short_term ? trendSettings.macd_short_term : 12} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="macd_short_term" className="form-control" />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                  <Label
                                    htmlFor="formrow-firstname-Input"
                                    md="4"
                                    className="col-form-label"
                                  >
                                    Long length <i className="bx bx-info-circle" id ="Long_length" />
                                    <UncontrolledTooltip placement="top" target="Long_length">
                                        {ToolTipData.macd_sign_line_cross.long_length}
                                    </UncontrolledTooltip>
                                    </Label>
                                  <Col md="8">
                                    <input type="number" required min={1} step={1} style={DefaultColorSetting.macd_long_term ? {} :{color:"gray"}} value={trendSettings.macd_long_term ? trendSettings.macd_long_term : 26} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="macd_long_term" className="form-control" />
                                  </Col>
                                </FormGroup>

                                <FormGroup className="mb-4" row>
                                  <Label
                                    htmlFor="formrow-firstname-Input"
                                    md="4"
                                    className="col-form-label"
                                  >
                                    Signal length <i className="bx bx-info-circle" id ="Signal_length" />
                                    <UncontrolledTooltip placement="top" target="Signal_length">
                                        {ToolTipData.macd_sign_line_cross.signal_length}
                                    </UncontrolledTooltip>
                                    </Label>
                                  <Col md="8">
                                    <input type="number" required min={1} step={1} style={DefaultColorSetting.macd_signal_term ? {} :{color:"gray"}} value={trendSettings.macd_signal_term ? trendSettings.macd_signal_term : 9} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="macd_signal_term" className="form-control" />
                                  </Col>
                                </FormGroup>
                              </>
                              : trendSettings.trend_method == "supertrend" ? <>
                                <FormGroup className="mb-4" row>
                                  <Label
                                    htmlFor="formrow-firstname-Input"
                                    md="4"
                                    className="col-form-label"
                                  >
                                    Interval <i className="bx bx-info-circle" id ="Interval" />
                                    <UncontrolledTooltip placement="top" target="Interval">
                                        {ToolTipData.supertrend.interval}
                                    </UncontrolledTooltip>
                                    </Label>
                                  <Col md="8">
                                    <select name="st_time_frame" required label="st_time_frame" className="form-select" style={DefaultColorSetting.st_time_frame ? {} :{color:"gray"}} value={trendSettings.st_time_frame} onChange={(e) => handleTrendSettingsChange(e)} >
                                      <option value='1m'>1 minute</option>
                                      <option value='5m'>5 minutes</option>
                                      <option value='15m'>15 minutes</option>
                                      <option value='30m'>30 minutes</option>
                                      <option value='1h'>1 hour</option>
                                      <option value='2h'>2 hours</option>
                                      <option value='4h'>4 hours</option>
                                      <option value='1d'>1 day</option>
                                      <option value='1w'>1 week</option>
                                      {/*<option value='1M'>1 month</option>*/}
                                    </select>
                                  </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                  <Label
                                    htmlFor="formrow-firstname-Input"
                                    md="4"
                                    className="col-form-label"
                                  >
                                    ATR length <i className="bx bx-info-circle" id ="ATR_length" />
                                    <UncontrolledTooltip placement="top" target="ATR_length">
                                        {ToolTipData.supertrend.atr_length}
                                    </UncontrolledTooltip>
                                    </Label>
                                  <Col md="8">
                                    <input type="number" required min={1} step={1} style={DefaultColorSetting.st_atr_length ? {} :{color:"gray"}} value={trendSettings.st_atr_length ? trendSettings.st_atr_length : 10} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="st_atr_length" className="form-control" />
                                  </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                  <Label
                                    htmlFor="formrow-firstname-Input"
                                    md="4"
                                    className="col-form-label"
                                  >
                                    Factor <i className="bx bx-info-circle" id ="Factor" />
                                    <UncontrolledTooltip placement="top" target="Factor">
                                        {ToolTipData.supertrend.factor}
                                    </UncontrolledTooltip>
                                    </Label>
                                  <Col md="8">
                                    <input type="number" required min={1} step={0.1} style={DefaultColorSetting.st_factor ? {} :{color:"gray"}} value={trendSettings.st_factor ? trendSettings.st_factor : 2} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="st_factor" className="form-control" />
                                  </Col>
                                </FormGroup>
                                {/* <h5 className="mt-5 mb-3 font-size-15">
                                  Supertrend distance for BTC/USDT trading pair
                                </h5> */}
                                {/* <div className="p-4 border"> */}
                                  <FormGroup className="mb-4" row>
                                    <Label
                                      htmlFor="formrow-firstname-Input"
                                      md="4"
                                      className="col-form-label"
                                    >
                                    Minimum supertrend distance <i className="bx bx-info-circle" id ="Minimum_supertrend_distance" />
                                    <UncontrolledTooltip placement="top" target="Minimum_supertrend_distance">
                                        {ToolTipData.supertrend.minimum_supertrend_distance}
                                    </UncontrolledTooltip>
                                    </Label>
                                    <Col md="8">
                                      <input type="number" step={0.00001} style={DefaultColorSetting.st_min_distance ? {} :{color:"gray"}} value={trendSettings.st_min_distance ? trendSettings.st_min_distance : ''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="st_min_distance" className="form-control" />
                                    </Col>
                                  </FormGroup>
                                  <FormGroup className="mb-4" row>
                                    <Label
                                      htmlFor="formrow-firstname-Input"
                                      md="4"
                                      className="col-form-label"
                                    >
                                    Maximum supertrend distance <i className="bx bx-info-circle" id ="Maximum_supertrend_distance" />
                                    <UncontrolledTooltip placement="top" target="Maximum_supertrend_distance">
                                        {ToolTipData.supertrend.maximum_supertrend_distance}
                                    </UncontrolledTooltip>
                                    </Label>
                                    <Col md="8">
                                      <input type="number" step={1}  style={DefaultColorSetting.st_max_distance ? {} :{color:"gray"}} value={trendSettings.st_max_distance ? trendSettings.st_max_distance : ''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="st_max_distance" className="form-control" />
                                    </Col>
                                  </FormGroup>
                                {/* </div> */}
                              </> : ''}
                              <FormGroup className="mb-4" row>
                              {/* <Label
                                htmlFor="formrow-firstname-Input"
                                md="4"
                                className="col-form-label"
                              > */}
                              {/* {  (trendSettings.trend_method === 'sma' && (trendSettings.short_term !== '' && trendSettings.short_term !== undefined && trendSettings.short_term !== null && trendSettings.long_term !== '' && trendSettings.long_term !== undefined && trendSettings.long_term !== null) ||
                              (trendSettings.trend_method === 'ema' && (trendSettings.ema_time_frame !== '' && trendSettings.ema_time_frame !== undefined && trendSettings.ema_time_frame !== null && trendSettings.ema_short_term !== '' && trendSettings.ema_short_term !== undefined && trendSettings.ema_short_term !== null && trendSettings.ema_long_term !== '' && trendSettings.ema_long_term !== undefined && trendSettings.ema_long_term !== null))  ||  
                              (trendSettings.trend_method === 'macd' && (trendSettings.macd_time_frame !== '' && trendSettings.macd_time_frame !== undefined && trendSettings.macd_time_frame !== null && trendSettings.macd_short_term !== '' && trendSettings.macd_short_term !== undefined && trendSettings.macd_short_term !== null && trendSettings.macd_long_term !== '' && trendSettings.macd_long_term !== undefined && trendSettings.macd_long_term !== null  && trendSettings.macd_signal_term !== '' && trendSettings.macd_signal_term !== undefined && trendSettings.macd_signal_term !== null)) ||
                              (trendSettings.trend_method === 'supertrend' && (trendSettings.st_time_frame !== '' && trendSettings.st_time_frame !== undefined && trendSettings.st_time_frame !== null && trendSettings.st_atr_length !== '' && trendSettings.st_atr_length !== undefined && trendSettings.st_atr_length !== null && trendSettings.st_max_distance !== '' && trendSettings.st_max_distance !== undefined && trendSettings.st_max_distance !== null && trendSettings.st_min_distance !== '' && trendSettings.st_min_distance !== undefined && trendSettings.st_min_distance !== null)) ) ? */}
                                
                                <TradingView data={`${(Trendfollowpair.exchange_id === 'binance_us_paper_trade' ? 'binanceus' : Trendfollowpair.exchange_id)  }:${Trendfollowpair.display_text},${trendSettings.trend_method},${trendSettings.time_frame?trendSettings.time_frame:""},${trendSettings.short_term?trendSettings.short_term:9},${trendSettings.long_term?trendSettings.long_term:20},${trendSettings.ema_time_frame?trendSettings.ema_time_frame:""},${trendSettings.ema_short_term?trendSettings.ema_short_term:9},${trendSettings.ema_long_term?trendSettings.ema_long_term:21},${trendSettings.macd_time_frame?trendSettings.macd_time_frame:""},${trendSettings.macd_short_term?trendSettings.macd_short_term:12},${trendSettings.macd_long_term?trendSettings.macd_long_term:26},${trendSettings.macd_signal_term?trendSettings.macd_signal_term:9},${trendSettings.st_time_frame?trendSettings.st_time_frame:""},${trendSettings.st_atr_length?trendSettings.st_atr_length:10},${trendSettings.st_factor?trendSettings.st_factor:2},${trendSettings.st_min_distance?trendSettings.st_min_distance:""},${trendSettings.st_max_distance?trendSettings.st_max_distance:""}`}/>
                              {/* // : <TradingView/>}  */}
                                {/* Tap to view the <a href={`/CCXT/Trendview?${document.getElementById('select_exchange_pair') ? document.getElementById('select_exchange_pair').value:""}:${Trendfollowpair.display_text},${trendSettings.trend_method},${trendSettings.time_frame?trendSettings.time_frame:""},${trendSettings.short_term?trendSettings.short_term:""},${trendSettings.long_term?trendSettings.long_term:""},${trendSettings.ema_time_frame?trendSettings.ema_time_frame:""},${trendSettings.ema_short_term?trendSettings.ema_short_term:""},${trendSettings.ema_long_term?trendSettings.ema_long_term:""},${trendSettings.macd_time_frame?trendSettings.macd_time_frame:""},${trendSettings.macd_short_term?trendSettings.macd_short_term:""},${trendSettings.macd_long_term?trendSettings.macd_long_term:""},${trendSettings.macd_signal_term?trendSettings.macd_signal_term:""},${trendSettings.st_time_frame?trendSettings.st_time_frame:""},${trendSettings.st_atr_length?trendSettings.st_atr_length:""},${trendSettings.st_factor?trendSettings.st_factor:""},${trendSettings.st_min_distance?trendSettings.st_min_distance:""},${trendSettings.st_max_distance?trendSettings.st_max_distance:""}`} target="_blank" rel="noreferrer">Chart</a> */}
                              {/* </Label> */}
                            </FormGroup>   
                          </div>
                          <div className="text-sm-end mt-3"> 
                            <Button className="btn-label w-md" color="success" type="submit" disabled={form6_loader.loading}>
                              {form6_loader.loading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> : ''}
                              <i className="bx bx-smile label-icon"></i> Submit
                            </Button>
                          </div>
                        </form>
                      </TabPane>
                      </ModalBody>
        </div>
        {/* <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setTrendFollowSettings(false);
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>                        
        </div> */}
      </Modal>
      <Modal
        isOpen={CredentialSetup}
        toggle={() => {
        setCredentialSetup(false);
        }}
        style={{width:"100%"}} 
        className="modal-lg"
        >
        <div className="modal-header" >
        <h5 className="modal-title mt-0" id="myModalLabel">
          Please Follow the Steps to Generate API Keys.
        </h5>
        <button
          type="button"
          onClick={() => {
            setCredentialSetup(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body">
        {parse(''+ CredentialsSetupMsg)}
        </div>
        <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setCredentialSetup(false);
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>                        
        </div>
      </Modal>

      <Modal
        isOpen={pasteSettingsModal}
        toggle={() => {
        setPasteSettingsModal(false);
        }}
        style={{width:"100%"}} 
        className="modal-lg"
        >
        <div className="modal-header" >
        <h5 className="modal-title mt-0" id="myModalLabel">
          Are you sure want to apply the settings for <b className="text-primary">{pastePair.pair}</b>?
        </h5>
        <button
          type="button"
          onClick={() => {
            setPasteSettingsModal(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '48%' }} className="border-end">
          <h5>Order Settings</h5>
          {
            copiedSettings.ordersettings && copiedSettings.ordersettings.quantity ? (
              <p className="text-muted text-info font-size-11">
                Quantity: <b>{copiedSettings.ordersettings.quantity || copiedSettings.ordersettings.qty}{copiedSettings.ordersettings.quantity_type=="balance_per"?"% of available quote currency margin":""}{copiedSettings.ordersettings.quantity_type=="balance_per_base"?"% of available base currency margin":""}</b>
                {copiedSettings.ordersettings.fill_type && copiedSettings.ordersettings.fill_type.length !== 0 &&
                  <> <br /> Fill type: <b>{copiedSettings.ordersettings.fill_type === 'instant_fill' ? 'Instant Fill' : 'Wait and Fill'}</b></>
                }
                {copiedSettings.ordersettings.price_distance_percentage && copiedSettings.ordersettings.fill_type !== 'instant_fill' &&
                  <> <br /> Price Distance % for Wait and Fill: <b>{copiedSettings.ordersettings.price_distance_percentage}</b></>
                }
                {copiedSettings.ordersettings.stop_loss && copiedSettings.ordersettings.stop_loss.length !== 0 &&
                  <> <br /> Market Stop Loss: <b>{copiedSettings.ordersettings.stop_loss}{copiedSettings.ordersettings.stop_loss_type === "points" ? 'Pts' : "%"}</b></>
                }
                {copiedSettings.ordersettings.min_take_profit_percentage &&
                  <> <br /> Minimum Take Profit%: <b>{copiedSettings.ordersettings.min_take_profit_percentage}</b></>
                }
                {copiedSettings.ordersettings.trailing_take_profit_percentage &&
                  <> <br /> Trailing Take Profit%: <b>{copiedSettings.ordersettings.trailing_take_profit_percentage}</b></>
                }
                {copiedSettings.ordersettings.waiting_time_for_next_order_stop_loss &&
                  <> <br /> Waiting Time for Order after Stop Loss: <b>{timeFormat(copiedSettings.ordersettings.waiting_time_for_next_order_stop_loss)}</b></>
                }
                {copiedSettings.ordersettings.waiting_time_for_next_order_trailing_profit &&
                  <> <br /> Waiting Time for Order after Trailing Take Profit: <b>{timeFormat(copiedSettings.ordersettings.waiting_time_for_next_order_trailing_profit)}</b></>
                }
                {copiedSettings.ordersettings.order_side &&
                  <> <br /> Order Side: <b>{copiedSettings.ordersettings.order_side}</b></>
                }
                {copiedSettings.ordersettings.order_side === "sell" && copiedSettings.ordersettings.min_sell_price &&
                  <> <br /> Minimum Sell Price: <b>{copiedSettings.ordersettings.min_sell_price}</b><br /></>
                }
                {copiedSettings.ordersettings.order_side === "buy" && copiedSettings.ordersettings.max_buy_price &&
                  <> <br /> Maximum Buy Price: <b>{copiedSettings.ordersettings.max_buy_price}</b><br /></>
                }
              </p>
            ) : <div className="text-warning font-size-11">No Order Settings available</div>
          }
        </div>

        <div style={{ width: '48%' }}>
          <h5>Trend Settings</h5>
          {copiedSettings.trendsettings ?  <div className="font-size-11">{renderTrendSettings()}</div> : <div className="text-warning font-size-11">No Trend Settings available</div>}
        </div>
      </div>
    </div>
        <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            pasteApply(pastePair);
          }}
          className="btn btn-primary"
          data-dismiss="modal"
        >
          Apply Settings
        </button>   
        <button
          type="button"
          onClick={() => {
            setPasteSettingsModal(false);
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Cancel
        </button>                        
        </div>
      </Modal>

      <Modal isOpen={addPairVisible} toggle={() => { setAddPairVisible(false);setPairErrors({exchange_error:"",pair_error:""});setSpotPairs([]);setPairExchange('');setSelectedSpotPair({});}} centered>
        <ModalHeader toggle={() => { setAddPairVisible(false);setPairErrors({exchange_error:"",pair_error:""});setSpotPairs([]);setPairExchange('');setSelectedSpotPair({}) }} tag="h4" className="text-capitalize">
          {/* {editExchange ? "Update " + getExchangeName(selectNewExchange) + " Settings" :  ''} */}
          Add Pair
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              addNewPair();
            }}
          >
            <Row>
              <Col className="col-12">
                <div className="mb-3">
                  <Label className="form-label">Select Exchange
                    </Label>
                    <select id="select-exchange-pair" name="pair_exchange_id" value={pairExchange} selected={true} className="form-select" onChange={async (e) => { handlePairExchange(e) }}>
                    <option value="" >Select Exchange</option>
                    {ccxtUserExchanges.map((exchange, index) => {
                      return (
                      <React.Fragment key={index}>
                        <option value={exchange.details.id} > {exchange.details.name}</option>
                      </React.Fragment>
                      )
                    })}
                  </select>

                  {pairErrors.exchange_error  ? (
                    <div style={{color:"#f46a6a",width:"100%",marginTop:"0.25rem",fontSize:"80%"}}>{pairErrors.exchange_error}</div>
                  ) : null}
                </div>

                {formLoader8 ? <div className="d-flex justify-content-center">
                  <Spinner animation="border" size="sm" />
                </div> :null}
                {
                  pairExchange != "" && !formLoader8 ?
                  <div className="mb-3">
                  <Label className="form-label">Select Pair
                  </Label>
                  <Select id="select-add-pair" placeholder="Add new pair"  name="pair_symbol" onChange={handleSelect} options={spotPairs} classNamePrefix="select2-selection"></Select>
                  {pairErrors.pair_error ? (
                    <div style={{color:"#f46a6a",width:"100%",marginTop:"0.25rem",fontSize:"80%"}}>{pairErrors.pair_error}</div>
                  ) : null}
                  </div>
                  :null
                }
               
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    className={formLoader6 ? "btn btn-success save-user disabled" : "btn btn-success save-user"}
                  >
                    {formLoader6 ? <span><i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Processing... </span> : 'Save'}
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      {/* set stop loss  */}
      {/* <Modal isOpen={slVisible} toggle={() => { setVisible(false), setStopLoss.resetForm() }} centered>
        <ModalHeader toggle={() => { setSlVisible(false), setStopLoss.resetForm() }} tag="h4" className="text-capitalize">
          {editExchange ? "Update " + getExchangeName(selectNewExchange) + " Settings" :  ''}
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              // document.getElementById('select_exchange').value = 'SelectExchange';
              // setSelectExchange2(true)
              e.preventDefault();
              setStopLoss.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col className="col-12">
                <div className="mb-3">
                  <Label className="form-label">Overall portfolio stop loss %
                    </Label>
                  <Input
                    name="stop_loss"
                    type="number"
                    onChange={setStopLoss.handleChange}
                    onBlur={setStopLoss.handleBlur}
                    value={setStopLoss.values.stop_loss || ""}
                    invalid={
                      setStopLoss.touched.stop_loss && setStopLoss.errors.stop_loss ? true : false
                    }
                    autoComplete="off"
                    min={0}
                    max={100}
                  />

                  {setStopLoss.touched.stop_loss && setStopLoss.errors.stop_loss ? (
                    <FormFeedback type="invalid">{setStopLoss.errors.stop_loss}</FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    className={formLoader8 ? "btn btn-success save-user disabled" : "btn btn-success save-user"}
                  >
                    {formLoader8 ? <span><i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Processing... </span> : 'Save'}
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal> */}
    </React.Fragment>
: tradeStatus==0? <React.Fragment>
   <div className="page-content">
        <Container fluid>
        <Col lg="6" sm="3" style={{"margin":"0px auto"}}><Alert color="danger">
<i className="mdi mdi-alert-outline me-2"></i><strong>Account disabled</strong><br />
Your account is disabled. Please contact the administrator to enable your account
</Alert></Col>
</Container>
</div>
</React.Fragment>
 :'' )
}

export default Settings

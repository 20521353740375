import React, { useCallback, useEffect, useState } from "react"

import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  Row,
  Modal,
  Spinner,
  TabContent,
  TabPane,
  Table,
  Tooltip,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  CardText,
  Alert,
  UncontrolledTooltip, Popover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap"
import parse from 'html-react-parser';
import classnames from "classnames"
import { Link } from "react-router-dom"
import Switch from "react-switch";
import { MinimumTakeProfit, TaillingTakeProfit, MidPrize } from "../../helpers/help-msg";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { formatUTCDate, CustomParseFloat, getCalendarTypeName, getFirstPositionName, getFillTypeName, getPrices, getMomentDateTimeFormat, formatUTCDateTime, getOrderStatusNameStyle, counterRenderer, getMarketActionTriggerName, getSecondsFromTime, getSettingsDateTimeFormat, getOrderLogs, timeFormat } from "services/common.service"
import { toast } from "react-toastify";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import firebase from "firebase/compat/app";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import Countdown from "react-countdown"
import Swal from "sweetalert2"
import HistoryModal from "components/Modal/HistoryModal"
import Flatpickr from "react-flatpickr";
import { getStraddleExpiryDates, getStraddleStrikes, marketClose, marketCloseOrderAPI, startBot, stopBot, stopReentryAPI } from "helpers/straddle_helper"
const { default: axios } = require("axios");
import api from 'helpers/api_helper';
import { doc } from "prettier";
import Bot_settings_default from '../../helpers/Common/Bot_default_settings_values.json';
import DatePicker from "react-flatpickr";

const Straddles = () => {
  firebase.auth();
  //Current User  
  //tooltip
  const apiUrl = `${process.env.REACT_APP_API_URL}/static/helptexts.json`;
  const [ToolTipData, setToolTipData] = useState([]);
  useEffect(() => {
    try {
      const gettoottipData = async () => {

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/static/helptexts.json`)
        // console.log(response)
        setToolTipData(JSON.parse(JSON.stringify(response.data)))

      }

      gettoottipData()
    } catch (error) {
      console.log(error)
    }

  }, [apiUrl])
  //tooltip
  const currentUser = JSON.parse(localStorage.getItem('user'));
  let uid = currentUser.uid;
  const handleError = (err) => {
    if (err && err.response && err.response.data && err.response.data.message) {
      toast.error(err.response.data.message, { toastId: err.response.data.message });
    } else if (err.message) {
      toast.error(err.message, { toastId: err.message });
    } else {
      toast.error(err, { toastId: err });
    }
  }

  //meta title
  document.title = "Straddles";
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [open, setOpen] = useState('0');
  const firestoreDb = firebase.firestore();
  const [formLoader, setformLoader] = useState(false);
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const [visibleMin, setVisibleMin] = useState(false);
  const [visibleTailling, setVisibleTailling] = useState(false);
  const [MintakeProfitMsg, setMintakeProfitMsg] = useState();
  const [TaillingTakeProfitMsg, setTaillingTakeProfitMsg] = useState();
  const [isStraddlebotEnabled, setIsStraddlebotEnabled] = useState(false);
  const [tradeStatus, setTradeStatus] = useState("0");
  const [straddleExpiryDates, setStraddleExpiryDates] = useState([]);
  const [startBotParams, setStartBotParams] = useState({});
  const [SlTriggerStraddles, setSlTriggerStraddles] = useState(true);
  const firestoreDbUser = firestoreDb.collection("users").doc(uid);
  const [StraddlesDeltahedging, setStraddlesDeltahedging] = useState(false);
  const [StraddlesDeltahedging2, setStraddlesDeltahedging2] = useState(false);
  const [TraillingStraddles, setTraillingStraddles] = useState(false);
  const [straddleswait_time_reentry, setstraddleswait_time_reentry] = useState(0);
  const [straddlereentry_enabled, setstraddlereentry_enabled] = useState(false);
  const [time_to_close_orders_before_expiry_straddles, settime_to_close_orders_before_expiry_straddles] = useState(0);
  const [StraddleMidPrice, setStraddleMidPrice] = useState("");
  const [visibleMidPrize, setVisibleMidPrize] = useState(false);
  const [MidPrizeMsg, setMidPrizeMsg] = useState();
  const [straddleAdvancedSettings, setStraddleAdvancedSettings] = useState(false)

  const [loading, setLoading] = useState({
    loading: false
  });
  //Get Expiry dates list and set in options
  const getExpiryDates = async () => {
    try {
      const bodyParams = {
        currency: 'BTC'
      };
      await getStraddleExpiryDates(bodyParams).then((res) => {
        setStraddleExpiryDates(res.data.response.expiry_dates);
      }).catch(e => {
        throw e
      })
    } catch (e) {
      handleError(e);
    }
  }
  function toHoursAndMinutes(totalSeconds) {
    const totalMinutes = Math.floor(totalSeconds / 60);

    const seconds = totalSeconds % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return hours + ":" + minutes;
  }
  const handleDH = (e, strategy = '') => {
    var time = e[0]
    var hours = time.getHours();
    var minutes = time.getMinutes();
    var H_M = toSeconds(hours, minutes, 0);
    if (strategy == 'straddleswait_time_reentry') {
      setstraddleswait_time_reentry(H_M)
    } else if (strategy == 'time_to_close_orders_before_expiry_straddles') {
      settime_to_close_orders_before_expiry_straddles(H_M)
    }
  }
  function toSeconds(hours, minutes, seconds) {
    return hours * 3600 + minutes * 60 + seconds;
  }
  //Get Site Settings
  const getSiteSettings = async () => {
    await api({ contentType: true, auth: true }).get('/delta/get-site-settings').then(res => {
      if (res && res.data && res.data.response && res.data.response.settings) {
        setIsStraddlebotEnabled(res.data.response.settings.enable_straddle);
      }
    });

    // check admin trade status
    // const check_trade_status = await firestoreDbUser.collection("profile").doc('data').get().then((res)=>{
    //   if(res.exists)
    //   {
    //       const check_status  = res.data();
    //       setTradeStatus(check_status.trade_status);                
    //   }
    // })

    const check_trade_status = await api({ contentType: true, auth: true }).get('/user/profile')
      .then(res => {
        if (res.data.response) {
          setTradeStatus(res.data.response.user.trade_status);
        }
      });

    // var site_settings = firestoreDb.collection("site-settings").doc('data');
    // await site_settings.get().then((res) => {
    //   if (res.exists) {
    //     const site_setting = res.data();
    //     setIsStraddlebotEnabled(site_setting.enable_straddle);
    //   }
    // });
  }
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    );
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Yes
      </div>
    );
  };
  const getStraddleOrderSettings = async () => {
    // await api({ contentType: true, auth: true }).get('/delta/get-order-settings?type=straddlessettings-global').then(res => {
    //   if (res && res.data && res.data.response && res.data.response.settings) {
    //     const order_settings =res.data.response.settings;
    // var docRef = firestoreDbUser.collection("order-settings").doc('straddlessettings-global');    
    // await docRef.get().then((doc) => {
    //     if (doc.exists) {
    // const order_settings = doc.data();

    await api({ contentType: true, auth: true }).get('/straddles/getUserStraddlesSettings')
      .then((res_str) => {
        if (res_str.data.response && res_str.data.response.data.toString().length > 0) {
          const order_settings = res_str.data.response.data;
          order_settings['delta_hedging_range'] = order_settings.delta_hedging_range ? order_settings.delta_hedging_range : Bot_settings_default.Delta_hedge.delta_hedging_range,
            order_settings['delta_hedging_interval'] = order_settings.delta_hedging_interval ? order_settings.delta_hedging_interval : Bot_settings_default.Delta_hedge.delta_hedging_interval
          setBotSettings(order_settings)
          setStraddlesDeltahedging(order_settings.deltahedging ? (order_settings.deltahedging === 1 ? true : false) : false)
          setStraddlesDeltahedging2(order_settings.Delta_Hedging_on_Stop_Loss_in_One_Leg ? (order_settings.Delta_Hedging_on_Stop_Loss_in_One_Leg === 1 ? true : false) : false)
          setSlTriggerStraddles(order_settings && order_settings.Sl_trigger_take_profit !== undefined ? order_settings.Sl_trigger_take_profit : true)
          setTraillingStraddles(order_settings.combined_trailing_profit ? (order_settings.combined_trailing_profit === 1 ? true : false) : false)
          setStraddleMidPrice(order_settings.mid_price_percentage ? order_settings.mid_price_percentage : "0")
          setstraddleswait_time_reentry(order_settings.wait_time_reentry ? order_settings.wait_time_reentry : "0");
          setstraddlereentry_enabled(order_settings.reentry_enabled ? (order_settings.reentry_enabled == 1 ? true : false) : false);
          settime_to_close_orders_before_expiry_straddles(order_settings.time_to_close_orders_before_expiry ? order_settings.time_to_close_orders_before_expiry : "0");

          // setBotSettings({
          //   ...botSettings,
          //   'stop_loss_percentage': (order_settings.stop_loss_percentage) ? order_settings.stop_loss_percentage : botSettings.stop_loss_percentage,
          //   'minimum_take_profit_percentage': (order_settings.minimum_take_profit_percentage) ? order_settings.minimum_take_profit_percentage : botSettings.minimum_take_profit_percentage,
          //   'trailing_take_profit_percentage': (order_settings.trailing_take_profit_percentage) ? order_settings.trailing_take_profit_percentage : botSettings.trailing_take_profit_percentage,
          //   'quantity': (order_settings.quantity)?order_settings.quantity:botSettings.quantity
          // });          
          //     }
          // })
        }
      });
  }
  const [popovertop, setpopovertop] = useState(false);
  const popovertopchange = targetName => {
    if (!popovertop[targetName] || !popovertop[targetName].popover) {
      setpopovertop({
        ...popovertop,
        [targetName]: {
          popover: true
        }
      });
    } else {
      setpopovertop({
        ...popovertop,
        [targetName]: {
          popover: false
        }
      });
    }
  };
  const ispopoverOpen = targetName => {
    return popovertop[targetName] ? popovertop[targetName].popover : false;
  };
  //Choose Expiry & Get Strikes   
  const [straddleStrikes, setStraddleStrikes] = useState('');
  const toggle = (id) => {
    if (open === id) {
      setOpen('0');
    } else {
      setOpen(id);
    }
  };

  const processStep1 = async () => {
    try {
      setformLoader(true);
      setStraddleStrikes("");
      const expiry_date = document.getElementById('expiry_date').value;

      const bodyParams = {
        expiry_date: expiry_date
      };

      await getStraddleStrikes(bodyParams).then(res => {
        setformLoader(false);
        setStartBotParams({ ...startBotParams, 'expiry_date': expiry_date });
        setStraddleStrikes(res.data.response.strikes);
      }).catch(err => {
        throw err
      });

      setformLoader(false);
      return true;
    } catch (err) {
      setformLoader(false);
      handleError(err);
      return false;
    }
  }

  //Confirm Strike
  const processStep2 = async () => {
    try {
      setformLoader(true);

      //check order exists in strangles for the symbol
      // let doc = await firestoreDbUser.collection("strangle_orders").doc(straddleStrikes.calls.symbol).get();
      // if (doc.exists) {
      //   setformLoader(false);
      //   toast.error('This symbol ' + straddleStrikes.calls.symbol + ' is already running in strangles now', { toastId: 'straddle_step2_2' });
      //   return false;
      // }

      // doc = await firestoreDbUser.collection("strangle_orders").doc(straddleStrikes.puts.symbol).get();
      // if (doc.exists) {
      //   setformLoader(false);
      //   toast.error('This symbol ' + straddleStrikes.puts.symbol + ' is already running in strangles now', { toastId: 'straddle_step2_3' });
      //   return false;
      // }

      // //check order exists in diagonal for the symbol
      // doc = await firestoreDbUser.collection("diagonal_orders").doc(straddleStrikes.calls.symbol).get();
      // if (doc.exists) {
      //   setformLoader(false);
      //   toast.error('This symbol ' + straddleStrikes.calls.symbol + ' is already running in diagonals now', { toastId: 'straddle_step2_4 ' });
      //   return false;
      // }

      // doc = await firestoreDbUser.collection("diagonal_orders").doc(straddleStrikes.puts.symbol).get();
      // if (doc.exists) {
      //   setformLoader(false);
      //   toast.error('This symbol ' + straddleStrikes.puts.symbol + ' is already running in diagonals now', { toastId: 'straddle_step2_5' });
      //   return false;
      // }

      // //check order exists in straddles for the symbol
      // doc = await firestoreDbUser.collection("straddle_orders").doc(straddleStrikes.calls.symbol).get();
      // if (doc.exists) {
      //   setformLoader(false);
      //   toast.error('This symbol ' + straddleStrikes.calls.symbol + ' is already running in straddles now', { toastId: 'straddle_step2_6 ' });
      //   return false;
      // }

      // doc = await firestoreDbUser.collection("straddle_orders").doc(straddleStrikes.puts.symbol).get();
      // if (doc.exists) {
      //   setformLoader(false);
      //   toast.error('This symbol ' + straddleStrikes.puts.symbol + ' is already running in straddles now', { toastId: 'straddle_step2_7' });
      //   return false;
      // }

      //  //check order exists in straddles for the symbol
      //  doc = await firestoreDbUser.collection("trend_orders").doc(straddleStrikes.calls.symbol).get();
      //  if (doc.exists) {
      //    setformLoader(false);
      //    toast.error('This symbol ' + straddleStrikes.calls.symbol + ' is already running in trend following now', { toastId: 'straddle_step2_6 ' });
      //    return false;
      //  }

      //  doc = await firestoreDbUser.collection("trend_orders").doc(straddleStrikes.puts.symbol).get();
      //  if (doc.exists) {
      //    setformLoader(false);
      //    toast.error('This symbol ' + straddleStrikes.puts.symbol + ' is already running in trend following now', { toastId: 'straddle_step2_7' });
      //    return false;
      //  }
      const required_margin = Number(straddleStrikes.calls.order_margin) + Number(straddleStrikes.puts.order_margin);
      setStartBotParams({
        ...startBotParams,
        'calls': {
          symbol: straddleStrikes.calls.symbol,
          product_id: straddleStrikes.calls.product_id,
          best_ask: straddleStrikes.calls.best_ask,
          best_bid: straddleStrikes.calls.best_bid,
        },
        'puts': {
          symbol: straddleStrikes.puts.symbol,
          product_id: straddleStrikes.puts.product_id,
          best_ask: straddleStrikes.puts.best_ask,
          best_bid: straddleStrikes.puts.best_bid,
        },
        'strike_price': straddleStrikes.calls.strike_price,
        'required_margin': required_margin,
        'current_index_price': straddleStrikes.index_price
      });
      setformLoader(false);
      return true;
    } catch (err) {
      setformLoader(false);
      handleError(err);
      return false;
    }
  }

  //Bot setup / settings
  const [botSettings, setBotSettings] = useState({
    stop_loss_percentage: Bot_settings_default.straddles.market_stop_loss_percentage,
    minimum_take_profit_percentage: Bot_settings_default.straddles.minimum_take_profit_percentage,
    trailing_take_profit_percentage: Bot_settings_default.straddles.trailling_take_profit_percentage,
    stop_loss_index_percentage: Bot_settings_default.straddles.index_price_range_percentage_for_stop_loss,
    trigger_percentage: Bot_settings_default.straddles.stop_loss_active_percentage,
    quantity: Bot_settings_default.straddles.quantity,
    combined_sl: Bot_settings_default.straddles.combined_stop_loss,
    delta_hedging_range: Bot_settings_default.Delta_hedge.delta_hedging_range,
    delta_hedging_interval: Bot_settings_default.Delta_hedge.delta_hedging_interval,
    reentry_enabled: 0,
    wait_time_reentry: "0",
    time_to_close_orders_before_expiry: "0",
    combined_sl_percentage:0,
    combined_trailing_take_profit_percentage:0,
    combined_trailing_stop_price_percentage:0,
  });

  const handleRangeInput = (e, setting_name, isInteger = true) => {
    if ((e.target.value === null || e.target.value === undefined || e.target.value === '')) {
      setBotSettings({ ...botSettings, [setting_name]: '' });
      return false;
    }

    let newvalue = Math.max(Number(e.target.getAttribute('min')), Math.min(Number(e.target.getAttribute('max')), Number(e.target.value)));
    setBotSettings({ ...botSettings, [setting_name]: (isInteger) ? parseInt(newvalue) : newvalue });
  }
  const processStep3 = async () => {
    try {
      setformLoader(true);
      let stop_loss_percentage = Number(botSettings.stop_loss_percentage);
      let stop_loss_index_percentage = Number(botSettings.stop_loss_index_percentage);
      let trigger_percentage = Number(botSettings.trigger_percentage);
      let minimum_take_profit_percentage = Number(botSettings.minimum_take_profit_percentage);
      let trailing_take_profit_percentage = Number(botSettings.trailing_take_profit_percentage);
      let quantity = Number(botSettings.quantity);

      // if (!stop_loss_percentage) {
      //   toast.error('Please select Market Stop Loss Percentage', { toastId: 'straddle_step3_1' });
      //   setformLoader(false);
      //   return false;
      // }
      // if (!Number(trigger_percentage) >= Number(stop_loss_percentage)) {
      //   toast.error('SL % must more than Stop Loss Activate Percentage', { toastId: 'straddle_step3_1' });
      //   setformLoader(false);
      //   return false;
      // }
      // if (!minimum_take_profit_percentage) {
      //   toast.error('Please select Trailing Take Profit Percentage', { toastId: 'straddle_step3_2' });
      //   setformLoader(false);
      //   return false;
      // }

      // if (!trailing_take_profit_percentage) {
      //   toast.error('Please select Trailing Stop Price Percentage', { toastId: 'straddle_step3_3' });
      //   setformLoader(false);
      //   return false;
      // }

      if (!quantity) {
        toast.error('Please select quantity', { toastId: 'straddle_step3_4' });
        setformLoader(false);
        return false;
      }
      let botParams = { ...botSettings, ...startBotParams };
      botParams.stop_loss_percentage = stop_loss_percentage;
      botParams.stop_loss_index_percentage = stop_loss_index_percentage;
      botParams.trigger_percentage = trigger_percentage;
      botParams.minimum_take_profit_percentage = minimum_take_profit_percentage;
      botParams.trailing_take_profit_percentage = trailing_take_profit_percentage;
      botParams.quantity = quantity;
      botParams.combined_sl = botSettings.combined_sl ? botSettings.combined_sl : false;
      botParams.Sl_trigger_take_profit = SlTriggerStraddles;
      botParams.Delta_Hedging_on_Stop_Loss_in_One_Leg = StraddlesDeltahedging2 === true ? 1 : 0;
      botParams.deltahedging = StraddlesDeltahedging === true ? 1 : 0;
      botParams.combined_trailing_profit = TraillingStraddles === true ? 1 : 0;
      botParams.wait_time_reentry = straddleswait_time_reentry;
      botParams.time_to_close_orders_before_expiry = time_to_close_orders_before_expiry_straddles;
      botParams.reentry_enabled = straddlereentry_enabled === true ? 1 : 0;
      botParams.mid_price_percentage = StraddleMidPrice ? StraddleMidPrice : "0";
      //Calls sell Order
      await startBot(botParams).then(async (res) => {
        setformLoader(false);
        toast.success(res.data.message);
      }).catch(err => {
        throw err;
      });

      setformLoader(false);
      return true;
    } catch (err) {
      setformLoader(false);
      handleError(err);
      return false;
    }
  }

  //Handle Form Wizard
  const toggleTab = async (tab) => {

    if (!isStraddlebotEnabled || tradeStatus == 0) {
      toast.error('Straddle bot creation disabled by admin', { toastId: 'straddle_bot_disabled' });
      return false;
    }

    let modifiedSteps = passedSteps;
    if (activeTab !== tab) {
      if (tab > activeTab) {
        let is_processed = false;
        switch (activeTab) {
          case 1:
            //Choose Expiry
            is_processed = await processStep1();
            break;
          case 2:
            //Confirm Strikes
            is_processed = await processStep2();
            break;
          case 3:
            //Bot Setup and start bot
            is_processed = await processStep3();
            break;
        }

        const index = modifiedSteps.indexOf(tab);
        if (index > -1) {
          modifiedSteps = modifiedSteps.slice(0, index);
        }

        if (is_processed) {
          modifiedSteps.push(tab);
          if (tab >= 1 && tab <= 4) {
            setactiveTab(tab)
            setPassedSteps(modifiedSteps)
            window.scrollTo(0, 0);
            if (tab === 4) {
              setactiveTab(1);
              setPassedSteps([1]);
              setOpen('0');
            }
          }
        } else {
          setPassedSteps(modifiedSteps)
        }
      } else {
        if (tab >= 1 && tab <= 4) {
          setactiveTab(tab)
          window.scrollTo(0, 0);
        }
      }
    }
  }

  const [activeOrderTab, setActiveOrderTab] = useState("1");
  const [ttop, setttop] = useState(false);
  const [ttop1, setttop1] = useState(false);
  const defaultStartDate = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString();
  const defaultEndDate = new Date(new Date()).toISOString();
  const [filterStartDate, setFilterStartDate] = useState(defaultStartDate);
  const [filterEndDate, setFilterEndDate] = useState(defaultEndDate);
  const toggleOrderTab = tab => {
    if (activeOrderTab !== tab) {
      setActiveOrderTab(tab);
      if (tab == 2) {
        setFilterStartDate(defaultStartDate);
        setFilterEndDate(defaultEndDate);
      }
    }
  };
  const [modalShow, setModalShow] = useState(false);
  const [orderHistoryData, setOrderHistoryData] = useState([]);

  //Active Straddles
  const [activeStraddles, setActiveStraddles] = useState([]);
  const getActiveStraddles = useCallback(() => {
    setpopovertop({});
    setLoading({
      ...loading,
      Table_load: true
    })
    const activeStraddlesResult = firestoreDbUser.collection("straddle_orders").orderBy("created_at", "desc");
    activeStraddlesResult.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        let item = doc.data();
        item.id = doc.id;
        item.dbid = doc.id;
        items.push(item);
        // Display if any notifications
        let currentdate = new Date().toISOString()
        let currentdateTime = new Date(currentdate).getTime();
        if (item.notification && item.notification.timestamp >= currentdateTime) {
          if (item.notification.type === 'success') {
            toast.success(item.notification.message, { toastId: item.notification.toast_id });
          } else {
            toast.error(item.notification.message, { toastId: item.notification.toast_id });
          }
        }
      });

      //Combine orders by straddle id
      const all_straddles = items.reduce(function (row, obj) {
        row[obj.straddle_id] = row[obj.straddle_id] || [];
        row[obj.straddle_id].push(obj);
        return row;
      }, []);

      //Convert to array & Find straddle status - To enable market close
      const active_straddles = Object.keys(all_straddles).map(straddle_index => {
        const rows = all_straddles[straddle_index];
        const filled_order = rows.find(element => element.status.toLowerCase() === 'closed' || element.filled_quantity > 0);
        let is_market_close = rows.find(element => element.market_action && element.market_action.toLowerCase() === 'market_close');
        if (!is_market_close) {
          const market_closed_rows = rows.filter(element => element.market_action && (element.market_action.toLowerCase() === 'market_close' || element.market_action.toLowerCase() === 'market_close_single'));
          if (rows.length === market_closed_rows.length) {
            is_market_close = true;
          }
        }
        const straddle_status = (filled_order) ? "closed" : "open";
        return rows.map((row) => {
          row.straddle_status = straddle_status;
          row.is_market_close = (is_market_close) ? true : false;
          return row
        })
      });
      setLoading({
        ...loading,
        Table_load: false
      })
      setActiveStraddles(active_straddles);
    });
  }, []);


  const getLogsBysymbol = async (uid, dbid, symbol) => {
    try {
      var body = {
        uid: uid,
        strategy: "straddles",
        dbid: dbid
      }
      let order_history = await getOrderLogs(body);
      if (order_history) {
        setModalShow({
          symbol: symbol,
          show: true,
        });
        setOrderHistoryData(order_history);
      } else {
        setOrderHistoryData([]);
        setModalShow({
          symbol: symbol,
          show: true,
        });
      }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message);
      } else if (err.message) {
        toast.error(err.message);
      } else if (err.request) {
        //console.log(err.message);
      } else {
        //console.log(err.message);
      }
    }
  };
  // const getLogsBysymbol = async (symbol) => {
  //   try {
  //     await firestoreDbUser
  //       .collection("straddle_orders_log")
  //       .doc(symbol)
  //       .get()
  //       .then(async (doc) => {
  //         if (doc.exists) {

  //           let order_history = [];

  //           if (doc.data().order_history) {
  //             order_history = doc.data().order_history;
  //             order_history.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());
  //           }

  //           setModalShow({
  //             symbol: symbol,
  //             show: true,
  //           });
  //           setOrderHistoryData(order_history);
  //         } else {
  //           setOrderHistoryData([]);
  //           setModalShow({
  //             symbol: symbol,
  //             show: true,
  //           });
  //         }
  //       });
  //   } catch (err) {
  //     handleError(err);
  //   }
  // };

  // Stop straddle
  const stopStraddleBot = async (straddle_id) => {
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to close the bot ?",
        icon: 'warning',
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-secondary",
        reverseButtons: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then(async (result) => {
        if (result.isConfirmed) {

          toast.success('Stop straddle was initiated.', { toastId: 'stop_straddle_initiated' });
          const params = {
            straddle_id: straddle_id
          };
          await stopBot(params).then((res) => {
            if (res.data !== "") {
              toast.success(res.data.message);
            }
          }).catch(err => {
            handleError(err);
          });
        }
      })
    } catch (err) {
      handleError(err);
    }
  };

  const marketCloseOrder = async (order) => {
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to close the bot ?",
        icon: 'warning',
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-secondary",
        reverseButtons: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then(async (result) => {
        if (result.isConfirmed) {
          toast.success('Close order was initiated..', { toastId: 'close_order_initiated_' + order.symbol });
          console.log(order);
          const params = {
            symbol: order.symbol,
            dbid: order.id,
          };
          await marketCloseOrderAPI(params).then((res) => {
            if (res.data !== "") {
              toast.success(res.data.message);
            }
          }).catch(err => {
            handleError(err);
          });
        }
      })
    } catch (err) {
      handleError(err);
    }
  };

  const marketCloseStraddle = async (straddle_id) => {
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to close the bot ?",
        icon: 'warning',
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-secondary",
        reverseButtons: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then(async (result) => {
        if (result.isConfirmed) {
          toast.success('Market close was initiated.', { toastId: 'market_close_initiated_' + straddle_id });
          const params = {
            straddle_id: straddle_id
          };
          await marketClose(params).then((res) => {
            if (res.data !== "") {
              toast.success(res.data.message);
            }
          }).catch(err => {
            handleError(err);
          });
        }
      })
    } catch (err) {
      handleError(err);
    }
  };
  const stopReentry = async (straddle_id) => {
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to stop reentry ?",
        icon: 'warning',
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-secondary",
        reverseButtons: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then(async (result) => {
        if (result.isConfirmed) {
          toast.success('Stop reentry was initiated.', { toastId: 'stop_reentry_initiated_' + straddle_id });
          const params = {
            straddle_id: straddle_id
          };
          await stopReentryAPI(params).then((res) => {
            if (res.data !== "") {
              toast.success(res.data.message);
            }
          }).catch(err => {
            handleError(err);
          });
        }
      })
    } catch (err) {
      handleError(err);
    }
  };

  //Completed Straddles  
  const [completedStraddles, setCompletedStraddles] = useState([]);

  const convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  const handleCreatedDateChange = (selectedDates, datestr) => {
    setpopovertop({});
    if (selectedDates && selectedDates.length) {
      let converted_date = new Date(convert(selectedDates[0]));
      converted_date.setHours(0, 0, 0, 0);
      setFilterStartDate(converted_date.toISOString());
      if (selectedDates[1]) {
        let converted_end_date = new Date(convert(selectedDates[1]));
        converted_end_date.setHours(23, 59, 59, 999);
        setFilterEndDate(converted_end_date.toISOString());
      }
    } else {
      setFilterStartDate(defaultStartDate);
      setFilterEndDate(defaultEndDate);
    }
  }

  const getCompletedStraddles = async () => {
    setLoading({
      ...loading,
      Table_load: true
    })
    var body = {
      uid: uid,
      filterStartDate: filterStartDate,
      filterEndDate: filterEndDate
    }
    await api({ contentType: true, auth: true }).post('/straddles/straddles-history-data', body).then(async (res) => {
      var completedStraddlesResult = res.data.response.data;
      const items = [];
      completedStraddlesResult.forEach((doc) => {
        let item = doc.data;
        items.push(item)
      });

      const all_straddles = items.reduce(function (row, obj) {
        const index = obj.straddle_id + '_str';
        row[index] = row[index] || [];
        row[index].push(obj);
        return row;
      }, []);

      const completed_straddles = Object.keys(all_straddles).map(straddle_index => {
        return all_straddles[straddle_index];
      });
      setLoading({
        ...loading,
        Table_load: false
      })
      setCompletedStraddles(completed_straddles);
    });
  }


  useEffect(() => {
    getSiteSettings();
    getExpiryDates();
    getStraddleOrderSettings();
    setTaillingTakeProfitMsg(TaillingTakeProfit());
    setMintakeProfitMsg(MinimumTakeProfit())
    setMidPrizeMsg(MidPrize())
    setLoading({
      ...loading,
      Table_load: true
    })
  }, []);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getActiveStraddles();
    }
    return () => {
      // actions to be performed when component unmounts
      mounted = false;
    }
  }, []);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getCompletedStraddles();
    }
    return () => {
      // actions to be performed when component unmounts
      mounted = false;
    }
  }, [filterStartDate, filterEndDate]);
  if (ToolTipData == "" && ToolTipData.length === 0) {
    return <div>Loading...</div>;
  }
  return (
    <React.Fragment>
      <div className="page-content common">
        <Container fluid={true}>
          <Breadcrumbs title="Derisnap" breadcrumbItem="Straddles" />
          {isStraddlebotEnabled && tradeStatus == 1 ?
            <>
              <Row>
                <Col>
                  <span className="float-end" >You can change the settings for Straddles strategy <a href="/settings?straddles" target="_blank">here</a></span>
                </Col>

                <Col lg="12">
                  <Card >
                    <CardBody>
                      <Accordion open={open} toggle={toggle}>
                        <AccordionItem className="brd-soft">
                          <AccordionHeader targetId="1" className="bg-soft">
                            <b>Create Straddles</b>
                          </AccordionHeader>
                          <AccordionBody accordionId="1">
                            <div className="wizard clearfix">
                              <div className="steps clearfix">
                                <ul>
                                  <NavItem
                                    className={classnames({ current: activeTab === 1 })}
                                  >
                                    <NavLink
                                      className={classnames({ current: activeTab === 1 })}
                                      onClick={() => {
                                        setpopovertop({});
                                        setactiveTab(1)
                                      }}
                                      disabled={!(passedSteps || []).includes(1)}
                                    >
                                      <span className="number">1.</span> Choose Expiry
                                    </NavLink>
                                  </NavItem>
                                  <NavItem
                                    className={classnames({ current: activeTab === 2 })}
                                  >
                                    <NavLink
                                      className={classnames({ active: activeTab === 2 })}
                                      onClick={() => {
                                        setpopovertop({});
                                        setactiveTab(2)
                                      }}
                                      disabled={!(passedSteps || []).includes(2)}
                                    >
                                      <span className="number">2.</span> Confirm Strikes
                                    </NavLink>
                                  </NavItem>
                                  <NavItem
                                    className={classnames({ current: activeTab === 3 })}
                                  >
                                    <NavLink
                                      className={classnames({ active: activeTab === 3 })}
                                      onClick={() => {
                                        setactiveTab(3)
                                      }}
                                      disabled={!(passedSteps || []).includes(3)}
                                    >
                                      <span className="number">3.</span> Bot Setup
                                    </NavLink>
                                  </NavItem>
                                  <NavItem
                                    className={classnames({ current: activeTab === 4 })}
                                  >
                                    <NavLink
                                      className={classnames({ active: activeTab === 4 })}
                                      onClick={() => {
                                        setactiveTab(4)
                                      }}
                                      disabled={!(passedSteps || []).includes(4)}
                                    >
                                      <span className="number">4.</span> Bot Success
                                    </NavLink>
                                  </NavItem>
                                </ul>
                              </div>
                              <div className="content clearfix">
                                <TabContent activeTab={activeTab} className="body">
                                  <TabPane tabId={1}>
                                    <div className="p-4 border">
                                      <Form>
                                        <Row>
                                          <Col lg="6">
                                            <div className="mb-3">
                                              <Label htmlFor="expiry_date">Expiration Date</Label>{" "}<i className="bx bx-info-circle" id="EXPDATE" />
                                              <UncontrolledTooltip placement="top" target="EXPDATE">
                                                {ToolTipData.expiration_date}
                                              </UncontrolledTooltip>
                                              <select id="expiry_date" className="form-select">
                                                {straddleExpiryDates.map((index) => (
                                                  <React.Fragment key={index}>
                                                    <option value={index} > {formatUTCDate(index)} </option>
                                                  </React.Fragment>
                                                ))}
                                              </select>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Form>
                                    </div>
                                  </TabPane>
                                  <TabPane tabId={2}>
                                    <div className="p-4 border">
                                      {straddleStrikes ?
                                        <Row>
                                          <Col lg="4">
                                            <Card outline color="primary" className="border">
                                              <CardHeader className="bg-transparent">
                                                <h5 className="my-0 text-primary">
                                                  <i className="mdi mdi-arrow-up-bold-outline me-1" />Calls
                                                </h5>
                                              </CardHeader>
                                              <CardBody>
                                                <CardTitle className="mt-0">{straddleStrikes.calls.symbol}</CardTitle>
                                                <ul className="list-group">
                                                  <li className="list-group-item"><b className="text-body fw-bold">Best Ask</b> : <span className="text-danger">{straddleStrikes.calls.best_ask}</span> </li>
                                                  <li className="list-group-item"><b className="text-body fw-bold">Best Bid</b> : <span className="text-success">{straddleStrikes.calls.best_bid}</span> </li>
                                                  <li className="list-group-item"><b className="text-body fw-bold">Sell Price</b> : <span className="text-primary"> {straddleStrikes.calls.sell_price}</span> </li>
                                                  <li className="list-group-item"><b className="text-body fw-bold">Order Margin</b> : {Number(straddleStrikes.calls.order_margin).toFixed(2)} (1 cont)</li>
                                                  <li className="list-group-item"><b className="text-body fw-bold">Leverage</b> : {straddleStrikes.calls.leverage} </li>
                                                </ul>
                                              </CardBody>
                                            </Card>
                                          </Col>
                                          <Col lg="4">
                                            <Card outline color="primary" className="border">
                                              <CardHeader className="bg-transparent">
                                                <h5 className="my-0 text-primary">
                                                  <i className="mdi mdi-arrow-down-bold-outline me-1" />Puts
                                                </h5>
                                              </CardHeader>
                                              <CardBody>
                                                <CardTitle className="mt-0">{straddleStrikes.puts.symbol}</CardTitle>
                                                <ul className="list-group">
                                                  <li className="list-group-item"><b className="text-body fw-bold">Best Ask</b> : <span className="text-danger">{straddleStrikes.puts.best_ask}</span> </li>
                                                  <li className="list-group-item"><b className="text-body fw-bold">Best Bid</b> : <span className="text-success">{straddleStrikes.puts.best_bid}</span> </li>
                                                  <li className="list-group-item"><b className="text-body fw-bold">Sell Price</b> : <span className="text-primary"> {straddleStrikes.puts.sell_price}</span> </li>
                                                  <li className="list-group-item"><b className="text-body fw-bold">Order Margin</b> : {Number(straddleStrikes.puts.order_margin).toFixed(2)} (1 cont)</li>
                                                  <li className="list-group-item"><b className="text-body fw-bold">Leverage</b> : {straddleStrikes.puts.leverage} </li>
                                                </ul>
                                              </CardBody>
                                            </Card>
                                          </Col>
                                          <Col lg="4">
                                            <Card outline color="primary" className="border" style={{ "height": "94%" }}>
                                              <CardHeader className="bg-transparent">
                                                <h5 className="my-0 text-primary">
                                                  <i className="mdi mdi-information-outline me-1" />Other Informations
                                                </h5>
                                              </CardHeader>
                                              <CardBody>
                                                <CardTitle className="mt-0"></CardTitle>
                                                <ul className="list-group">
                                                  <li className="list-group-item"><b className="text-body fw-bold">Contract Index</b> : {straddleStrikes.contract_index} </li>
                                                  <li className="list-group-item"><b className="text-body fw-bold">Current Index Price</b>: {Number(straddleStrikes.index_price).toFixed(2)} </li>
                                                  <li className="list-group-item"><b className="text-body fw-bold">Required Margin</b>: {Number(Number(straddleStrikes.puts.order_margin) + Number(straddleStrikes.calls.order_margin)).toFixed(2)} (1 cont)</li>
                                                  <li className="list-group-item"><b className="text-body fw-bold">Time to expire</b>:  <Countdown date={startBotParams.expiry_date} renderer={counterRenderer} /></li>
                                                </ul>
                                              </CardBody>
                                            </Card>
                                          </Col>
                                        </Row>
                                        :
                                        <Alert color="danger" role="alert">
                                          No strikes found, please check with some other expiry date
                                        </Alert>
                                      }
                                    </div>
                                  </TabPane>
                                  <TabPane tabId={3} >
                                    <div className="p-4 border">
                                      <Row>
                                        <Col lg="4">
                                          <div className="mb-3">
                                            <Label className="mt-3">Quantity</Label>{" "}<i className="bx bx-info-circle" id="QTY4" />
                                            <UncontrolledTooltip placement="top" target="QTY4">
                                              {ToolTipData.quantity}
                                            </UncontrolledTooltip>
                                            {/* <Slider value={botSettings.quantity } min={1} max={1000} onChange={(value) => setBotSettings({ ...botSettings, quantity: value })} /> */}
                                            <Input
                                              type="number" step="1" min="1" max="1000"
                                              className="form-control"
                                              id="quantity" value={botSettings.quantity}
                                              onChange={(e) => handleRangeInput(e, 'quantity')}
                                              onInput={(e) => handleRangeInput(e, 'quantity')}
                                            />
                                          </div>
                                        </Col>
                                        
                                      </Row>
                                      <Row className="border opacity-75  bg-light" >
                                          <Col lg="2">
                                            <h5 className="font-size-15 mt-5 mb-3 ">
                                              Re-entry Settings
                                            </h5>
                                          </Col>
                                          <Col lg="2" className="mt-3 mb-3">
                                            {/* <FormGroup className="mb-0"> */}
                                            <Label htmlFor="formrow-firstname-Input">
                                              Enable Re-Entry
                                            </Label>{" "}
                                            <i className="bx bx-info-circle" id="DHOSLIOLreentry_enabled" />
                                            <UncontrolledTooltip placement="top" target="DHOSLIOLreentry_enabled">
                                              {ToolTipData.reentry_enabled}
                                            </UncontrolledTooltip>
                                            {/* <input type="checkbox" className="btn-check" id="btn-check-outlined2"  onClick={(e)=> {setDeltahedging2(e.target.checked)}}/><br></br>
                                      <label style={{width:"80px"}} className={"btn " + (Deltahedging2 === true ? "btn-outline-success" : "btn-outline-danger")} htmlFor="btn-check-outlined2" >{Deltahedging2 === true ? "Disable" : "Enable"}</label><br></br> */}
                                            {/* </FormGroup> */}
                                            {/* </Col> */}
                                            {/* <Col md="1"> */}
                                            <div>
                                              <Switch
                                                uncheckedIcon={<Offsymbol />}
                                                checkedIcon={<OnSymbol />}
                                                className="mb-sm-8 mb-0"
                                                onChange={(e) => setstraddlereentry_enabled(e)}
                                                checked={straddlereentry_enabled}
                                              />
                                            </div>
                                          </Col>
                                          {straddlereentry_enabled?<>
                                          <Col lg="3" className="mt-3 ">
                                            <FormGroup className="mb-0">
                                              <Label htmlFor="formrow-firstname-Input">
                                                Waiting time for Re-Entry (H : M)
                                              </Label> {" "}
                                              <i className="bx bx-info-circle" id="wait_time_reentry" />
                                              <UncontrolledTooltip placement="top" target="wait_time_reentry">
                                                {ToolTipData.wait_time_reentry}
                                              </UncontrolledTooltip>
                                              <Flatpickr
                                                className="form-control d-block"
                                                placeholder="Select time"
                                                name="wait_time_reentry"
                                                onChange={(e) => handleDH(e, 'straddleswait_time_reentry')}
                                                value={toHoursAndMinutes(straddleswait_time_reentry)}
                                                options={{
                                                  enableTime: true,
                                                  noCalendar: true,
                                                  dateFormat: "H:i",
                                                  time_24hr: true,
                                                }}
                                              />
                                            </FormGroup>
                                          </Col><Col lg="3" className="mt-3 ">
                                            <FormGroup className="mb-0">
                                              <Label htmlFor="formrow-firstname-Input">
                                                Time to Close Orders Before Expiry (H : M)
                                              </Label> {" "}
                                              <i className="bx bx-info-circle" id="time_to_close_orders_before_expiry" />
                                              <UncontrolledTooltip placement="top" target="time_to_close_orders_before_expiry">
                                                {ToolTipData.time_to_close_orders_before_expiry}
                                              </UncontrolledTooltip>
                                              <Flatpickr
                                                className="form-control d-block"
                                                placeholder="Select time"
                                                name="wait_time_reentry"
                                                onChange={(e) => handleDH(e, 'time_to_close_orders_before_expiry_straddles')}
                                                value={toHoursAndMinutes(time_to_close_orders_before_expiry_straddles)}
                                                options={{
                                                  enableTime: true,
                                                  noCalendar: true,
                                                  dateFormat: "H:i",
                                                  time_24hr: true,
                                                  maxTime: "12:00"
                                                }}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col lg="2" className="mt-3">
                                            <FormGroup className="mb-0">
                                              <Label htmlFor="formrow-firstname-Input">
                                                Date to stop reentry
                                              </Label> {" "}
                                              <i className="bx bx-info-circle" id="stopdate_reentry" />
                                              <UncontrolledTooltip placement="top" target="stopdate_reentry">
                                                {ToolTipData.stop_date_reentry}
                                              </UncontrolledTooltip>
                                              {/*must select only future date*/}
                                              <DatePicker
                                                className="form-control d-block"
                                                placeholder="Select date"
                                                name="stopdate_reentry"
                                                onChange={(val) => {
                                                  const date = new Date(val);
                                                  const dateOnly = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
                                                  setBotSettings({
                                                    ...botSettings,
                                                    stopdate_reentry: dateOnly,
                                                  });
                                                }}

                                                value={botSettings.stopdate_reentry}
                                                options={{
                                                  minDate: tomorrow // Set to current date to disable past dates
                                                }}
                                              />

                                            </FormGroup>
                                          </Col></>:''
                                          }
                                        </Row>
                                        <div  className="mb-1 mt-1" >
                                      <a href="#"
                                        onClick={() => {
                                          setStraddleAdvancedSettings(!straddleAdvancedSettings);
                                        }}
                                        className="mb-3" >
                                        Advanced settings
                                      </a>
                                      </div>

                                      {straddleAdvancedSettings ? <> <Row className="border opacity-75 ">
                                        <Col lg="4">
                                          <div className="mb-3">
                                            <Label className="mt-3">Stop Loss Activate Percentage</Label>{" "}<i className="bx bx-info-circle" id="stoploss" />
                                            <UncontrolledTooltip placement="top" target="stoploss">
                                              {ToolTipData.trigger}
                                            </UncontrolledTooltip>
                                            {/* <Slider value={botSettings.trigger_percentage } min={0} max={100} step={0.5} onChange={(value) => setBotSettings({ ...botSettings, trigger_percentage: value })} /> */}
                                            <div className="input-group">
                                              <Input
                                                type="number" step="0.5" max="100"
                                                className="form-control"
                                                id="trigger_percentage" value={botSettings.trigger_percentage}
                                                onChange={(e) => handleRangeInput(e, 'trigger_percentage', false)}
                                                onInput={(e) => handleRangeInput(e, 'trigger_percentage', false)}
                                              />
                                              <Label className="input-group-text" htmlFor="trigger_percentage">%</Label>
                                            </div>
                                            {straddleStrikes && botSettings.trigger_percentage && botSettings.trigger_percentage > 0 ? 
                                            <><p className="mb-0" style={{position:"",fontSize:"12px"}}>Call stop loss activate value<span className="fw-bold"> {((straddleStrikes.calls.sell_price * (botSettings.trigger_percentage/100)) + Number(straddleStrikes.calls.sell_price)).toFixed(2)}</span></p>
                                            <p className="mb-0" style={{position:"",fontSize:"12px"}}> Put stop loss activate value <span className="fw-bold">{((straddleStrikes.puts.sell_price * (botSettings.trigger_percentage/100)) + Number(straddleStrikes.puts.sell_price)).toFixed(2)}</span></p></>:null}
                                          </div>
                                        </Col>
                                        <Col lg="4">
                                          <div className="mb-3">
                                            <div className="row">
                                              <div className="col-md-8">
                                                <Label className="mt-3">Market Stop Loss Percentage{" "}<i className="bx bx-info-circle" id="stoploss1" /></Label>
                                              </div>
                                            </div>
                                            <UncontrolledTooltip placement="top" target="stoploss1">
                                              {ToolTipData.stop_loss}
                                            </UncontrolledTooltip>
                                            {/* <Slider value={botSettings.stop_loss_percentage } min={0} max={100} step={0.5} onChange={(value) => setBotSettings({ ...botSettings, stop_loss_percentage: value })} /> */}
                                            <div className="input-group">
                                              <Input
                                                type="number" step="0.5" min="0" max="100"
                                                className="form-control"
                                                id="stop_loss_percentage" value={botSettings.stop_loss_percentage}
                                                onChange={(e) => handleRangeInput(e, 'stop_loss_percentage', false)}
                                                onInput={(e) => handleRangeInput(e, 'stop_loss_percentage', false)}
                                              />
                                              <Label className="input-group-text" htmlFor="stop_loss_percentage">%</Label>
                                            </div>
                                            {straddleStrikes && botSettings.stop_loss_percentage && botSettings.stop_loss_percentage > 0 ? 
                                            <><p className="mb-0" style={{position:"",fontSize:"12px"}}>Call market stop loss value<span className="fw-bold"> {((straddleStrikes.calls.sell_price * (botSettings.stop_loss_percentage/100)) + Number(straddleStrikes.calls.sell_price)).toFixed(2)}</span></p>
                                            <p className="mb-0" style={{position:"",fontSize:"12px"}}> Put market stop loss value <span className="fw-bold">{((straddleStrikes.puts.sell_price * (botSettings.stop_loss_percentage/100)) + Number(straddleStrikes.puts.sell_price)).toFixed(2)}</span></p></>:null}
                                          </div>
                                          {/* <div className="col-md-5">
                                            <div className="form-check form-checkbox-outline form-check-success mt-3">
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="combined_sl"
                                                checked={botSettings.combined_sl ? botSettings.combined_sl : false}
                                                onChange={(event) => setBotSettings({ ...botSettings, combined_sl: event.target.checked })}
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="combined_sl"
                                              >
                                                Combined Stop Loss
                                              </label>{" "}<i className="bx bx-info-circle" id="Stop_loss_combined" />
                                              <UncontrolledTooltip placement="top" target="Stop_loss_combined">
                                                {ToolTipData.stop_loss_combined}
                                              </UncontrolledTooltip>
                                            </div>
                                          </div> */}
                                        </Col>
                                        <Col lg="4" >
                                          <div className="mb-3">
                                            <Label className="mt-3"> Index Price Range % for Stop Loss</Label>{" "}<i className="bx bx-info-circle" id="stoploss2" />
                                            <UncontrolledTooltip placement="top" target="stoploss2">
                                              {ToolTipData.Index_Price_Range}
                                            </UncontrolledTooltip>
                                            {/* <Slider value={botSettings.stop_loss_index_percentage } min={0} max={100} step={0.5} onChange={(value) => setBotSettings({ ...botSettings, stop_loss_index_percentage: value })} /> */}
                                            <div className="input-group">
                                              <Input
                                                type="number" step="0.5" max="100"
                                                className="form-control"
                                                id="stop_loss_index_percentage" value={botSettings.stop_loss_index_percentage}
                                                onChange={(e) => handleRangeInput(e, 'stop_loss_index_percentage', false)}
                                                onInput={(e) => handleRangeInput(e, 'stop_loss_index_percentage', false)}
                                              />
                                              <Label className="input-group-text" htmlFor="stop_loss_index_percentage">%</Label>
                                            </div>
                                          </div>
                                        </Col>
                                        </Row>


                                    <Row className="border opacity-75 bg-light" >
                                        <Col lg="4" >
                                          <div className="mb-3">
                                            <Label className="mt-3">Trailing Take Profit Percentage</Label>{" "}<i className="bx bx-info-circle" onClick={() => setVisibleMin(!visibleMin)} />
                                            {/* <Slider value={botSettings.minimum_take_profit_percentage } min={1} max={100} step={0.5} onChange={(value) => setBotSettings({ ...botSettings, minimum_take_profit_percentage: value })} /> */}
                                            <div className="input-group">
                                              <Input
                                                type="number" step="0.5" max="100"
                                                className="form-control"
                                                id="minimum_take_profit_percentage" value={botSettings.minimum_take_profit_percentage}
                                                onChange={(e) => handleRangeInput(e, 'minimum_take_profit_percentage', false)}
                                                onInput={(e) => handleRangeInput(e, 'minimum_take_profit_percentage', false)}
                                              />
                                              <Label className="input-group-text" htmlFor="minimum_take_profit_percentage">%</Label>
                                            </div>
                                            <p className="text-muted mx-1 font-size-11 mb-0">Note: At which the Trailing take profit will become active</p>
                                            {straddleStrikes && botSettings.minimum_take_profit_percentage && botSettings.minimum_take_profit_percentage > 0 ? 
                                            <><p className="mb-0 mx-1" style={{position:"",fontSize:"12px"}}>Call trailing profit value<span className="fw-bold"> {(Number(straddleStrikes.calls.sell_price)-(straddleStrikes.calls.sell_price * (botSettings.minimum_take_profit_percentage/100))).toFixed(2)}</span></p>
                                            <p className="mb-0 mx-1" style={{position:"",fontSize:"12px"}}> Put trailing profit value <span className="fw-bold">{(Number(straddleStrikes.puts.sell_price)-(straddleStrikes.puts.sell_price * (botSettings.minimum_take_profit_percentage/100))).toFixed(2)}</span></p></>:null}
                                            <div className="form-check form-checkbox-outline form-check-success mt-3">
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="straddlesMin"
                                                checked={SlTriggerStraddles}
                                                onClick={(event) => { setSlTriggerStraddles(event.target.checked); handleRangeInput(event, 'Sl_trigger_take_profit', false) }}
                                              />
                                              <label className="form-check-label font-size-10" htmlFor="straddlesMin">
                                                Activate trailing take profit when stop loss triggered on one leg
                                              </label>{" "}
                                            </div>
                                          </div>
                                        </Col>
                                        <Col lg="4" >
                                          <div className="mb-3">
                                            <Label className="mt-3">Trailing Stop Price Percentage</Label>{" "}<i className="bx bx-info-circle" onClick={() => setVisibleTailling(!visibleTailling)} />
                                            {/* <Slider value={botSettings.trailing_take_profit_percentage } min={1} max={100} step={0.5} onChange={(value) => setBotSettings({ ...botSettings, trailing_take_profit_percentage: value })} /> */}
                                            <div className="input-group">
                                              <Input
                                                type="number" step="0.5" max="100"
                                                className="form-control"
                                                id="trailing_take_profit_percentage" value={botSettings.trailing_take_profit_percentage}
                                                onChange={(e) => handleRangeInput(e, 'trailing_take_profit_percentage', false)}
                                                onInput={(e) => handleRangeInput(e, 'trailing_take_profit_percentage', false)}
                                              />
                                              <Label className="input-group-text" htmlFor="trailing_take_profit_percentage">%</Label>
                                            </div>
                                            <p className="text-muted mx-1 font-size-11 mb-0">Note: Active after minimum take profit is reached</p>
                                            {straddleStrikes && botSettings.trailing_take_profit_percentage && botSettings.trailing_take_profit_percentage > 0 ? 
                                            <><p className="mb-0 mx-1" style={{position:"",fontSize:"12px"}}>Call trailing stop value<span className="fw-bold"> {(Number(straddleStrikes.calls.sell_price)-(straddleStrikes.calls.sell_price * (botSettings.trailing_take_profit_percentage/100))).toFixed(2)}</span></p>
                                            <p className="mb-0 mx-1" style={{position:"",fontSize:"12px"}}> Put trailing stop value <span className="fw-bold">{(Number(straddleStrikes.puts.sell_price)-(straddleStrikes.puts.sell_price * (botSettings.trailing_take_profit_percentage/100))).toFixed(2)}</span></p></>:null}
                                          </div>
                                          {/* <div className="form-check form-checkbox-outline form-check-success mt-3">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="StarangleTrailling"
                                              checked={TraillingStraddles}
                                              onChange={(event) => { setTraillingStraddles(event.target.checked), setBotSettings({ ...botSettings, combined_trailing_profit: event == true ? 1 : 0 }) }}
                                            />
                                            <label className="form-check-label font-size-10" htmlFor="StarangleTrailling">
                                              Combined Profit
                                            </label>{" "}
                                            <i className="bx bx-info-circle" id ="SSLTMTP" />
                                    <UncontrolledTooltip placement="top" target="SSLTMTP"> 
                                        {ToolTipData.stop_loss_trigger_market_take_profit}
                                    </UncontrolledTooltip>
                                          </div> */}
                                        </Col>
                                        <Col lg="4" >
                                          <FormGroup className="mt-3 mb-0">
                                            <Label htmlFor="formrow-firstname-Input">
                                              {/* <a className='link-secondary' target="_blank" href='/help#make_limit_orders_xpercentage'> */}
                                              Make Limit Orders x% from Mid/MarkPrice:
                                              {/* </a> */}
                                              {" "} <i className="bx bx-info-circle" onClick={() => setVisibleMidPrize(!visibleMidPrize)}></i>
                                            </Label>{" "}
                                            <input autoComplete="off" type="number" max={1000} step={0.5} value={StraddleMidPrice} onChange={(e) => { e.target.value <= 1000 ? setStraddleMidPrice(e.target.value) : setStraddleMidPrice(1000) }} onBlur={(e) => e.target.value <= 1000 ? setStraddleMidPrice(e.target.value) : setStraddleMidPrice(100)} name="mid_price_percentage" className="form-control" />
                                          </FormGroup>
                                        </Col>
                                      </Row>

                                      <Row className="border opacity-75 ">
                                        <Col lg="4">
                                        <FormGroup className="mt-4 mb-0">
                                          <Label htmlFor="starddlesrow-combined-sl">
                                          Combined Stop Loss %
                                          {" "}
                                          <i className="bx bx-info-circle" id ="straddles-combinedsl-trigger" />
                                            <UncontrolledTooltip placement="top" target="straddles-combinedsl-trigger">
                                                {ToolTipData.stop_loss_combined}
                                            </UncontrolledTooltip>
                                            </Label>
                                          {/* <Slider value={triggerPercentage?triggerPercentage:0} min={0} max={100} step={0.5} onChange={value => {setTriggerPercentage(value)}} /> */}
                                          <div className="input-group">

                                          <input  type="number" max={100} step={0.5} value={botSettings.combined_sl_percentage} onChange={(e) => { 
                                          const value = e.target.value;
                                          let newValue = 100; // Default value
                                          if (value === null || value === '' || value < 0) {
                                            newValue = '';
                                          } else if (value <= 100) {
                                            newValue = value;
                                          }
                                          setBotSettings({
                                            ...botSettings,
                                            combined_sl_percentage: newValue
                                          });
                                        } } onBlur={(e) => { 
                                          const value = e.target.value;
                                          let newValue = 100; // Default value
                                          if (value === null || value === '' || value < 0) {
                                            newValue = '';
                                          } else if (value <= 100) {
                                            newValue = value;
                                          }
                                          setBotSettings({
                                            ...botSettings,
                                            combined_sl_percentage: newValue
                                          });
                                        } } name="straddle_combined_sl_percentage" className="form-control" />
                                          <Label className="input-group-text" htmlFor="take_profit">%</Label>
                                          </div>
                                        {straddleStrikes && botSettings.combined_sl_percentage && botSettings.combined_sl_percentage > 0 ? 
                                        <p className="mb-0" style={{position:"",fontSize:"12px"}}>Combined stop loss value<span className="fw-bold"> {((Number(straddleStrikes.calls.sell_price)+Number(straddleStrikes.puts.sell_price))+((Number(straddleStrikes.calls.sell_price)+Number(straddleStrikes.puts.sell_price)) * (botSettings.combined_sl_percentage/100))).toFixed(2)}</span></p>
                                        :null}
                                        </FormGroup>
                                      </Col>

                                      <Col lg="4">
                                        <FormGroup className="mt-4 mb-0">
                                          <Label htmlFor="starddlesrow-combined-profit">
                                          Combined Trailing Take Profit % { " "} 
                                          <i className="bx bx-info-circle" id ="straddles-combined-trail-pro-trigger" />
                                            <UncontrolledTooltip placement="top" target="straddles-combined-trail-pro-trigger">
                                                {ToolTipData.combined_trailing_profit_percentage}
                                            </UncontrolledTooltip>
                                        </Label>
                                        <div className="input-group">
                                            
                                          <input  type="number"   max={100} step={0.5} value={botSettings.combined_trailing_take_profit_percentage} onChange={(e) => { 
                                          const value = e.target.value;
                                          let newValue = 100; // Default value
                                          if (value === null || value === '' || value < 0) {
                                            newValue = '';
                                          } else if (value <= 100) {
                                            newValue = value;
                                          }
                                          setBotSettings({
                                            ...botSettings,
                                            combined_trailing_take_profit_percentage: newValue
                                          });
                                        } } onBlur={(e) => { 
                                          const value = e.target.value;
                                          let newValue = 100; // Default value
                                          if (value === null || value === '' || value < 0) {
                                            newValue = '';
                                          } else if (value <= 100) {
                                            newValue = value;
                                          }
                                          setBotSettings({
                                            ...botSettings,
                                            combined_trailing_take_profit_percentage: newValue
                                          });
                                        }} name="straddle_combined_trailing_take_profit_percentage" className="form-control" />
                                          <Label className="input-group-text" htmlFor="take_profit">%</Label>
                                          </div>
                                          {straddleStrikes && botSettings.combined_trailing_take_profit_percentage && botSettings.combined_trailing_take_profit_percentage > 0 ? 
                                        <p className="mb-0" style={{position:"",fontSize:"12px"}}>Combined trailing profit value<span className="fw-bold"> {((Number(straddleStrikes.calls.sell_price)+Number(straddleStrikes.puts.sell_price))-((Number(straddleStrikes.calls.sell_price)+Number(straddleStrikes.puts.sell_price)) * (botSettings.combined_trailing_take_profit_percentage/100))).toFixed(2)}</span></p>
                                        :null}
                                        </FormGroup>
                                      
                                      </Col>
                                      <Col lg="4">
                                        <FormGroup className="mt-4 mb-0">
                                          <Label htmlFor="starddlesrow-combined-sp">
                                          Combined Trailing Stop Price % { " "} 
                                          <i className="bx bx-info-circle" id ="straddles-combined-trail-stop-trigger" />
                                            <UncontrolledTooltip placement="top" target="straddles-combined-trail-stop-trigger">
                                                {ToolTipData.combined_trailing_stop_price_percentage}
                                            </UncontrolledTooltip>
                                        </Label>
                                        <div className="input-group">
                                            
                                          <input  type="number" max={100} step={0.5} value={botSettings.combined_trailing_stop_price_percentage} onChange={(e) => { 
                                          const value = e.target.value;
                                          let newValue = 100; // Default value
                                          if (value === null || value === '' || value < 0) {
                                            newValue = '';
                                          } else if (value <= 100) {
                                            newValue = value;
                                          }
                                          setBotSettings({
                                            ...botSettings,
                                            combined_trailing_stop_price_percentage: newValue
                                          });
                                        }}onBlur={(e) => { 
                                          const value = e.target.value;
                                          let newValue = 100; // Default value
                                          if (value === null || value === '' || value < 0) {
                                            newValue = '';
                                          } else if (value <= 100) {
                                            newValue = value;
                                          }
                                          setBotSettings({
                                            ...botSettings,
                                            combined_trailing_stop_price_percentage: newValue
                                          });
                                        }} name="straddle_combined_trailing_stop_price_percentage" className="form-control" />
                                          <Label className="input-group-text" htmlFor="take_profit">%</Label>
                                          </div>
                                         {straddleStrikes && botSettings.combined_trailing_stop_price_percentage && botSettings.combined_trailing_stop_price_percentage > 0 ? 
                                        <p className="mb-0" style={{position:"",fontSize:"12px"}}>Combined trailing stop value<span className="fw-bold"> {((Number(straddleStrikes.calls.sell_price)+Number(straddleStrikes.puts.sell_price))-((Number(straddleStrikes.calls.sell_price)+Number(straddleStrikes.puts.sell_price)) * (botSettings.combined_trailing_stop_price_percentage/100))).toFixed(2)}</span></p>
                                        :null}
                                        </FormGroup>
                                       
                                      </Col>
                                      </Row>


                                        


                                        <Row className="border opacity-75 bg-light" >
                                          <Col lg="2">
                                            <h5 className="font-size-15 mt-5 mb-3 ">
                                              Delta Hedging Settings
                                            </h5>
                                          </Col>
                                          <Col lg="4" className="mt-4 mb-3">
                                            <FormGroup className="mb-4">
                                              <Label htmlFor="formrow-firstname-Input">
                                                Enable Delta Hedging
                                                {" "}
                                                <i className="bx bx-info-circle" id="EDH" />
                                                <UncontrolledTooltip placement="top" target="EDH">
                                                  {ToolTipData.delta_hedging}
                                                </UncontrolledTooltip>

                                              </Label>
                                              {/* <input type="checkbox" className="btn-check" id="btn-check-outlined"  onClick={(e)=> {setDeltahedging(e.target.checked)}}/><br></br>
                                      <label style={{width:"80px"}} className={"btn " + (Deltahedging === true ? "btn-outline-success" : "btn-outline-danger")} htmlFor="btn-check-outlined" >{Deltahedging === true ? "Disable" : "Enable"}</label><br></br> */}
                                              <Col md="2">
                                                <Switch
                                                  uncheckedIcon={<Offsymbol />}
                                                  checkedIcon={<OnSymbol />}
                                                  className="me-1 mb-sm-8 mb-2"
                                                  onChange={(e) => { setStraddlesDeltahedging(e), setBotSettings({ ...botSettings, deltahedging: e === true ? 1 : 0 }) }}
                                                  checked={StraddlesDeltahedging}
                                                />
                                              </Col>
                                              {
                                                botSettings && botSettings.delta_hedging_range && botSettings.delta_hedging_interval ?
                                                  <div className='font-size-10'>Your Settings<i className="mdi mdi-arrow-right
                          ms-1 text-primary "></i>
                                                    <span className='text-secondary'>Delta Range: <span className='text-info'>{botSettings.delta_hedging_range}</span> , Interval time for delta checking:  <span className='text-info'>{timeFormat(botSettings.delta_hedging_interval)} </span> , Order Type: <span className='text-info'>{botSettings.delta_hedging_order_type ? botSettings.delta_hedging_order_type : 'market_order'}</span> ,
                                                      Hedge only for the option with loss: <span className='text-info'>{botSettings.hedge_only_for_loss ? 'Yes (' + botSettings.hedging_min_loss_value + ' ' + (botSettings.hedging_min_loss_type == 'percentage' ? '%' : 'pnts') + ')' : 'No'}</span>< Link to="/settings?straddles"><i className="bx bx-edit-alt" style={{ width: "15px" }} /></Link></span>
                                                  </div> : ""
                                              }
                                            </FormGroup>
                                          </Col>
                                          {
                                            StraddlesDeltahedging ? <>
                                              <Col lg="4" className="mt-4 ">
                                                <FormGroup >
                                                  <Label htmlFor="formrow-firstname-Input">
                                                    Disable Delta Hedging on Stop Loss in One Leg
                                                  </Label>{" "}
                                                  <i className="bx bx-info-circle" id="DHOSLIOL" />
                                                  <UncontrolledTooltip placement="top" target="DHOSLIOL">
                                                    {ToolTipData.delta_hedging_on_stop_loss_in_one_leg}
                                                  </UncontrolledTooltip>
                                                  {/* <input type="checkbox" className="btn-check" id="btn-check-outlined2"  onClick={(e)=> {setDeltahedging2(e.target.checked)}}/><br></br>
                                      <label style={{width:"80px"}} className={"btn " + (Deltahedging2 === true ? "btn-outline-success" : "btn-outline-danger")} htmlFor="btn-check-outlined2" >{Deltahedging2 === true ? "Disable" : "Enable"}</label><br></br> */}
                                                  <Col md="2">
                                                    <Switch
                                                      uncheckedIcon={<Offsymbol />}
                                                      checkedIcon={<OnSymbol />}
                                                      className="me-1 mb-sm-8 mb-2"
                                                      onChange={(e) => { setStraddlesDeltahedging2(e), setBotSettings({ ...botSettings, Delta_Hedging_on_Stop_Loss_in_One_Leg: e === true ? 1 : 0 }) }}
                                                      checked={StraddlesDeltahedging2}
                                                    />
                                                  </Col>
                                                </FormGroup>
                                              </Col>
                                            </> : ''
                                          }
                                        </Row>

                                      </> : ""}
                                    </div>
                                  </TabPane>
                                  <TabPane tabId={4}>
                                    <div className="p-4 border">
                                      <div className="row justify-content-center">
                                        <Col lg="6">
                                          <div className="text-center">
                                            <div className="mb-4">
                                              <i className="mdi mdi-check-circle-outline text-success display-4" />
                                            </div>
                                            <div>
                                              <h5>Straddle Bot intitiated successfully</h5>
                                              {/* <p className="text-muted">
                              If several languages coalesce, the grammar
                              of the resulting
                            </p> */}
                                            </div>
                                          </div>
                                        </Col>
                                      </div>
                                    </div>
                                  </TabPane>
                                </TabContent>
                              </div>
                              <div className="actions clearfix mt-4">
                                <ul className={formLoader ? "disabled" : ""}>
                                  {activeTab !== 1 ?
                                    <li
                                      className={activeTab === 1 ? "previous disabled" : "previous"}
                                    >
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          { !formLoader ? toggleTab(activeTab - 1) : "" }
                                        }}
                                      >
                                        Previous
                                      </Link>
                                    </li>
                                    : ""
                                  }
                                  {activeTab !== 4 ?
                                    <li
                                      className="next"
                                    >
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          { !formLoader ? toggleTab(activeTab + 1) : "" }
                                        }}
                                      >
                                        {formLoader ? <span><i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Processing...</span> : <span>{activeTab === 3 ? "Start Bot" : "Next"}</span>}
                                      </Link>
                                    </li>
                                    : ""
                                  }
                                </ul>
                              </div>
                            </div>
                          </AccordionBody>
                        </AccordionItem>
                      </Accordion>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
            : ""
          }
        </Container>
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card className="box_4">
                <CardBody>
                  <h4 className="card-title mb-3">Orders</h4>
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeOrderTab === "1",
                        })}
                        onClick={() => {
                          setpopovertop({});
                          toggleOrderTab("1");
                        }}
                      >
                        Active Straddles
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeOrderTab === "2",
                        })}
                        onClick={() => {
                          setpopovertop({});
                          toggleOrderTab("2");
                        }}
                      >
                        Completed Straddles
                      </NavLink>
                    </NavItem>
                  </ul>

                  <TabContent activeTab={activeOrderTab} className="p-3">
                    <TabPane tabId="1" id="all-order">
                      <div className="table-responsive">
                        {/*********/}
                        <Table className={"table table-bordered table align-middle table-nowrap table-check "}>
                          <thead >
                            <tr>
                              <th>Expiry Date</th>
                              <th>Side</th>
                              <th>Symbol</th>
                              <th>Sell Price</th>
                              <th>Stop Price</th>
                              <th>Filled/Qty</th>
                              <th>Order ID</th>
                              <th>Status</th>
                              <th>LTP</th>
                              <th className="text-decoration-underline" >
                                <Tooltip
                                  placement="top"
                                  isOpen={ttop}
                                  target="UPNL"
                                  toggle={() => {
                                    setttop(!ttop);
                                  }}
                                >
                                  Sell order: Price - Lowest ask <br /> Buy order: Highest bid - Price
                                </Tooltip>
                                <span id="UPNL">UPNL</span>
                              </th>
                              <th className="text-center" colSpan={2}>Action</th>
                            </tr>
                          </thead>
                          {loading.Table_load === true ?
                            (
                              <tbody>
                                <tr>
                                  <td colSpan={13} style={{ textAlign: "center" }}>
                                    Loading...
                                  </td>
                                </tr>
                              </tbody>
                            ) : activeStraddles.length > 0 ? (
                              activeStraddles.map((row, index) => {
                                let indexs = (index + 1) % 2
                                let index_1 = index
                                return (
                                  <tbody key={index}>
                                    {row.map((order, index, arr) => {
                                      return (
                                        <tr style={{ color: "#8a90a0" }} className={"trow-hover " + (indexs === 0 ? 'table-light' : '')} key={index} >
                                          {index === 0 ?
                                            <td rowSpan={row.length}>
                                              <div className="p-2">
                                                <b className="text-primary font-size-15">{formatUTCDate(order.expiry_date)}</b>
                                                <div style={{ "float": "right" }}><a
                                                  id={"Popovertop-completed-" + (order.order_id ? order.order_id : index_1)}
                                                  color="secondary"
                                                  onClick={() => {
                                                    popovertopchange(`completed-${order.order_id ? order.order_id : index_1}`);
                                                  }}
                                                >
                                                  <i className="bx bxs-info-circle text-dark font-size-15"></i>
                                                </a>
                                                  <Popover
                                                    placement="right"
                                                    isOpen={ispopoverOpen(`completed-${order.order_id ? order.order_id : index_1}`)}
                                                    target={"Popovertop-completed-" + (order.order_id ? order.order_id : index_1)}
                                                    toggle={() => {
                                                      popovertopchange(`completed-${order.order_id ? order.order_id : index_1}`);
                                                    }}
                                                  >
                                                    <PopoverHeader style={{ backgroundColor: "black", color: "white", borderBottomColor: "white" }}><b>Info</b></PopoverHeader>
                                                    <PopoverBody style={{ backgroundColor: "black", color: "white" }}>
                                                      {order.order_settings.mid_price_percentage ? <><div className="font-size-12">Mid price% : {order.order_settings.mid_price_percentage}</div></> : ''}
                                                      {order.order_settings.trigger_percentage ? <div className="font-size-12">Stop Loss Activate: {Number(order.order_settings.trigger_percentage)}%</div> : ''}
                                                      {/* {order.order_settings.stop_loss_percentage ? <div className="font-size-12">Market Stop Loss {order.order_settings.combined_sl ? " (Combined)" : ""}: {Number(order.order_settings.stop_loss_percentage)}%</div> : ''} */}
                                                      {order.order_settings.stop_loss_percentage ? <div className="font-size-12">Market Stop Loss:  {Number(order.order_settings.stop_loss_percentage)}%</div> : ''}
                                                      {order.order_settings.stop_loss_index_percentage ? <div className="font-size-12">Index Price Range for Stop Loss: {Number(order.order_settings.stop_loss_index_percentage)}%</div> : ''}
                                                      {/* {order.order_settings.minimum_take_profit_percentage ? <div className="font-size-12">Minimum take profit{order.order_settings.combined_trailing_profit ? " (combined)" : ""}: {Number(order.order_settings.minimum_take_profit_percentage)}%</div> : ''} */}
                                                      {order.order_settings.minimum_take_profit_percentage ? <div className="font-size-12">Minimum take profit: {Number(order.order_settings.minimum_take_profit_percentage)}%</div> : ''} 
                                                      {order.order_settings.trailing_take_profit_percentage ? <div className="font-size-12">Trailing take profit: {Number(order.order_settings.trailing_take_profit_percentage)}%</div> : ''}
                                                      {order.order_settings.deltahedging ? <div className="font-size-12">Enable Delta Hedging: {(order.order_settings.deltahedging == 1 ? 'true' : 'false')}</div> : ''}
                                                      {order.order_settings.deltahedging == 1 ? <div className="font-size-12">Disable Delta Hedging on Stop Loss<br /> -in One Leg: {(order.order_settings.Delta_Hedging_on_Stop_Loss_in_One_Leg == 1 ? 'true' : 'false')}</div> : ''}
                                                      {order.order_settings.combined_deactivated ? (<>Combined Deactivated: <b>true</b></>) : ''}
                                                      {order.order_settings.reentry_enabled ? (
                                                        <>Re-Entry Enabled: <b>true</b><br />Waiting time for Re-Entry: <b>{order.order_settings.wait_time_reentry ? timeFormat(order.order_settings.wait_time_reentry) : "--"}</b></>
                                                      ) : <>Re-Entry Enabled: <b>false</b></>}
                                                      {order.order_settings.stopdate_reentry ? (
                                                        <><br />Stop Date Re-Entry: <b>{order.order_settings.stopdate_reentry}</b></>
                                                      ) : ''}
                                                      {order.order_settings.combined_sl_percentage ? <div className="font-size-12">Combined stop loss: {Number(order.order_settings.combined_sl_percentage)}%</div> : ''} 
                                                      {order.order_settings.combined_trailing_take_profit_percentage ? <div className="font-size-12">Combined trailing take profit: {Number(order.order_settings.combined_trailing_take_profit_percentage)}%</div> : ''} 
                                                      {order.order_settings.combined_trailing_stop_price_percentage ? <div className="font-size-12">Combined trailing stop price: {Number(order.order_settings.combined_trailing_stop_price_percentage)}%</div> : ''} 

                                                    </PopoverBody>
                                                  </Popover>
                                                </div>
                                                <div className="text-primary">
                                                  Expiry: {" "}
                                                  <span className="expiry-countdown font-size-11" >
                                                    <Countdown date={order.expiry_date} renderer={counterRenderer} />
                                                  </span>
                                                </div>
                                                {order.order_settings.current_index_price ? <div className="font-size-12">Index Price: {Number(order.order_settings.current_index_price).toFixed(2)}</div> : ''}
                                                <div className="text-muted font-size-12 mt-2"><i className="far fa-calendar-alt text-primary me-1"></i>Created at: {getSettingsDateTimeFormat(order.created_at)}</div>
                                                <div className="text-muted font-size-10">{moment.utc(getMomentDateTimeFormat(order.created_at)).local().startOf('seconds').fromNow()}</div>
                                                {(order.automatic_bot && order.automatic_bot == 1) ? <Badge pill color="warning" className="me-1">Automated Admin Bot</Badge> : order.admin_bot ? <Badge pill color="primary" className="me-1">Admin Bot</Badge> : ''}

                                                {order.reentry_order ? <><Badge pill color="success" className="me-1">Re-Entry Order</Badge></> : ''}
                                              </div>
                                            </td>
                                            : ''}
                                          <td>
                                            {order.market_action_order ?
                                              <Badge
                                                color={order.market_action_order.side === "buy" ? "success" : "danger"}
                                                style={{ "textTransform": "uppercase" }}
                                              >
                                                {order.market_action_order.side}
                                              </Badge>
                                              :

                                              <Badge
                                                color={order.direction === "buy" ? "success" : "danger"}
                                                style={{ "textTransform": "uppercase" }}
                                              >
                                                {order.direction}
                                              </Badge>
                                            }
                                          </td>
                                          <td >

                                            {order.market_action_order ?

                                              <a href={process.env.REACT_APP_DELTA_TRADE_LINK + '/BTC/' + order.market_action_order.product_symbol} target="_blank" rel="noreferrer" >
                                                <Badge color={order.market_action_order.side === "buy" ? "success" : "danger"}>
                                                  {order.market_action_order.product_symbol}
                                                </Badge></a>

                                              :

                                              <a href={process.env.REACT_APP_DELTA_TRADE_LINK + '/BTC/' + order.symbol} target="_blank" rel="noreferrer" >
                                                <Badge color={order.direction === "buy" ? "success" : "danger"}>
                                                  {order.symbol}
                                                </Badge></a>
                                            }
                                            {
                                              order.market_action_trigger ?

                                                <div>
                                                  <span className="text-muted font-size-10 ">{getMarketActionTriggerName(order).split('@')[0]} </span><br></br>
                                                  <span className="text-muted font-size-10 ">{getMarketActionTriggerName(order).split('@')[1] !== '' || getMarketActionTriggerName(order).split('@')[1] !== undefined ? ' @ ' + getMarketActionTriggerName(order).split('@')[1] : ""} </span>
                                                  {order.reentry_stopped ? <div><span className="text-muted font-size-10 ">Re-Entry Stopped</span></div> : ""}
                                                </div>
                                                :
                                                order.is_min_take_profit_reached ?
                                                  <div>
                                                    <span className="text-muted font-size-10 d-block">Minimum Take Profit reached: {order.minimum_take_profit_price}</span>
                                                    <span className="text-muted font-size-10 d-block">Trailing Take Profit Activated</span>
                                                  </div>
                                                  :
                                                  order.minimum_take_profit_price ?
                                                    <div>
                                                      <span className="text-muted font-size-10 d-block">Minimum Take Profit Price: {order.minimum_take_profit_price}</span>

                                                    </div>
                                                    :
                                                    ""

                                            }
                                            {order.trigger_price ? <div>
                                              <span className="text-muted  font-size-10 d-block" > Trigger price{order.order_settings.combined_sl ? " (Combined)" : ""}: {Number(order.trigger_price).toFixed(2)}
                                              </span></div> : ""}
                                            {order.current_profit_percentage && order.bot_status !== "completed" ? <div>
                                              <span className="text-muted font-size-10 d-block">Current Profit/Loss: {
                                                order.current_profit_percentage > 0 ?
                                                  <span className="text-success">{
                                                    Number(order.current_profit_percentage).toFixed(2)}</span> : <span className="text-danger">{Number(order.current_profit_percentage).toFixed(2)}</span>}%</span>
                                            </div>
                                              : ""}
                                          </td>

                                          <td >
                                            {order.market_action_order ?

                                              <h6 className={order.market_action_order.side === "buy" ? "text-success" : "text-danger"}>{order.market_action_order.average_fill_price ? Number(order.market_action_order.average_fill_price).toFixed(2) : order.market_action_order.price ? Number(order.market_action_order.price).toFixed(2) : "..."}</h6>

                                              :
                                              <h6 className={order.direction === "buy" ? "text-success" : "text-danger"}>{order.average_fill_price ? Number(order.average_fill_price).toFixed(2) : order.price ? Number(order.price).toFixed(2) : "..."}</h6>
                                            }
                                            {order.highest_bid_now || order.lowest_ask_now ?
                                              <div>
                                                <span className="text-muted font-size-11">Bid: <span className="text-success">{order.highest_bid_now ? Number(order.highest_bid_now).toFixed(1) : '...'}</span></span>
                                                <span className="text-muted">, </span>
                                                <span className="text-muted font-size-11">Ask: <span className="text-danger">{order.lowest_ask_now ? Number(order.lowest_ask_now).toFixed(1) : '...'}</span></span>
                                                {(order.combined_sl_triggered || order.order_settings.combined_trailing_profit) && order.stop_loss_price && order.sum_of_current_price && order.bot_status != 'completed' ? <div className="text-muted font-size-10">Combined Current price: <span className="text-primary">{order.sum_of_current_price}</span></div> : ''} 
                                              </div>

                                              : ""
                                            }
                                          </td>
                                          <td >{order.stop_loss_price ? Number(order.stop_loss_price).toFixed(2) : "..."}
                                            {order.is_min_take_profit_reached && order.stop_loss_price ? <div className="text-muted font-size-10">Trailing take profit {order.order_settings.combined_trailing_profit ? "(Combined)" : ''}</div> : order.combined_sl_triggered && order.stop_loss_price ? <div className="text-muted font-size-10">(Combined Stop Loss)</div> : ""}
                                            {order.new_tailing_profit_percentage && order.is_min_take_profit_reached ? "(" + order.new_tailing_profit_percentage + "%)" : ""}
                                          </td>
                                          <td >{Number(order.filled_quantity).toFixed(0)}/{Number(order.quantity).toFixed(0)}</td>
                                          <td >
                                            {order.market_action_order ?

                                              order.market_action_order_id ? order.market_action_order_id : "Nil"
                                              :
                                              order.order_id ? order.order_id : "Nil"
                                            }
                                          </td>
                                          <td > <span dangerouslySetInnerHTML={{ __html: getOrderStatusNameStyle(order) }}></span></td>
                                          <td >{order.last_trade_price ? order.last_trade_price.toFixed(1) : '...'}</td>
                                          <td title={order.unrealized_pnl ? order.unrealized_pnl : ''}><small className={order.unrealized_pnl > 0 ? "text-success" : "text-danger"}>{order.unrealized_pnl ? CustomParseFloat(order.unrealized_pnl, 2) + ' USD' : '...'}</small></td>
                                          <td >
                                            <div className="d-flex gap-2 justify-content-center">
                                              <a
                                                onClick={() =>
                                                  getLogsBysymbol(order.profile.uid, order.dbid, order.symbol)
                                                }
                                                className="text-info"
                                                disabled={order.status.toLowerCase() === "pending" ? true : false}
                                              >
                                                <i className="mdi mdi-history font-size-18"></i>
                                              </a>
                                              {order.market_action && order.market_action === "market_close_single" && order.market_action_status === 'pending' ?
                                                <a className="text-danger"><span><i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle"></i></span></a>
                                                :
                                                order.market_action && order.market_action === "market_close_single" && order.market_action_status !== 'pending' ? ''
                                                  :
                                                  order.is_market_close ? '' :
                                                    <a onClick={() => marketCloseOrder(order)} className="text-danger">
                                                      <i className="mdi mdi-delete font-size-18"></i>
                                                    </a>
                                              }
                                            </div>
                                          </td>
                                          {index === 0 ?
                                            order.straddle_status.toLowerCase() === 'closed' || order.is_market_close ?
                                              <td className="align-middle" rowSpan={row.length}>
                                                <div
                                                  style={{

                                                    flexDirection: "row",
                                                    justifyContent: "space-evenly",
                                                    alignItems: "center",
                                                    textAlign: "center"
                                                  }}
                                                >
                                                  {order.is_market_close ?
                                                    <span className="text-sm">{order.order_settings.reentry_enabled == 1 && order.status == "closed" && order.step == 3 ? 'Closed and waiting for Re-Entry' : <> Closing... <i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle"></i></>}</span>
                                                    :
                                                    <button
                                                      type="button"
                                                      onClick={() =>
                                                        marketCloseStraddle(order.straddle_id)
                                                      }
                                                      className="btn btn-soft-danger btn-sm"
                                                    >
                                                      Market Close
                                                    </button>
                                                  }
                                                  {order.order_settings.reentry_enabled == 1 ?
                                                    <div><br /><button type="button" onClick={() => stopReentry(order.straddle_id)} className="btn btn-soft-danger btn-sm">Stop Re-Entry</button></div>
                                                    : ''}
                                                </div>
                                              </td>
                                              :
                                              <td className="align-middle" rowSpan={row.length} style={{ "verticalAlign": "top" }}>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "space-evenly",
                                                    alignItems: "center",
                                                    textAlign: "center"
                                                  }}
                                                >
                                                  {!order.is_stop_triggered ? <>
                                                    <button
                                                      type="button"
                                                      onClick={() =>
                                                        stopStraddleBot(order.straddle_id)
                                                      }
                                                      className="btn btn-danger btn-sm"
                                                    >
                                                      Stop
                                                    </button>
                                                    {/* { order.order_settings.reentry_enabled==1 && order.step!==3 && order.status.toLowerCase() == "open"?
                                                  <div><br /><button type="button" onClick={() => stopReentry(order.straddle_id)} className="btn btn-soft-danger btn-sm">Stop Re-Entry</button></div>
                                                  :''} */}
                                                  </>
                                                    :
                                                    <span className="text-sm">Closing... <i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle"></i></span>
                                                  }
                                                </div>
                                              </td>
                                            : ''}
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                )
                              })
                            ) : (
                              <tbody>
                                <tr>
                                  <td colSpan={13} style={{ textAlign: "center" }}>
                                    No orders found
                                  </td>
                                </tr>
                              </tbody>
                            )}
                        </Table>
                        {/*********/}
                      </div>
                    </TabPane>
                    <TabPane tabId="2" id="processing">
                      <Card className="border box_4">
                        <CardBody>
                          <Row>
                            <Col xxl={1} lg={6} className="d-flex align-items-center">
                              <h5><b>Filter</b></h5>
                            </Col>
                            <Col xxl={2} lg={6}>
                              <Label htmlFor="Created">Created date</Label>
                              <Flatpickr className="form-control d-block"
                                placeholder="Created Date"
                                options={{
                                  mode: "range",
                                  dateFormat: "d/m/Y",
                                  defaultDate: [filterStartDate, filterEndDate]
                                }}
                                onChange={handleCreatedDateChange}
                              />
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <div className="table-responsive">
                        {/*********/}
                        {filterStartDate && filterEndDate ? <p className="fw-bold">Showing data from {formatUTCDate(filterStartDate)} to {formatUTCDate(filterEndDate)}</p> : ''}
                        <Table className={"table table-bordered table align-middle table-nowrap table-check "}>
                          <thead >
                            <tr>
                              <th>Expiry Date</th>
                              <th>Side</th>
                              <th>Symbol</th>
                              <th>Sold Price</th>
                              <th>Stop Price</th>
                              <th>Filled/Qty</th>
                              <th>Order ID</th>
                              <th>Status</th>
                              <th className="text-decoration-underline" >
                                <Tooltip
                                  placement="top"
                                  isOpen={ttop1}
                                  target="BOOKEDPNL"
                                  toggle={() => {
                                    setttop1(!ttop1);
                                  }}
                                >
                                  Booked PNL = sell price - buy price
                                </Tooltip>
                                <span id="BOOKEDPNL">Booked PNL</span>
                              </th>
                            </tr>
                          </thead>
                          {loading.Table_load === true ?
                            (
                              <tbody>
                                <tr>
                                  <td colSpan={13} style={{ textAlign: "center" }}>
                                    Loading...
                                  </td>
                                </tr>
                              </tbody>
                            ) : completedStraddles.length > 0 ? (
                              completedStraddles.map((row, index) => {
                                let indexs = (index + 1) % 2
                                let PNL = 0;
                                let index_1 = index
                                {
                                  row.map((order2, index2, arr) => {
                                    let booked_pnl2 = order2.booked_pnl ? order2.booked_pnl : 0
                                    if (index2 === 0) {
                                      PNL = 0;
                                    }
                                    var pnl = parseFloat(order2.realized_pnl ? order2.realized_pnl : booked_pnl2 !== '...' ? parseFloat(booked_pnl2).toFixed(4) : 0)
                                    PNL = PNL + pnl
                                  })
                                }
                                return (
                                  <tbody key={index}>
                                    {row.map((order, index, arr) => {
                                      return (
                                        <tr style={{ color: "#8a90a0", "verticalAlign": "top" }} className={"trow-hover " + (indexs === 0 ? 'table-light' : '')} key={index} >
                                          {index === 0 ?
                                            <td rowSpan={row.length}>
                                              <div className="p-2">
                                                <b className="text-primary font-size-15">{formatUTCDate(order.expiry_date)}</b>
                                                <div style={{ "float": "right" }}><a
                                                  id={"Popovertop-completed-" + (order.order_id ? order.order_id : index_1)}
                                                  color="secondary"
                                                  onClick={() => {
                                                    popovertopchange(`completed-${order.order_id ? order.order_id : index_1}`);
                                                  }}
                                                >
                                                  <i className="bx bxs-info-circle text-dark font-size-15"></i>
                                                </a>
                                                  <Popover
                                                    placement="right"
                                                    isOpen={ispopoverOpen(`completed-${order.order_id ? order.order_id : index_1}`)}
                                                    target={"Popovertop-completed-" + (order.order_id ? order.order_id : index_1)}
                                                    toggle={() => {
                                                      popovertopchange(`completed-${order.order_id ? order.order_id : index_1}`);
                                                    }}
                                                  >
                                                    <PopoverHeader style={{ backgroundColor: "black", color: "white", borderBottomColor: "white" }}><b>Info</b></PopoverHeader>
                                                    <PopoverBody style={{ backgroundColor: "black", color: "white" }}>
                                                      {
                                                        order.order_settings.mid_price_percentage !== undefined && order.order_settings.mid_price_percentage != '' ? <>
                                                          Make Limit Orders x% from Mid/MarkPrice: <b>{order.order_settings.mid_price_percentage}</b>
                                                        </> : ""
                                                      }
                                                      {order.order_settings.trigger_percentage ? <div className="font-size-12">Stop Loss Activate: <b>{Number(order.order_settings.trigger_percentage)}%</b></div> : ''}
                                                      {order.order_settings.stop_loss_percentage ? <div className="font-size-12">Market Stop Loss  <b>{Number(order.order_settings.stop_loss_percentage)}%</b></div> : ''}
                                                      {order.order_settings.stop_loss_index_percentage ? <div className="font-size-12">Index Price Range % for Stop Loss: <b>{Number(order.order_settings.stop_loss_index_percentage)}</b></div> : ''}
                                                      {order.order_settings.minimum_take_profit_percentage ? <div className="font-size-12">Minimum take profit <b>{Number(order.order_settings.minimum_take_profit_percentage)}%</b></div> : ''}
                                                      {order.order_settings.trailing_take_profit_percentage ? <div className="font-size-12">Trailing take profit: <b>{Number(order.order_settings.trailing_take_profit_percentage)}%</b></div> : ''}
                                                      {order.order_settings.deltahedging ? <div className="font-size-12">Enable Delta Hedging: <b>{(order.order_settings.deltahedging == 1 ? 'true' : 'false')}</b></div> : ''}
                                                      {order.order_settings.deltahedging == 1 ? <div className="font-size-12">Disable Delta Hedging on Stop Loss in One Leg: <b>{(order.order_settings.Delta_Hedging_on_Stop_Loss_in_One_Leg == 1 ? 'true' : 'false')}</b></div> : ''}
                                                      {order.order_settings.combined_deactivated ? (<>Combined Deactivated: <b>true</b></>) : ''}
                                                      {order.order_settings.reentry_enabled ? (
                                                        <>Re-Entry Enabled: <b>true</b><br />Waiting time for Re-Entry: <b>{order.order_settings.wait_time_reentry ? timeFormat(order.order_settings.wait_time_reentry) : "--"}</b></>
                                                      ) : <>Re-Entry Enabled: <b>false</b></>}
                                                      {order.order_settings.stopdate_reentry ? (
                                                        <><br />Stop Date Re-Entry: <b>{order.order_settings.stopdate_reentry}</b></>
                                                      ) : ''}
                                                      {order.order_settings.combined_sl_percentage ? <div className="font-size-12">Combined stop loss: {Number(order.order_settings.combined_sl_percentage)}%</div> : ''} 
                                                      {order.order_settings.combined_trailing_take_profit_percentage ? <div className="font-size-12">Combined trailing take profit: {Number(order.order_settings.combined_trailing_take_profit_percentage)}%</div> : ''} 
                                                      {order.order_settings.combined_trailing_stop_price_percentage ? <div className="font-size-12">Combined trailing stop price: {Number(order.order_settings.combined_trailing_stop_price_percentage)}%</div> : ''} 
                                                    </PopoverBody>
                                                  </Popover>
                                                </div>
                                                <div className="text-primary">
                                                  Expiry: {" "}
                                                  <span className="expiry-countdown font-size-11" >
                                                    <Countdown date={order.expiry_date} renderer={counterRenderer} />
                                                  </span>
                                                </div>

                                                {order.order_settings.current_index_price ? <div className="font-size-12">Index Price: {Number(order.order_settings.current_index_price).toFixed(2)}</div> : ''}
                                                <div className="text-muted font-size-12" style={{ float: "left" }}>Total PNL : <text style={PNL > 0 ? { color: "green" } : { color: "red" }}>{PNL.toFixed(4)}</text> USD</div><br></br>
                                                <div className="text-muted font-size-12 mt-2"><i className="far fa-calendar-alt text-primary me-1"></i>Created at: {getSettingsDateTimeFormat(order.created_at)}</div>
                                                {(order.automatic_bot && order.automatic_bot == 1) ? <Badge pill color="warning" className="me-1">Automated Admin Bot</Badge> : order.admin_bot ? <Badge pill color="primary" className="me-1">Admin Bot</Badge> : ''}
                                                {order.reentry_order ? <><Badge pill color="success" className="me-1">Re-Entry Order</Badge></> : ''}
                                              </div>
                                            </td>
                                            : ''}
                                          <td className={order.market_action_order ? "pt-1" : ''} style={{ "verticalAlign": "top" }}>
                                            <Badge
                                              color={order.direction === "buy" ? "success" : "danger"}
                                              style={{ "textTransform": "uppercase" }}
                                            >
                                              {order.direction}
                                            </Badge><br></br>
                                            {order.market_action_order ?
                                              <Badge
                                                color={order.market_action_order.side === "buy" ? "success" : "danger"}
                                                style={{ "textTransform": "uppercase" }}
                                              >
                                                {order.market_action_order.side}
                                              </Badge>
                                              : ''}
                                          </td>
                                          <td className={order.market_action_order ? "pt-1" : ''} style={{ "verticalAlign": "top" }}>
                                            <Badge color={order.direction === "buy" ? "success" : "danger"}>
                                              {order.symbol}
                                            </Badge>
                                            <br></br>
                                            {order.market_action_order ?
                                              <Badge
                                                color={order.market_action_order.side === "buy" ? "success" : "danger"}
                                                style={{ "textTransform": "uppercase" }}
                                              >
                                                {order.market_action_order.product_symbol}
                                              </Badge>
                                              : ''}

                                            {order.market_action_trigger ?
                                              <div>     {order.order_settings.combined_sl}
                                                <span className="text-muted font-size-10 " id={"trigger_calc" + order.order_id} >{getMarketActionTriggerName(order)} </span>{order.index_price !== undefined ? <><br></br>
                                                  {order.reentry_stopped ? <div><span className="text-muted font-size-10 ">Re-Entry Stopped</span></div> : ""}
                                                  <span className="text-muted font-size-10 ">Closed Index Price : {order.index_price}</span> </> : ""}
                                                {order.market_action_trigger.toLowerCase() == "stop_loss" && order.order_settings.combined_sl && order.market_action_associateorder_lowest_ask ?
                                                  <UncontrolledTooltip placement="bottom" target={"trigger_calc" + order.order_id}>
                                                    {"(" + order.market_action_associateorder_lowest_ask + " + " + order.market_action_trigger_statistics.lowest_ask + ") > " + order.stop_loss_price}
                                                  </UncontrolledTooltip> : ""}
                                              </div>
                                              : ""}

                                          </td>

                                          <td className={order.market_action_order ? "pt-2" : ''} style={{ "verticalAlign": "top" }}>
                                            <h6 className={order.direction === "buy" ? "text-success" : "text-danger"}>
                                              {order.average_fill_price ? Number(order.average_fill_price).toFixed(2) : order.price ? Number(order.price).toFixed(2) : "..."}
                                            </h6>

                                            {order.market_action_order ?
                                              <h6 className={order.market_action_order.side === "buy" ? "text-success" : "text-danger"}>
                                                {order.market_action_order.average_fill_price ? Number(order.market_action_order.average_fill_price).toFixed(2) : order.market_action_order.price ? Number(order.market_action_order.price).toFixed(2) : "..."}
                                              </h6>
                                              : ''}
                                          </td>
                                          <td style={{ "verticalAlign": "top" }} className={order.market_action_order ? "pt-2" : ''}>
                                            {order.stop_loss_price ? Number(order.stop_loss_price).toFixed(2) : "..."}
                                            {order.is_min_take_profit_reached && order.stop_loss_price ? <div className="text-muted font-size-10">
                                              ( {order.combined_trail_profit_triggered?"Combined ":""}Trailing take profit)</div> : order.combined_sl_triggered && order.stop_loss_price ? <div className="text-muted font-size-10">(Combined Stop Loss)</div> : ""}

                                            {/* <br></br>

                                          {order.market_action_order ?
                                            order.stop_loss_price ? Number(order.stop_loss_price).toFixed(2) : "..."                                          
                                          :''} */}
                                          </td>
                                          <td style={{ "verticalAlign": "top" }} className={order.market_action_order ? "pt-2" : ''}>
                                            {Number(order.filled_quantity).toFixed(0)}/{Number(order.quantity).toFixed(0)}
                                            {/* <br></br>
                                          {order.market_action_order ?
                                           Number(order.filled_quantity).toFixed(0)+'/'+Number(order.quantity).toFixed(0)
                                            :''} */}
                                          </td>
                                          <td style={{ "verticalAlign": "top" }} className={order.market_action_order ? "pt-2" : ''}>
                                            {order.order_id ? order.order_id : "Nil"}
                                            <br></br>
                                            {order.market_action_order ?
                                              order.market_action_order_id ? order.market_action_order_id : "Nil"
                                              : ''}
                                          </td>
                                          <td style={{ "verticalAlign": "top" }} className={order.market_action_order ? "pt-2" : ''}>
                                            <span dangerouslySetInnerHTML={{ __html: getOrderStatusNameStyle(order) }}></span>
                                            {/* <br></br>
                                          {order.market_action_order ?
                                            <span dangerouslySetInnerHTML={{ __html: getOrderStatusNameStyle(order) }}></span>
                                            :''} */}

                                          </td>

                                          <td style={{ "verticalAlign": "top" }} className={order.market_action_order ? "pt-2" : ''} title={order.booked_pnl ? order.booked_pnl : order.realized_pnl ? order.realized_pnl : ''}>
                                            <small className={order.booked_pnl > 0 ? "text-success" : "text-danger"}>{order.booked_pnl ? parseFloat(order.booked_pnl).toFixed(2) + ' USD' : order.realized_pnl ? parseFloat(order.realized_pnl).toFixed(2) + ' USD' : '...'}</small>
                                            {/* <br></br>
                                          {order.market_action_order ? 
                                            '...'
                                          :''} */}
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                )
                              })
                            ) : (
                              <tbody>
                                <tr>
                                  <td colSpan={13} style={{ textAlign: "center" }}>
                                    No orders found
                                  </td>
                                </tr>
                              </tbody>
                            )}
                        </Table>
                        {/*********/}
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <HistoryModal
          show={modalShow.show}
          onHide={() => setModalShow({ dbid: "", show: false })}
          data={orderHistoryData}
          symbolname={modalShow.symbol}
        />
      </div>
      <Modal
        isOpen={visibleMin}
        toggle={() => {
          setVisibleMin(false);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            To set the Minimum profit for an order.
          </h5>
          <button
            type="button"
            onClick={() => {
              setVisibleMin(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {parse('' + MintakeProfitMsg)}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setVisibleMin(false);
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={visibleTailling}
        toggle={() => {
          setVisibleTailling(false);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            To set the Minimum profit for an order.
          </h5>
          <button
            type="button"
            onClick={() => {
              setVisibleTailling(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {parse('' + TaillingTakeProfitMsg)}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setVisibleTailling(false);
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={visibleMidPrize}
        toggle={() => {
          setVisibleMidPrize(false);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">

          </h5>
          <button
            type="button"
            onClick={() => {
              setVisibleMidPrize(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {parse('' + MidPrizeMsg)}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setVisibleMidPrize(false);
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default Straddles
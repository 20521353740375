import React, { useCallback, useEffect, useState } from "react"

import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  Row,
  Spinner,
  TabContent,
  TabPane,
  Table,
  Tooltip,
  Accordion,
  AccordionBody,
  AccordionHeader,
  UncontrolledTooltip,
  AccordionItem
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"

//Import Breadcrumb
import Switch from "react-switch";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getDiagonalCommonStrikes, getDiagonalExpiryDates, marketClose, startBot, stopBot } from "helpers/diagonal_helper"
import { formatUTCDate, CustomParseFloat, getCalendarTypeName, getFirstPositionName, getFillTypeName, formatUTCDateTime, getOrderStatusNameStyle,getMomentDateTimeFormat, counterRenderer, getMarketActionTriggerName,getSettingsDateTimeFormat,getOrderLogs, timeFormat } from "services/common.service"
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import firebase from "firebase/compat/app";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import Countdown from "react-countdown"
import Swal from "sweetalert2"
import HistoryModal from "components/Modal/HistoryModal"
import Flatpickr from "react-flatpickr";
import api from 'helpers/api_helper';
import Bot_settings_default from '../../helpers/Common/Bot_default_settings_values.json';
import moment from "moment"
const { default: axios } = require("axios");
const Diagonals = () => {
  //tooltip
  const apiUrl = `${process.env.REACT_APP_API_URL}/static/helptexts.json`;
  const [ToolTipData, setToolTipData] = useState([]);
  useEffect(()=>{
       try{
      const gettoottipData = async () => {
       
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/static/helptexts.json`)
          // console.log(response)
            setToolTipData(JSON.parse(JSON.stringify(response.data)))
        
        }

        gettoottipData()
      }catch(error){
          console.log(error)
      }

  },[apiUrl])
  //tooltip
  firebase.auth();
  //Current User  
  const currentUser = JSON.parse(localStorage.getItem('user'));
  let uid = currentUser.uid;
  const [DiagonalsDeltahedging,setDiagonalsDeltahedging]=useState(false);
  const [DiagonalsDeltahedging2, setDiagonalsDeltahedging2] = useState(false);
  const handleError = (err) => {
    if (err && err.response && err.response.data && err.response.data.message) {
      toast.error(err.response.data.message, { toastId: err.response.data.message });
    } else if (err.message) {
      toast.error(err.message, { toastId: err.message });
    } else {
      toast.error(err, { toastId: err });
    }
  }
  //meta title
  document.title = "Diagonals";
  const [open, setOpen] = useState('0');
  const toggle = (id) => {
    if (open === id) {
      setOpen('0');
    } else {
      setOpen(id);
    }
  };
  const firestoreDb = firebase.firestore();
  const [formLoader, setformLoader] = useState(false);
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const [startBotParams, setStartBotParams] = useState({ option: 'calls' });

  const [isDiagonalbotEnabled, setIsDiagonalbotEnabled] = useState(false);
  const [tradeStatus, setTradeStatus] = useState("0");
  const [diagonalExpiryDates, setDiagonalExpiryDates] = useState([]);
  const [longExpiryDates, setLongExpiryDates] = useState([]);
  const [shortExpiryDates, setShortExpiryDates] = useState([]);
  const [selectedLongExpiry, setselectedLongExpiry] = useState('');
  const [loading, setLoading] = useState({
      loading: false
  });
  const firestoreDbUser = firestoreDb.collection("users").doc(uid);

  //Get Expiry dates list and set in options
  const getExpiryDates = async () => {
    try {
      const bodyParams = {
        currency: 'BTC'
      };
      await getDiagonalExpiryDates(bodyParams).then((res) => {
        setDiagonalExpiryDates(res.data.response.expiry_dates);
        setShortExpiryDates(res.data.response.expiry_dates.slice(0, -1));
        let longExpiryDates = res.data.response.expiry_dates.slice(1)
        setLongExpiryDates(longExpiryDates);
        setselectedLongExpiry(longExpiryDates[0] ? longExpiryDates[0] : '')
      }).catch(e => {
        throw e
      })
    } catch (e) {
      handleError(e);
    }
  }
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    );
  };
  
  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Yes
      </div>
    );
  };
  //Set long expiry date options based on short expiry selection
  const setLongExpiryDatesFunc = async (key_value) => {
    const selected_date = diagonalExpiryDates.findIndex(m => m == key_value.target.value);
    setLongExpiryDates(diagonalExpiryDates.slice(selected_date + 1))
  }

  //Get Site Settings
  const getSiteSettings = async () => {
    await api({ contentType: true, auth: true }).get('/delta/get-site-settings').then(res => {
      if (res && res.data && res.data.response && res.data.response.settings) {
        setIsDiagonalbotEnabled(res.data.response.settings.enable_diagonal);
      }
    });

    // check admin trade status
    // const check_trade_status = await firestoreDbUser.collection("profile").doc('data').get().then((res)=>{
    //   if(res.exists)
    //   {
    //       const check_status  = res.data();
    //       setTradeStatus(check_status.trade_status);                
    //   }
    // })
    
  
    const check_trade_status = await api({ contentType: true, auth: true }).get('/user/profile')
    .then(res => {
      if(res.data.response)
      {
        setTradeStatus(res.data.response.user.trade_status);
      }
    });    

    // var site_settings = firestoreDb.collection("site-settings").doc('data');
    // await site_settings.get().then((res) => {
    //   if (res.exists) {
    //     const site_setting = res.data();
    //     setIsDiagonalbotEnabled(site_setting.enable_diagonal);
    //   }
    // });
  }

  //Choose Expiry & Get Strikes 
  const [activeOption, setActiveOption] = useState("1"); // Tabs
  const [commonStrikes, setCommonStrikes] = React.useState('');
  const [filteredCommonStrikes, setFilteredCommonStrikes] = React.useState('');
  const [filteredCommonStrikesLong, setFilteredCommonStrikesLong] = React.useState('');

  const processStep1 = async () => {
    try {
      setformLoader(true);
      setCommonStrikes("");
      setFilteredCommonStrikes("");
      setFilteredCommonStrikesLong("")
      const short_expiry = document.getElementById('short_expiry').value;
      const long_expiry = document.getElementById('long_expiry').value;
      if (short_expiry === long_expiry) {
        toast.error('Short expiry and Long expiry should not be same', { toastId: 'diagonal_step1_1' });
        setformLoader(false);
        return false;
      }

      if (short_expiry > long_expiry) {
        toast.error('Short expiry should be lower than long expiry', { toastId: 'diagonal_step1_2' });
        setformLoader(false);
        return false;
      }

      const bodyParams = {
        short_expiry: short_expiry,
        long_expiry: long_expiry
      };

      await getDiagonalCommonStrikes(bodyParams).then(res => {
        setformLoader(false);
        setActiveOption("1");
        setStartBotParams({ ...startBotParams, 'short_expiry': short_expiry, 'long_expiry': long_expiry, option: 'calls', option_index: undefined });
        setCommonStrikes(res.data.response.common_strikes);
        setFilteredCommonStrikes(res.data.response.common_strikes);
        setFilteredCommonStrikesLong(res.data.response.common_strikes);
      }).catch(err => {
        throw err
      });
      return true;
    } catch (err) {
      setformLoader(false);
      handleError(err);
      return false;
    }
  }

  const validateStrikeFilterForm = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      delta_condition: '==',
      markiv_condition: '==',
      price_condition: '==',
      delta_diff: '',
      markiv_diff: '',
      price_diff: ''
    },
    validationSchema: Yup.object().shape({
      delta_diff: Yup.number(),
      markiv_diff: Yup.number(),
      price_diff: Yup.number()
    }),
    onSubmit: async (data) => {
      try {
        let condition = '';
        if (data.delta_condition && data.delta_diff) {
          condition += "strike.delta_difference " + data.delta_condition + " " + CustomParseFloat(data.delta_diff, 2);
        }

        if (data.markiv_condition && data.markiv_diff) {
          condition = (condition !== '') ? condition + " && " : '';
          condition += "strike.mark_iv_difference " + data.markiv_condition + " " + parseFloat(data.markiv_diff).toFixed(1);
        }

        if (data.price_condition && data.price_diff) {
          condition = (condition !== '') ? condition + " && " : '';
          condition += "strike.price_difference " + data.price_condition + " " + parseFloat(data.price_diff).toFixed(1);
        }

        let filtered_strikes = { ...commonStrikes };
        if (condition) {
          let filteredStrikesCalls = commonStrikes.calls.filter(strike => eval(condition));
          let filteredStrikesPuts = commonStrikes.puts.filter(strike => eval(condition));
          filtered_strikes.calls = filteredStrikesCalls;
          filtered_strikes.puts = filteredStrikesPuts;
        }
        setFilteredCommonStrikes(filtered_strikes);
      setFilteredCommonStrikesLong(filtered_strikes);
      }
      catch (err) {
        toast.error('Unable to filter the results. Please try again', { toastId: 'diagonal_step2_filter' });
      }
    }
  });

  //Choose a Strike
  const processStep2 = async () => {
    try {

      setformLoader(true);
      const option = startBotParams.option;
      const option_index_long = startBotParams.option_index_long;
      const option_index_short = startBotParams.option_index_short;
      if (option_index_long === undefined || option_index_short === undefined) {
        setformLoader(false);
        toast.error('Please select a strike in ' + option + ' option', { toastId: 'diagonal_step2_1' });
        return false;
      }

     
      setformLoader(false);
      return true;
    } catch (err) {
      setformLoader(false);
      handleError(err);
      return false;
    }
  }

  //Default bot setup settings
  const [botSettings, setBotSettings] = useState({
    hours_before_to_market_close: Bot_settings_default.Diagonals.hours_before_to_market_close,
    take_profit_percentage: Bot_settings_default.Diagonals.take_profit_percentage,
    quantity: Bot_settings_default.Diagonals.quantity,
    delta_hedging_range : Bot_settings_default.Delta_hedge.delta_hedging_range,
    delta_hedging_interval : Bot_settings_default.Delta_hedge.delta_hedging_interval
  });

  const formatPercentage = value => value + " %"

  const handleRangeInput = (e, setting_name, isInteger = true) => {
    if ((e.target.value === null || e.target.value === undefined || e.target.value === '')) {
      setBotSettings({ ...botSettings, [setting_name]: '' });
      return false;
    }

    let newvalue = Math.max(Number(e.target.getAttribute('min')), Math.min(Number(e.target.getAttribute('max')), Number(e.target.value)));
    setBotSettings({ ...botSettings, [setting_name]: (isInteger) ? parseInt(newvalue) : newvalue });
  }

  //Bot setup / settings
  const processStep3 = async () => {
    try {

      setformLoader(true);
      let quantity = Number(document.getElementById('quantity').value);
      let calendar_option = document.getElementById('calendar_type');
      let calendar_type = calendar_option.options[calendar_option.selectedIndex].value;
      let expiry_position_option = document.getElementById('expiry_position_to_begin');
      let expiry_position_to_begin = expiry_position_option.options[expiry_position_option.selectedIndex].value;
      let fill_option = document.getElementById('fill_type');
      let fill_type = fill_option.options[fill_option.selectedIndex].value;
      let hours_before_to_market_close = Number(document.getElementById('hours_before_to_market_close').value);
      let take_profit_percentage = Number(document.getElementById('take_profit_percentage').value);
      let deltahedging = DiagonalsDeltahedging === true ? 1 : 0; 
      let Delta_Hedging_on_Stop_Loss_in_One_Leg = DiagonalsDeltahedging2 === true ? 1 : 0;
      if (!calendar_type) {
        toast.error('Please select calendar type', { toastId: 'diagonal_step3_1' });
        setformLoader(false);
        return false;
      }

      if (!expiry_position_to_begin) {
        toast.error('Please select expiry position to enter at first', { toastId: 'diagonal_step3_2' });
        setformLoader(false);
        return false;
      }

      if (!fill_type) {
        toast.error('Please select fill type', { toastId: 'diagonal_step3_3' });
        setformLoader(false);
        return false;
      }

      if (isNaN(hours_before_to_market_close) || hours_before_to_market_close <= 0) {
        toast.error('Please enter valid hours', { toastId: 'diagonal_step3_4' });
        setformLoader(false);
        return false;
      }

      if (isNaN(take_profit_percentage) || take_profit_percentage < 0) {
        toast.error('Please enter valid take profit', { toastId: 'diagonal_step3_5' });
        setformLoader(false);
        return false;
      }

      if (!quantity || quantity < 1) {
        toast.error('Please enter valid quantity', { toastId: 'diagonal_step3_6' });
        setformLoader(false);
        return false;
      }

      setStartBotParams({
        ...startBotParams,
        calendar_type: calendar_type,
        expiry_position_to_begin: expiry_position_to_begin,
        fill_type: fill_type,
        take_profit_percentage: take_profit_percentage,
        hours_before_to_market_close: hours_before_to_market_close,
        quantity: quantity,
        Delta_Hedging_on_Stop_Loss_in_One_Leg : Delta_Hedging_on_Stop_Loss_in_One_Leg,
        deltahedging : deltahedging
      });

      setformLoader(false);
      return true;
    } catch (err) {
      setformLoader(false);
      handleError(err);
      return false;
    }
  }
  const getDiagonalsSettings = async () => {
    try {
      await api({ contentType: true, auth: true }).get('/diagonals/get-diagonals-positions').then((res) => {
        setDiagonalsDeltahedging(res.data.response.data[0] && res.data.response.data[0].data ? res.data.response.data[0].data.delta_hedging_enable == 1 ? true : false : false)
        setDiagonalsDeltahedging2(res.data.response.data[0] && res.data.response.data[0].data ? res.data.response.data[0].data.delta_hedging_on_stop_loss_in_one_leg == 1 ? true : false : false)
        setBotSettings({
          ...botSettings,
          delta_hedging_range : res.data.response.data[0] && res.data.response.data[0].data ? res.data.response.data[0].data.delta_hedging_range ?res.data.response.data[0].data.delta_hedging_range :  Bot_settings_default.Delta_hedge.delta_hedging_range :  Bot_settings_default.Delta_hedge.delta_hedging_range ,
        delta_hedging_interval :res.data.response.data[0] && res.data.response.data[0].data? res.data.response.data[0].data.delta_hedging_interval ? res.data.response.data[0].data.delta_hedging_interval :  Bot_settings_default.Delta_hedge.delta_hedging_interval :  Bot_settings_default.Delta_hedge.delta_hedging_interval
        })
      }).catch(err => {
        throw err
      });
    } catch (err) {
      handleError(err);
    }
  }
  //Bot setup / settings
  const processStep4 = async () => {
    try {

      setformLoader(true);

      const params = {
        short_expiry: startBotParams.short_expiry,
        long_expiry: startBotParams.long_expiry,
        option: startBotParams.option,
        strike_price_short: startBotParams.strike_price_short,
        strike_price_long:startBotParams.strike_price_long,
        short_expiry_symbol: startBotParams.short_expiry_symbol,
        short_expiry_product_id: startBotParams.short_expiry_product_id,
        long_expiry_symbol: startBotParams.long_expiry_symbol,
        long_expiry_product_id: startBotParams.long_expiry_product_id,
        calendar_type: startBotParams.calendar_type,
        expiry_position_to_begin: startBotParams.expiry_position_to_begin,
        fill_type: startBotParams.fill_type,
        take_profit_percentage: startBotParams.take_profit_percentage,
        hours_before_to_market_close: startBotParams.hours_before_to_market_close,
        quantity: startBotParams.quantity,
        Delta_Hedging_on_Stop_Loss_in_One_Leg : startBotParams.Delta_Hedging_on_Stop_Loss_in_One_Leg,
        deltahedging : startBotParams.deltahedging
      };

      //Calls sell Order
      await startBot(params).then(async (res) => {
        setformLoader(false);
        toast.success(res.data.message);
      }).catch(err => {
        throw err;
      });
      return true;
    } catch (err) {
      setformLoader(false);
      handleError(err);
      return false;
    }
  }

  //Handle Form Wizard
  const toggleTab = async (tab) => {

    if (!isDiagonalbotEnabled || tradeStatus == 0) {
      toast.error('Diagonal bot creation disabled by admin', { toastId: 'diagonal_bot_disabled' });
      return false;
    }

    let modifiedSteps = passedSteps;
    if (activeTab !== tab) {
      if (tab > activeTab) {
        let is_processed = false;
        switch (activeTab) {
          case 1:
            //Choose Expiry
            is_processed = await processStep1();
            break;
          case 2:
            //Choose Strike
            is_processed = await processStep2();
            break;
          case 3:
            //Bot Setup
            is_processed = await processStep3();
            break;
          case 4:
            //Start Bot
            is_processed = await processStep4();
            break;
        }

        if (activeTab != 2) { validateStrikeFilterForm.resetForm({ values: {} }) }

        const index = modifiedSteps.indexOf(tab);
        if (index > -1) {
          modifiedSteps = modifiedSteps.slice(0, index);
        }

        if (is_processed) {
          modifiedSteps.push(tab);
          if (tab >= 1 && tab <= 5) {
            setactiveTab(tab)
            setPassedSteps(modifiedSteps)
            window.scrollTo(0, 0);

            if(tab==5){
              setTimeout(() => {
                setactiveTab(1);
                setPassedSteps([1])
                setOpen('0');                
              }, 3000);  
            }
          }
        } else {
          setPassedSteps(modifiedSteps)
        }
      } else {
        if (tab >= 1 && tab <= 5) {
          setactiveTab(tab)
          window.scrollTo(0, 0);
        }
      }
    }
  }

  const [activeOrderTab, setActiveOrderTab] = useState("1");
  const [ttop, setttop] = useState(false);
  const [ttop1, setttop1] = useState(false);
  const defaultStartDate = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString();
  const defaultEndDate = new Date(new Date()).toISOString();
  const [filterStartDate, setFilterStartDate] = useState(defaultStartDate);
  const [filterEndDate, setFilterEndDate] = useState(defaultEndDate); 
  const toggleOrderTab = tab => {
    if (activeOrderTab !== tab) {
      setActiveOrderTab(tab);
      if (tab == 2) {        
        setFilterStartDate(defaultStartDate); 
        setFilterEndDate(defaultEndDate);        
      }
    }
  };
  const [modalShow, setModalShow] = useState(false);	
  const [orderHistoryData, setOrderHistoryData] = useState([]);

  //Active Diagonals
  const [activeDiagonals, setActiveDiagonals] = useState([]);    
  const getActiveDiagonals = useCallback(() => {	   
    setLoading({
      ...loading,
      Table_load : true
  })  
    const activeDiagonalsResult = firestoreDbUser.collection("diagonal_orders").orderBy("created_at", "desc");

    activeDiagonalsResult.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        let item = doc.data();
        item.dbid = doc.id;
        items.push(item);
        // Display if any notifications
        let currentdate = new Date().toISOString()
        let currentdateTime = new Date(currentdate).getTime();
        if (item.notification && item.notification.timestamp >= currentdateTime) {          
          if (item.notification.type === 'success') {          
            toast.success(item.notification.message, { toastId: item.notification.toast_id });
          } else {
            toast.error(item.notification.message, { toastId: item.notification.toast_id });    
          }        
        }            
      }); 

      //Combine orders by diagonal id
      const all_diagonals = items.reduce(function (row, obj) {
        row[obj.diagonal_id] = row[obj.diagonal_id] || [];        
        row[obj.diagonal_id].push(obj);
        return row;
      }, []);
      
      //Convert to array & Find diagonal status - To enable market close
      const active_diagonals = Object.keys(all_diagonals).map(diagonal_index => {                 
        const rows = all_diagonals[diagonal_index];
        const filled_order = rows.find(element => element.status.toLowerCase() === 'closed' || element.filled_quantity > 0);
        const diagonal_status = (filled_order) ? "closed" : "open";
        return rows.map((row) => {
          row.diagonal_status = diagonal_status;          
          return row
        })
      });
      setLoading({
        ...loading,
        Table_load : false
    }) 
      setActiveDiagonals(active_diagonals);
    });

  }, []);
  const getLogsBysymbol = async (uid,dbid,symbol) => {
    try {  
      var body =  {
        uid : uid,
        strategy : "diagonals",
        dbid : dbid
      }  
      let order_history= await getOrderLogs(body);
      if(order_history){
          setModalShow({
            symbol: symbol,
            show: true,
          });
          setOrderHistoryData(order_history);
        } else {
          setOrderHistoryData([]);
          setModalShow({
            symbol: symbol,
            show: true,
          });
        }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message);        
      } else if (err.message) {
        toast.error(err.message);         
      } else if (err.request) {
        //console.log(err.message);
      } else {        
        //console.log(err.message);
      }
    }
  };
  // const getLogsBysymbol = async (symbol) => {
  //   try {
  //     await firestoreDbUser
  //       .collection("diagonal_orders_log")
  //       .doc(symbol)
  //       .get()
  //       .then(async (doc) => {
  //         if (doc.exists) {

  //           let order_history = [];

  //           if (doc.data().order_history) {
  //             order_history = doc.data().order_history;
  //             order_history.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());
  //           }

  //           setModalShow({
  //             symbol: symbol,
  //             show: true,
  //           });
  //           setOrderHistoryData(order_history);
  //         } else {
  //           setOrderHistoryData([]);
  //           setModalShow({
  //             symbol: symbol,
  //             show: true,
  //           });
  //         }
  //       });
  //   } catch (err) {
  //     handleError(err);
  //   }
  // };

  // Stop diagonal
  const stopDiagonalBot = async (diagonal_id) => {
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to close the bot ?",
        icon: 'warning',
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-secondary",
        reverseButtons: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then(async (result) => {
        if (result.isConfirmed) {

          toast.success('Stop diagonal was initiated.');
          const params = {
            diagonal_id: diagonal_id
          };
          await stopBot(params).then((res) => {
            if (res.data !== "") {
              toast.success(res.data.message);
            }
          }).catch(err => {
            handleError(err);
          });
        }
      })
    } catch (err) {
      handleError(err); 
    }
  };

  const marketCloseDiagonal = async (diagonal_id) => {
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to close the bot ?",
        icon: 'warning',
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-secondary",
        reverseButtons: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then(async (result) => {
        if (result.isConfirmed) {
          toast.success('Market close was initiated.');
          const params = {
            diagonal_id: diagonal_id
          };
          await marketClose(params).then((res) => {
            if (res.data !== "") {
              toast.success(res.data.message);
            }
          }).catch(err => {
            handleError(err);
          });
        }
      })
    } catch (err) {
      handleError(err); 
    }
  };


  //Completed Diagonals  
  const [completedDiagonals, setCompletedDiagonals] = useState([]);   
 
  const convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  const handleCreatedDateChange = (selectedDates, datestr) => {    
    if (selectedDates && selectedDates.length) {
      let converted_date = new Date(convert(selectedDates[0]));
      converted_date.setHours(0, 0, 0, 0);
      setFilterStartDate(converted_date.toISOString()); 
      if(selectedDates[1]){
        let converted_end_date = new Date(convert(selectedDates[1]));
        converted_end_date.setHours(23, 59, 59, 999);
        setFilterEndDate(converted_end_date.toISOString()); 
      }
    } else {
      setFilterStartDate(defaultStartDate); 
      setFilterEndDate(defaultEndDate);       
    }
  }
  
  const getCompletedDiagonals = async () => {     
    setLoading({
      ...loading,
      Table_load : true
  }) 
  var body =  {
    uid : uid,
    filterStartDate : filterStartDate,
    filterEndDate : filterEndDate
  }
  await api({ contentType: true, auth: true }).post('/diagonals/diagonal-history-data',body).then(async(res)  => {
    var completedDiagonalsResult = res.data.response.data;
    // const completedDiagonalsResult = firestoreDbUser.collection("history").where("strategy", '==', 'diagonals').where("created_at", '>=', filterStartDate).where("created_at", '<=', filterEndDate).orderBy("created_at", "desc");

      const items = [];
      completedDiagonalsResult.forEach((doc) => {
        let item = doc.data;
        items.push(item)
      });
      
      const all_diagonals = items.reduce(function (row, obj) {
        const index =obj.diagonal_id+'_sta';
        row[index] = row[index] || [];        
        row[index].push(obj);
        return row;
      }, []);
      
      const completed_diagonals = Object.keys(all_diagonals).map(diagonal_index => {
          return all_diagonals[diagonal_index];        
      });
      setLoading({
        ...loading,
        Table_load : false
    }) 
      setCompletedDiagonals(completed_diagonals);	    
    });     
    
  }  

  useEffect(() => {
    getDiagonalsSettings();
    getSiteSettings();
    getExpiryDates();
    setLoading({
      ...loading,
      Table_load : true
  }) 
  }, []);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getActiveDiagonals();          
    }         
    return () => {
      // actions to be performed when component unmounts
      mounted = false;
    }
  }, []);

  useEffect(() => { 
    let mounted = true;
    if (mounted) {      
      getCompletedDiagonals();
    }         
    return () => {
      // actions to be performed when component unmounts
      mounted = false;
    }
  },[filterStartDate,filterEndDate]);
  if (ToolTipData=="" && ToolTipData.length === 0) {
    return <div>Loading...</div>;
  }
  return (
    <React.Fragment>
      <div className="page-content common">
        <Container fluid={true}>
          <Breadcrumbs title="Derisnap" breadcrumbItem="Diagonals" />
          {isDiagonalbotEnabled && tradeStatus == 1 ?
             <Row>
              <Col lg="12">
            <Card>
              <CardBody>
              <Accordion open={open} toggle={toggle}>
                  <AccordionItem className="brd-soft">
                  <AccordionHeader targetId="1" className="bg-soft">
                    <b>Create Diagonal Bot</b>
                  </AccordionHeader>
                  <AccordionBody accordionId="1">  
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              setactiveTab(1)
                            }}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">1.</span> Choose Expiry
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => {
                              setactiveTab(2)
                            }}
                            disabled={!(passedSteps || []).includes(2)}
                          >
                            <span className="number">2.</span> Choose Strike
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 3 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 3 })}
                            onClick={() => {
                              setactiveTab(3)
                            }}
                            disabled={!(passedSteps || []).includes(3)}
                          >
                            <span className="number">3.</span> Bot Setup
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 4 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 4 })}
                            onClick={() => {
                              setactiveTab(4)
                            }}
                            disabled={!(passedSteps || []).includes(4)}
                          >
                            <span className="number">4.</span> Bot Summary
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 5 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 5 })}
                            onClick={() => {
                              setactiveTab(5)
                            }}
                            disabled={!(passedSteps || []).includes(5)}
                          >
                            <span className="number">5.</span> Bot Success
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={1}>
                          <div className="p-4 border">
                            <Form>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label htmlFor="short_expiry">Short Expiry</Label>{" "}<i className="bx bx-info-circle" id ="shortexp" />
                                      <UncontrolledTooltip placement="top" target="shortexp"> 
                                          {ToolTipData.short_expiry}
                                      </UncontrolledTooltip>
                                    
                                    <select id="short_expiry" className="form-select" onChange={(e) => setLongExpiryDatesFunc(e)} >
                                      {shortExpiryDates.map((index) => (
                                        <React.Fragment key={index}>
                                          <option value={index} > {formatUTCDate(index)} </option>
                                        </React.Fragment>
                                      ))}
                                    </select>
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label>Long Expiry</Label>{" "}<i className="bx bx-info-circle" id ="longexp" />
                                      <UncontrolledTooltip placement="top" target="longexp"> 
                                          {ToolTipData.long_expiry}
                                      </UncontrolledTooltip>
                                    <select id="long_expiry" className="form-select" value={selectedLongExpiry} onChange={e => setselectedLongExpiry(e.target.value)} >
                                      {longExpiryDates.map((index) => (
                                        <React.Fragment key={index}>
                                          <option value={index} > {formatUTCDate(index)} </option>
                                        </React.Fragment>
                                      ))}
                                    </select>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </TabPane>
                        <TabPane tabId={2}>
                          {filteredCommonStrikes ?
                            <>
                              <div className="p-4 border">
                                <Form className="row gy-2 gx-3 align-items-center" onSubmit={(e) => {
                                  e.preventDefault();
                                  validateStrikeFilterForm.handleSubmit();
                                  return false;
                                }}>
                                  <div className="col-lg-3">
                                    <Row>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label htmlFor="delta_condition">Delta</Label>{" "}<i className="bx bx-info-circle" id ="Delta" />
                                          <UncontrolledTooltip placement="top" target="Delta"> 
                                              {ToolTipData.delta}
                                          </UncontrolledTooltip>
                                          <select name="delta_condition" defaultValue="==" className="form-select" onChange={validateStrikeFilterForm.handleChange} onBlur={validateStrikeFilterForm.handleBlur}>
                                            <option value="==">{'='}</option>
                                            <option value=">">{'>'}</option>
                                            <option value="<">{'<'}</option>
                                          </select>
                                        </div>
                                      </Col>
                                      <Col md={8}>
                                        <div className="mb-3">
                                          <Label className="invisible" htmlFor="delta_diff">Delta Value</Label>
                                          <Input
                                            type="text"
                                            name="delta_diff"
                                            className="form-control"
                                            id="delta_diff"
                                            placeholder="Delta"
                                            onChange={validateStrikeFilterForm.handleChange}
                                            onBlur={validateStrikeFilterForm.handleBlur}
                                            value={validateStrikeFilterForm.values.delta_diff || ""}
                                            invalid={
                                              validateStrikeFilterForm.touched.delta_diff && validateStrikeFilterForm.errors.delta_diff ? true : false
                                            }
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>

                                  <div className="col-lg-3">
                                    <Row>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label htmlFor="markiv_condition">Mark IV</Label>{" "}<i className="bx bx-info-circle" id ="markIV" />
                                           <UncontrolledTooltip placement="top" target="markIV"> 
                                               {ToolTipData.mark_iv}
                                           </UncontrolledTooltip>
                                          <select name="markiv_condition" defaultValue="==" className="form-select" onChange={validateStrikeFilterForm.handleChange} onBlur={validateStrikeFilterForm.handleBlur}>
                                            <option value="==">{'='}</option>
                                            <option value=">">{'>'}</option>
                                            <option value="<">{'<'}</option>
                                          </select>
                                        </div>
                                      </Col>
                                      <Col md={8}>
                                        <div className="mb-3">
                                          <Label className="invisible" htmlFor="markiv_diff">Delta Value</Label>
                                          <Input
                                            type="text"
                                            name="markiv_diff"
                                            className="form-control"
                                            id="markiv_diff"
                                            placeholder="Mark IV"
                                            onChange={validateStrikeFilterForm.handleChange}
                                            onBlur={validateStrikeFilterForm.handleBlur}
                                            value={validateStrikeFilterForm.values.markiv_diff || ""}
                                            invalid={
                                              validateStrikeFilterForm.touched.markiv_diff && validateStrikeFilterForm.errors.markiv_diff ? true : false
                                            }
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>

                                  <div className="col-lg-3">
                                    <Row>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label htmlFor="price_condition">Price</Label>{" "}<i className="bx bx-info-circle" id ="price" />
                                          <UncontrolledTooltip placement="top" target="price"> 
                                              {ToolTipData.price}
                                          </UncontrolledTooltip>
                                          <select name="price_condition" defaultValue="==" className="form-select" onChange={validateStrikeFilterForm.handleChange} onBlur={validateStrikeFilterForm.handleBlur}>
                                            <option value="==">{'='}</option>
                                            <option value=">">{'>'}</option>
                                            <option value="<">{'<'}</option>
                                          </select>
                                        </div>
                                      </Col>
                                      <Col md={8}>
                                        <div className="mb-3">
                                          <Label className="invisible" htmlFor="price_diff">Delta Value</Label>
                                          <Input
                                            type="text"
                                            name="price_diff"
                                            className="form-control"
                                            id="price_diff"
                                            placeholder="Price"
                                            onChange={validateStrikeFilterForm.handleChange}
                                            onBlur={validateStrikeFilterForm.handleBlur}
                                            value={validateStrikeFilterForm.values.price_diff || ""}
                                            invalid={
                                              validateStrikeFilterForm.touched.price_diff && validateStrikeFilterForm.errors.price_diff ? true : false
                                            }
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>

                                  <div className="col-lg-3 mt-3">

                                    <button type="submit" className="btn btn-outline-secondary w-lg m-2"><i className="ml-2 bx bx-filter font-size-16 align-middle me-2"></i>Filter</button>

                                    <button type="buttton" className="btn btn-outline-danger w-lg m-2" onClick={() => validateStrikeFilterForm.resetForm({ values: validateStrikeFilterForm.initialValues })}><i className="ml-2 bx bx-reset font-size-16 align-middle me-2"></i>Reset</button>

                                  </div>
                                </Form>
                              </div>
                              <div className="p-4 border mt-2">
                                <Nav tabs className="nav-tabs-custom">
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        'active': activeOption === "1"
                                      })}
                                      onClick={() => {
                                        setActiveOption("1"); setStartBotParams({ ...startBotParams, option: 'calls', option_index: undefined })
                                      }}
                                    >
                                      Calls
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        'active': activeOption === "2"
                                      })}
                                      onClick={() => {
                                        setActiveOption("2"); setStartBotParams({ ...startBotParams, option: 'puts', option_index: undefined })
                                      }}
                                    >
                                      Puts
                                    </NavLink>
                                  </NavItem>
                                </Nav>
                                <TabContent activeTab={activeOption} className="p-3 text-muted">
                                  <TabPane tabId="1">
                                    <div className="table-responsive border">
                                      <Table className="table table-sm table-hover align-middle mb-0">
                                        <thead>
                                          <tr className="text-center">
                                            <th></th>
                                            <th className="table-success" colSpan="3">Differences</th>
                                            <th colSpan="4">Short Expiry: <small>{formatUTCDate(filteredCommonStrikes.settings.short_expiry)}</small></th>
                                            <th className="table-success">Common Strikes</th>
                                            <th colSpan="5">Long Expiry:  <small>{formatUTCDate(filteredCommonStrikes.settings.long_expiry)}</small></th>
                                          </tr>
                                          <tr className="text-center">
                                            <th width="3%">#</th>
                                            <th className="table-success" width="5%">Delta</th>
                                            <th className="table-success" width="5%">Mark IV</th>
                                            <th className="table-success" width="5%">Price</th>
                                            <th width="7%">Bid Qty</th>
                                            <th className="text-end" width="7%">Bid (Price / IV)</th>
                                            <th className="text-end" width="7%">Ask (Price / IV)</th>
                                            <th width="7%">Ask Qty</th>
                                            <th className="table-success" width="10%">Strike Price</th>
                                            <th width="7%">Bid Qty</th>
                                            <th className="text-end" width="7%">Bid (Price / IV)</th>
                                            <th className="text-end" width="7%">Ask (Price / IV)</th>
                                            <th width="7%">Ask Qty</th>
                                            <th width="3%">#</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {filteredCommonStrikes.calls && filteredCommonStrikes.calls.length ?
                                            filteredCommonStrikes.calls.map((strike, index, arr) => {
                                              return (
                                                <tr key={`calls_strike_${index}`} className="text-center">
                                                  <td><input type="radio" name="selected_strike" id={`flexRadioDefault_calls_${index}`} className="cursor-pointer form-check-input border-primary" value={index} checked={startBotParams.option === 'calls' && startBotParams.option_index_short === index && startBotParams.is_selected_short == true } onChange={(e) => { setStartBotParams({ ...startBotParams,is_selected_short:true, option_index_short: Number(e.target.value), 'strike_price_short': filteredCommonStrikes.calls[Number(e.target.value)].short.strike_price, 'short_expiry_symbol': filteredCommonStrikes.calls[Number(e.target.value)].short.symbol, 'short_expiry_product_id': filteredCommonStrikes.calls[Number(e.target.value)].short.product_id}) }} /></td>
                                                  <td className="table-success">{strike.delta_difference}</td>
                                                  <td className="table-success">{strike.mark_iv_difference}%</td>
                                                  <td className="table-success">{strike.price_difference}%</td>
                                                  <td>{Number(strike.short.quotes.bid_size) / 1000}</td>
                                                  <td className="text-end text-success">{Number(strike.short.quotes.best_bid).toFixed(1)} <span className="d-block text-secondary">{(Number(strike.short.quotes.bid_iv) * 100).toFixed(1)}%</span>
                                                  </td>
                                                  <td className="text-end text-danger">{Number(strike.short.quotes.best_ask).toFixed(1)} <span className="d-block text-secondary">{(Number(strike.short.quotes.ask_iv) * 100).toFixed(1)}%</span></td>
                                                  <td>{Number(strike.short.quotes.ask_size) / 1000}</td>
                                                  <td className="table-success">{strike.strike_price}</td>
                                                  <td>{Number(strike.long.quotes.bid_size) / 1000}</td>
                                                  <td className="text-end text-success">{Number(strike.long.quotes.best_bid).toFixed(1)} <span className="d-block text-secondary">{(Number(strike.long.quotes.bid_iv) * 100).toFixed(1)}%</span>
                                                  </td>
                                                  <td className="text-end text-danger">{Number(strike.long.quotes.best_ask).toFixed(1)}
                                                    <span className="d-block text-secondary">{(Number(strike.long.quotes.ask_iv) * 100).toFixed(1)}%</span>
                                                  </td>
                                                  <td>{Number(strike.long.quotes.ask_size) / 1000}</td>
                                                  <td><input type="radio" name="selected_strike_2" id={`flexRadioDefault_calls_2${index}`} className="cursor-pointer form-check-input border-primary" value={index} checked={startBotParams.option === 'calls' && startBotParams.option_index_long === index && startBotParams.is_selected_long == true } onChange={(e) => { setStartBotParams({ ...startBotParams,is_selected_long: true, option_index_long: Number(e.target.value), 'strike_price_long': filteredCommonStrikesLong.calls[Number(e.target.value)].long.strike_price, 'long_expiry_symbol': filteredCommonStrikesLong.calls[Number(e.target.value)].long.symbol, 'long_expiry_product_id': filteredCommonStrikesLong.calls[Number(e.target.value)].long.product_id }) }} /></td>
                                                </tr>
                                              )
                                            })
                                            :
                                            <tr className="text-center">
                                              <td colSpan="13">No strikes found</td>
                                            </tr>
                                          }
                                        </tbody>
                                      </Table>
                                    </div>
                                  </TabPane>
                                  <TabPane tabId="2">
                                    <div className="table-responsive border">
                                      <Table className="table table-sm table-hover align-middle mb-0">
                                        <thead>
                                          <tr className="text-center">
                                            <th></th>
                                            <th className="table-success" colSpan="3">Differences</th>
                                            <th colSpan="4">Short Expiry: <small>{formatUTCDate(filteredCommonStrikes.settings.short_expiry)}</small></th>
                                            <th className="table-success">Common Strikes</th>
                                            <th colSpan="5">Long Expiry:  <small>{formatUTCDate(filteredCommonStrikes.settings.long_expiry)}</small></th>
                                          </tr>
                                          <tr className="text-center">
                                            <th width="3%">#</th>
                                            <th className="table-success" width="5%">Delta</th>
                                            <th className="table-success" width="5%">Mark IV</th>
                                            <th className="table-success" width="5%">Price</th>
                                            <th width="7%">Bid Qty</th>
                                            <th className="text-end" width="7%">Bid (Price / IV)</th>
                                            <th className="text-end" width="7%">Ask (Price / IV)</th>
                                            <th width="7%">Ask Qty</th>
                                            <th className="table-success" width="10%">Strike Price</th>
                                            <th width="7%">Bid Qty</th>
                                            <th className="text-end" width="7%">Bid (Price / IV)</th>
                                            <th className="text-end" width="7%">Ask (Price / IV)</th>
                                            <th width="7%">Ask Qty</th>
                                            <th width="3%">#</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {filteredCommonStrikes.puts && filteredCommonStrikes.puts.length ?
                                            filteredCommonStrikes.puts.map((strike, index, arr) => {
                                              return (
                                                <tr key={`puts_strike_${index}`} className="text-center">
                                                  <td><input type="radio" name="selected_strike" id={`flexRadioDefault_puts_${index}`} className="cursor-pointer form-check-input border-primary" value={index} checked={startBotParams.option === 'puts' && startBotParams.option_index_short === index && startBotParams.is_selected_short_puts == true} onChange={(e) => { setStartBotParams({ ...startBotParams,is_selected_short_puts:true, option_index_short: Number(e.target.value), 'strike_price_short': filteredCommonStrikes.puts[Number(e.target.value)].short.strike_price, 'short_expiry_symbol': filteredCommonStrikes.puts[Number(e.target.value)].short.symbol, 'short_expiry_product_id': filteredCommonStrikes.puts[Number(e.target.value)].short.product_id}) }} /></td>
                                                  <td className="table-success">{strike.delta_difference}</td>
                                                  <td className="table-success">{strike.mark_iv_difference}%</td>
                                                  <td className="table-success">{strike.price_difference}%</td>
                                                  <td>{Number(strike.short.quotes.bid_size) / 1000}</td>
                                                  <td className="text-end text-success">{Number(strike.short.quotes.best_bid).toFixed(1)} <span className="d-block text-secondary">{(Number(strike.short.quotes.bid_iv) * 100).toFixed(1)}%</span>
                                                  </td>
                                                  <td className="text-end text-danger">{Number(strike.short.quotes.best_ask).toFixed(1)} <span className="d-block text-secondary">{(Number(strike.short.quotes.ask_iv) * 100).toFixed(1)}%</span></td>
                                                  <td>{Number(strike.short.quotes.ask_size) / 1000}</td>
                                                  <td className="table-success">{strike.strike_price}</td>
                                                  <td>{Number(strike.long.quotes.bid_size) / 1000}</td>
                                                  <td className="text-end text-success">{Number(strike.long.quotes.best_bid).toFixed(1)} <span className="d-block text-secondary">{(Number(strike.long.quotes.bid_iv) * 100).toFixed(1)}%</span>
                                                  </td>
                                                  <td className="text-end text-danger">{Number(strike.long.quotes.best_ask).toFixed(1)}
                                                    <span className="d-block text-secondary">{(Number(strike.long.quotes.ask_iv) * 100).toFixed(1)}%</span>
                                                  </td>
                                                  <td>{Number(strike.long.quotes.ask_size) / 1000}</td>
                                                  <td><input type="radio" name="selected_strike_2" id={`flexRadioDefault_puts_2${index}`} className="cursor-pointer form-check-input border-primary" value={index} checked={startBotParams.option === 'puts' && startBotParams.option_index_long === index && startBotParams.is_selected_long_puts == true} onChange={(e) => { setStartBotParams({ ...startBotParams, is_selected_long_puts: true, option_index_long: Number(e.target.value), 'strike_price_long': filteredCommonStrikes.puts[Number(e.target.value)].short.strike_price,  'long_expiry_symbol': filteredCommonStrikes.puts[Number(e.target.value)].long.symbol, 'long_expiry_product_id': filteredCommonStrikes.puts[Number(e.target.value)].long.product_id }) }} /></td>
                                                </tr>
                                              )
                                            })
                                            :
                                            <tr className="text-center">
                                              <td colSpan="13">No strikes found</td>
                                            </tr>
                                          }
                                        </tbody>
                                      </Table>
                                    </div>
                                  </TabPane>
                                </TabContent>
                              </div>
                            </>
                            :
                            <>

                            </>
                          }
                        </TabPane>
                        <TabPane tabId={3}>
                          <div className="p-4 border">
                            <Form>
                              <Row>
                              <Col lg="4">
                                  <div className="mb-3">
                                    <Label for="quantity">
                                      Quantity
                                    </Label>{" "}<i className="bx bx-info-circle" id ="QTY3" />
                                          <UncontrolledTooltip placement="top" target="QTY3"> 
                                              {ToolTipData.quantity}
                                          </UncontrolledTooltip>
                                    <Slider value={botSettings.quantity}
                                      min={1} max={1000} step={1}
                                      onChange={(value) => setBotSettings({ ...botSettings, quantity: value })} 
                                    />

                                    <Input type="number" className="form-control" id="quantity"
                                      min="1" max="1000" step="1"
                                      value={botSettings.quantity}
                                      onChange={(e) => handleRangeInput(e, 'quantity')}
                                      onInput={(e) => handleRangeInput(e, 'quantity')}
                                    />
                                  </div>
                                </Col>
                               
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label for="hours_before_to_market_close">
                                      Hours before to market close
                                    </Label>{" "}<i className="bx bx-info-circle" id ="HBTMC" />
                                          <UncontrolledTooltip placement="top" target="HBTMC"> 
                                              {ToolTipData.hours_before_to_market_close}
                                          </UncontrolledTooltip>
                                    <Slider value={botSettings.hours_before_to_market_close}
                                      min={1} max={24} step={1}
                                      onChange={(value) => setBotSettings({ ...botSettings, hours_before_to_market_close: value })}
                                    />

                                    <Input type="number" className="form-control" id="hours_before_to_market_close"
                                      min="1" max="24" step="1"
                                      value={botSettings.hours_before_to_market_close }
                                      onChange={(e) => handleRangeInput(e, 'hours_before_to_market_close')}
                                      onInput={(e) => handleRangeInput(e, 'hours_before_to_market_close')}
                                    />
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label for="take_profit_percentage">
                                      Take Profit Percentage
                                    </Label>{" "}<i className="bx bx-info-circle" id ="TPP1" />
                                          <UncontrolledTooltip placement="top" target="TPP1"> 
                                              {ToolTipData.take_profit}
                                          </UncontrolledTooltip>
                                    <Slider value={botSettings.take_profit_percentage }
                                      min={0} max={100} step={0.5}
                                      onChange={(value) => setBotSettings({ ...botSettings, take_profit_percentage: value })}
                                      format={formatPercentage}
                                    />
                                    <div className="input-group">
                                      <Input type="number" className="form-control" id="take_profit_percentage"
                                         max="100" step="0.5"
                                        value={botSettings.take_profit_percentage}
                                        onChange={(e) => handleRangeInput(e, 'take_profit_percentage',false)}
                                        onInput={(e) => handleRangeInput(e, 'take_profit_percentage',false)}
                                      />
                                      <Label className="input-group-text" htmlFor="take_profit_percentage">%</Label>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                              <Col lg="4">
                                  <div className="mt-3">
                                    <Label htmlFor="fill_type">Fill type</Label>{" "}<i className="bx bx-info-circle" id ="filltype" />
                                          <UncontrolledTooltip placement="top" target="filltype"> 
                                              {ToolTipData.fill_type}
                                          </UncontrolledTooltip>
                                    <select id="fill_type" name="fill_type" className="form-select">
                                      {/* <option value="">Select fill type</option> */}
                                      <option selected={true} value="instant_fill">Instant fill</option>
                                      <option value="wait_and_fill">Wait & fill</option>
                                    </select>
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mt-3">
                                    <Label htmlFor="calendar_type">Calendar type</Label>{" "}<i className="bx bx-info-circle" id ="CT" />
                                          <UncontrolledTooltip placement="top" target="CT"> 
                                              {ToolTipData.calendar_type}
                                          </UncontrolledTooltip>
                                    <select id="calendar_type" name="calendar_type" className="form-select">
                                      {/* <option value="">Select calendar type</option> */}
                                      <option selected={true} value="short_calendar">Short Calendar</option>
                                      <option value="long_calendar">Long Calendar</option>
                                    </select>
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mt-3">
                                    <Label htmlFor="expiry_position_to_begin">Expiry position to enter at first</Label>{" "}<i className="bx bx-info-circle" id ="EPEF" />
                                          <UncontrolledTooltip placement="top" target="EPEF"> 
                                              {ToolTipData.expiry_position}
                                          </UncontrolledTooltip>
                                    <select id="expiry_position_to_begin" name="expiry_position_to_begin" className="form-select">
                                      {/* <option value="">Select expiry position to enter at first</option> */}
                                      <option selected={true} value="short_expiry_position">Short Expiry Position</option>
                                      <option value="long_expiry_position">Long Expiry Position</option>
                                    </select>
                                  </div>
                                </Col>
                                
                              </Row>
                              <Row className="mt-4">
                              <Col lg="4">
                                <FormGroup className="mb-4">
                                  <Label htmlFor="formrow-firstname-Input">
                                    Enable Delta Hedging
                                  {" "}
                                  <i className="bx bx-info-circle" id ="EDH" />
                                    <UncontrolledTooltip placement="top" target="EDH">
                                        {ToolTipData.delta_hedging}
                                    </UncontrolledTooltip>
                                    {
                                      botSettings && botSettings.delta_hedging_range && botSettings.delta_hedging_interval ?
                                    <div  className='font-size-10'>Your Settings<i className="mdi mdi-arrow-right
                                                                                      ms-1 text-primary "></i>
                                                                                                <span  className='text-secondary'>Delta Range: <span className='text-info'>{botSettings.delta_hedging_range}</span> , Interval time for delta checking:  <span className='text-info'>{timeFormat(botSettings.delta_hedging_interval)} </span> , Order Type: <span className='text-info'>{botSettings.delta_hedging_order_type?botSettings.delta_hedging_order_type:'market_order'}</span> , 
    Hedge only for the option with loss: <span className='text-info'>{botSettings.hedge_only_for_loss? 'Yes ('+botSettings.hedging_min_loss_value+' '+ (botSettings.hedging_min_loss_type=='percentage'?'%':'pnts')+')': 'No'}</span><Link to="/settings?diagonals"><i className="bx bx-edit-alt" style={{width:"15px"}}/></Link> </span>
                                                                                                </div> : ""
}
                                                                                                </Label>
                                    {/* <input type="checkbox" className="btn-check" id="btn-check-outlined"  onClick={(e)=> {setDeltahedging(e.target.checked)}}/><br></br>
                                      <label style={{width:"80px"}} className={"btn " + (Deltahedging === true ? "btn-outline-success" : "btn-outline-danger")} htmlFor="btn-check-outlined" >{Deltahedging === true ? "Disable" : "Enable"}</label><br></br> */}
                                      <Col md="2"> 
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"                                      
                                      onChange={(e) => {setDiagonalsDeltahedging(e),setBotSettings({ ...botSettings, deltahedging: e === true ? 1 : 0 })}} 
                                      checked={DiagonalsDeltahedging}
                                    />
                                    </Col>
                                </FormGroup>
                              </Col>
                              {
                                DiagonalsDeltahedging ? <>
                              <Col lg="4">
                                <FormGroup className="mb-4">
                                  <Label htmlFor="formrow-firstname-Input">
                                  Disable Delta Hedging on Stop Loss in One Leg
                                  </Label>{" "}
                                  <i className="bx bx-info-circle" id ="DHOSLIOL" />
                                    <UncontrolledTooltip placement="top" target="DHOSLIOL">
                                        {ToolTipData.delta_hedging_on_stop_loss_in_one_leg}
                                    </UncontrolledTooltip>
                                    {/* <input type="checkbox" className="btn-check" id="btn-check-outlined2"  onClick={(e)=> {setDeltahedging2(e.target.checked)}}/><br></br>
                                      <label style={{width:"80px"}} className={"btn " + (Deltahedging2 === true ? "btn-outline-success" : "btn-outline-danger")} htmlFor="btn-check-outlined2" >{Deltahedging2 === true ? "Disable" : "Enable"}</label><br></br> */}
                                      <Col md="2">
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"                                       
                                      onChange={(e) => {setDiagonalsDeltahedging2(e),setBotSettings({ ...botSettings, Delta_Hedging_on_Stop_Loss_in_One_Leg: e === true ? 1 : 0 })}} 
                                      checked={DiagonalsDeltahedging2}
                                    />
                                    </Col>
                                </FormGroup>
                              </Col>
                              </> : ''
                              }
                              </Row>
                            </Form>
                          </div>
                        </TabPane>
                        <TabPane tabId={4}>
                          <div className="p-4 border">
                            <Row>
                              <Col md="4">
                                <Card className="mini-stats-wid border">
                                  <CardBody>
                                    <div className="d-flex">
                                      <div className="flex-grow-1">
                                        <p className="text-muted fw-medium">
                                          Short Expiry
                                        </p>
                                        <h4 className="mb-0">{startBotParams.short_expiry_symbol}</h4>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                                <Card className="mini-stats-wid border">
                                  <CardBody>
                                    <div className="d-flex">
                                      <div className="flex-grow-1">
                                        <p className="text-muted fw-medium">
                                          Long Expiry
                                        </p>
                                        <h4 className="mb-0">{startBotParams.long_expiry_symbol}</h4>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                                <Card className="mini-stats-wid border">
                                  <CardBody>
                                    <div className="d-flex">
                                      <div className="flex-grow-1">
                                        <p className="text-muted fw-medium">
                                          Strike Price short
                                        </p>
                                        <h4 className="mb-0">{startBotParams.strike_price_short}</h4>
                                      </div>
                                      <div className="flex-grow-1">
                                        <p className="text-muted fw-medium">
                                          Strike Price long
                                        </p>
                                        <h4 className="mb-0">{startBotParams.strike_price_long}</h4>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                              <Col md="8">
                                <Card className="border">
                                  <CardBody>
                                    <CardTitle className="mb-3">
                                      Bot Settings
                                    </CardTitle>
                                    <div className="table-responsive">
                                      <Table className="table table-border mb-0 align-middle">
                                        <tbody>
                                          <tr>
                                            <td>Calendar Type : </td>
                                            <td>{getCalendarTypeName(false, startBotParams.calendar_type)}</td>
                                          </tr>
                                          <tr>
                                            <td>Expiry Position To Enter At First : </td>
                                            <td>{getFirstPositionName(false, startBotParams.expiry_position_to_begin)}</td>
                                          </tr>
                                          <tr>
                                            <td>Fill type : </td>
                                            <td>{getFillTypeName(false, startBotParams.fill_type)}</td>
                                          </tr>
                                          <tr>
                                            <td>Market Close Time (Hours before short expiry) :</td>
                                            <td>{startBotParams.hours_before_to_market_close}</td>
                                          </tr>
                                          <tr>
                                            <td>Take Profit Percentage :</td>
                                            <td>{startBotParams.take_profit_percentage}%</td>
                                          </tr>
                                          <tr>
                                            <td>Quantity :</td>
                                            <td>{startBotParams.quantity}</td>
                                          </tr>
                                          <tr>
                                            <td>Enable Delta Hedging :</td>
                                            <td>{startBotParams.deltahedging === 1 ? 'True' : 'False'  }</td>
                                          </tr>
                                          <tr>
                                            <td>Disable Delta Hedging on Stop Loss in One Leg :</td>
                                            <td>{startBotParams.Delta_Hedging_on_Stop_Loss_in_One_Leg === 1 ? 'True' : 'False' }</td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </div>
                        </TabPane>
                        <TabPane tabId={5}>
                          <div className="p-4 border">
                            <div className="row justify-content-center">
                              <Col lg="6">
                                <div className="text-center">
                                  <div className="mb-4">
                                    <i className="mdi mdi-check-circle-outline text-success display-4" />
                                  </div>
                                  <div>
                                    <h5>Diagonal Bot intitiated successfully</h5>
                                    {/* <p className="text-muted">
                              If several languages coalesce, the grammar
                              of the resulting
                            </p> */}
                                  </div>
                                </div>
                              </Col>
                            </div>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>                        
                    <div className="actions clearfix mt-4">
                      <ul className={formLoader ? "disabled" : ""}>
                        {activeTab !== 1 ?
                          <li
                            className={activeTab === 1 ? "previous disabled" : "previous"}
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                { !formLoader ? toggleTab(activeTab - 1) : "" }
                              }}
                            >
                              Previous
                            </Link>
                          </li>
                          : ""
                        }
                        {activeTab !== 5 ?
                          <li
                            className="next"
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                { !formLoader ? toggleTab(activeTab + 1) : "" }
                              }}
                            >
                              {formLoader ? <span><i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Processing...</span> : <span>{activeTab === 4 ? "Start Bot" : "Next"}</span>}
                            </Link>
                          </li>
                          : ""
                        }
                      </ul>
                    </div>
                  </div>
                  </AccordionBody>
                  </AccordionItem>
              </Accordion>    
              </CardBody>
            </Card>
            </Col>
            </Row>
        :''  
        }
        </Container>
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card className="box_4">
                <CardBody>
                  <h4 className="card-title mb-3">Orders</h4>
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeOrderTab === "1",
                        })}
                        onClick={() => {
                          toggleOrderTab("1");
                        }}
                      >
                        Active Diagonals
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeOrderTab === "2",
                        })}
                        onClick={() => {
                          toggleOrderTab("2");
                        }}
                      >
                        Completed Diagonals
                      </NavLink>
                    </NavItem>
                  </ul>

                  <TabContent activeTab={activeOrderTab} className="p-3">
                    <TabPane tabId="1" id="all-order">
                      <div className="table-responsive">
                        {/*********/}
                        <Table className={"table table-bordered table align-middle table-nowrap table-check "}>
                          <thead >
                            <tr>
                              <th>Diagonal</th>
                              <th width="10%">Order Expiry</th>
                              <th>Side</th>
                              <th>Symbol</th>
                              <th>Sell/Buy Price</th>
                              <th>Filled/Qty</th>
                              <th>Order ID</th>
                              <th>Status</th>
                              <th>LTP</th>
                              <th className="text-decoration-underline" >
                                <Tooltip
                                  placement="top"
                                  isOpen={ttop}
                                  target="TooltipTop"
                                  toggle={() => {
                                    setttop(!ttop);
                                  }}
                                >
                                  Sell order: Price - Lowest ask <br /> Buy order: Highest bid - Price
                                </Tooltip>
                                <span id="TooltipTop">UPNL</span>
                              </th>
                              <th>History</th>
                              <th className="text-center" colSpan={2}>Action</th>
                            </tr>
                          </thead>
                          { loading.Table_load === true ? 
                           (
                              <tbody>
                                  <tr>
                                      <td colSpan={13} style={{ textAlign: "center" }}>
                                          Loading...
                                      </td>
                                  </tr>
                              </tbody>
                          ) : 
                            activeDiagonals.length > 0 ? (
                            activeDiagonals.map((row, index) => {
                              let indexs = (index+1)%2
                              return (
                                <tbody key={index}>
                                  {row.map((order, index, arr) => {
                                    return (
                                      <tr style={{color:"#8a90a0"}}className={"trow-hover "+ (indexs === 0 ? 'table-light': '')} key={index}>
                                        {index === 0 ?
                                          <td rowSpan={row.length}>
                                            <div className="p-2">
                                              <b className="text-primary font-size-15">{formatUTCDate(order.order_settings.short_expiry)}</b>
                                              <div className="text-primary">
                                                Expiry:
                                                <span className="expiry-countdown font-size-11" >
                                                  <Countdown date={order.order_settings.market_close_time} renderer={counterRenderer} />
                                                </span>
                                              </div>
                                              {order.order_settings.calendar_type ? <div className="font-size-12 mt-1">{getCalendarTypeName(order)}</div> : ''}
                                              {order.order_settings.expiry_position_to_begin ? <div className="font-size-12">Start from: {getFirstPositionName(order)}</div> : ''}
                                              {order.order_settings.take_profit_percentage ? <div className="font-size-12">Take Profit: {order.order_settings.take_profit_percentage}%</div> : ''}
                                              {order.order_settings.deltahedging ? <div className="font-size-12">Enable Delta Hedging: {order.order_settings.deltahedging?'true':'false'}</div> : ''}
                                              {order.order_settings.deltahedging ? <div className="font-size-12">Disable Delta Hedging on Stop Loss in One Leg: {order.order_settings.Delta_Hedging_on_Stop_Loss_in_One_Leg?'true':'false'}</div> : ''}
                                              {/* {order.diagonal_total_pnl ? <div className="font-size-12" title={order.diagonal_total_pnl}>Total PNL is {order.diagonal_total_pnl.toFixed(2)} USDT</div> : ''} */}
                                              {order.diagonal_total_pnl ? <><div className="text-muted font-size-12" style={{float:"left"}}>Total UPNL : <text style={order.diagonal_total_pnl > 0 ? {color:"green"} : {color:"red"}}>{order.diagonal_total_pnl.toFixed(4)}</text> USD <text  className="text-primary">{order.diagonal_total_pnl_percentage?"("+order.diagonal_total_pnl_percentage.toFixed(2)+' %)':'-'}</text></div><br></br></> : ''}

                                              <div className="text-muted font-size-12 mt-2"><i className="far fa-calendar-alt text-primary me-1"></i>Created at: {getSettingsDateTimeFormat(order.created_at)}</div>
                                              <div className="text-muted font-size-10">{moment.utc(getMomentDateTimeFormat(order.created_at)).local().startOf('seconds').fromNow()}</div>
                                              
                                              {order.admin_bot ? <Badge pill color="primary" className="me-1">Admin Bot</Badge> : ''}
                                            </div>
                                          </td>
                                          : ''}
                                        <td>
                                          <div className="font-size-12">{formatUTCDate(order.expiry_date)}</div>
                                          <div className="">
                                            <span className="expiry-countdown font-size-11" >
                                              <Countdown date={order.expiry_date} renderer={counterRenderer} />
                                            </span>
                                          </div>
                                        </td>
                                        <td>
                                        {order.market_action_order ? 

                                        <Badge
                                            color={order.market_action_order.side === "buy" ? "success" : "danger"}
                                            style={{ "textTransform": "uppercase" }}
                                          >
                                            {order.market_action_order.side}
                                          </Badge>
                                          :

                                          <Badge
                                            color={order.direction === "buy" ? "success" : "danger"}
                                            style={{ "textTransform": "uppercase" }}
                                          >
                                            {order.direction}
                                          </Badge>
                                        }
                                        </td>
                                        <td>
                                        {order.market_action_order ?

                                          <a href={process.env.REACT_APP_DELTA_TRADE_LINK+'/BTC/'+order.market_action_order.product_symbol} target="_blank" rel="noreferrer" >
                                          <Badge color={order.market_action_order.side === "buy" ? "success" : "danger"}>
                                            {order.market_action_order.product_symbol}
                                          </Badge></a>

                                          :
                                          <a href={process.env.REACT_APP_DELTA_TRADE_LINK+'/BTC/'+order.symbol} target="_blank" rel="noreferrer" >
                                            <Badge color={order.direction === "buy" ? "success" : "danger"}>
                                              {order.symbol}
                                            </Badge></a>
                                        }
                                        </td>

                                        <td>
                                        {order.market_action_order ?
                                        <h6 className={order.market_action_order.side === "buy" ? "text-success" : "text-danger"}>{order.market_action_order.average_fill_price ? Number(order.market_action_order.average_fill_price).toFixed(2) : order.market_action_order.price ? Number(order.market_action_order.price).toFixed(2) : "..."}</h6>
                                        
                                        :
                                          <h6 className={order.direction === "buy" ? "text-success" : "text-danger"}>{order.average_fill_price ? Number(order.average_fill_price).toFixed(2) : order.price ? Number(order.price).toFixed(2) : "..."}</h6>
                                        }
                                          {order.highest_bid_now || order.lowest_ask_now ?
                                            <div>
                                              <span className="text-muted font-size-11">Bid: <span className="text-success">{order.highest_bid_now ? Number(order.highest_bid_now).toFixed(1) : '...'}</span></span>
                                              <span className="text-muted">, </span>
                                              <span className="text-muted font-size-11">Ask: <span className="text-danger">{order.lowest_ask_now ? Number(order.lowest_ask_now).toFixed(1) : '...'}</span></span>
                                            </div>
                                            : ""
                                          }
                                        </td>
                                        <td>{Number(order.filled_quantity).toFixed(0)}/{Number(order.quantity).toFixed(0)}</td>
                                        <td>
                                        {order.market_action_order ?

                                          order.market_action_order_id ? order.market_action_order_id : "Nil"
                                          :                                          
                                          order.order_id ? order.order_id : "Nil"
                                        }                                          
                                          </td>
                                        <td> <span dangerouslySetInnerHTML={{ __html: getOrderStatusNameStyle(order) }}></span></td>
                                        <td>{order.last_trade_price ? order.last_trade_price.toFixed(1) : '...'}</td>
                                        <td title={order.unrealized_pnl ? order.unrealized_pnl : ''}><small className={order.unrealized_pnl > 0 ?"text-success" : "text-danger"}>{order.unrealized_pnl ? CustomParseFloat(order.unrealized_pnl, 2) + ' USD' : '...'}</small></td>
                                        <td>
                                          <div className="d-flex justify-content-center">
                                            <a
                                              onClick={() =>
                                                getLogsBysymbol(order.profile.uid,order.dbid,order.symbol)
                                              }
                                              className="text-info"
                                              disabled={order.status.toLowerCase() === "pending" ? true : false}
                                            >
                                              <i className="mdi mdi-history font-size-18"></i>
                                            </a>
                                          </div>
                                        </td>
                                        {index === 0 ?
                                          order.diagonal_status.toLowerCase() === 'closed' || order.market_action ?
                                            <td className="align-middle" rowSpan={row.length}>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  justifyContent: "space-evenly",
                                                  alignItems: "center",
                                                  textAlign: "center"
                                                }}
                                              >
                                                {!order.market_action && !order.market_action_status ?
                                                  <button
                                                    type="button"
                                                    onClick={() =>
                                                      marketCloseDiagonal(order.diagonal_id)
                                                    }
                                                    className="btn btn-danger text-white"
                                                  >
                                                    Market Close
                                                  </button>
                                                  :
                                                  <span className="text-sm">Closing... <i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle"></i></span>
                                                }
                                              </div>
                                            </td>
                                            :
                                            <td className="align-middle" rowSpan={row.length}>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  justifyContent: "space-evenly",
                                                  alignItems: "center",
                                                  textAlign: "center"
                                                }}
                                              >
                                                {!order.is_stop_triggered ?
                                                  <button
                                                    type="button"
                                                    onClick={() =>
                                                      stopDiagonalBot(order.diagonal_id)
                                                    }
                                                    className="btn btn-danger btn-lg text-white"
                                                  >
                                                    Stop
                                                  </button>
                                                  :
                                                  <span className="text-sm">Closing... <i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle"></i></span>
                                                }
                                              </div>
                                            </td>
                                          : ''}
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              )
                            })
                          ) : (
                            <tbody>
                              <tr>
                                <td colSpan={13} style={{ textAlign: "center" }}>
                                  No orders found
                                </td>
                              </tr>
                            </tbody>
                          )}
                        </Table>
                        {/*********/}
                      </div>
                    </TabPane>
                    <TabPane tabId="2" id="processing">
                      <Card className="border">
                        <CardBody>
                          <Row>
                            <Col xxl={1} lg={6} className="d-flex align-items-center">
                              <h5><b>Filter</b></h5>
                            </Col>
                            <Col xxl={2} lg={6}>
                              <Label htmlFor="Created">Created date</Label>
                              <Flatpickr className="form-control d-block"
                                placeholder="Created Date"
                                options={{
                                  mode: "range",
                                  dateFormat: "d/m/Y",
                                  defaultDate: [filterStartDate, filterEndDate]
                                }}
                                onChange={handleCreatedDateChange}
                              />
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <div className="table-responsive">
                        {/*********/}
                        {filterStartDate && filterEndDate ? <p className="fw-bold">Showing data from {formatUTCDate(filterStartDate)} to {formatUTCDate(filterEndDate)}</p>:''}
                        <Table className={"table table-bordered table align-middle table-nowrap table-check " }>
                          <thead>
                            <tr>
                              <th>Diagonal</th>
                              <th width="10%">Order Expiry</th>
                              <th>Side</th>
                              <th>Symbol</th>
                              <th>Sold/Bought Price</th>
                              <th>Filled/Qty</th>
                              <th>Order ID</th>
                              <th>Status</th>
                              <th className="text-decoration-underline" >
                                <Tooltip
                                  placement="top"
                                  isOpen={ttop1}
                                  target="TooltipTop1"
                                  toggle={() => {
                                    setttop1(!ttop1);
                                  }}
                                >
                                  Booked PNL = sell price - buy price
                                </Tooltip>
                                <span id="TooltipTop1">Booked PNL</span>
                              </th>

                            </tr>
                          </thead>
                          { loading.Table_load === true ? 
                           (
                              <tbody>
                                  <tr>
                                      <td colSpan={13} style={{ textAlign: "center" }}>
                                          Loading...
                                      </td>
                                  </tr>
                              </tbody>
                          ) : completedDiagonals.length > 0 ? (
                            completedDiagonals.map((row, index) => {
                              let indexs = (index+1)%2
                              let PNL = 0;
                              {row.map((order2, index2, arr) => {
                                let booked_pnl2 = order2.booked_pnl ? order2.booked_pnl : 0 
                                if (index2 === 0){
                                    PNL = 0;
                                }
                                var pnl = parseFloat(order2.realized_pnl ? order2.realized_pnl  : booked_pnl2 !== '...' && booked_pnl2 !== 0 ? parseFloat(booked_pnl2).toFixed(4)  : 0)
                                PNL = PNL + pnl
                              })}
                              return (
                                <tbody key={index}>
                                  {row.map((order, index, arr) => {
                                    return (
                                      <tr style={{color:"#8a90a0"}}className={"trow-hover "+ (indexs === 0 ? 'table-light': '')} key={index}>
                                        {index === 0 ?
                                          <td rowSpan={row.length} style={{ "verticalAlign": "top" }}>
                                            <div className="p-2" style={{ "verticalAlign": "top" }}>
                                              <b className="text-primary font-size-15">{formatUTCDate(order.order_settings.short_expiry)}</b>
                                              {order.order_settings.calendar_type ? <div className="font-size-12 mt-1">{getCalendarTypeName(order)}</div> : ''}
                                              {order.order_settings.expiry_position_to_begin ? <div className="font-size-12">Start from: {getFirstPositionName(order)}</div> : ''}
                                              {order.order_settings.take_profit_percentage ? <div className="font-size-12">Take Profit: {order.order_settings.take_profit_percentage}%</div> : ''}
                                              {order.order_settings.deltahedging ? <div className="font-size-12">Enable Delta Hedging: {order.order_settings.deltahedging?'true':'false'}</div> : ''}
                                              {order.order_settings.deltahedging ? <div className="font-size-12">Disable Delta Hedging on Stop Loss in One Leg: {order.order_settings.Delta_Hedging_on_Stop_Loss_in_One_Leg?'true':'false'}</div> : ''}
                                              <div className="text-muted font-size-12" style={{float:"left"}}>Total PNL : <text style={PNL > 0 ? {color:"green"} : {color:"red"}}>{PNL.toFixed(4)}</text> USD</div><br></br>
                                              <div className="text-muted font-size-12 mt-2"><i className="far fa-calendar-alt text-primary me-1"></i>Created at: {getSettingsDateTimeFormat(order.created_at)}</div>
                                              {order.admin_bot ? <Badge pill color="primary" className="me-1">Admin Bot</Badge> : ''}
                                            </div>
                                          </td>
                                          : ''}
                                        <td style={{ "verticalAlign": "top" }}>
                                          <div className="font-size-12">{formatUTCDate(order.expiry_date)}</div>
                                          <div className="">
                                            <span className="expiry-countdown font-size-11" >
                                              <Countdown date={order.expiry_date} renderer={counterRenderer} />
                                            </span>
                                          </div>
                                        </td>
                                        <td style={{ "verticalAlign": "top" }}>
                                          <Badge
                                            color={order.direction === "buy" ? "success" : "danger"}
                                            style={{ "textTransform": "uppercase" }}
                                          >
                                            {order.direction}
                                          </Badge>
                                          <br></br>
                                          {order.market_action_order ? 
                                          <Badge
                                            color={order.market_action_order.side === "buy" ? "success" : "danger"}
                                            style={{ "textTransform": "uppercase" }}
                                          >
                                            {order.market_action_order.side}
                                          </Badge>
                                          :''}
                                        </td>
                                        <td style={{ "verticalAlign": "top" }}>
                                          <Badge color={order.direction === "buy" ? "success" : "danger"}>
                                            {order.symbol}
                                          </Badge>
                                          <br></br>
                                          {order.market_action_order ? 
                                          <Badge
                                            color={order.market_action_order.side === "buy" ? "success" : "danger"}
                                            style={{ "textTransform": "uppercase" }}
                                          >
                                            {order.market_action_order.product_symbol}
                                          </Badge>
                                          :''}
                                          <br></br>
                                          {order.market_action_trigger ?
                                            <div>
                                              <span className="text-muted font-size-10 ">{getMarketActionTriggerName(order)} </span>{order.index_price !== undefined ? <><br></br><span className="text-muted font-size-10 ">Closed Index Price : {order.index_price}</span> </> : ""}
                                            </div>
                                            : ""}
                                        </td>

                                        <td style={{ "verticalAlign": "top" }}>
                                          <h6 className={order.direction === "buy" ? "text-success" : "text-danger"}>
                                            {order.average_fill_price ? Number(order.average_fill_price).toFixed(2) : order.price ? Number(order.price).toFixed(2) : "..."}
                                          </h6>
                                          {order.market_action_order ? 
                                          <h6 className={order.market_action_order.side === "buy" ? "text-success" : "text-danger"}>
                                            {order.market_action_order.average_fill_price ? Number(order.market_action_order.average_fill_price).toFixed(2) : order.market_action_order.price ? Number(order.market_action_order.price).toFixed(2) : "..."}
                                          </h6>
                                          :''}
                                        </td>
                                        <td style={{ "verticalAlign": "top" }}>
                                          {Number(order.filled_quantity).toFixed(0)}/{Number(order.quantity).toFixed(0)}

                                          {/* <br></br>
                                          {order.market_action_order ?
                                           Number(order.filled_quantity).toFixed(0)+'/'+Number(order.quantity).toFixed(0)
                                            :''} */}
                                          </td>
                                        <td style={{ "verticalAlign": "top" }}>
                                          {order.order_id ? order.order_id : "Nil"}

                                          <br></br>
                                          {order.market_action_order ?
                                          order.market_action_order_id ? order.market_action_order_id : "Nil"
                                          :''}
                                        </td>
                                        <td style={{ "verticalAlign": "top" }}> 
                                          <span dangerouslySetInnerHTML={{ __html: getOrderStatusNameStyle(order) }}></span>
                                          
                                          {/* <br></br>
                                          {order.market_action_order ?
                                            <span dangerouslySetInnerHTML={{ __html: getOrderStatusNameStyle(order) }}></span>
                                            :''} */}
                                          </td>
                                        <td style={{ "verticalAlign": "top" }} title={order.booked_pnl ? order.booked_pnl : order.realized_pnl ? order.realized_pnl : ''}>
                                          <small className={order.booked_pnl > 0 ?"text-success" : "text-danger"}>{order.booked_pnl ? parseFloat(order.booked_pnl.toFixed(2)) + ' USD' : order.realized_pnl ? parseFloat(order.realized_pnl) + ' USD' : '...'}</small>
                                          {/* <br></br>
                                          {order.market_action_order ? 
                                            '...'
                                          :''} */}
                                          </td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              )
                            })
                          ) : (
                            <tbody>
                              <tr>
                                <td colSpan={13} style={{ "textAlign": "center" }}>
                                  No orders found
                                </td>
                              </tr>
                            </tbody>
                          )}
                        </Table>
                        {/*********/}
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <HistoryModal
          show={modalShow.show}
          onHide={() => setModalShow({ dbid: "", show: false })}
          data={orderHistoryData}
          symbolname={modalShow.symbol}
        />
      </div>
    </React.Fragment>
  )
}

export default Diagonals
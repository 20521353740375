import React, { useCallback, useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    Input,
    Label,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    CardHeader,
    CardText,
    CardTitle, Spinner, Alert, Table, Tooltip, Badge,
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Popover,
    PopoverHeader,
    PopoverBody,
    Button,
    Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import classnames from "classnames";
import { Link } from "react-router-dom";
import firebase from 'firebase/compat/app'
import "firebase/compat/firestore";
import 'firebase/auth';
import api from '../../services/api.service';
import Swal from 'sweetalert2';
import { formatUTCDate, getContractValue, formatUTCDateTime, getStrangleMarketAction, getOrderStatusStyle,getNewtrendOrderStatusStyle, getTrendmethodStyle, getPrices,getTrendPrices, counterRenderer, CustomParseFloat,getMomentDateTimeFormat, timeFormat, timeAgo,getSettingsDateTimeFormat,getUserTimeToUtcDateTime,getOrderLogs } from "../../services/common.service";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { toast } from 'react-toastify';
import Countdown from "react-countdown"
import HistoryModal from "../../components/Modal/HistoryModal";
import Layout from "store/layout/reducer";
import "react-rangeslider/lib/index.css"
import moment from "moment";
import {  map, round } from "lodash";
const ListOptions = (props) => {
    firebase.auth();
    const currentUser = JSON.parse(localStorage.getItem('user'));
    let uid = currentUser.uid;
    var firestoreDb = firebase.firestore().collection("users").doc(uid);
    const contractValue = getContractValue('BTCUSDT');
    const ordersRef = firestoreDb.collection("trend_orders").orderBy("created_at", "desc");
    const [activeOrderTab, setActiveOrderTab] = useState("1");
    //for settings
    const [orderData, setOrderData] = useState([]);
    const [transactionData, setTransactionData] = useState([]);
    const [FulltransactionData, setFullTransactionData] = useState([]);
    const [stopTrendFlag, setStopTrendFlag] = useState([]);
    const [stopTrendOrderFlag, setStopTrendOrderFlag] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [historyData, setHistoryData] = useState([]);
    const [ttop, setttop] = useState(false);
    const [ttop1, setttop1] = useState(false);
    const [trendbot, setTrendbot] = useState(false);
    const [popovertop, setpopovertop] = useState(false);
    const [lastsignal, setLastsignal] = useState([]);
    const [loading, setLoading] = useState({
        loading: false,
        Table_load : false
    });
    const [historyloading, setHistoryloading] = useState(false);
    const [settingloading, setSettingloading] = useState(false);
    const [totalPage, setTotalPage] = useState(1)
    const [page, setPage] = useState(1)
    //meta title
    document.title = "Trend Following";
    const popovertopchange = targetName => {
        if (!popovertop[targetName] || !popovertop[targetName].popover) {
            setpopovertop({
                ...popovertop,
                [targetName]: {
                    popover: true
                }
            });
        } else {
            setpopovertop({
                ...popovertop,
                [targetName]: {
                    popover: false
                }
            });
        }
    };

    const ispopoverOpen = targetName => {
        return popovertop[targetName] ? popovertop[targetName].popover : false;
    };

    const toggleOrderTab = tab => {
        setpopovertop({});
        if (activeOrderTab !== tab) {
            setActiveOrderTab(tab);
            if (tab == 2) {
                setFilterStartDate(defaultStartDate);
                setFilterEndDate(defaultEndDate);
            }
        }
    };
    const HandlePagetransactions = (page = 1) => {
        setTransactionData([]);
        let list = [];
        let startindex = ((page-1) * 50)
        let endindex = ((page) * 50)
        FulltransactionData.map((trans, index)=>{
            if (((index) >= startindex )&& (index+1 <=  (endindex-1))){
                list.push(trans)
            }
        })
        setTransactionData(list);
    }
    const handlePageClick = page => {
        setpopovertop(false)
        setPage(page)
        HandlePagetransactions(page);
      }

    const getOrders = useCallback(() => {
        setLoading({
            ...loading,
            Table_load : true
        })
        ordersRef.onSnapshot((querySnapshot) => {

            const items = [];
            querySnapshot.forEach((doc) => {
                let item = doc.data();
                item.dbid = doc.id;
                if ((item.status.toLowerCase() === "closed" || item.filled_quantity > 1) && item.direction === "sell") {
                    let sold_price = item.price;
                    if (item.direction === 'buy') {
                        sold_price = (item.average_fill_price) ?(item.average_fill_price): (item.sold_price) 
                    }

                }

                items.push(item)
            });

            //Combine orders by diagonal id
            const all_trends = items.reduce(function (row, obj) {
                row[obj.trend_signal_id] = row[obj.trend_signal_id] || [];
                row[obj.trend_signal_id].push(obj);
                return row;
            }, []);

            //Convert to array & Find diagonal status - To enable market close
            const active_trends = Object.keys(all_trends).map(trend_index => {
                const rows = all_trends[trend_index];
                if (stopTrendFlag[rows[0].trend_signal_id] && stopTrendFlag[rows[0].trend_signal_id] == 1 && rows[0].is_stop_triggered == 0 && rows[0].status.toLowerCase() !== 'cancelled') {
                    toast.error("Unable to stop the order, order may moved to position, please try again");
                    stopTrendFlag[rows[0].trend_signal_id] = 0;
                    setStopTrendFlag(stopTrendFlag);
                }
                const filled_order = rows.find(element => (element.direction.toLowerCase() === 'buy' && element.status.toLowerCase() === 'open') || (element.direction.toLowerCase() === 'sell' && (element.status.toLowerCase() === 'closed' || element.filled_quantity > 0)));

                const trend_status = (filled_order) ? "closed" : "open";
                return rows.map((row, index) => {
                    row.trend_status = trend_status;
                    return row
                });
            });
            setLoading({
                ...loading,
                Table_load : false
            })
            setOrderData(active_trends);

        });

    }, [ordersRef, contractValue]);


    //Completed Trends filter
    // const defaultStartDatemain = new Date(new Date()).setDate(new Date().getDate() - 1);
    // const defaultStartDate_hr=new Date(defaultStartDatemain).setHours(0, 0, 0, 0)
    // const defaultStartDate=new Date(defaultStartDate_hr).toISOString()
    // const defaultEndDatemain = new Date(new Date()).toISOString();
    // const defaultEndDate_hr=new Date(defaultEndDatemain).setHours(0, 0, 0, 0)
    // const defaultEndDate=new Date(defaultEndDate_hr).toISOString()
    // const [filterStartDate, setFilterStartDate] = useState(defaultStartDate);
    // const [filterEndDate, setFilterEndDate] = useState(defaultEndDate);

    const convert = (str) => {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }


    let defaultStartDate = new Date(convert(new Date().setDate(new Date().getDate() - 1)));
    let defaultEndDate = new Date(convert(new Date()));
    defaultStartDate.setHours(0, 0, 0, 0)
    defaultEndDate.setHours(23, 59, 59, 999)

    const [filterStartDate, setFilterStartDate] = useState(defaultStartDate.toISOString());
    const [filterEndDate, setFilterEndDate] = useState(defaultEndDate.toISOString());  
    
    //console.log('filterStartDate....',filterStartDate);
    //console.log('filterEndDate....',filterEndDate);
    

    const handleCreatedDateChange = (selectedDates, datestr) => {
        if (selectedDates && selectedDates.length) {
            let converted_date = new Date(convert(selectedDates[0]));
            converted_date.setHours(0, 0, 0, 0);
            setFilterStartDate(converted_date.toISOString());
            if (selectedDates[1]) {
                let converted_end_date = new Date(convert(selectedDates[1]));
                converted_end_date.setHours(23, 59, 59, 999);
                setFilterEndDate(converted_end_date.toISOString());
            }
        } else {
            setFilterStartDate(defaultStartDate);
            setFilterEndDate(defaultEndDate);
        }
    }

    const getTransactions = async () => {
       setHistoryloading(true)
        var body =  {
            uid : uid,
            filterStartDate : filterStartDate,
            filterEndDate : filterEndDate
          }
        await api({ contentType: true, auth: true }).post('/trendfollowing/trend-following-history-data',body).then(async(res)  => {
            var data = res.data.response.data;
            const items = [];
            data.forEach((doc) => {
                let item = doc.data;
                let sold_price = item.price;
                if (item.direction === 'buy') {
                    sold_price = (item.average_fill_price) ?(item.average_fill_price): (item.sold_price) ?  item.sold_price : item.price
                }
                items.push(item)
            });
            // const all_trends = items.reduce(function (row, obj) {
            //     row[obj.trend_signal_id] = row[obj.trend_signal_id] || [];
            //     row[obj.trend_signal_id].push(obj);
            //     return row;
            // }, []);
          
            // // const completed_trends = Object.keys(all_trends).map(trend_index => {
            // //     return all_trends[trend_index];
            // // });

            // const completed_trends = Object.values(all_trends).sort(function(a, b) {
            //     // Compare the updated_at fields for sorting
            //     return new Date(a[0].updated_at) - new Date(b[0].updated_at);
            // });
            let uniqueids = []
            const all_trends = items.reduce(function (row, obj) {
                uniqueids.push(obj.trend_signal_id)
                let indexval = uniqueids.indexOf(obj.trend_signal_id)
                row[indexval] = row[indexval] || [];
                row[indexval].push(obj);
                return row;
            }, []);
            // const completed_trends = Object.values(all_trends).sort(function(a, b) {
            //     const aLatestDate = new Date(Math.max(...a.map(item => new Date(item.updated_at))));
            //     const bLatestDate = new Date(Math.max(...b.map(item => new Date(item.updated_at))));
            //     return bLatestDate - aLatestDate; // Sort in descending order
            // });
          
            setFullTransactionData(all_trends);
            let Pagecount =  all_trends.length / 50
            let page = all_trends.length % 50
            if (page !== 0 && round(Pagecount) == parseInt(Pagecount)){
                setTotalPage(round(Pagecount) + 1)
            }else{
                setTotalPage(round(Pagecount))
            }
            if (Pagecount == 0 && page == 0){
                setTotalPage(1)
            }
            setHistoryloading(true)
        });
        HandlePagetransactions();
        setHistoryloading(false)
    }



    const marketCloseTrend = async (trendId, type) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to close the bot ?",
            icon: 'warning',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-secondary",
            reverseButtons: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (type == 'stop') {
                    stopTrendFlag[trendId] = 1;
                    setStopTrendFlag(stopTrendFlag);
                } else {
                    stopTrendFlag[trendId] = 2;
                    setStopTrendFlag(stopTrendFlag);
                }

                try {
                    const params = {
                        trend_signal_id: trendId,
                        type: type
                    };
                    await api({ contentType: true, auth: true }).post('/delta/market-close-trend', params).then((res) => {
                        if (res.data !== "") {
                            toast.error(res.data.message);
                        }
                    });
                } catch (e) {

                    toast.error(e.message);
                }
            }
        })
    };
    const marketCloseOrder= async (order) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to close the bot ?",
            icon: 'warning',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-secondary",
            reverseButtons: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                stopTrendOrderFlag[order.trend_signal_id]=[]
                stopTrendOrderFlag[order.trend_signal_id][order.symbol] = 1;
                setStopTrendOrderFlag(stopTrendOrderFlag);
                try {
                    let type=order.status.toLowerCase() === 'closed' ? 'market_close' : 'stop'
                    const params = {
                        trend_signal_id: order.trend_signal_id,
                        symbol:order.symbol,
                        type: type,
                        dbid:order.dbid
                    };
                    await api({ contentType: true, auth: true }).post('/delta/market-close-trend', params).then((res) => {
                        if (res.data !== "") {
                            toast.error(res.data.message);
                        }
                    });
                } catch (e) {

                    toast.error(e.message);
                }
            }
        })
    };
    const getHistoryBysymbol = async (uid,dbid,symbol) => {
        try {  
          var body =  {
            uid : uid,
            strategy : "trend",
            dbid : dbid
          }  
          let order_history= await getOrderLogs(body);
          if(order_history){
              setModalShow({
                dbid: symbol,
                show: true,
              });
              setHistoryData(order_history);
            } else {
                setHistoryData([]);
              setModalShow({
                dbid: symbol,
                show: true,
              });
            }
        } catch (err) {
          if (err.response) {
            toast.error(err.response.data.message);        
          } else if (err.message) {
            toast.error(err.message);         
          } else if (err.request) {
            //console.log(err.message);
          } else {        
            //console.log(err.message);
          }
        }
      };
    // const getHistoryBysymbol = async (symbol) => {
    //     try {

    //         await firestoreDb
    //             .collection("trend_orders_log")
    //             .doc(symbol)
    //             .get()
    //             .then(async (doc) => {
    //                 if (doc.exists) {

    //                     let order_history = [];

    //                     if (doc.data().order_history) {
    //                         order_history = doc.data().order_history;
    //                         order_history.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());
    //                     }

    //                     setModalShow({
    //                         symbol: symbol,
    //                         show: true,
    //                     });
    //                     setHistoryData(order_history);
    //                 } else {
    //                     setHistoryData([]);
    //                     setModalShow({
    //                         symbol: symbol,
    //                         show: true,
    //                     });
    //                 }
    //             });
    //     } catch (e) {
    //         //console.log("History symbol --: ", e);
    //     }
    // };
    const getSettings = async () => {
        setSettingloading(true)

        // var get_settings = await firestoreDb.collection("order-settings").doc('trend-following-settings-global');
        // get_settings.get().then((res) => {
        //     if (res.exists) {
        //         const site_settings = res.data();
        //         console.log("site_settings",site_settings)
        //         setTrendbot(site_settings.enable_trend_following)
        //     }
        //     setSettingloading(false);
        // });

        const get_trend_settings = await api({ contentType: true, auth: true }).get('/trendfollowing/getUserTrendSettings')      
        .then((res)=>{
        if(res.data.response && res.data.response.data.toString().length > 0)
        {
            const trend_settings = res.data.response.data;
            setTrendbot(trend_settings.enable_trend_following ? true : false) 
        }
        setSettingloading(false);
        })

        // var get_settings = await firestoreDb.collection("order-settings").doc('trend-following-settings-global');
        // get_settings.get().then((res) => {
        //     if (res.exists) {
        //         const site_settings = res.data();
        //         setTrendbot(site_settings.enable_trend_following)
        //     }
        //     setLoading({
        //         ...loading,
        //         setting: false,
        //     });
        // });

        // var get_last_signal = await firestoreDb.collection("trend_signals").orderBy("created_at", "desc").limit(1).get();
        // if (get_last_signal.docs.length > 0) {
        //     let lastsignal = get_last_signal.docs[0].data();
        //     console.log('lastsignallastsignal....',lastsignal);
        //     setLastsignal(lastsignal);
        // }

        let send_data = {uid : uid};
        await api({ contentType: true, auth: true }).post('/trendfollowing/getLastTrendSignal',send_data).then(async(res)  => {
            var data = res.data.response.data;
            setLastsignal(data);
        })


    }

    useEffect(() => {
        getOrders();
        
        setLoading({
            ...loading,
            Table_load : true
        })
        getSettings();
    }, []);
    useEffect(()=>{
        setPage(1)
        HandlePagetransactions();
    },[FulltransactionData])
    useEffect(() => {
        let mounted = true;
        if (mounted) {
            getTransactions();
        }
        return () => {
            // actions to be performed when component unmounts
            mounted = false;
        }
    }, [filterStartDate, filterEndDate]);

    const getTrendDay=(get_day)=>{
        let trend_day;
        if(get_day == 'D1')
        {
            trend_day = 'Today';
        }
        else if(get_day == 'D2')
        {
            trend_day = 'Tomorrow';
        } else if(get_day == 'D3')
        {
            trend_day = 'Day After Tomorrow';
        }
        else if(get_day == 'W1')
        {
            trend_day = 'Current Week Friday';
        }
        else if(get_day == 'W2')
        {
            trend_day = 'Next Week Friday';
        }
        else if(get_day == 'W3')
        {
            trend_day = 'Next to Next Week Friday';
        }
        else if(get_day == 'M1')
        {
            trend_day = 'Current Month End Friday';
        }
        else if(get_day == 'M2')
        {
            trend_day = 'Next Month End Friday';
        }
        else
        {
            trend_day = 'Today';
        }

        return trend_day;

    }

    return (
        <React.Fragment>
            <div className="page-content">

                <Container fluid>
                    <Breadcrumbs title="DeriSnap" breadcrumbItem="Trend Following" />
                    { /*!loading.setting ?
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col xs="12">
                                                <div className=" my-3">
                                                    {trendbot ? (
                                                        <Alert color="info" role="alert">
                                                            <i className="mdi mdi-block-helper me-2"></i>You can change the settings for Trend following strategy using <Link to="/settings?trend-following">Settings</Link>
                                                        </Alert>
                                                    ) :
                                                        <Alert color="danger" role="alert">
                                                            <i className="mdi mdi-block-helper me-2"></i>Trend Following strategy is in disabled status. You can enable it using <Link to="/settings?trend-following">Settings</Link>
                                                        </Alert>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                                                </Row> : '' */}
                    <Row>
                        <Col lg="12">
                            <Card className="box_4">
                                <CardBody>
                                    <Row>
                                        <Col lg="6">
                                            <h4 className="card-title mb-3" >Orders</h4></Col>
                                        {!settingloading ?
                                            <Col lg="6">
                                                {trendbot ? (
                                                    <span style={{ "float": "right" }} >
                                                        You can change the settings for Trend following strategy <a href="/settings?trend-following">here</a>
                                                    </span>
                                                ) :
                                                    <span style={{ "float": "right" }} className="text-danger">
                                                        Trend Following strategy is in disabled status. You can enable it <a href="/settings?trend-following">here</a>
                                                        <br />
                                                        {lastsignal && lastsignal.status == "open" && (
                                                            <span className="font-size-11 text-primary">  ({lastsignal.signal == 'calls' ? 'Downtrend' : 'Uptrend'} signal received {timeAgo(lastsignal.created_at)}.) {/*formatUTCDateTime(lastsignal.created_at)*/}</span>
                                                        )
                                                        } </span>
                                                }
                                            </Col>
                                            : ''}
                                    </Row>
                                    <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                                        <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active: activeOrderTab === "1",
                                                })}
                                                onClick={() => {
                                                    toggleOrderTab("1");
                                                }}
                                            >
                                                Active orders
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active: activeOrderTab === "2",
                                                })}
                                                onClick={() => {
                                                    toggleOrderTab("2");
                                                }}
                                            >
                                                Completed orders
                                            </NavLink>
                                        </NavItem>
                                    </ul>

                                    <TabContent activeTab={activeOrderTab} className="p-3">
                                        <TabPane tabId="1" id="all-order">
                                            <div className="table-responsive">
                                                {/*********/}
                                                <Table className={"table table-bordered table align-middle table-nowrap table-check "}>
                                                    <thead >
                                                        <tr>
                                                            <th>Expiry Date</th>
                                                            <th>Side</th>
                                                            <th>Symbol</th>
                                                            <th>Sell/Buy Price</th>
                                                            <th>SL / TSL price	</th>
                                                            <th>Qty</th>
                                                            <th>Order ID</th>
                                                            <th>Status</th>
                                                            <th>LTP</th>
                                                            <th className="text-decoration-underline" >
                                                                <Tooltip
                                                                    placement="top"
                                                                    isOpen={ttop}
                                                                    target="TooltipTop"
                                                                    toggle={() => {
                                                                        setttop(!ttop);
                                                                    }}
                                                                >
                                                                    Unrealised PNL = price - latest ask
                                                                </Tooltip>
                                                                <span id="TooltipTop">UPNL</span>
                                                            </th>
                                                            <th className="text-center" colSpan={2}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    { loading.Table_load === true ? 
                                                     (
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={13} style={{ textAlign: "center" }}>
                                                                    Loading...
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    ) : orderData.length > 0 ? (
                                                        orderData.map((row, index) => {
                                                            let indexs = (index+1)%2
                                                            return (
                                                                <tbody key={index}>
                                                                    {row.map((order, index, arr) => {
                                                                        let market_action = getStrangleMarketAction(order);
                                                                        order.site_settings = order.site_settings ? order.site_settings : order.settings
                                                                        let trend_method = order.site_settings.trend_method ? getTrendmethodStyle(order.site_settings.trend_method) : ''
                                                                        let trend_side=order.settings.org_trend_side && order.settings.org_trend_side?order.settings.org_trend_side:order.settings.trend_side;
                                                                        // trend_side=trend_side=="sell_only"?"sell":trend_side=="buy_only"?"buy":trend_side=="buy"?"Long":trend_side=="sell"?"Short":trend_side;
                                                                        return (
                                                                            <tr style={{color:"#8a90a0"}}className={"trow-hover "+ (indexs === 0 ? 'table-light': '')} key={index}>
                                                                                {index === 0 ?
                                                                                    <td className="p-2 " rowSpan={row.length}>
                                                                                        <div className="border p-2">
                                                                                            <b className="text-primary font-size-15">{formatUTCDate(order.expiry_date)}</b>
                                                                                            <div style={{ "float": "right" }}><a
                                                                                                id={"Popovertop-open-" + order.trend_signal_id}
                                                                                                color="secondary"
                                                                                                onClick={() => {
                                                                                                    popovertopchange(`open-${order.trend_signal_id}`);
                                                                                                }}
                                                                                            >
                                                                                                <i className="bx bxs-info-circle text-dark font-size-15"></i>
                                                                                            </a>
                                                                                                <Popover
                                                                                                    placement="right"
                                                                                                    isOpen={ispopoverOpen(`open-${order.trend_signal_id}`)}
                                                                                                    target={"Popovertop-open-" + order.trend_signal_id}
                                                                                                    toggle={() => {
                                                                                                        popovertopchange(`open-${order.trend_signal_id}`);
                                                                                                    }}
                                                                                                    style={{border:'none'}}
                                                                                                >
                                                                                                    <PopoverHeader style={{backgroundColor:"black",color:"white",borderBottomColor:"white"}}><b>Info</b></PopoverHeader>
                                                                                                    <PopoverBody style={{backgroundColor:"black",color:"white"}}>
                                                                                                        Index Distance: <b>{order.settings.index_distance && order.settings.index_distance.length !==  0? <>{order.settings.index_distance} {order.settings.index_distance_type == "points" ? 'Pts' : "%"}</> : '--' }</b> <br />
                                                                                                        Trend Expiry day : <b>{order.settings.trend_day && getTrendDay(order.settings.trend_day).length !== 0 ? getTrendDay(order.settings.trend_day) : '--'}</b> <br />
                                                                                                        Trend Side : <b>{trend_side}</b> <br />
                                                                                                        Strike Counts : <b>{order.settings.all_strike_count?order.settings.all_strike_count:order.settings.strike_count}</b> <br />
                                                                                                        Quantity :<b>{order.settings.all_qty?order.settings.all_qty:order.settings.quantity}</b> <br />
                                                                                                        Mid price% :<b>{order.settings.mid_price_percentage ? order.settings.mid_price_percentage : 0}</b> <br />
                                                                                                        Market Stop Loss%: <b>{order.settings.stop_loss_percentage && order.settings.stop_loss_percentage.length !== 0 ? order.settings.stop_loss_percentage : '--'}</b><br />
                                                                                                        Total quantity limit: <b>{order.settings.total_quantity_limit ? order.settings.total_quantity_limit : '--'}</b><br />
                                                                                                        Close Open Positions & Orders on Trend Change:  <b>{order.settings.close_old_positions_trend_change==1 ? 'true' : 'false'}</b><br />
                                                                                                        Close Open Orders on Trend Reversal:  <b>{order.settings.close_old_orders_only_open_orders==1 || order.settings.close_old_orders_only_open_orders==true ? 'true' : 'false'}</b><br />
                                                                                                        Waiting Time for entry:  <b>{order.settings.waitTimeForPrice ? timeFormat(order.settings.waitTimeForPrice) : '--'}</b><br />
                                                                                                        Waiting time after stop loss:  <b>{order.settings.waitTimeAfterStopLoss && timeFormat(order.settings.waitTimeAfterStopLoss).length !== 0 ? timeFormat(order.settings.waitTimeAfterStopLoss): '--'}</b><br />
                                                                                                        Waiting time for re-entries:  <b>{order.settings.waitTimeForReEntry && timeFormat(order.settings.waitTimeForReEntry).length !== 0 ? timeFormat(order.settings.waitTimeForReEntry) : '--'}</b><br />
                                                                                                        Minimum Take Profit%:  <b>{(order.settings.min_take_profit_percentage && order.settings.min_take_profit_percentage.length !== 0 ? order.settings.min_take_profit_percentage : '--')}</b><br />
                                                                                                        Trailing Take Profit%: <b> {(order.settings.trailing_take_profit_percentage && order.settings.trailing_take_profit_percentage.length !== 0 ? order.settings.trailing_take_profit_percentage : '--')}</b><br />
                                                                                                        Trend signal method: <b>{trend_method.name}</b>
                                                                                                        <br /> Maximum number of orders from same trend signal: <b>{order.settings.same_trend_no_of_trade && order.settings.same_trend_no_of_trade.length !== 0 ? order.settings.same_trend_no_of_trade : '--'}</b>
                                                                                                        <br />
                                                                                                        Order Interval for same trend signal: <b>{order.settings.same_trend_trade_interval_clock && (order.settings.same_trend_trade_interval_clock).length !== 0 ? (order.settings.same_trend_trade_interval_clock): '--'}</b>
                                                                                                        <br />
                                                                                                        {order.site_settings.trend_method == "sma" ?
                                                                                                            <>
                                                                                                                Time frame: <b>{order.site_settings.time_frame && order.site_settings.time_frame.length !== 0 ? order.site_settings.time_frame : '--'}</b><br />
                                                                                                                Short length: <b>{order.site_settings.short_term && order.site_settings.short_term.length !== 0 ? order.site_settings.short_term : '--'}</b><br />
                                                                                                                Long length: <b>{order.site_settings.long_term && order.site_settings.long_term.length !== 0 ? order.site_settings.long_term : '--'}</b><br />
                                                                                                            </> : order.site_settings.trend_method == "ema" ?
                                                                                                            <>
                                                                                                                Time frame: <b>{order.site_settings.ema_time_frame && order.site_settings.ema_time_frame.length !== 0 ? order.site_settings.ema_time_frame : '--'}</b><br />
                                                                                                                Short length: <b>{order.site_settings.ema_short_term && order.site_settings.ema_short_term.length !== 0 ? order.site_settings.ema_short_term : '--'}</b><br />
                                                                                                                Long length: <b>{order.site_settings.ema_long_term && order.site_settings.ema_long_term.length !== 0 ? order.site_settings.ema_long_term : '--'}</b><br />
                                                                                                            </> :order.site_settings.trend_method == "macd" ?
                                                                                                                <>
                                                                                                                    Time frame: <b>{order.site_settings.macd_time_frame && order.site_settings.macd_time_frame.length !== 0 ? order.site_settings.macd_time_frame : '--'}</b><br />
                                                                                                                     Short length: <b>{order.site_settings.macd_short_term && order.site_settings.macd_short_term.length !== 0 ? order.site_settings.macd_short_term : '--'}</b><br />
                                                                                                                     Long length: <b>{order.site_settings.macd_long_term && order.site_settings.macd_long_term.length !== 0 ? order.site_settings.macd_long_term : '--'}</b><br />
                                                                                                                     Signal length: <b>{order.site_settings.macd_signal_term && order.site_settings.macd_signal_term.length !== 0 ? order.site_settings.macd_signal_term : '--'}</b><br />
                                                                                                                </> : order.site_settings.trend_method == "supertrend" ?
                                                                                                                    <>
                                                                                                                         Time frame: <b>{order.site_settings.st_time_frame && order.site_settings.st_time_frame.length !== 0 ? order.site_settings.st_time_frame : '--'}</b><br />
                                                                                                                        ATR length: <b>{order.site_settings.st_atr_length && order.site_settings.st_atr_length.length !== 0 ? order.site_settings.st_atr_length: '--'}</b><br />
                                                                                                                        Factor: <b>{order.site_settings.st_factor !== 0 ? order.site_settings.st_factor: '--'}</b><br />
                                                                                                                        Minimum supertrend distance: <b>{order.site_settings.st_min_distance && order.site_settings.st_min_distance.length !== 0 ? order.site_settings.st_min_distance : '--'}</b><br />
                                                                                                                        Maximum supertrend distance:  <b>{order.site_settings.st_max_distance && order.site_settings.st_max_distance.length !== 0 ? order.site_settings.st_max_distance : '--'}</b>
                                                                                                                    </>
                                                                                                                    : <>
                                                                                                                        Time frame: <b>15m</b><br />
                                                                                                                        Short length: <b>10</b><br />
                                                                                                                        Long length: <b>100</b><br />
                                                                                                                    </>
                                                                                                        }
                                                                                                    </PopoverBody>
                                                                                                </Popover>
                                                                                            </div>
                                                                                            <div className="text-primary">Expiry: <span className="expiry-countdown font-size-11" ><Countdown date={order.expiry_date} renderer={counterRenderer} /></span></div>


                                                                                            {order.settings.current_index_price ? <div className="align-text-bottom created-date">Index Price: {order.settings.current_index_price}</div> : ''}
                                                                                            {order.settings.signal ? <div className="align-text-bottom created-date"><span title={trend_method.fullform}>{trend_method.name}</span> Signal:{order.settings.signal == 'puts' ? <><span className="text-success font-size-18" style={{ "verticalAlign": "middle" }}><i className="bx bx-caret-up"></i></span>Uptrend</> : <><span className="text-danger font-size-18" style={{ "verticalAlign": "middle" }}><i className="bx bx-caret-down"></i></span>Downtrend</>}</div> : ''}
                                                                                            <div className="text-muted font-size-12"><i className="far fa-calendar-alt text-primary me-1"></i>Created at: {getSettingsDateTimeFormat(order.settings.created_at)}</div>
                                                                                            <div className="text-muted font-size-10">{moment.utc(getMomentDateTimeFormat(order.settings.created_at)).local().startOf('seconds').fromNow()}</div>
                                                                                            {(order.settings.admin_trend_signal_id) ? <Badge pill color="warning" className="me-1">Admin Bot</Badge> : ''}

                                                                                        </div>
                                                                                    </td>
                                                                                    : ''}
                                                                                <td>
                                                                                    <Badge
                                                                                        color={order.direction === "buy" ? "success" : "danger"}
                                                                                        style={{ "textTransform": "uppercase" }}
                                                                                    >
                                                                                        {order.direction}
                                                                                    </Badge>
                                                                                    {/* {order.settings.trend_side && order.settings.trend_side !== '' && (order.settings.trend_side=="sell" || order.settings.trend_side=="buy") ?<div>
                                                                                    <span className="text-muted font-size-10 ">{trend_side}</span></div> :''} */}
                                                                                </td>
                                                                                <td>
                                                                                    <a href={process.env.REACT_APP_DELTA_TRADE_LINK + '/BTC/' + order.symbol} target="_blank" rel="noreferrer" >
                                                                                        <Badge color={order.direction === "buy" ? "success" : "danger"}>
                                                                                            {order.symbol}
                                                                                        </Badge>
                                                                                    </a>
                                                                                    {market_action != '' ?
                                                                                        <div>
                                                                                            <span className="text-danger font-size-11 "> {market_action} </span>
                                                                                        </div>
                                                                                        : ''}
                                                                                </td>

                                                                                <td>
                                                                                    <h6 className={order.direction === "buy" ? "text-success" : "text-danger"}>{(order.average_fill_price && order.direction!=="buy"  && order.status=='closed') ? Number(order.average_fill_price).toFixed(2) : order.price ? Number(order.price).toFixed(2) : "..."}</h6>
                                                                                    {order.highest_bid_now || order.lowest_ask_now ?
                                                                                        <div>
                                                                                            <span className="text-muted font-size-11">Bid: <span className="text-success">{order.highest_bid_now ? Number(order.highest_bid_now).toFixed(1) : '...'}</span></span>
                                                                                            <span className="text-muted">, </span>
                                                                                            <span className="text-muted font-size-11">Ask: <span className="text-danger">{order.lowest_ask_now ? Number(order.lowest_ask_now).toFixed(1) : '...'}</span></span>
                                                                                            <span className="text-muted">, </span>
                                                                                            <span className="text-muted font-size-11">Mark: <span className="text-primary">{order.mark_price ? Number(order.mark_price).toFixed(1) : '...'}</span></span>
                                                                                        </div>
                                                                                        : ""
                                                                                    }
                                                                                </td>
                                                                                <td><div className="text-muted text-success font-size-10">
                                                                                    <span className={(order.order_type.toLowerCase() == "trailing_stop_loss" || order.order_type.toLowerCase() == "stop_loss") && order.trailing_stop_loss_price && order.trailing_stop_loss_price > 0? "text-muted" : (order.order_type.toLowerCase() == "stop_loss" ? "text-danger" : 'text-muted')}><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Stop loss Price: {getTrendPrices(order, "stop_loss")}</span><br />
                                                                                    {order.trailing_stop_loss_price ? <span className={(order.order_type.toLowerCase() == "trailing_stop_loss" || order.order_type.toLowerCase() == "stop_loss") && order.trailing_stop_loss_price && order.trailing_stop_loss_price > 0 ? "text-primary" : 'text-muted'}><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Trailing Take Profit Price: {Number(order.trailing_stop_loss_price).toFixed(1)}</span> : ''}
                                                                                </div>
                                                                                </td>
                                                                                <td>{Number(order.filled_quantity).toFixed(0)}/{Number(order.settings.quantity).toFixed(0)}</td>
                                                                                <td>{order.order_id !== "" ? order.order_id : "Nil"}</td>
                                                                                {/* <td>{order.status.toLowerCase()==='open' ? 'Open Order' : order.status.toLowerCase()==='cancelled' ? 'Cancelled' : order.status.toLowerCase()==='closed' ? 'Open Position': order.status}</td> */}
                                                                                <td> <span dangerouslySetInnerHTML={{ __html: getNewtrendOrderStatusStyle(order,) }}></span></td>
                                                                                <td>{order.last_trade_price ? order.last_trade_price.toFixed(1) : '...'}</td>
                                                                                {/* <td title={order.unrealized_pnl_exact ? order.unrealized_pnl_exact : ''}><small>{order.unrealized_pnl} {order.unrealized_pnl_currency ? order.unrealized_pnl_currency : ''}</small></td> */}
                                                                                <td title={order.unrealized_pnl ? order.unrealized_pnl : ''}><small className={order.unrealized_pnl > 0 ?"text-success" : "text-danger"}>{order.unrealized_pnl && order.unrealized_pnl != '' ? CustomParseFloat(order.unrealized_pnl, 2) + ' USD' : '...'}</small></td>
                                                                                <td className="align-middle  ">
                                                                                    <div className="d-flex">
                                                                                        <a
                                                                                            onClick={() =>
                                                                                                getHistoryBysymbol(order.profile.uid,order.dbid,order.symbol)
                                                                                            }
                                                                                            className="text-info"
                                                                                            disabled={order.status.toLowerCase() === "waiting" ? true : false}
                                                                                        >
                                                                                            <i className="mdi mdi-history font-size-18"></i>
                                                                                        </a>
                                                                                        {stopTrendOrderFlag[order.trend_signal_id] && stopTrendOrderFlag[order.trend_signal_id][order.symbol] && stopTrendOrderFlag[order.trend_signal_id][order.symbol] == 1 ?
                                                                                            <a className="btn btn-sm">
                                                                                                <div className="spinner-border text-danger  spinner-border-sm" role="status">
                                                                                                    <span className="visually-hidden">Loading...</span>
                                                                                                </div>
                                                                                            </a>
                                                                                            : <a onClick={() =>
                                                                                                marketCloseOrder(order)
                                                                                            }
                                                                                                className="text-danger"
                                                                                            >
                                                                                                <i className="mdi mdi-delete font-size-18"></i>
                                                                                            </a>
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                                
                                                                                {/*console.log(stopTrendFlag[order.trend_signal_id])*/}
                                                                                {index === 0 ?
                                                                                    !stopTrendFlag[order.trend_signal_id] ?
                                                                                        order.status.toLowerCase() === 'closed' ?
                                                                                            <td className="align-middle  " rowSpan={row.length}>
                                                                                                <div
                                                                                                    style={{
                                                                                                        flexDirection: "row",
                                                                                                        justifyContent: "space-evenly",
                                                                                                        alignItems: "center",
                                                                                                        textAlign: "center"
                                                                                                    }}
                                                                                                >
                                                                                                    <div>
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            onClick={() =>
                                                                                                                marketCloseTrend(order.trend_signal_id, 'market_close')
                                                                                                            }
                                                                                                            className="btn btn-soft-danger btn-sm"
                                                                                                        >
                                                                                                            Market Close
                                                                                                        </button></div>

                                                                                                </div>
                                                                                            </td>
                                                                                            : stopTrendFlag[order.trend_signal_id] && stopTrendFlag[order.trend_signal_id] == 1 ? <td rowSpan={row.length}><div style={{ "color": "red" }}><Spinner animation="border" size="sm" variant="danger" />Stopping the order(s)..</div></td> : <td className="align-middle" rowSpan={row.length}>
                                                                                                <div
                                                                                                    style={{
                                                                                                        flexDirection: "row",
                                                                                                        justifyContent: "space-evenly",
                                                                                                        alignItems: "center",
                                                                                                        textAlign: "center"
                                                                                                    }}
                                                                                                >
                                                                                                    <div>
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            onClick={() =>
                                                                                                                marketCloseTrend(order.trend_signal_id, 'stop')
                                                                                                            }
                                                                                                            className="btn btn-danger btn-sm "
                                                                                                        >
                                                                                                            Stop
                                                                                                        </button>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </td>
                                                                                        : stopTrendFlag[order.trend_signal_id] && <td rowSpan={row.length}><div style={{ "color": "red" }}><Spinner animation="border" size="sm" variant="danger" /> Closing</div> 											</td>
                                                                                    : ''}
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            )
                                                        })
                                                    ) : (
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={13} style={{ textAlign: "center" }}>
                                                                    No orders found
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    )}
                                                </Table>
                                                {/*********/}
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="2" id="processing">
                                            <Card className="border">
                                                <CardBody>
                                                    <Row>
                                                        <Col xxl={1} lg={6} className="d-flex align-items-center">
                                                            <h5><b>Filter</b></h5>
                                                        </Col>
                                                        <Col xxl={2} lg={6}>
                                                            <Label htmlFor="Created">Created date</Label>
                                                            <Flatpickr className="form-control d-block"
                                                                placeholder="Created Date"
                                                                options={{
                                                                    mode: "range",
                                                                    dateFormat: "d/m/Y",
                                                                    defaultDate: [filterStartDate, filterEndDate]
                                                                }} 
                                                                onChange={handleCreatedDateChange}
                                                            />
                                                        </Col>
                                                        <Col   className="justify-content-end">
                                                           <Pagination count={1} className="pagination pagination-rounded justify-content-end mt-3 mb-4 pb-1">
                                                             <PaginationItem disabled={page === 1}>
                                                               <PaginationLink
                                                                 previous
                                                                 to="#"
                                                                 onClick={() => handlePageClick(page - 1)}
                                                               />
                                                             </PaginationItem>
                                                             <div className="d-flex align-items-center mx-1"><div className="avatar-xs">
                                                             <a className="avatar-title rounded-circle text-white font-size-12" style={{backgroundColor:"#556EE6"}}>
                                                                {page}
                                                             </a>
                                                             </div></div>
                                                             {/* {map(Array(totalPage), (item, i) => (
                                                               <PaginationItem active={i + 1 === page} key={i}>
                                                                 <PaginationLink
                                                                   onClick={() => handlePageClick(i + 1)}
                                                                   to="#"
                                                                 >
                                                                   {i + 1}
                                                                 </PaginationLink>
                                                               </PaginationItem>
                                                             ))} */}
                                                             <PaginationItem disabled={page === totalPage}>
                                                               <PaginationLink
                                                                 next
                                                                 to="#"
                                                                 onClick={() => handlePageClick(page + 1)}
                                                               />
                                                             </PaginationItem>
                                                           </Pagination>
                                                         </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                            <div className="table-responsive">
                                                {/*********/}
                                                {filterStartDate && filterEndDate ? <p className="fw-bold">Showing data from {formatUTCDate(filterStartDate)} to {formatUTCDate(filterEndDate)}</p>:''}
                                                <Table className={"table table-bordered table align-middle table-nowrap table-check "}>
                                                    <thead>
                                                        <tr>
                                                            <th>Expiry Date</th>
                                                            <th>Side</th>
                                                            <th>Symbol</th>
                                                            <th>Sold/Bought Price</th>
                                                            <th>SL / TSL price</th>
                                                            <th>Qty</th>
                                                            <th>Order ID</th>
                                                            <th>Status</th>
                                                            <th className="text-decoration-underline" >
                                                                <Tooltip
                                                                    placement="top"
                                                                    isOpen={ttop1}
                                                                    target="TooltipTop1"
                                                                    toggle={() => {
                                                                        setttop1(!ttop1);
                                                                    }}
                                                                >
                                                                    Booked PNL = sell price - buy price
                                                                </Tooltip>
                                                                <span id="TooltipTop1">Booked PNL</span>
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    { historyloading === true ? 
                                                     (
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={13} style={{ textAlign: "center" }}>
                                                                    Loading...
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    ) : transactionData.length > 0 ? (
                                                        transactionData.map((row, index) => {
                                                            let indexs = (index+1)%2
                                                            let PNL = 0;
                                                            {row.map((order2, index2, arr) => {
                                                              let booked_pnl2 = order2.booked_pnl ? getTrendPrices(order2, "booked_pnl") : "..."
                                                              if (index2 === 0){
                                                                  PNL = 0;
                                                              }
                                                              var pnl = parseFloat(order2.realized_pnl ? order2.realized_pnl  : booked_pnl2 !== '...' ? parseFloat(booked_pnl2).toFixed(4)  : 0)
                                                              PNL = PNL + pnl
                                                            })}
                                                            return (
                                                                <tbody key={index}>
                                                                    {row.map((order, index, arr) => {
                                                                        let booked_pnl = order.booked_pnl ? getTrendPrices(order, "booked_pnl") : "..."
                                                                        let market_action = getStrangleMarketAction(order);
                                                                        order.site_settings = order.site_settings ? order.site_settings : order.settings
                                                                        let trend_method = order.site_settings && order.site_settings.trend_method ? getTrendmethodStyle(order.site_settings.trend_method) : ''
                                                                        let trend_side=order.settings.org_trend_side && order.settings.org_trend_side?order.settings.org_trend_side:order.settings.trend_side;
                                                                        // trend_side=trend_side=="sell_only"?"sell":trend_side=="buy_only"?"buy":trend_side=="buy"?"Long":trend_side=="sell"?"Short":trend_side;
                                                                        
                                                                        return (
                                                                            <tr style={{color:"#8a90a0"}}className={"trow-hover "+ (indexs === 0 ? 'table-light': '')} key={index}>
                                                                                {index === 0 ?
                                                                                    <td className="p-2" style={{ "verticalAlign": "top" }} rowSpan={row.length} >
                                                                                        <div className="border p-2">
                                                                                            <b className="text-primary font-size-15">{formatUTCDate(order.expiry_date)}</b>
                                                                                            <div style={{ "float": "right" }}><a
                                                                                                id={"Popovertop-" + order.trend_signal_id}
                                                                                                color="secondary"
                                                                                                onClick={() => {
                                                                                                    popovertopchange(`${order.trend_signal_id}`);
                                                                                                }}
                                                                                            >
                                                                                                <i className="bx bxs-info-circle text-dark font-size-15"></i>
                                                                                            </a>
                                                                                                <Popover
                                                                                                    placement="right"
                                                                                                    isOpen={ispopoverOpen(`${order.trend_signal_id}`)}
                                                                                                    target={"Popovertop-" + order.trend_signal_id}
                                                                                                    toggle={() => {
                                                                                                        popovertopchange(`${order.trend_signal_id}`);
                                                                                                    }}
                                                                                                >
                                                                                                    <PopoverHeader style={{backgroundColor:"black",color:"white",borderBottomColor:"white"}}><b>Info</b></PopoverHeader>
                                                                                                    <PopoverBody style={{backgroundColor:"black",color:"white"}}>
                                                                                                    Index distance: <b>{order.settings.index_distance && order.settings.index_distance.length !==  0? <>{order.settings.index_distance} {order.settings.index_distance_type == "points" ? 'Pts' : "%"}</> : '--' }</b> <br />
                                                                                                        Trend Expiry Day : <b>{order.settings.trend_day && getTrendDay(order.settings.trend_day).length !== 0 ? getTrendDay(order.settings.trend_day) : '--'}</b> <br />
                                                                                                        Trend Side : <b>{trend_side}</b> <br />
                                                                                                        Strike Counts : <b>{order.settings.all_strike_count?order.settings.all_strike_count:order.settings.strike_count}</b> <br />
                                                                                                        Quantity : <b>{order.settings.all_qty?order.settings.all_qty:order.settings.quantity}</b> <br />
                                                                                                        Make Limit Orders x% from Mid/MarkPrice: <b>{order.settings.mid_price_percentage ? order.settings.mid_price_percentage : 0}</b> <br />
                                                                                                        Market Stop Loss%: <b>{order.settings.stop_loss_percentage}</b><br />
                                                                                                        Total quantity limit: <b>{order.settings.total_quantity_limit ? order.settings.total_quantity_limit : '-'}</b><br />
                                                                                                        Close Open Positions & Orders on Trend Change:  <b>{order.settings.close_old_positions_trend_change==1 ? 'true' : 'false'}</b><br />
                                                                                                        Close Open Orders on Trend Reversal:  <b>{order.settings.close_old_orders_only_open_orders==1 || order.settings.close_old_orders_only_open_orders==true ? 'true' : 'false'}</b><br />
                                                                                                        Waiting Time for entry:  <b>{order.settings.waitTimeForPrice ? timeFormat(order.settings.waitTimeForPrice) : '--'}</b><br />
                                                                                                        Waiting time after stop loss:  <b>{order.settings.waitTimeAfterStopLoss && timeFormat(order.settings.waitTimeAfterStopLoss).length !== 0 ? timeFormat(order.settings.waitTimeAfterStopLoss): '--'}</b><br />
                                                                                                        Waiting time for re-entries:  <b>{order.settings.waitTimeForReEntry &&  timeFormat(order.settings.waitTimeForReEntry).length !== 0 ? timeFormat(order.settings.waitTimeForReEntry) : '--'}</b><br />
                                                                                                        Minimum Take Profit%:  <b>{(order.settings.min_take_profit_percentage && order.settings.min_take_profit_percentage.length !== 0 ? order.settings.min_take_profit_percentage : '--')}</b><br />
                                                                                                        Trailing Take Profit%: <b> {(order.settings.trailing_take_profit_percentage && order.settings.trailing_take_profit_percentage.length !== 0 ? order.settings.trailing_take_profit_percentage : '--')}</b><br />
                                                                                                        Trend signal method: <b>{trend_method.name && trend_method.name.length !== 0 ? trend_method.name : '--'}</b>
                                                                                                        <br /> Maximum number of orders from same trend signal: <b>{order.settings.same_trend_no_of_trade && order.settings.same_trend_no_of_trade.length !== 0 ? order.settings.same_trend_no_of_trade : '--'}</b>
                                                                                                        <br />
                                                                                                        Order Interval for same trend signal: <b>{order.settings.same_trend_trade_interval_clock && (order.settings.same_trend_trade_interval_clock).length !== 0 ? (order.settings.same_trend_trade_interval_clock): '--'}</b>
                                                                                                        <br />
                                                                                                        {order.site_settings && order.site_settings.trend_method == "sma" ?
                                                                                                            <>
                                                                                                                 Time frame: <b>{order.site_settings.time_frame && order.site_settings.time_frame.length !== 0 ? order.site_settings.time_frame : '--'}</b><br />
                                                                                                                Short length: <b>{order.site_settings.short_term && order.site_settings.short_term.length !== 0 ? order.site_settings.short_term : '--'}</b><br />
                                                                                                                Long length: <b>{order.site_settings.long_term && order.site_settings.long_term.length !== 0 ? order.site_settings.long_term : '--'}</b><br />
                                                                                                            </> : order.site_settings.trend_method == "ema" ?
                                                                                                            <>
                                                                                                                Time frame: <b>{order.site_settings.ema_time_frame && order.site_settings.ema_time_frame.length !== 0 ? order.site_settings.ema_time_frame : '--'}</b><br />
                                                                                                                Short length: <b>{order.site_settings.ema_short_term && order.site_settings.ema_short_term.length !== 0 ? order.site_settings.ema_short_term : '--'}</b><br />
                                                                                                                Long length: <b>{order.site_settings.ema_long_term && order.site_settings.ema_long_term.length !== 0 ? order.site_settings.ema_long_term : '--'}</b><br />
                                                                                                            </>: order.site_settings && order.site_settings.trend_method == "macd" ?
                                                                                                                <>
                                                                                                                    Time frame: <b>{order.site_settings.macd_time_frame && order.site_settings.macd_time_frame.length !== 0 ? order.site_settings.macd_time_frame : '--'}</b><br />
                                                                                                                     Short length: <b>{order.site_settings.macd_short_term && order.site_settings.macd_short_term.length !== 0 ? order.site_settings.macd_short_term : '--'}</b><br />
                                                                                                                     Long length: <b>{order.site_settings.macd_long_term && order.site_settings.macd_long_term.length !== 0 ? order.site_settings.macd_long_term : '--'}</b><br />
                                                                                                                     Signal length: <b>{order.site_settings.macd_signal_term && order.site_settings.macd_signal_term.length !== 0 ? order.site_settings.macd_signal_term : '--'}</b><br />
                                                                                                                </> : order.site_settings && order.site_settings.trend_method == "supertrend" ?
                                                                                                                    <>
                                                                                                                         Time frame: <b>{order.site_settings.st_time_frame && order.site_settings.st_time_frame.length !== 0 ? order.site_settings.st_time_frame : '--'}</b><br />
                                                                                                                        ATR length: <b>{order.site_settings.st_atr_length && order.site_settings.st_atr_length.length !== 0 ? order.site_settings.st_atr_length: '--'}</b><br />
                                                                                                                        Factor: <b>{order.site_settings.st_factor !== 0 ? order.site_settings.st_factor: '--'}</b><br />
                                                                                                                        Minimum supertrend distance: <b>{order.site_settings.st_min_distance && order.site_settings.st_min_distance.length !== 0 ? order.site_settings.st_min_distance : '--'}</b><br />
                                                                                                                        Maximum supertrend distance:  <b>{order.site_settings.st_max_distance && order.site_settings.st_max_distance.length !== 0 ? order.site_settings.st_max_distance : '--'}</b>
                                                                                                                    </>
                                                                                                                    : <>
                                                                                                                        Time frame: <b>15m</b><br />
                                                                                                                        Short length: <b>10</b><br /> 
                                                                                                                        Long length: <b>100</b><br />
                                                                                                                    </>
                                                                                                        }

                                                                                                    </PopoverBody>
                                                                                                </Popover>
                                                                                            </div>
                                                                                            <div className="text-primary">Expiry: <span className="expiry-countdown font-size-11" ><Countdown date={order.expiry_date} renderer={counterRenderer} /></span></div>


                                                                                            {order.settings.current_index_price ? <div className="align-text-bottom created-date">Index Price: {order.settings.current_index_price}</div> : ''}
                                                                                            {order.settings.signal ? <div className="align-text-bottom created-date"><span title={trend_method.fullform}>{trend_method.name}</span> Signal:{order.settings.signal == 'puts' ? <><span className="text-success font-size-18" style={{ "verticalAlign": "middle" }}><i className="bx bx-caret-up"></i></span>Uptrend</> : <><span className="text-danger font-size-18" style={{ "verticalAlign": "middle" }}><i className="bx bx-caret-down"></i></span>Downtrend</>}</div> : ''}
                                                                                            <div className="text-muted font-size-12" style={{float:"left"}}>Total PNL : <text style={PNL > 0 ? {color:"green"} : {color:"red"}}>{PNL.toFixed(4)}</text> USD</div><br></br>
                                                                                            <div className="text-muted font-size-12"><i className="far fa-calendar-alt text-primary me-1"></i>Created at: {getSettingsDateTimeFormat(order.settings.created_at)}</div>
                                                                                            {(order.settings.admin_trend_signal_id) ? <Badge pill color="warning" className="me-1">Admin Bot</Badge> : ''}

                                                                                        </div>
                                                                                    </td>
                                                                                    : ''}
                                                                                <td style={{ "verticalAlign": "top" }}>
                                                                                    <Badge
                                                                                        color={order.direction === "buy" ? "success" : "danger"}
                                                                                        style={{ "textTransform": "uppercase" }}
                                                                                    >
                                                                                        {order.direction}
                                                                                    </Badge>
                                                                                    {/* {order.settings.trend_side && order.settings.trend_side !== '' && (order.settings.trend_side=="sell" || order.settings.trend_side=="buy") ?<div>
                                                                                    <span className="text-muted font-size-10 ">{trend_side}</span></div> :''
                                                                                    } */}
                                                                                </td>
                                                                                <td style={{ "verticalAlign": "top" }}>
                                                                                    <a href={process.env.REACT_APP_DELTA_TRADE_LINK + '/BTC/' + order.symbol} target="_blank" rel="noreferrer" >
                                                                                        <Badge color={order.direction === "buy" ? "success" : "danger"}>
                                                                                            {order.symbol}
                                                                                        </Badge>
                                                                                    </a>
                                                                                    {market_action != '' ?
                                                                                        <div>
                                                                                            <span className="text-muted font-size-10 ">{market_action} </span>{order.index_price !== undefined ? <><br></br><span className="text-muted font-size-10 ">Closed Index Price : {order.index_price}</span> </> : ""}
                                                                                        </div>
                                                                                        : ''}
                                                                                </td>

                                                                                {/* className={highlighting && GetModifiOrderId == order.order_id && order.status.toLowerCase() === "open" ? 'element highlight':'element'} */}
                                                                                {/* <td><h6 className={order.direction === "buy" ? "text-success" : "text-danger"}>{order.sold_price && order.direction !== "buy" ? Number(order.sold_price).toFixed(2) : Number(order.price).toFixed(2)}</h6></td> */}
                                                                                <td style={{ "verticalAlign": "top" }}><h6 className={order.direction === "buy" ? "text-success" : "text-danger"}>{order.order_type == 'base' ? order.average_fill_price ?  Number(order.average_fill_price).toFixed(2) : order.sold_price ?  Number(order.sold_price).toFixed(2) : Number(order.price).toFixed(2)  : order.average_fill_buy_price ?  Number(order.average_fill_buy_price).toFixed(2) : Number(order.price).toFixed(2)  }</h6></td>
                                                                                <td style={{ "verticalAlign": "top" }}>
                                                                                    <div className="text-muted text-success font-size-10">
                                                                                        <span className={(order.order_type.toLowerCase() == "trailing_stop_loss" || order.order_type.toLowerCase() == "stop_loss") && order.trailing_stop_loss_price && order.trailing_stop_loss_price > 0? "text-muted" : (order.order_type.toLowerCase() == "stop_loss" ? "text-danger" : 'text-muted')}><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Stop loss Price: {getTrendPrices(order, "stop_loss")}</span><br />

                                                                                        {order.trailing_stop_loss_price ? <span className={(order.order_type.toLowerCase() == "trailing_stop_loss" || order.order_type.toLowerCase() == "stop_loss") && order.trailing_stop_loss_price && order.trailing_stop_loss_price > 0 ? "text-primary" : 'text-muted'}><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Trailing Take Profit Price: {Number(order.trailing_stop_loss_price).toFixed(1)}</span> : ''}
                                                                                    </div>
                                                                                </td>
                                                                                <td style={{ "verticalAlign": "top" }}>{Number(order.filled_quantity).toFixed(0)}/{Number(order.settings.quantity).toFixed(0)}</td>
                                                                                <td style={{ "verticalAlign": "top" }}>{order.order_id !== "" ? order.order_id : "Nil"}</td>
                                                                                <td style={{ "verticalAlign": "top" }}><span dangerouslySetInnerHTML={{ __html: getNewtrendOrderStatusStyle(order, "history") }}></span></td>
                                                                                {/* <td>{order.status}</td> */}
                                                                                <td style={{ "verticalAlign": "top" }}><small title={order.realized_pnl ? order.realized_pnl : booked_pnl !== '...' ? booked_pnl : ''} className={order.realized_pnl ? (order.realized_pnl > 0 ?"text-success" : "text-danger") : (parseFloat(booked_pnl) > 0 ?"text-success" : "text-danger")}>{order.realized_pnl ? order.realized_pnl + ' USD' : booked_pnl !== '...' ? parseFloat(booked_pnl).toFixed(4) + ' USD' : '...'}</small></td>


                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            )
                                                        })
                                                    ) : (
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={13} style={{ "textAlign": "center" }}>
                                                                    No orders found
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    )}
                                                </Table>
                                                {/*********/}
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/*Listing orders */}
            <div className="page-content">

            </div>
            <HistoryModal
                show={modalShow.show}
                onHide={() => setModalShow({ dbid: "", show: false })}
                data={historyData}
                symbolname={modalShow.dbid}
            />
        </React.Fragment>
    );
}
export default ListOptions;
